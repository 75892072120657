import { CORE_ERROR_CODE } from '@bugbug/core/constants/errors';
import { prop } from 'ramda';
import { all, put, takeLatest, delay, take, race, call } from 'redux-saga/effects';

import { WebsocketActions } from '~/modules/websocket/websocket.redux';
import toasts from '~/services/toasts';

import { HEARTBEAT_INTERVAL } from './core.constants';
import { CoreTypes } from './core.redux';

export function* handleError({ data = {} }) {
  switch (prop('errorCode', data)) {
    case CORE_ERROR_CODE.EXTENSION_DISCONNECTED: {
      yield call(toasts.core.showExtensionDisconnected);
      break;
    }
    case CORE_ERROR_CODE.DISCONNECTED_OR_CORS: {
      yield call(toasts.core.showDisconnectedOrCorsError);
      break;
    }
    default: {
      yield call(toasts.core.showInternalError);
    }
  }
}

export function* startHeartbeatRequested() {
  let isActive = true;
  while (isActive) {
    const { stopped } = yield race({
      delayed: delay(HEARTBEAT_INTERVAL),
      stopped: take(CoreTypes.STOP_HEARTBEAT_REQUESTED),
    });

    if (stopped) {
      isActive = false;
    }

    yield put(WebsocketActions.sendRequested(CoreTypes.PING, {}));
  }
}

export default function* coreSagas() {
  yield all([
    yield takeLatest(CoreTypes.ERROR, handleError),
    yield takeLatest(CoreTypes.START_HEARTBEAT_REQUESTED, startHeartbeatRequested),
  ]);
}
