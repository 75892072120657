import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import localStorage from '~/services/localStorage';

import * as S from './MoreSection.styled';

export const STORAGE_MORE_SECTION_EXPANDED = 'moreSectionExpanded';

interface MoreSectionProps {
  className?: string;
  children: React.ReactNode;
  storageKey?: string;
  showButtonText?: string;
  hideButtonText?: string;
  defaultExpanded?: boolean;
}

const MoreSection = (props: MoreSectionProps) => {
  const { t } = useTranslation();
  const {
    className,
    children,
    storageKey = STORAGE_MORE_SECTION_EXPANDED,
    defaultExpanded = true,
    showButtonText = t('moreSection.toggleButton.show', 'Show more'),
    hideButtonText = t('moreSection.toggleButton.hide', 'Show less'),
  } = props;
  const defaultValue = localStorage.getUserItem(storageKey);
  const [isExpanded, setIsExpanded] = useState(defaultValue ?? defaultExpanded);

  const handleToggleClick = useCallback(() => {
    localStorage.setUserItem(storageKey, !isExpanded);
    setIsExpanded(!isExpanded);
  }, [isExpanded, storageKey]);

  return (
    <S.Container className={className} aria-expanded={isExpanded}>
      <S.ToggleButton onClick={handleToggleClick}>
        <S.Icon name="expanded" />
        {isExpanded ? hideButtonText : showButtonText}
      </S.ToggleButton>
      {isExpanded && <S.Content>{children}</S.Content>}
    </S.Container>
  );
};

export default MoreSection;
