import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteActions } from '~/modules/suite/suite.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

const DeleteSuitesModal = ({ className, suitesIds, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = () => {
    onDelete();
    modal.hide();
  };

  const removeSelectedRequest = useActionState(SuiteActions.removeSelectedRequest, {
    reset: false,
    onSuccess: handleSuccess,
  });
  const removeAllRequest = useActionState(SuiteActions.removeAllRequest, {
    reset: false,
    onSuccess: handleSuccess,
  });

  useEffect(
    () => () => {
      removeSelectedRequest.reset();
      removeAllRequest.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDeleteConfirm = useCallback(() => {
    if (suitesIds && suitesIds.length) {
      dispatch(SuiteActions.removeSelectedRequest(suitesIds));
    } else {
      dispatch(SuiteActions.removeAllRequest());
    }
    analytics.trackEvent(
      suitesIds?.length === 1 ? TRACK_EVENT_TYPE.DELETE_SUITE : TRACK_EVENT_TYPE.BULK_DELETE_SUITES,
    );
  }, [dispatch, suitesIds]);

  const title = suitesIds
    ? t('deleteSuitesModal.selected.title', {
        defaultValue_one: 'Do you want to remove this suite?',
        defaultValue_other: 'Do you want to remove these suites?',
        count: suitesIds.length,
      })
    : t('deleteSuitesModal.all.title', 'Do you want to remove all suites?');

  return (
    <ConfirmModal
      hasInternalServerError={
        removeSelectedRequest.hasInternalServerError || removeAllRequest.hasInternalServerError
      }
      className={className}
      title={title}
      pending={removeAllRequest.isLoading || removeSelectedRequest.isLoading}
      onConfirm={handleDeleteConfirm}
      onCancel={modal.hide}
    />
  );
};

DeleteSuitesModal.defaultProps = {
  className: null,
  suitesIds: null,
  onDelete: Function.prototype,
};

DeleteSuitesModal.propTypes = {
  className: PropTypes.string,
  suitesIds: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
};

export default DeleteSuitesModal;
