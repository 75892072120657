import type { NativeIntegration } from '@bugbug/core/types/integrations';
import i18n from '~/translations';

import { GitHubDetails } from './GitHubDetails';
import { SlackDetails } from './SlackDetails';
import { TeamsDetails } from './TeamsDetails';

export const INTEGRATION_DETAILS: Record<
  NativeIntegration,
  {
    Details: React.FC;
    proceedText: string;
  }
> = {
  slack: {
    Details: SlackDetails,
    proceedText: i18n.t('integrationModal.details.slack.proceedButton', 'Connect'),
  },
  github: {
    Details: GitHubDetails,
    proceedText: i18n.t('integrationModal.details.github.proceedButton', 'Open GitHub Action'),
  },
  teams: {
    Details: TeamsDetails,
    proceedText: i18n.t('integrationModal.details.teams.proceedButton', 'Open Teams Marketplace'),
  },
};
