import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPE } from '~/components/modals';
import { Header, Footer, InfoIcon } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import { Container, Content } from './UpdateAvailableModal.styled';

const TIME_TO_RETRY_AFTER_CANCEL = 30 * 1000;

const UpdateAvailableModal = () => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleUpdateConfirm = useCallback(() => {
    window.location.reload();
  }, []);

  const handleCancel = useCallback(() => {
    modal.hide();
    setTimeout(() => {
      modal.show(MODAL_TYPE.UPDATE_AVAILABLE);
    }, TIME_TO_RETRY_AFTER_CANCEL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.hide]);

  return (
    <Container>
      <Content>
        <InfoIcon />
        <div>
          <Header>{t('updateAvailableModal.title', 'BugBug web app needs updating')}</Header>
          <p>
            {t(
              'updateAvailableModal.description',
              "You haven't refreshed the page for a while, and in the meantime we have released a new version of the BugBug web application. Please refresh the page to load the latest version.",
            )}
          </p>
        </div>
      </Content>
      <Footer>
        <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleCancel} bordered>
          {t('updateAvailableModal.cancel', 'Not now')}
        </Button>
        <ActionButton
          onClick={handleUpdateConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          iconName="refresh"
        >
          {t('updateAvailableModal.refresh', 'Refresh')}
        </ActionButton>
      </Footer>
    </Container>
  );
};
export default UpdateAvailableModal;
