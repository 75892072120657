import { createContext, useContext } from 'react';

import type { PrintContent } from './PrintableDocument.types';

interface PrintContext {
  print: () => void;
  setContent: (content: PrintContent) => void;
}

const PrintContext = createContext<PrintContext>({
  print: () => {
    throw new Error('Not implemented');
  },
  setContent: () => {
    throw new Error('Not implemented');
  },
});

export const usePrint = () => useContext(PrintContext);

export const PrintContextProvider = PrintContext.Provider;

export default PrintContext;
