import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgGoToBrowser = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-go-to-browser" }),
  /* @__PURE__ */ jsx("g", { id: "abb89944-c9ff-473b-9a4d-f34f699b9078", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "e32bd1b6-fab2-4f87-8112-bcf2b1cc7bf6", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("rect", { x: 5, y: 6, width: 22, height: 20, rx: 1, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 5, y1: 10, x2: 27, y2: 10, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("line", { x1: 17, y1: 21, x2: 21, y2: 21, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("polyline", { points: "11 15 14 18 11 21", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgGoToBrowser);
export default ForwardRef;
