import Button, { ActionButton } from '@bugbug/core/components/Button';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';
import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useDeleteComponentMutation } from '~/modules/components';

import RelatedTestsGuard from './components/RelatedTestsGuard';
import * as S from './DeleteComponentModal.styled';

interface DeleteComponentModalProps {
  data: Component | Component[];
  onDeleteSuccess?: SideEffect;
}

const DeleteComponentModal = ({
  data,
  onDeleteSuccess = R.always(undefined),
}: DeleteComponentModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [handleDelete, { isError, isSuccess, isLoading }] = useDeleteComponentMutation();
  const [isValid, setIsValid] = useState<boolean>(false);

  const components = useMemo(() => (Array.isArray(data) ? data : [data]), [data]);
  const totalTestsCount = useMemo(
    () => components.reduce((acc, { testsCount }) => acc + testsCount, 0),
    [components],
  );

  useEffect(() => {
    if (isSuccess) {
      onDeleteSuccess();
      modal.hide();
    }
  }, [isSuccess, modal, onDeleteSuccess]);

  const handleOnDelete = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async () => {
    if (isValid || totalTestsCount === 0) {
      try {
        await Promise.all(components.map(({ id }) => handleDelete(id).unwrap()));
      } catch (err) {
        // actual error handling is done by the RTK Query
      }
    }
  }, [components, handleDelete, isValid, totalTestsCount]);

  return (
    <S.Container>
      <S.Content>
        <DeleteIcon />
        <div>
          <Header>
            {t('deleteComponentModal.title', {
              defaultValue_one: 'Delete component "{{- name}}"?',
              defaultValue_other: 'Delete {{count}} components?',
              count: components.length,
              name: components[0].name,
            })}
          </Header>
          <p>
            {components.length === 1 ? (
              <Trans i18nKey="deleteComponentModal.description.common.single" shouldUnescape>
                Are you sure you want to <b>permanently</b> delete this component?
              </Trans>
            ) : (
              <Trans i18nKey="deleteComponentModal.description.common.multiple" shouldUnescape>
                Are you sure you want to <b>permanently</b> delete these components?
              </Trans>
            )}
          </p>
          {totalTestsCount > 0 && (
            <RelatedTestsGuard onValidationChange={setIsValid} testsCount={totalTestsCount} />
          )}
        </div>
      </S.Content>

      <Footer>
        <ErrorInfo
          isVisible={isError}
          inlineErrorText={t('deleteComponent.errorMessage', {
            defaultValue_one: 'Deleting component failed',
            defaultValue_other: 'Deleting components failed',
            count: components.length,
          })}
        />
        <Button onClick={modal.hide}>{t('deleteComponentModal.cancel', 'Cancel')}</Button>
        <ActionButton
          data-testid="DeleteComponentModal.ConfirmButton"
          onClick={handleOnDelete}
          pending={isLoading}
          succeeded={isSuccess}
          variant={totalTestsCount > 0 ? 'danger' : 'primary'}
        >
          {totalTestsCount === 0
            ? t('deleteComponentModal.confirm.noRelatedTestsButton', 'Delete component')
            : t('deleteComponentModal.confirm.someRelatedTestsButton', {
                defaultValue_one: 'Delete from 1 test',
                defaultValue_other: 'Delete from {{count}} tests',
                count: totalTestsCount,
              })}
        </ActionButton>
      </Footer>
    </S.Container>
  );
};
export default DeleteComponentModal;
