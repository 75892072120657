import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import { useTranslation } from 'react-i18next';

import type { NativeIntegration } from '@bugbug/core/types/integrations';
import type { Project } from '@bugbug/core/types/projects';
import {
  selectIntegrationError,
  selectIsIntegrationsActiveInProject,
} from '~/modules/integrations';
import { useAppSelector } from '~/modules/store';

import { INTEGRATION_ERROR_MESSAGE } from './IntegrationStatus.constants';
import * as S from './IntegrationStatus.styled';

interface IntegrationStatusProps {
  type: NativeIntegration;
  projectId: Project['id'];
}

const IntegrationStatus = ({ type, projectId }: IntegrationStatusProps) => {
  const { t } = useTranslation();
  const isConnected = useAppSelector(selectIsIntegrationsActiveInProject(projectId, type));
  const errorCode = useAppSelector(selectIntegrationError(projectId, type));

  const errorMessage = errorCode
    ? INTEGRATION_ERROR_MESSAGE[errorCode] ?? INTEGRATION_ERROR_MESSAGE.DEFAULT
    : '';

  if (!isConnected) return null;

  return (
    <>
      {errorCode && (
        <Tooltip content={errorMessage}>
          <S.IntegrationStatusBadge variant="warning">
            <Icon name="errorTriangle" />
          </S.IntegrationStatusBadge>
        </Tooltip>
      )}
      <S.IntegrationStatusBadge variant="success">
        <Icon name="checkRegular" />
        {t('integrationStatus.connectedBadge', 'Connected')}
      </S.IntegrationStatusBadge>
    </>
  );
};

export default IntegrationStatus;
