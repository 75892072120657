import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTrello = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 2600 530.16", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "linear-gradient", x1: 260.82, y1: 200.7, x2: 260.82, y2: 722, gradientTransform: "matrix(1, 0, 0, -1, 0, 722)", gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { offset: 0, stopColor: "#0052cc" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#2684ff" })
  ] }) }),
  /* @__PURE__ */ jsxs("g", { id: "Logos", children: [
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", fill: "url(#linear-gradient)", d: "M459.71,0H62A62,62,0,0,0,0,61.92V459A62,62,0,0,0,61.76,521.3h398a62,62,0,0,0,61.93-62.09V61.92A62,62,0,0,0,459.71,0ZM225,375.48a20.73,20.73,0,0,1-20.81,20.64h-87a20.65,20.65,0,0,1-20.55-20.64V116.83a20.63,20.63,0,0,1,20.55-20.64h86.92a20.72,20.72,0,0,1,20.64,20.64ZM425.53,256.72a20.65,20.65,0,0,1-20.64,20.65H317.73a20.72,20.72,0,0,1-20.64-20.65V116.83a20.72,20.72,0,0,1,20.64-20.64h86.91a20.63,20.63,0,0,1,20.56,20.64Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M1080.88,38.19V139H961.46V521.3H846.3V139H726.88V38.19Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M1227.46,521.3H1121V145.24h106.46v72c20.23-50.73,52.57-80.9,110.15-77.22v111.4c-75.22-5.85-110.15,12.53-110.15,72.79Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M1919.25,524.23c-69.78,0-113.66-33.43-113.66-112.49V0h107.22V397c0,22.81,15,30.92,33.43,30.92a118.69,118.69,0,0,0,15.88-.76v92.68A156.64,156.64,0,0,1,1919.25,524.23Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M2142.88,524.23c-69.78,0-113.74-33.43-113.74-112.49V0h107.22V397c0,22.81,15.12,30.92,33.84,30.92a114.22,114.22,0,0,0,15.8-.76v92.68A156.12,156.12,0,0,1,2142.88,524.23Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M2237.39,333.11c0-116.16,66.86-195.64,182-195.64S2600,216.69,2600,333.11s-66.19,197-180.59,197S2237.39,448.51,2237.39,333.11Zm104.38,0c0,56.57,23.74,101.45,77.64,101.45s76.3-44.88,76.3-101.45-23-100.29-76.3-100.29S2341.77,276.45,2341.77,333.11Z" }),
    /* @__PURE__ */ jsx("path", { fill: "#293856", d: "M1481.34,367.12a885.48,885.48,0,0,0,89.76,5.26c81.56,0,150.42-21.89,150.42-100.86,0-76.64-70.78-134.22-155.94-134.22-114.65,0-188.11,83.16-188.11,199.32,0,120.59,63.34,192.2,206.5,192.2a343.36,343.36,0,0,0,124.35-22.22V416.51c-36.77,11.78-78.14,23.48-120.59,23.48C1530.73,440,1491,421.27,1481.34,367.12Zm82.07-147.75c30.17,0,54.4,20.47,54.4,48.47,0,36-38,47.3-81.81,47.3a374.51,374.51,0,0,1-55.66-4.43,126.7,126.7,0,0,1,14.79-50.14,78.81,78.81,0,0,1,68.28-41Z" })
  ] })
] });
const ForwardRef = forwardRef(SvgTrello);
export default ForwardRef;
