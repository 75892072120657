import * as artifacts from './artifacts';
import * as auth from './auth';
import instance from './common';
import * as extension from './extension';
import * as groups from './groups';
import * as onboarding from './onboarding';
import * as organizations from './organizations';
import * as profiles from './profiles';
import * as projects from './projects';
import * as schedules from './schedules';
import * as steps from './steps';
import * as suiteRuns from './suiteRuns';
import * as suites from './suites';
import * as testRuns from './testRuns';
import * as tests from './tests';
import * as user from './user';
import * as variables from './variables';

export default {
  instance,
  auth,
  user,
  projects,
  schedules,
  suites,
  suiteRuns,
  tests,
  groups,
  testRuns,
  extension,
  organizations,
  steps,
  artifacts,
  variables,
  profiles,
  onboarding,
};
