import Tooltip from '@bugbug/core/components/Tooltip';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';

import type { ReactNode } from 'react';

import { Container, Label, Description, HelperIcon, LabelWithHelper } from './FormField.styled';

interface FormFieldProps {
  className?: string;
  children: ReactNode;
  label?: string;
  labelId?: string;
  description?: string;
  helperText?: string;
  counter?: string;
  disabled?: boolean;
}

const FormField = ({
  className,
  children,
  counter,
  label,
  description,
  helperText,
  labelId = label,
  disabled,
}: FormFieldProps) => {
  const renderLabel = renderWhenTrue(() => (
    <Label id={labelId}>
      {counter && <div>{counter}</div>}
      {label}
    </Label>
  ));

  const renderLabelWithHelper = renderWhenTrue(() => (
    <Tooltip content={disabled ? '' : helperText}>
      <LabelWithHelper>
        {renderLabel(true)}
        <HelperIcon />
      </LabelWithHelper>
    </Tooltip>
  ));

  const renderDescription = renderWhenTrue(() => <Description>{description}</Description>);

  return (
    <Container className={className} data-testid="FormField" data-disabled={disabled}>
      {renderLabel(!!label && !helperText)}
      {renderLabelWithHelper(!!label && !!helperText)}
      {renderDescription(!!description)}
      {children}
    </Container>
  );
};

FormField.defaultProps = {
  className: null,
  label: '',
  labelId: null,
  description: '',
  helperText: '',
  disabled: false,
};

export default FormField;
