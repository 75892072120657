import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Header as HeaderBase } from '~/components/modals/Modal';

export const Container = styled.div``;

export const Header = styled(HeaderBase)`
  padding-right: 30px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 290px);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLOR.WHITE};
  z-index: 1;
`;
