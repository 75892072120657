import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Header = styled.span`
  display: inline;
`;

export const Warning = styled.span`
  color: ${COLOR.ERROR};
`;
