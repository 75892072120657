import isArray from 'lodash/isArray';
import { both, complement, isNil, startsWith, when } from 'ramda';

export const stringifyObjects = (body) => {
  const result = {};
  Object.keys(body).forEach((bodyKey) => {
    if (body[bodyKey] && typeof body[bodyKey] === 'object' && !isArray(body[bodyKey])) {
      result[bodyKey] = JSON.stringify(body[bodyKey]);
    } else {
      result[bodyKey] = body[bodyKey];
    }
  });

  return result;
};

export const addProtocolWhenNotExists = when(
  both(complement(isNil), both(complement(startsWith('http')), complement(startsWith('{{')))),
  (v) => `http://${v}`,
);

export default {};
