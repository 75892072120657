import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDesktop = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M4 3H20C21.1 3 22 3.9 22 5V15C22 16.1 21.1 17 20 17H4C2.9 17 2 16.1 2 15V5C2 3.9 2.9 3 4 3Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M8 21H16", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M12 17V21", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M12 6.89999L13 8.89999L15.2 9.29999L13.6 10.9L14 13.1L12 12L10 13.1L10.4 10.9L8.80005 9.29999L11 8.89999L12 6.89999Z", stroke: "currentColor", strokeWidth: 1.5, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" })
] });
const ForwardRef = forwardRef(SvgDesktop);
export default ForwardRef;
