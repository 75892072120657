export const SUBSCRIPTION_TYPE = {
  FREE: 'free',
  PRO: 'pro',
  PARALLEL: 'parallel',
  // old plans
  BASIC: 'basic',
  BUSINESS: 'business',
};

export const SUBSCRIPTION_LOWER_PLANS = {
  [SUBSCRIPTION_TYPE.FREE]: [],
  [SUBSCRIPTION_TYPE.PRO]: [SUBSCRIPTION_TYPE.FREE],
  // old plans
  [SUBSCRIPTION_TYPE.BASIC]: [SUBSCRIPTION_TYPE.FREE],
  [SUBSCRIPTION_TYPE.BUSINESS]: [SUBSCRIPTION_TYPE.FREE],
};

export const SUBSCRIPTION_PERIOD = {
  MONTHLY: 'monthly',
  YEARLY: 'annually',
};
