import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { VariableActions } from '~/modules/variable/variable.redux';

const DeleteVariableModal = ({ className, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const deleteRequestState = useActionState(VariableActions.deleteRequest, {
    onSuccess: modal.hide,
  });

  const handleDeleteConfirm = useCallback(
    () => dispatch(VariableActions.deleteRequest(id)),
    [dispatch, id],
  );

  return (
    <ConfirmModal
      className={className}
      title={t('deleteVariableModal.title', 'Do you want to delete this variable?')}
      pending={deleteRequestState.isLoading}
      hasInternalServerError={deleteRequestState.hasInternalServerError}
      onConfirm={handleDeleteConfirm}
      onCancel={modal.hide}
    />
  );
};

DeleteVariableModal.defaultProps = {
  className: null,
};

DeleteVariableModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default DeleteVariableModal;
