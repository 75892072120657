import produce from 'immer';
import { normalize } from 'normalizr';
import { omit, path } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { UserTypes } from '~/modules/user/user.redux';

import { INITIAL_STATE } from './organization.constants';
import {
  organizationListSchema,
  subscriptionsListSchema,
  organizationEntitySchema,
} from './organization.schema';

export { INITIAL_STATE } from './organization.constants';

export const { Types: OrganizationTypes, Creators: OrganizationActions } = createActions(
  {
    createRequest: ['data'],
    createSuccess: ['data'],
    createFailure: ['error'],
    switchOrganizationRequest: ['currentOrganizationId', 'isNew'],
    switchOrganizationSuccess: ['currentOrganizationId'],
    switchOrganizationFailure: ['error'],
    updateOrganizationRequest: ['data'],
    updateOrganizationSuccess: ['data'],
    updateOrganizationFailure: ['error'],
    updateOrganizationNameRequest: ['name'],
    updateOrganizationNameSuccess: ['name'],
    updateOrganizationNameFailure: ['error'],
    updateDefaultCardRequest: ['paymentMethodId'],
    updateDefaultCardSuccess: ['paymentMethod'],
    updateDefaultCardFailure: ['error'],
    subscribeRequest: ['plan', 'paymentMethodId', 'isDowngrade'],
    subscribeSuccess: ['subscription', 'subscriptionSchedule'],
    subscribeFailure: ['error'],
    cancelSubscriptionRequest: [],
    cancelSubscriptionSuccess: [],
    cancelSubscriptionFailure: ['error'],
    getSubscriptionsRequest: [],
    getSubscriptionsSuccess: ['data'],
    getSubscriptionsFailure: ['error'],
    getInvoicesRequest: [],
    getInvoicesSuccess: ['invoices'],
    getInvoicesFailure: ['error'],
    startTrialRequest: [],
    startTrialSuccess: ['subscription'],
    startTrialFailure: ['error'],
    subscriptionChangedSuccess: ['organizationId', 'subscription', 'subscriptionSchedule'],
    //
    subscriptionChanged: ['data'],
    subscriptionPaymentFailed: ['data'],
  },
  { prefix: 'ORGANIZATION/' },
);

const createSuccess = (state, action) =>
  produce(state, (draftState) => {
    const { entities, result } = normalize(action.data, organizationEntitySchema);
    draftState.currentOrganizationId = result;
    draftState.organizations[result] = entities.organizations[result];
  });

const fetchOrganizationSuccess = (state, action) =>
  produce(state, (draftState) => {
    const { entities } = normalize(action.data.organizations, organizationListSchema);

    draftState.organizations = entities.organizations || {};
    if (!draftState.organizations[draftState.currentOrganizationId]) {
      const initialOrgId = path([0, 'id'], action.data.organizations);
      if (initialOrgId) {
        draftState.currentOrganizationId = initialOrgId;
      }
    }
  });

const switchOrganizationSuccess = (state, action) =>
  produce(state, (draftState) => {
    draftState.currentOrganizationId = action.currentOrganizationId;
  });

const subscribeSuccess = (state, { subscription, subscriptionSchedule = null }) =>
  produce(state, (draftState) => {
    const { currentOrganizationId } = draftState;
    draftState.organizations[currentOrganizationId].subscriptionSchedule = subscriptionSchedule;
    draftState.organizations[currentOrganizationId].subscription = {
      ...draftState.organizations[currentOrganizationId].subscription,
      ...omit('usage', subscription),
    };
  });

const startTrialSuccess = (state, { subscription }) => subscribeSuccess(state, { subscription });

const subscriptionChangedSuccess = (
  state,
  { organizationId, subscription, subscriptionSchedule },
) =>
  produce(state, (draftState) => {
    if (draftState.organizations[organizationId]) {
      draftState.organizations[organizationId].subscriptionSchedule = subscriptionSchedule;
      draftState.organizations[organizationId].subscription = subscription;
    }
  });

const subscriptionPaymentFailed = (state, { data: { organizationId, invoice } }) =>
  produce(state, (draftState) => {
    if (draftState.organizations[organizationId]) {
      draftState.organizations[organizationId].subscription.problematicInvoice = invoice;
    }
  });

const updateDefaultCardSuccess = (state, { paymentMethod }) =>
  produce(state, (draftState) => {
    draftState.organizations[draftState.currentOrganizationId].subscription.defaultPaymentMethod =
      paymentMethod;
  });

const cancelSubscriptionSuccess = (state) =>
  produce(state, (draftState) => {
    draftState.organizations[draftState.currentOrganizationId].subscriptionSchedule = null;
  });

const updateOrganizationSuccess = (state, { data }) =>
  produce(state, (draftState) => {
    draftState.organizations[draftState.currentOrganizationId] = data;
  });

const updateOrganizationNameSuccess = (state, { name }) =>
  produce(state, (draftState) => {
    draftState.organizations[draftState.currentOrganizationId].name = name;
  });

const getInvoicesSuccess = (state, action) =>
  produce(state, (draftState) => {
    draftState.invoices = action.invoices;
  });

const getSubscriptionsSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data.reverse(), subscriptionsListSchema);
    draftState.subscriptionsList = {
      subscriptions: data.entities.subscriptions,
      order: data.result,
    };
  });

const reset = (state) =>
  produce(state, (draftState) => {
    draftState.organizations = {};
    draftState.subscriptionsList = {};
    draftState.invoices = {};
  });

export const reducer = createReducer(INITIAL_STATE, {
  [OrganizationTypes.CREATE_SUCCESS]: createSuccess,
  [OrganizationTypes.SUBSCRIBE_SUCCESS]: subscribeSuccess,
  [OrganizationTypes.UPDATE_DEFAULT_CARD_SUCCESS]: updateDefaultCardSuccess,
  [OrganizationTypes.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,
  [OrganizationTypes.UPDATE_ORGANIZATION_NAME_SUCCESS]: updateOrganizationNameSuccess,
  [OrganizationTypes.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccess,
  [OrganizationTypes.GET_INVOICES_SUCCESS]: getInvoicesSuccess,
  [OrganizationTypes.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccess,
  [OrganizationTypes.SWITCH_ORGANIZATION_SUCCESS]: switchOrganizationSuccess,
  [OrganizationTypes.START_TRIAL_SUCCESS]: startTrialSuccess,
  [OrganizationTypes.SUBSCRIPTION_CHANGED_SUCCESS]: subscriptionChangedSuccess,
  [OrganizationTypes.SUBSCRIPTION_PAYMENT_FAILED]: subscriptionPaymentFailed,
  //
  [OrganizationTypes.SUBSCRIPTION_CHANGED]: (state, { data }) =>
    subscriptionChangedSuccess(state, data),
  [UserTypes.GET_SINGLE_SUCCESS]: fetchOrganizationSuccess,
  [UserTypes.UPDATE_ONBOARDING_PROFILE_SUCCESS]: fetchOrganizationSuccess,
  [UserTypes.LOGOUT_SUCCESS]: reset,
});
