import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

import { Header, Warning } from './GroupSplitConfirmationModal.styled';

const GroupSplitConfirmationModal = ({ testId, groupId, testsCount, index }) => {
  const modal = useModal();
  const dispatch = useDispatch();

  const requestState = useActionState(TestActions.splitGroupRequest, {
    onSuccess: modal.hide,
    reset: true,
  });

  const handleConfirm = () => {
    dispatch(TestActions.splitGroupRequest(testId, groupId, index));
  };

  return (
    <ConfirmModal
      onConfirm={handleConfirm}
      onCancel={modal.hide}
      pending={requestState.isLoading}
      hasInternalServerError={requestState.hasInternalServerError}
      title={
        <Header>
          <Trans i18nKey="groupSplitConfirmationModal.title" values={{ testsCount }}>
            The action will affect <Warning>all {{ testsCount }} tests</Warning> using this
            component. Do you want to proceed?
          </Trans>
        </Header>
      }
    />
  );
};

GroupSplitConfirmationModal.propTypes = {
  testId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  testsCount: PropTypes.number,
  index: PropTypes.number.isRequired,
};

export default GroupSplitConfirmationModal;
