import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

const CloneTestModal = ({ className, id, shouldNavigate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const cloneRequestState = useActionState(TestActions.cloneRequest, {
    onSuccess: modal.hide,
  });

  const handleCloneConfirm = useCallback(
    () => dispatch(TestActions.cloneRequest(id, shouldNavigate)),
    [dispatch, id, shouldNavigate],
  );

  return (
    <ConfirmModal
      hasInternalServerError={cloneRequestState.hasInternalServerError}
      className={className}
      title={t('cloneTestModal.title', 'Do you want to duplicate this test?')}
      pending={cloneRequestState.isLoading}
      onConfirm={handleCloneConfirm}
      onCancel={modal.hide}
    />
  );
};

CloneTestModal.defaultProps = {
  className: null,
  shouldNavigate: false,
};

CloneTestModal.propTypes = {
  className: PropTypes.string,
  shouldNavigate: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default CloneTestModal;
