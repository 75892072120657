import { flatten } from 'ramda';

export const KEY_BINDINGS = {
  ESC: 'esc',
  ARROW_UP: 'up',
  ARROW_DOWN: 'down',
  ENTER: 'enter',
} as const;

export const KEY_CODES_BINDINGS = {
  ESC: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ENTER: 13,
  BACKSPACE: 8,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  HOME: 36,
  END: 35,
  0: [48, 96],
  1: [49, 97],
  2: [50, 98],
  3: [51, 99],
  4: [52, 100],
  5: [53, 101],
  6: [54, 102],
  7: [55, 103],
  8: [56, 104],
  9: [57, 105],
};

export const NUMBERS_KEY_CODES = flatten([
  KEY_CODES_BINDINGS[0],
  KEY_CODES_BINDINGS[1],
  KEY_CODES_BINDINGS[2],
  KEY_CODES_BINDINGS[3],
  KEY_CODES_BINDINGS[4],
  KEY_CODES_BINDINGS[5],
  KEY_CODES_BINDINGS[6],
  KEY_CODES_BINDINGS[7],
  KEY_CODES_BINDINGS[8],
  KEY_CODES_BINDINGS[9],
]);
