import { getFormattedDuration } from '~/utils/dates';

export const getInitialTime = (duration?: string) => {
  if (!duration) {
    return 0;
  }

  const [h, min, s] = duration.split(':').map(Number);
  return h * 3600000 + min * 60000 + s * 1000;
};

export const getDurationString = (
  ms: number,
  showMilliseconds = false,
  showOnlySeconds = false,
) => {
  try {
    const date = new Date();
    date.setTime(ms);
    return getFormattedDuration(
      date.toISOString().substring(11, 23),
      showMilliseconds,
      showOnlySeconds,
    );
  } catch (e) {
    return '';
  }
};
