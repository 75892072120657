import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgKeyboard = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-keyboard" }),
  /* @__PURE__ */ jsx("g", { id: "b74e785f-89fe-430f-9725-68932290ec3e", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "bbf9e668-4fc8-4218-b00e-edc7fcce900e", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("line", { x1: 22, y1: 19, x2: 24, y2: 19, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 23, y1: 16, x2: 25, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 20, y1: 16, x2: 22, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 17, y1: 16, x2: 19, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 14, y1: 16, x2: 16, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 11, y1: 16, x2: 13, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 8, y1: 16, x2: 10, y2: 16, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 20, y1: 13, x2: 22, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 23, y1: 13, x2: 25, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 17, y1: 13, x2: 19, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 14, y1: 13, x2: 16, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 11, y1: 13, x2: 13, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 8, y1: 13, x2: 10, y2: 13, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 9, y1: 19, x2: 11, y2: 19, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 12, y1: 19, x2: 21, y2: 19, style: {
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("rect", { x: 6, y: 10, width: 21, height: 12, rx: 1.03, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] })
  ] }) })
] });
const ForwardRef = forwardRef(SvgKeyboard);
export default ForwardRef;
