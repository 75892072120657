import type { ReactNode } from 'react';

interface VariableNameProps {
  className?: string;
  children: ReactNode;
}

const VariableName = ({ className, children }: VariableNameProps) => (
  <span className={className}>
    {'{{'}
    {children}
    {'}}'}
  </span>
);

export default VariableName;
