import Icon from '@bugbug/core/components/Icon';

import type { ToastProps } from '../../Toast';

import Toast from '../../Toast';

const ErrorToast = (props: ToastProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Toast Icon={<Icon name="error" />} {...props} />
);

export default ErrorToast;
