import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FormField from '~/components/FormField';
import { Footer, Header, Content, ErrorInfo } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { USER_ROLE, USER_ROLE_LABELS } from '~/modules/constans';
import { selectRoleInCurrentOrganization } from '~/modules/organization/organization.selectors';
import { OrganizationUsersActions } from '~/modules/organizationUsers';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import * as validators from '~/utils/validators';

import { FIELD_NAMES } from './InviteUserModal.constants';
import { Container, Info } from './InviteUserModal.styled';

const InviteUserModalSchema = Yup.object().shape({
  email: validators.emailValidator,
  role: validators.userRoleValidator,
});

const InviteUserModal = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const currentUserRole = useSelector(selectRoleInCurrentOrganization);
  const handleSubmit = useCallback(
    ({ email, role }) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.USER_INVITATION_SENT, { role: USER_ROLE_LABELS[role] });
      dispatch(OrganizationUsersActions.addRequest({ email, role: Number(role) }));
    },
    [dispatch],
  );

  const {
    getFieldProps,
    getFieldMeta,
    touched,
    errors,
    isValid,
    isSubmitting,
    setSubmitting,
    ...formik
  } = useFormik({
    initialValues: {
      [FIELD_NAMES.EMAIL]: '',
      [FIELD_NAMES.ROLE]: USER_ROLE.EDITOR,
    },
    validationSchema: InviteUserModalSchema,
    onSubmit: handleSubmit,
  });

  const availableRoles = Object.values(USER_ROLE).filter((role) => role <= currentUserRole);

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        formik.setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSubmitting],
  );

  const { isFailure, hasInternalServerError } = useActionState(
    OrganizationUsersActions.addRequest,
    {
      onSuccess: modal.hide,
      onFailure: handleFailure,
    },
  );

  return (
    <Container className={className} data-testid="InviteUserModal" onSubmit={formik.handleSubmit}>
      <Header>{t('inviteUserModal.title', 'Invite a user to your organization')}</Header>
      <Content>
        <Info>
          {t('inviteUserModal.subTitle', 'Organization members have access to all projects.')}
        </Info>
        <FormField label={t('inviteUserModal.form.email.label', 'E-mail address')}>
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.EMAIL)}
            error={touched[FIELD_NAMES.EMAIL] && errors[FIELD_NAMES.EMAIL]}
            placeholder={t('inviteUserModal.form.email.placeholder', 'E-mail address')}
          />
        </FormField>
        <FormField label={t('inviteUserModal.form.role.label', 'Role')}>
          <Select
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.ROLE)}
            error={touched[FIELD_NAMES.ROLE] && errors[FIELD_NAMES.ROLE]}
          >
            {availableRoles.map((role) => (
              <SelectOption key={role} value={role}>
                {USER_ROLE_LABELS[role]}
              </SelectOption>
            ))}
          </Select>
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo isVisible={hasInternalServerError} />
        <Button
          data-testid="InviteUserModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting && !isFailure}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="InviteUserModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          disabled={!isValid}
          pending={isSubmitting && !isFailure}
        >
          {t('inviteUserModal.form.submitButtonLabel', 'Invite')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

InviteUserModal.defaultProps = {
  className: null,
};

InviteUserModal.propTypes = {
  className: PropTypes.string,
};

export default InviteUserModal;
