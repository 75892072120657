import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAnswerPrompt = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_3544_72649)", children: [
    /* @__PURE__ */ jsx("path", { d: "M26 6H6C5.44772 6 5 6.44772 5 7V25C5 25.5523 5.44772 26 6 26H26C26.5523 26 27 25.5523 27 25V7C27 6.44772 26.5523 6 26 6Z", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M5 10H27", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M13.6677 15.2832C13.8558 14.7485 14.2271 14.2976 14.7158 14.0104C15.2045 13.7231 15.7791 13.6182 16.3378 13.714C16.8965 13.8098 17.4032 14.1003 17.7682 14.5339C18.1333 14.9676 18.3331 15.5164 18.3322 16.0833C18.3322 17.6835 15.932 18.4835 15.932 18.4835", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M15.9958 21.6839H16.0039", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_3544_72649", children: /* @__PURE__ */ jsx("rect", { width: 32, height: 32, fill: "white" }) }) })
] });
const ForwardRef = forwardRef(SvgAnswerPrompt);
export default ForwardRef;
