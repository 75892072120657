import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgWifiOff = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 23, height: 23, viewBox: "0 0 24 24", fill: "none", stroke: "#c1c1c1", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", className: "feather feather-wifi-off", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("line", { x1: 1, y1: 1, x2: 23, y2: 23 }),
  /* @__PURE__ */ jsx("path", { d: "M16.72 11.06A10.94 10.94 0 0 1 19 12.55" }),
  /* @__PURE__ */ jsx("path", { d: "M5 12.55a10.94 10.94 0 0 1 5.17-2.39" }),
  /* @__PURE__ */ jsx("path", { d: "M10.71 5.05A16 16 0 0 1 22.58 9" }),
  /* @__PURE__ */ jsx("path", { d: "M1.42 9a15.91 15.91 0 0 1 4.7-2.88" }),
  /* @__PURE__ */ jsx("path", { d: "M8.53 16.11a6 6 0 0 1 6.95 0" }),
  /* @__PURE__ */ jsx("line", { x1: 12, y1: 20, x2: 12.01, y2: 20 })
] });
const ForwardRef = forwardRef(SvgWifiOff);
export default ForwardRef;
