import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAlert = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_4787_193886)", children: [
    /* @__PURE__ */ jsx("path", { d: "M10 0.833328V2.49999", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M19.1666 10L17.5 10", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M0.833374 10L2.50004 10", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M14.5834 2.06143L13.75 3.50481", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M2.06152 5.41667L3.5049 6.25001", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M17.9386 5.41667L16.4952 6.25001", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M5.41675 2.06143L6.25008 3.50481", stroke: "currentColor", strokeWidth: 1.66667, strokeLinecap: "round" }),
    /* @__PURE__ */ jsx("mask", { id: "path-8-inside-1_4787_193886", fill: "white", children: /* @__PURE__ */ jsx("path", { d: "M5 10C5 7.23857 7.23858 5 10 5C12.7614 5 15 7.23858 15 10V14.1667H5V10Z" }) }),
    /* @__PURE__ */ jsx("path", { d: "M3.33333 10C3.33333 6.3181 6.3181 3.33333 10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10H13.3333C13.3333 8.15905 11.8409 6.66667 10 6.66667C8.15905 6.66667 6.66667 8.15905 6.66667 10H3.33333ZM15 14.1667H5H15ZM3.33333 14.1667V10C3.33333 6.3181 6.3181 3.33333 10 3.33333V6.66667C8.15905 6.66667 6.66667 8.15905 6.66667 10V14.1667H3.33333ZM10 3.33333C13.6819 3.33333 16.6667 6.3181 16.6667 10V14.1667H13.3333V10C13.3333 8.15905 11.8409 6.66667 10 6.66667V3.33333Z", fill: "currentColor", mask: "url(#path-8-inside-1_4787_193886)" }),
    /* @__PURE__ */ jsx("path", { d: "M4.16671 15.8333C4.16671 14.9129 4.9129 14.1667 5.83337 14.1667H14.1667C15.0872 14.1667 15.8334 14.9129 15.8334 15.8333V17.5H4.16671V15.8333Z", style: {
      fill: "none",
      stroke: "currentColor"
    }, strokeWidth: 1.66667 }),
    /* @__PURE__ */ jsx("path", { d: "M9.16671 10.8333C9.16671 10.3731 9.5398 10 10 10C10.4603 10 10.8334 10.3731 10.8334 10.8333C10.8334 11.2936 10.4603 11.6667 10 11.6667C9.5398 11.6667 9.16671 11.2936 9.16671 10.8333Z", fill: "currentColor", stroke: "currentColor", strokeWidth: 1.66667 })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_4787_193886", children: /* @__PURE__ */ jsx("rect", { width: 20, height: 20, fill: "white" }) }) })
] });
const ForwardRef = forwardRef(SvgAlert);
export default ForwardRef;
