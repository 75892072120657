import type { Dictionary } from '@reduxjs/toolkit';

import type { Invoice, Organization } from '@bugbug/core/types/organizations';
import type { Subscription, SubscriptionPlan } from '@bugbug/core/types/subscriptions';

interface OrganizationState {
  organizations: Dictionary<Organization>;
  currentOrganizationId: string;
  subscriptionsList: {
    subscriptions?: Dictionary<Subscription>;
    order?: SubscriptionPlan[];
  };
  invoices: {
    results?: Invoice[];
  };
}

export const INITIAL_STATE: OrganizationState = {
  organizations: {},
  currentOrganizationId: '',
  subscriptionsList: {},
  invoices: {},
};
