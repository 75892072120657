import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

import { Container, Content } from './DeleteAllGroupsModal.styled';

const DeleteAllGroupsModal = ({ testId, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => {
    modal.hide();
    onSuccess();
  }, [modal, onSuccess]);

  const removeRequestState = useActionState(TestActions.removeStepsRequest, {
    onSuccess: handleSuccess,
  });

  const handleDeleteConfirm = useCallback(() => {
    dispatch(TestActions.removeStepsRequest(testId));
  }, [dispatch, testId]);

  return (
    <Container>
      <Content>
        <DeleteIcon />
        <div>
          <Header>
            <Trans i18nKey="deleteAllGroupsModal.all.title" defaults="Delete all groups?" />
          </Header>
          <p>
            <Trans
              i18nKey="deleteAllGroupsModal.all.description"
              defaults="Are you sure you want to delete all groups? You <strong>can't undo this.</strong>"
            />
          </p>
        </div>
      </Content>
      <Footer>
        <ErrorInfo isVisible={removeRequestState.hasInternalServerError} />
        <Button onClick={modal.hide}>{t('deleteAllGroupsModal.cancel', 'Cancel')}</Button>
        <ActionButton
          onClick={handleDeleteConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={removeRequestState.isLoading}
        >
          {t('deleteAllGroupsModal.confirmAll', 'Delete all groups')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DeleteAllGroupsModal.defaultProps = {
  className: null,
  onSuccess: Function.prototype,
};

DeleteAllGroupsModal.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default DeleteAllGroupsModal;
