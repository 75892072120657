import styled from 'styled-components';

import { COLOR } from '../../theme/colors';

export const UnorganizedList = styled.ul`
  list-style: none;
  padding-left: 27px;
  position: relative;

  li {
    margin-bottom: 6px;
    line-height: 19.5px;
  }

  li:before {
    width: 8px;
    height: 8px;
    position: absolute;
    content: '';
    left: 0;
    transform: translate(6px, 7px);
    background-color: ${COLOR.PRIMARY};
    border-radius: 100%;
  }
`;
