import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import mixins from '@bugbug/core/theme/mixins';
import { RegularParagraph as BaseRegularParagraph } from '@bugbug/core/theme/typography';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import styled, { css } from 'styled-components';

import Card from '../Card';

export const Container = styled(Card)<{ fullInfo: boolean }>`
  align-items: top;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;

  margin: 0;
  margin-bottom: 25px;
  margin-top: 10px;

  ${styleWhenTrue(
    T.prop('fullInfo'),
    css`
      margin-bottom: 50px;
    `,
  )}
`;

export const MoneyIcon = styled(Icon).attrs(() => ({
  name: 'moneyPixelColor',
}))`
  align-items: top;
  height: 48px;
  width: 48px;

  // 800px because smaller than that, the text starts to wrap
  // so this is only aestetical criterion of the breakpoint
  @media (max-width: 800px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  flex-grow: 1;
`;

export const NonBreakingLink = styled(Link)`
  margin-left: 4px;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const RegularParagraph = styled(BaseRegularParagraph)`
  ${mixins.font.regular};
`;
