import PropTypes from 'prop-types';
import { move, prop, propEq, insert, difference } from 'ramda';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import TestsPickerList from '~/components/TestsPicker/components/TestsPickerList';

import { Container, AddIcon, TrashRemoveIcon } from './TestsPicker.styled';

const LIST_ID = {
  AVAILABLE: 'available',
  SELECTED: 'selected',
};

const TestsPicker = ({
  className,
  value,
  name,
  availableTests: allAvailableTests,
  onChange,
  searchValue,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/destructuring-assignment
  const availableTests = allAvailableTests.filter(({ id }) => value && !value.includes(id));
  const selectedTests = value.map((id) => allAvailableTests.find(propEq('id', id))).filter(Boolean);
  const availableTestsEmpty = !availableTests.length;
  const selectedTestsEmpty = !selectedTests.length;

  const handleSelectAll = () => {
    onChange({
      target: {
        value: [...value, ...difference(allAvailableTests.map(prop('id')), value)],
        name,
      },
    });
  };

  const handleDeselectAll = () => {
    onChange({ target: { value: [], name } });
  };

  const removeItem = (id) => () => {
    onChange({ target: { value: value.filter((item) => item !== id), name } });
  };

  const addItem = (id) => () => {
    onChange({ target: { value: [...value, id], name } });
  };

  const handleDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId === LIST_ID.SELECTED) {
        const newValue = move(source.index, destination.index, selectedTests);
        onChange({ target: { value: newValue.map(prop('id')), name } });
      }

      return;
    }

    if (destination.droppableId === LIST_ID.AVAILABLE) {
      const newValue = value.filter((v, index) => index !== source.index);
      onChange({ target: { value: newValue, name } });
    }

    if (destination.droppableId === LIST_ID.SELECTED) {
      const newItemId = availableTests[source.index].id;
      onChange({ target: { value: insert(destination.index, newItemId, value), name } });
    }
  };

  return (
    <Container className={className} data-testid="TestsPicker">
      <DragDropContext onDragEnd={handleDragEnd}>
        <TestsPickerList
          id={LIST_ID.AVAILABLE}
          title={t('suiteDetails.testsPicker.availableTests.title', 'Select tests')}
          description={t(
            'suiteDetails.testsPicker.availableTests.description',
            'Click "plus" or drag the test to the right',
          )}
          headerButtonLabel={t('suiteDetails.testsPicker.availableTests.selectAll', 'Select all')}
          onHeaderButtonClick={handleSelectAll}
          empty={availableTestsEmpty}
          emptyInfo={
            // eslint-disable-next-line react/destructuring-assignment
            !allAvailableTests.length
              ? t(
                  'suiteDetails.testsPicker.availableTests.noAvailableTestsInfo',
                  "You don't have any tests\nin the project.",
                )
              : t('suiteDetails.testsPicker.availableTests.emptyInfo', 'All tests selected.')
          }
          onItemClick={addItem}
          items={availableTests}
          Icon={<AddIcon data-testid="TestsPicker.AddIcon" />}
          searchValue={searchValue}
        />

        <TestsPickerList
          id={LIST_ID.SELECTED}
          title={t('suiteDetails.testsPicker.selectedTests.title', 'Tests order')}
          description={t(
            'suiteDetails.testsPicker.selectedTests.description',
            'Drag tests to reorder',
          )}
          headerButtonLabel={t(
            'suiteDetails.testsPicker.selectedTests.deselectAll',
            'Deselect all',
          )}
          onHeaderButtonClick={handleDeselectAll}
          empty={selectedTestsEmpty}
          emptyInfo={t(
            'suiteDetails.testsPicker.selectedTests.emptyInfo',
            'To select tests click "plus" or drag the tests here',
          )}
          onItemClick={removeItem}
          items={selectedTests}
          Icon={<TrashRemoveIcon data-testid="TestsPicker.RemoveIcon" />}
          searchValue={searchValue}
        />
      </DragDropContext>
    </Container>
  );
};

TestsPicker.defaultProps = {
  className: null,
  value: [],
  availableTests: [],
};

TestsPicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  availableTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default TestsPicker;
