import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';
import * as Yup from 'yup';

import FormField from '~/components/FormField';
import { Header, Footer, Content, ErrorInfo } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteActions } from '~/modules/suite/suite.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { nameValidator, idValidator, VALIDATION_MESSAGE } from '~/utils/validators';

const SuiteSchema = Yup.object().shape({
  id: idValidator.notRequired(),
  name: nameValidator.max(DATA_RESTRICTIONS.SUITE_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH),
  tests: Yup.array().of(idValidator).default([]),
});

const SuiteModal = ({ className, suite }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const updateRequest = useActionState(SuiteActions.updateRequest, {
    reset: false,
    onSuccess: modal.hide,
  });
  const createRequest = useActionState(SuiteActions.createRequest, {
    reset: false,
    onSuccess: modal.hide,
  });

  const isEdit = !!suite.id;

  useUnmount(() => {
    updateRequest.reset();
    createRequest.reset();
  });

  const labels = useMemo(() => {
    if (isEdit) {
      return {
        title: t('editSuiteModal.edit.title.edit', 'Edit suite "{{name}}"', { name: suite.name }),
        submitButton: t('editSuiteModal.edit.submitButton', 'Save suite'),
      };
    }
    return {
      title: t('editSuiteModal.new.title', 'New suite'),
      submitButton: t('editSuiteModal.new.submitButton', 'Create suite'),
    };
  }, [suite.name, t, isEdit]);

  const initialValues = useMemo(
    () => ({
      id: suite.id,
      name: suite.name || t('editSuiteModal.defaultName', 'Unnamed suite'),
      tests: suite.tests || [],
    }),
    [t, suite],
  );

  const handleSubmit = useCallback(
    (values) => {
      const normalizedValues = SuiteSchema.cast(values);
      if (isEdit) {
        dispatch(SuiteActions.updateRequest(suite.id, normalizedValues));
      } else {
        analytics.trackEvent(TRACK_EVENT_TYPE.CREATE_SUITE);
        dispatch(SuiteActions.createRequest(normalizedValues));
      }
    },
    [dispatch, isEdit, suite.id],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: SuiteSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className={className} data-testid="EditSuiteModal" onSubmit={formik.handleSubmit}>
      <Header>{labels.title}</Header>
      <Content>
        <FormField label={t('editSuiteModal.nameInputLabel', 'Name')}>
          <Input
            auto
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
          />
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo
          isVisible={updateRequest.hasInternalServerError || createRequest.hasInternalServerError}
        />
        <Button
          data-testid="EditSuiteModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="EditSuiteModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={formik.isSubmitting}
        >
          {labels.submitButton}
        </ActionButton>
      </Footer>
    </form>
  );
};

SuiteModal.defaultProps = {
  className: null,
  suite: {},
  name: '',
};

SuiteModal.propTypes = {
  className: PropTypes.string,
  suite: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tests: PropTypes.arrayOf(PropTypes.string),
  }),
  name: PropTypes.string,
};

export default SuiteModal;
