import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { SelectOptionItem } from '../../Select.types';

import SelectOptionChipsRow, { SelectOptionChipsRowStyle } from '../SelectOptionChipsRow';

import * as S from './SelectOptionChips.styled';

interface SelectOptionChipsProps {
  selectedOptions: SelectOptionItem[];
  onDelete: (item: SelectOptionItem) => void;
  maxChips?: number;
}

const SelectOptionChips = ({ selectedOptions, onDelete, maxChips }: SelectOptionChipsProps) => {
  const { t } = useTranslation();

  const options = maxChips ? selectedOptions.slice(0, maxChips) : selectedOptions;

  const restOptionsTotal = selectedOptions.length - options.length;

  return (
    <S.Container>
      {options.map((item) => (
        <SelectOptionChipsRow onDelete={onDelete} key={item.value} item={item} />
      ))}
      {!!restOptionsTotal && (
        <SelectOptionChipsRowStyle>
          {t('select.others.label', {
            defaultValue: '1 other',
            defaultValue_other: '{{ count }} others',
            count: restOptionsTotal,
          })}
        </SelectOptionChipsRowStyle>
      )}
    </S.Container>
  );
};

export default SelectOptionChips;
