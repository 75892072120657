import Button, { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import type { Project } from '@bugbug/core/types/projects';
import type { User } from '@bugbug/core/types/users';
import { HelpIcon, Header, Footer } from '~/components/modals/Modal';
import TextArea from '~/components/TextArea';
import useModal from '~/hooks/useModal';
import { useRequestSupportMutation } from '~/modules/core/core.api';
import { selectCurrentOrganizationId } from '~/modules/organization';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';
import { selectUserData } from '~/modules/user/user.selectors';
import { showInternalError } from '~/services/toasts/core';
import * as validators from '~/utils/validators';

import { Container, Content, Question, Checkbox } from './SupportRequestConfirmationModal.styled';

const SupportRequestSchema = Yup.object().shape({
  description: Yup.string().required(validators.VALIDATION_MESSAGE.REQUIRED),
  userAllowedToRunTests: Yup.string().required(validators.VALIDATION_MESSAGE.REQUIRED),
});

const SupportRequestConfirmation = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const [requestSupport, { isLoading }] = useRequestSupportMutation();

  const { failedStepRun = {} } = useSelector(selectFailedStepRunWithStep);
  const organizationId = useSelector(selectCurrentOrganizationId);
  const project = useSelector(selectSingleProject) as Project;
  const user = useSelector(selectUserData) as User;

  const { handleSubmit, handleChange, getFieldProps, values, touched, errors } = useFormik({
    initialValues: {
      description: '',
      userAllowedToRunTests: true,
    },
    validationSchema: SupportRequestSchema,
    onSubmit: async (formValues) => {
      const result = await requestSupport({
        ...formValues,
        project,
        failedStepRun,
        organizationId,
        user,
      });
      if ('error' in result) {
        showInternalError();
      } else {
        modal.show('support_request_success');
      }
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Container aria-label={t('supportRequestConfirmation.ariaLabel', 'Request support')}>
        <Content>
          <HelpIcon />
          <div>
            <Header>{t('supportRequestConfirmation.title', 'Request support')}</Header>
            <p>
              {t(
                'supportRequestConfirmation.description',
                'Our support can take a look at this test and help you fix it. A link to this test will be automatically attached to your message.',
              )}
            </p>
            <Question>
              {t('supportRequestConfirmation.issuedescription.label', 'How can we help you?')}
            </Question>
            <TextArea
              initialHeight={200}
              placeholder={t(
                'supportRequestConfirmation.issuedescription.placeholder',
                'Description of your problem (required)',
              )}
              {...getFieldProps('description')}
              autoFocus
              error={touched.description && errors.description}
              onChange={handleChange}
            />
            <Checkbox
              {...getFieldProps('userAllowedToRunTests')}
              onChange={handleChange}
              // @ts-expect-error missing typings
              error={touched.userAllowedToRunTests && errors.userAllowedToRunTests}
              checked={!!values.userAllowedToRunTests}
            >
              {t(
                'supportRequestConfirmation.userAllowedToRunTests',
                'I allow BugBug Support Team to run my test',
              )}
            </Checkbox>
          </div>
        </Content>
        <Footer>
          <Button onClick={modal.hide} variant={BUTTON_VARIANT.PRIMARY} bordered>
            {t('default.button.cancel')}
          </Button>
          <ActionButton type="submit" pending={isLoading} variant={BUTTON_VARIANT.PRIMARY}>
            {t('supportRequestConfirmation.send', 'Send')}
          </ActionButton>
        </Footer>
      </Container>
    </form>
  );
};

export default SupportRequestConfirmation;
