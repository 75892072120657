import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 664px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > * {
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const Description = styled.p`
  font-size: 13px;
  color: ${COLOR.GRAY_36};
  margin-bottom: 24px;
`;
