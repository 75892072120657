import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import styled from 'styled-components';

import FormField from '~/components/FormField';

export const ContainerTooltip = styled(TooltipBase).attrs({
  anchor: TOOLTIP_ANCHOR.LEFT_CENTER,
  offset: 20,
})`
  margin-top: -15px;
`;

export const Field = styled(FormField)`
  padding-top: 2px;
  padding-bottom: 16px;
`;
