import * as S from './ExternalLink.styled';

interface ExternalLinkProps {
  title: string;
  description: string;
  url: string;
}

export const ExternalLink = ({ url, title, description }: ExternalLinkProps) => (
  <S.Wrapper href={url} target="_blank">
    <div>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </div>
    <S.ExternalLinkIcon />
  </S.Wrapper>
);
