import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEye = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: /* @__PURE__ */ jsx("g", { id: "b7738a5a-881a-428e-b395-7689c156bc10", children: /* @__PURE__ */ jsxs("g", { id: "ab5b7003-e090-44fa-8468-333ae0c98ef5", children: [
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("path", { d: "M5,16S9,8,16,8s11,8,11,8-4,8-11,8S5,16,5,16Z", style: {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("circle", { cx: 16, cy: 16, r: 3, style: {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }),
  /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
    fill: "none"
  } })
] }) }) });
const ForwardRef = forwardRef(SvgEye);
export default ForwardRef;
