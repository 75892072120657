import SearchInput from '@bugbug/core/components/SearchInput';
import { KEY_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { RegularParagraph } from '@bugbug/core/theme/typography';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import type { SearchableListEntryProps, SearchableListProps } from './SearchableList.types';

import type { InputChangeHandler } from '@bugbug/core/components/Input/Input.types';

import * as S from './SearchableList.styled';

const SearchableList = <T extends { id: string; disabled?: boolean }>({
  data,
  onEntryClick,
  labelField,
  ListEntry,
  noDataMessage,
}: SearchableListProps<T>) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState<T[]>(data);

  const entriesRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const onClick = onEntryClick || R.always;

  const DefaultListEntry: React.FC<SearchableListEntryProps<T>> = useCallback(
    ({ entry }) => {
      // tsc cannot infere that `labelField` is a keyof T even though
      // it's restricted on type level of the component props
      const label = entry[labelField as keyof T];

      return <S.Label>{label}</S.Label>;
    },
    [labelField],
  );

  const EntryComponent: React.ComponentType<SearchableListEntryProps<T>> = useMemo(
    () => ListEntry || DefaultListEntry,
    [DefaultListEntry, ListEntry],
  );

  useEffect(() => {
    const newFilteredData = data.filter((entry) => {
      // with generics in place, tsc cannot infer these types even
      // though it is restricted on type-level that `labelField`
      // is a keyof T and the value under the key is a string
      const value = entry[labelField as keyof T] as string;

      return query === '' ? entry : value.toLowerCase().includes(query);
    });

    setFilteredData(newFilteredData);
  }, [data, labelField, query]);

  const handleOnQueryChange = useCallback<InputChangeHandler>((event) => {
    setQuery(event.target.value.toLowerCase());
  }, []);

  const handleNavigation = useCallback((key, event) => {
    let nextElement: HTMLElement | null;

    const isDownPressed = key === KEY_BINDINGS.ARROW_DOWN;
    const isSearchFocused = event.target === searchInputRef.current;

    const nextSibling = event.target.nextElementSibling;
    const prevSibling = event.target.previousElementSibling;

    switch (true) {
      case isSearchFocused && isDownPressed:
        nextElement = entriesRef.current?.children.item(0) as HTMLElement;
        break;

      case isDownPressed:
        nextElement = nextSibling;
        break;

      case !isDownPressed && prevSibling !== null:
        nextElement = prevSibling;
        break;

      default:
        nextElement = searchInputRef.current;
    }

    nextElement?.focus();
  }, []);

  const handleOnEntryEnterHit = useCallback(
    (__, event) => {
      const entryIndex = event.target.value;

      if (entryIndex !== '') {
        onClick(filteredData[entryIndex].id);
      }
    },
    [filteredData, onClick],
  );

  const handleKeyEvent = R.cond([
    [R.equals(KEY_BINDINGS.ENTER), handleOnEntryEnterHit],
    [R.equals(KEY_BINDINGS.ARROW_DOWN), handleNavigation],
    [R.equals(KEY_BINDINGS.ARROW_UP), handleNavigation],
  ]);

  return (
    <S.Container>
      <KeyboardEventHandler
        handleKeys={[KEY_BINDINGS.ARROW_DOWN, KEY_BINDINGS.ARROW_UP, KEY_BINDINGS.ENTER]}
        onKeyEvent={handleKeyEvent}
      >
        <SearchInput ref={searchInputRef} onChange={handleOnQueryChange} fullWidth autoFocus />

        <S.Entries data-testid="SearchableList.Results" ref={entriesRef}>
          {filteredData.map((e, index) => (
            <S.Entry
              disabled={e?.disabled}
              key={e.id}
              tabIndex={index + 1}
              value={index}
              onClick={() => onClick(e.id)}
            >
              <EntryComponent entry={e} />
            </S.Entry>
          ))}
        </S.Entries>
      </KeyboardEventHandler>

      {filteredData.length === 0 && (
        <RegularParagraph>
          {query === ''
            ? noDataMessage || t('searchableList.messages.noDataAvailable', 'No data available.')
            : t('SearchableList.messages.noDataMatchQuery', 'No data matches the query.')}
        </RegularParagraph>
      )}
    </S.Container>
  );
};

export default SearchableList;
