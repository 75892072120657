import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgSlack = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_4784_168587)", children: [
    /* @__PURE__ */ jsx("path", { d: "M13.7063 40.316C13.7063 43.9948 10.7331 46.968 7.05431 46.968C3.37557 46.968 0.402344 43.9948 0.402344 40.316C0.402344 36.6373 3.37557 33.6641 7.05431 33.6641H13.7063V40.316ZM17.0323 40.316C17.0323 36.6373 20.0055 33.6641 23.6842 33.6641C27.363 33.6641 30.3362 36.6373 30.3362 40.316V56.946C30.3362 60.6247 27.363 63.5979 23.6842 63.5979C20.0055 63.5979 17.0323 60.6247 17.0323 56.946V40.316Z", fill: "#E01E5A" }),
    /* @__PURE__ */ jsx("path", { d: "M23.6838 13.6086C20.0051 13.6086 17.0319 10.6354 17.0319 6.95666C17.0319 3.27792 20.0051 0.304688 23.6838 0.304688C27.3626 0.304688 30.3358 3.27792 30.3358 6.95666V13.6086H23.6838ZM23.6838 16.985C27.3626 16.985 30.3358 19.9582 30.3358 23.637C30.3358 27.3157 27.3626 30.2889 23.6838 30.2889H7.00353C3.32479 30.2889 0.351562 27.3157 0.351562 23.637C0.351562 19.9582 3.32479 16.985 7.00353 16.985H23.6838Z", fill: "#36C5F0" }),
    /* @__PURE__ */ jsx("path", { d: "M50.3409 23.637C50.3409 19.9582 53.3141 16.985 56.9928 16.985C60.6716 16.985 63.6448 19.9582 63.6448 23.637C63.6448 27.3157 60.6716 30.2889 56.9928 30.2889H50.3409V23.637ZM47.0149 23.637C47.0149 27.3157 44.0416 30.2889 40.3629 30.2889C36.6842 30.2889 33.7109 27.3157 33.7109 23.637V6.95666C33.7109 3.27792 36.6842 0.304688 40.3629 0.304688C44.0416 0.304688 47.0149 3.27792 47.0149 6.95666V23.637Z", fill: "#2EB67D" }),
    /* @__PURE__ */ jsx("path", { d: "M40.3629 50.294C44.0416 50.294 47.0149 53.2672 47.0149 56.946C47.0149 60.6247 44.0416 63.5979 40.3629 63.5979C36.6842 63.5979 33.7109 60.6247 33.7109 56.946V50.294H40.3629ZM40.3629 46.968C36.6842 46.968 33.7109 43.9948 33.7109 40.316C33.7109 36.6373 36.6842 33.6641 40.3629 33.6641H57.0432C60.722 33.6641 63.6952 36.6373 63.6952 40.316C63.6952 43.9948 60.722 46.968 57.0432 46.968H40.3629Z", fill: "#ECB22E" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_4784_168587", children: /* @__PURE__ */ jsx("rect", { width: 64, height: 64, fill: "white" }) }) })
] });
const ForwardRef = forwardRef(SvgSlack);
export default ForwardRef;
