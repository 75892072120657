import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUpgrade = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M8 1L14.9282 7H1.0718L8 1Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("rect", { x: 4, y: 6, width: 8, height: 4, fill: "currentColor" }),
  /* @__PURE__ */ jsx("rect", { x: 4, y: 11, width: 8, height: 1, fill: "currentColor" }),
  /* @__PURE__ */ jsx("rect", { x: 4, y: 13, width: 8, height: 1, fill: "currentColor" })
] });
const ForwardRef = forwardRef(SvgUpgrade);
export default ForwardRef;
