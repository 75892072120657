import CopyButton from '@bugbug/core/components/CopyButton';
import { useTranslation } from 'react-i18next';

import * as S from './RunMetadataSection.styled';

interface IdFieldProps {
  value?: {
    label: string;
    value?: string;
  };
}

export const IdField = ({ value }: IdFieldProps) => {
  const { t } = useTranslation();

  if (!value) {
    return null;
  }

  const val = value.value || t('runMetadataSection.noData', '(no data)');
  return (
    <S.MetaDataId>
      <S.Label>{value.label}</S.Label>
      <S.Value>
        {val}
        <CopyButton value={val} small />
      </S.Value>
    </S.MetaDataId>
  );
};
