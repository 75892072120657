export const isValidUrl = (url: string): boolean => {
  const supportedProtocols = ['https', 'http'];

  if (!url.includes('.') && !url.includes('://') && /\s/g.test(url)) {
    return false;
  }

  const [address, protocol = 'http'] = url.split(/:\/\/?/).reverse();

  try {
    // eslint-disable-next-line no-new
    new URL(`${protocol}://${address}`);

    return supportedProtocols.includes(protocol);
  } catch (e) {
    return false;
  }
};

export const isDigits = (value: string): boolean => /^\d+$/.test(value);
