import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Header, Footer } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import {
  selectSubscription,
  selectCurrentOrganizationId,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import { Container, Content } from './ReachedLimitModal.styled';

const ReachedLimitModal = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const subscription = useSelector(selectSubscription);
  const organizationId = useSelector(selectCurrentOrganizationId);

  const handleGoToSubscriptions = () => {
    modal.hide();
    analytics.trackEvent(TRACK_EVENT_TYPE.UPGRADE_SUBSCRIPTION);
    history.push(reverse(urls.subscription, { organizationId }));
  };

  return (
    <Container className={className} aria-label={t('reachedLimitModal.title')}>
      <Content>
        <Icon name="moneyPixel" />
        <div>
          <Header>{t('reachedLimitModal.title', 'Upgrade your plan to use this feature')}</Header>
          <p>
            <Trans
              i18nKey="reachedLimitModal.currentPlan"
              defaults="Your current plan is <strong>{{ planType, uppercase }}</strong>"
              values={{ planType: subscription.planType }}
            />
          </p>
          <p>
            <Trans i18nKey="reachedLimitModal.description">
              Some features are only available in higher plans. Please take a look at available
              plans in your <Link onClick={handleGoToSubscriptions}>subscription settings</Link>.
            </Trans>
          </p>
        </div>
      </Content>
      <Footer>
        <Button onClick={modal.hide}>{t('default.button.close')}</Button>
        <ActionButton onClick={handleGoToSubscriptions} variant={BUTTON_VARIANT.PRIMARY}>
          {t('reachedLimitModal.seeUpgradeOptionsButton', 'See upgrade options')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

export default ReachedLimitModal;
