import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { equals, defaultTo, pathEq, last, complement, always } from 'ramda';
import * as Yup from 'yup';

import {
  STEP_TYPE,
  INTERACTION_POSITION_TYPE,
  ASSERTION_TYPE,
  ASSERTION_PROPERTY_TYPE,
  ASSERTION_PROPERTIES,
  ASSERTION_TYPES_BY_PROPERTY,
  STEP_SCROLL_TARGET_TYPE,
  STEP_SCROLL_TO_TYPE,
  STEP_SCROLL_DIRECTION_TYPE,
  STEP_SCROLL_EDGE_TYPE,
  SELECT_TYPE,
  CUSTOM_SELECTOR,
  DND_TARGET_TYPE,
} from '~/constants/step';
import { VARIABLE_TYPE } from '~/constants/variables';
import { WAITING_CONDITION_TYPE } from '~/modules/constans';
import i18n from '~/translations';
import * as validators from '~/utils/validators';
import { VALIDATION_MESSAGE } from '~/utils/validators';

import { isDocumentAssertion, isTrueFalseAssertion } from '../Interaction/Interaction.helpers';

export const SECTION = {
  INTERACTION: 'INTERACTION',
  WAITING_CONDITIONS: 'WAITING_CONDITIONS',
  EXECUTION: 'EXECUTION',
  SCREENSHOTS: 'SCREENSHOTS',
};

export const SECTION_LABEL = {
  [SECTION.SCREENSHOTS]: i18n.t('stepDetails.tabs.screenshots.label', 'Screenshots'),
  [SECTION.INTERACTION]: i18n.t('stepDetails.tabs.interaction.label', 'Interaction'),
  [SECTION.EXECUTION]: i18n.t('stepDetails.tabs.execution.label', 'Execution'),
  [SECTION.WAITING_CONDITIONS]: i18n.t(
    'stepDetails.tabs.waitingConditions.label',
    'Waiting Conditions',
  ),
};

export const SECTION_DESCRIPTION = {
  [SECTION.WAITING_CONDITIONS]: i18n.t(
    'stepDetails.tabs.waitingConditions.description',
    'Some waiting conditions are by default inherited from project settings. You can override the global settings for each step.',
  ),
};

export const WAITING_CONDITION_STATE = {
  DEFAULT: 'DEFAULT',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const defaultValues = {
  frameLocation: 'main',
  assertionExpectedValue: 'true',
  assertionJavaScript: `// your code should return true (step passed) or false (failed)\nreturn true;`,
  assertionVariableName: '',
  assertionProperty: ASSERTION_PROPERTIES[0].value,
  assertionType: ASSERTION_TYPES_BY_PROPERTY[ASSERTION_PROPERTIES[0].value][0].value,
  scrollInside: STEP_SCROLL_TARGET_TYPE.WINDOW,
  scrollTo: STEP_SCROLL_TO_TYPE.COORDS,
  scrollDirection: STEP_SCROLL_DIRECTION_TYPE.DOWN,
  scrollEdge: STEP_SCROLL_EDGE_TYPE.BOTTOM_CENTER,
  expectedConditionNumber: 0,
  dragOn: DND_TARGET_TYPE.ELEMENT,
  dropOn: DND_TARGET_TYPE.ELEMENT,
  localVariableSource: VARIABLE_TYPE.VALUE,
  localVariableCode: 'return "value";',
};

const selectorEntityValidator = Yup.object().shape({
  id: Yup.string(),
  isActive: Yup.boolean(),
  isCustom: Yup.boolean(),
  selector: Yup.string().when('isActive', {
    is: true,
    then: () => validators.selectorPatternValidator,
  }),
});

const selectorsValidator = Yup.array()
  .of(
    Yup.object({
      id: Yup.string(),
      isActive: Yup.boolean(),
      isCustom: Yup.boolean(),
      selector: Yup.string().when('isActive', {
        is: true,
        then: () => validators.selectorPatternValidator,
      }),
    }),
  )
  .required(VALIDATION_MESSAGE.REQUIRED)
  .min(1)
  .transform((selectors = []) => {
    const containsCustom = pathEq(['isCustom'], true, last(selectors));
    if (!containsCustom) {
      return [...selectors, { ...CUSTOM_SELECTOR, isActive: !selectors.length }];
    }
    return selectors;
  })
  .ensure();

const waitingConditionSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(WAITING_CONDITION_TYPE)),
  isActive: Yup.boolean(),
  expected: Yup.string().nullable().default(null),
});

const sharedRules = {
  type: Yup.string()
    .oneOf(Object.values(STEP_TYPE))
    .required(validators.VALIDATION_MESSAGE.REQUIRED),
  isActive: Yup.boolean().default(true),
  isBreakpoint: Yup.boolean().default(false),
  runTimeout: validators.runTimeoutValidator.transform(defaultTo(null)).nullable().default(null),
  sleep: validators.sleepValidator.transform(defaultTo(null)).nullable().default(null),
  waitingConditions: Yup.array().of(waitingConditionSchema).ensure(),
  continueOnFailure: Yup.boolean().default(false),
  // TODO: used only in Type and Change
  hasSecretValue: Yup.boolean().nullable().default(false),
};

const interactionPositionRule = Yup.string()
  .oneOf(Object.values(INTERACTION_POSITION_TYPE))
  .transform(defaultTo(INTERACTION_POSITION_TYPE.SMART))
  .default(INTERACTION_POSITION_TYPE.SMART);

const selectTypeValidator = Yup.string()
  .oneOf(Object.values(SELECT_TYPE))
  .default(SELECT_TYPE.TEXT)
  .transform(defaultTo(SELECT_TYPE.TEXT))
  .required();

const urlSchema = Yup.object().shape({
  ...sharedRules,
  url: validators.urlWithVariablesValidator.ensure(),
  username: Yup.string().ensure(),
  password: Yup.string().ensure(),
});

const ignoredValueSchema = Yup.mixed().notRequired().strip();

const equalsScrollToType = (type) => ({
  is: equals(type),
  otherwise: () => ignoredValueSchema,
});

const selectorValidatorRequiredWhen = (relatedField, compareFunc) =>
  selectorsValidator.when(relatedField, {
    is: compareFunc,
    otherwise: (s) =>
      s
        .of(selectorEntityValidator.shape({ selector: Yup.string().notRequired() }))
        .min(0)
        .notRequired(),
  });

const scrollSchema = Yup.object().shape({
  ...sharedRules,
  selectors: selectorValidatorRequiredWhen('scrollInside', equals(STEP_SCROLL_TARGET_TYPE.ELEMENT)),
  scrollInside: Yup.string()
    .oneOf(Object.values(STEP_SCROLL_TARGET_TYPE))
    .transform(defaultTo(defaultValues.scrollInside))
    .default(defaultValues.scrollInside),
  scrollTo: Yup.string()
    .oneOf(Object.values(STEP_SCROLL_TO_TYPE))
    .transform(defaultTo(defaultValues.scrollTo))
    .default(defaultValues.scrollTo),
  scrollDirection: Yup.string()
    .oneOf(Object.values(STEP_SCROLL_DIRECTION_TYPE))
    .transform(defaultTo(defaultValues.scrollDirection))
    .default(defaultValues.scrollDirection)
    .when('scrollTo', equalsScrollToType(STEP_SCROLL_TO_TYPE.UNTIL_NEXT_STEP_ELEMENT_IS_VISIBLE)),
  scrollEdge: Yup.string()
    .oneOf(Object.values(STEP_SCROLL_EDGE_TYPE))
    .transform(defaultTo(defaultValues.scrollEdge))
    .default(defaultValues.scrollEdge)
    .when('scrollTo', equalsScrollToType(STEP_SCROLL_TO_TYPE.EDGE)),
  scrollX: validators.positiveIntegerValidator
    .required(VALIDATION_MESSAGE.REQUIRED)
    .when('scrollTo', equalsScrollToType(STEP_SCROLL_TO_TYPE.COORDS)),
  scrollY: validators.positiveIntegerValidator
    .required(VALIDATION_MESSAGE.REQUIRED)
    .when('scrollTo', equalsScrollToType(STEP_SCROLL_TO_TYPE.COORDS)),
});

const mouseEventSchema = Yup.object().shape({
  ...sharedRules,
  selectors: selectorsValidator,
  interactionPosition: interactionPositionRule,
});

export const STEP_SCHEMA = {
  [STEP_TYPE.GOTO]: urlSchema,
  [STEP_TYPE.NEW_TAB]: urlSchema,
  [STEP_TYPE.SCROLL]: scrollSchema,
  [STEP_TYPE.TYPE]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorsValidator,
    value: Yup.string().ensure().required(validators.VALIDATION_MESSAGE.REQUIRED),
  }),
  [STEP_TYPE.ANSWER_PROMPT]: Yup.object().shape({
    ...sharedRules,
    value: Yup.string().ensure().required(validators.VALIDATION_MESSAGE.REQUIRED),
  }),
  [STEP_TYPE.SELECT]: Yup.object().shape({
    ...sharedRules,
    selectType: selectTypeValidator,
    selectIsMultiple: Yup.boolean().transform(defaultTo(false)).default(false),
    selectors: selectorsValidator,
    value: Yup.string()
      .required(validators.VALIDATION_MESSAGE.REQUIRED)
      .when('selectIsMultiple', {
        is: true,
        otherwise: (schema) =>
          schema.test(
            'is-single-line',
            validators.VALIDATION_MESSAGE.SINGLE_LINE,
            (value) => (value?.split('\n')?.length || 1) < 2,
          ),
      })
      .ensure(),
  }),
  [STEP_TYPE.CHANGE]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorsValidator,
    value: Yup.string().required(validators.VALIDATION_MESSAGE.REQUIRED).ensure(),
  }),
  [STEP_TYPE.UPLOAD_FILE]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorsValidator,
    value: validators.fileValidator,
  }),
  [STEP_TYPE.DRAG_AND_DROP]: Yup.object().shape({
    ...sharedRules,
    // drag
    dndDragOn: Yup.string()
      .transform(defaultTo(defaultValues.dragOn))
      .default(defaultValues.dragOn),
    selectors: selectorValidatorRequiredWhen('dndDragOn', equals(DND_TARGET_TYPE.ELEMENT)),
    dndDragX: validators.positiveIntegerValidator,
    dndDragY: validators.positiveIntegerValidator,
    interactionPosition: interactionPositionRule,
    // drop
    dndDropOn: Yup.string()
      .transform(defaultTo(defaultValues.dropOn))
      .default(defaultValues.dropOn),
    dndDropSelectors: selectorValidatorRequiredWhen('dndDropOn', equals(DND_TARGET_TYPE.ELEMENT)),
    dndDropX: validators.positiveIntegerValidator,
    dndDropY: validators.positiveIntegerValidator,
    dndDropInteractionPosition: interactionPositionRule,
  }),
  [STEP_TYPE.EXECUTE]: Yup.object().shape({
    ...sharedRules,
    code: validators.jsCodeValidator.ensure().required(validators.VALIDATION_MESSAGE.REQUIRED),
  }),
  [STEP_TYPE.CLICK]: mouseEventSchema,
  [STEP_TYPE.DOUBLE_CLICK]: mouseEventSchema,
  [STEP_TYPE.RIGHT_CLICK]: mouseEventSchema,
  [STEP_TYPE.MOUSE_UP]: mouseEventSchema,
  [STEP_TYPE.MOUSE_DOWN]: mouseEventSchema,
  [STEP_TYPE.HOVER]: mouseEventSchema,
  [STEP_TYPE.SET_LOCAL_VARIABLE]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorValidatorRequiredWhen('localVariableSource', equals(VARIABLE_TYPE.ELEMENT)),
    isTargetDocument: Yup.boolean().when('localVariableSource', {
      is: equals(VARIABLE_TYPE.ELEMENT),
      then: (schema) => schema.transform(always(false)).default(false),
      otherwise: (schema) => schema.transform(always(true)).default(true),
    }),
    localVariableName: validators.valueNameValidator
      .nullable()
      .required(validators.VALIDATION_MESSAGE.REQUIRED)
      .transform(defaultTo('')),
    localVariableSource: Yup.string()
      .oneOf(Object.values(VARIABLE_TYPE))
      .default(defaultValues.localVariableSource),
    value: Yup.string()
      .when('localVariableSource', {
        is: equals(VARIABLE_TYPE.VALUE),
        then: (schema) => schema.required(validators.VALIDATION_MESSAGE.REQUIRED),
      })
      .ensure(),
    code: validators.jsCodeValidator
      .when('localVariableSource', {
        is: equals(VARIABLE_TYPE.EVALUATE),
        then: (schema) =>
          schema
            .required(validators.VALIDATION_MESSAGE.REQUIRED)
            .transform(defaultTo(defaultValues.localVariableCode))
            .default(defaultValues.localVariableCode),
      })
      .ensure(),
  }),
  [STEP_TYPE.ASSERT]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorValidatorRequiredWhen('assertionProperty', complement(isDocumentAssertion)),
    value: Yup.string().ensure(),
    assertionProperty: Yup.string().transform(defaultTo(defaultValues.assertionProperty)),
    assertionType: Yup.string().when(['assertionProperty'], ([assertionProperty], schema) => {
      const defaultType = ASSERTION_TYPES_BY_PROPERTY[assertionProperty]?.[0].value;
      return schema.transform((currentValue) => currentValue ?? defaultType).default(defaultType);
    }),
    assertionExpectedValue: Yup.mixed().when(['assertionProperty', 'assertionType'], {
      is: (property, type) =>
        [ASSERTION_TYPE.GREATER_THAN, ASSERTION_TYPE.LESS_THAN].includes(type) ||
        (property === ASSERTION_PROPERTY_TYPE.COUNT && type === ASSERTION_TYPE.EQUAL),
      then: () => validators.numberOrVariableValidator,
      otherwise: () =>
        Yup.mixed().when('assertionProperty', {
          is: (property) => !isTrueFalseAssertion(property),
          then: () =>
            Yup.string()
              .transform(defaultTo(''))
              .default('')
              .max(
                DATA_RESTRICTIONS.ASSERTION_EXPECTED_VALUE_MAX_LENGTH,
                validators.VALIDATION_MESSAGE.MAX_LENGTH,
              ),
          otherwise: () =>
            Yup.string()
              .transform(() => defaultValues.assertionExpectedValue)
              .default(defaultValues.assertionExpectedValue),
        }),
    }),
    assertionJavaScript: validators.jsCodeValidator
      .when('assertionProperty', {
        is: equals(ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT),
        then: (schema) =>
          schema
            .required(validators.VALIDATION_MESSAGE.REQUIRED)
            .transform(defaultTo(defaultValues.assertionJavaScript))
            .default(defaultValues.assertionJavaScript),
      })
      .ensure(),
    assertionVariableName: validators.variableNameValidator
      .when('assertionProperty', {
        is: equals(ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE),
        then: (schema) =>
          schema
            .required(validators.VALIDATION_MESSAGE.REQUIRED)
            .transform(defaultTo(defaultValues.assertionVariableName))
            .default(defaultValues.assertionVariableName),
      })
      .ensure(),
  }),
  [STEP_TYPE.SWITCH_CONTEXT]: Yup.object().shape({
    ...sharedRules,
    frameLocation: validators.framePathValidator
      .transform((value) => value || defaultValues.frameLocation)
      .default(defaultValues.frameLocation),
    tabNo: validators.positiveIntegerValidator.required(validators.VALIDATION_MESSAGE.REQUIRED),
  }),
  [STEP_TYPE.CLEAR]: Yup.object().shape({
    ...sharedRules,
    selectors: selectorsValidator,
  }),
  DEFAULT: Yup.object().shape({
    ...sharedRules,
  }),
};

export const SAVE_ACTION_TYPE = {
  SAVE: 'SAVE',
  SAVE_AND_CLOSE: 'SAVE_AND_CLOSE',
};

export const STEP_UNSAVED_CHANGES = i18n.t(
  'stepDetails.unsavedChangesPrompt',
  'You have unsaved changes. Are you sure to leave this page?',
);
