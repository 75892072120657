import Link from '@bugbug/core/components/Link';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Content, Footer, Header } from '~/components/modals/Modal';
import { PaidFeatureBanner } from '~/components/PaidFeatureGuard';
import useModal from '~/hooks/useModal';
import { selectProjectApiToken } from '~/modules/project/project.selectors';
import analytics from '~/services/analytics';

import * as S from './SuiteCliCommandsModal.styled';

const SuiteCliCommandsModal = ({ suiteId }) => {
  const { t } = useTranslation();
  const apiToken = useSelector(selectProjectApiToken);
  const modal = useModal();

  const handleCopyRunCommand = useCallback(() => {
    analytics.trackEvent('run_command_copied', {
      run_type: 'suite',
    });
  }, []);

  return (
    <S.Container>
      <Header>{t('suiteCliCommandsModal.title', 'Run this suite via CLI')}</Header>
      <Content>
        <PaidFeatureBanner messageFor="run suite via cli" onRedirect={modal.hide} />

        <S.Paragraph>
          {t(
            'suiteCliCommandsModal.topInfo',
            `Use BugBug.io command line tool to run tests in your continuous integration\u00A0pipelines`,
          )}
        </S.Paragraph>
        <S.StepsWrapper>
          <S.StepTitle>
            {t('suiteCliCommandsModal.firstStepTitle', '1. Install CLI and configure token')}
          </S.StepTitle>
          <S.Command
            value={t(
              'suiteCliCommandsModal.configureCliCommand',
              `npm install -g @testrevolution/bugbug-cli && \\\n    bugbug config set-token \\\n    {{apiToken}}`,
              { apiToken },
            )}
          />
          <S.StepTitle>{t('suiteCliCommandsModal.secondStepTitle', '2. Run suite')}</S.StepTitle>
          <S.Command
            value={t(
              'suiteCliCommandsModal.runSuiteCommand',
              'bugbug remote run suite {{suiteId}}',
              { suiteId },
            )}
            onCopy={handleCopyRunCommand}
          />
        </S.StepsWrapper>
        <S.Paragraph>
          {t(
            'suiteCliCommandsModal.bottomInfo',
            'You can also use API commands for listing tests or checking results.',
          )}
          <br />
          {t('suiteCliCommandsModal.learnMore', 'Learn more in ')}
          <Link
            to={t(
              'suiteCliCommandsModal.documentationUrl',
              'https://docs.bugbug.io/integrations/api',
            )}
          >
            {t('suiteCliCommandsModal.documentationLinkLabel', 'documentation')}
          </Link>
        </S.Paragraph>
      </Content>
      <Footer>
        <S.CloseButton onClick={modal.hide}>
          {t('suiteCliCommandsModal.closeButtonLabel', 'Close')}
        </S.CloseButton>
      </Footer>
    </S.Container>
  );
};

SuiteCliCommandsModal.propTypes = {
  suiteId: PropTypes.string.isRequired,
};

export default SuiteCliCommandsModal;
