import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Footer } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import { Container, Content } from './IncognitoTabsCloseModal.styled';

const IncognitoTabsCloseModal = ({ className, onConfirm }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleConfirm = useCallback(() => {
    modal.hide();
    onConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirm]);

  return (
    <Container
      className={className}
      aria-label={t('incognitoTabsCloseModal.ariaLabel', 'Close all incognito windows?')}
    >
      <Content>
        <Icon name="help" />
        <div>
          <Header>{t('incognitoTabsCloseModal.title', 'Close all incognito windows?')}</Header>
          <p>
            {t(
              'incognitoTabsCloseModal.description',
              'Before running or recording a test we need to close all open incognito windows. Are you sure you want to do this?',
            )}
          </p>
        </div>
      </Content>
      <Footer>
        <Button onClick={modal.hide}>{t('incognitoTabsCloseModal.cancel', 'Cancel')}</Button>
        <ActionButton onClick={handleConfirm} variant={BUTTON_VARIANT.PRIMARY}>
          {t('incognitoTabsCloseModal.confirm', 'Yes, close windows and continue')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

export default IncognitoTabsCloseModal;
