import CopyButton from '@bugbug/core/components/CopyButton';
import React from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';

import * as S from './Command.styled';

interface CommandProps {
  className?: string;
  value: string;
  onCopy?: SideEffect;
}
const Command = ({ className, value, onCopy }: CommandProps) => (
  <S.Container className={className} data-testid="Command">
    <S.Code>{value}</S.Code>
    <CopyButton onCopy={onCopy} value={value} small />
  </S.Container>
);

export default Command;
