import toasts from '~/services/toasts/common';
import i18n from '~/translations';

export const showCancelSubscriptionSuccess = (plan, period) => {
  const content = i18n.t(
    'notifications.cancelSubscription.success',
    'Success! You will keep {{ plan, uppercase }} {{ period }} plan',
    { plan, period },
  );

  toasts.showSuccess({ content });
};

export const showOrganizationUpdateError = () => {
  const content = i18n.t('notifications.organizationUpdate.error', 'An unexpected error occurred');

  toasts.showError({ content });
};

export const showStartTrialError = () => {
  const content = i18n.t(
    'notifications.startTrial.error',
    'An error occurred while starting trial',
  );

  toasts.showError({ content });
};
