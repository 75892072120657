import { useCallback } from 'react';
import { useLocation } from 'react-router';

import type { MixpanelCustomPageView } from '~/services/analytics/types';

import mixpanel from '~/services/analytics/mixpanel';

export const useTrackCustomPageView = () => {
  const location = useLocation();

  return useCallback(
    (page: MixpanelCustomPageView) => {
      mixpanel.trackEvent('page_viewed', {
        url: location.pathname,
        page_name: page,
      });
    },
    [location.pathname],
  );
};
