import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Alert } from '@bugbug/core/types/alerts';
import { ConfirmModal } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useDeleteAlertMutation } from '~/modules/alerts';
import toasts from '~/services/toasts';

interface DeleteAlertModalProps {
  alert: Alert;
}
const DeleteAlertModal = ({ alert }: DeleteAlertModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const [handleDelete, { isLoading, isError, isSuccess }] = useDeleteAlertMutation();

  const handleDeleteConfirm = () => {
    handleDelete(alert.id);
  };

  useEffect(() => {
    if (isSuccess) {
      toasts.showSuccess({
        content: t('deleteAlertModal.success', 'Alert has been deleted.'),
        id: 'deleteAlertModal',
      });
      modal.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, t]);

  return (
    <ConfirmModal
      title={t('deleteAlertModal.title', 'Do you want to delete this alert?')}
      pending={isLoading}
      hasInternalServerError={isError}
      onConfirm={handleDeleteConfirm}
      onCancel={modal.hide}
    />
  );
};

export default DeleteAlertModal;
