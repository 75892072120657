import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { useFormik } from 'formik';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import type { Component } from '@bugbug/core/types/components';
import FormField from '~/components/FormField';
import { ErrorInfo, Header, Footer, Content } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useRenameComponentMutation } from '~/modules/components';
import { getExtractedErrorMessages, isBadRequestError } from '~/services/api/utils';
import { idValidator, nameValidator, VALIDATION_MESSAGE } from '~/utils/validators';

import * as S from './EditComponentModal.styled';

const ComponentSchema = Yup.object().shape({
  id: idValidator.required(),
  name: nameValidator
    .max(DATA_RESTRICTIONS.COMPONENT_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH)
    .required(),
});

interface EditComponentModalProps {
  component: Component;
}

const EditComponentModal = ({ component }: EditComponentModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const [handleRename, { isError, isSuccess, error }] = useRenameComponentMutation();

  const initialValues = useMemo(() => R.pick(['id', 'name'], component), [component]);

  const handleSubmit = useCallback(
    (values) => {
      handleRename(ComponentSchema.cast(values));
    },
    [handleRename],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: ComponentSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isError) {
      if (isBadRequestError(error)) {
        const errorMessages = getExtractedErrorMessages(error);
        formik.setErrors(errorMessages);
      }
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      modal.hide();
    }
  }, [isSuccess, error, modal]);

  return (
    <S.Form data-testid="EditComponentModal" onSubmit={formik.handleSubmit}>
      <Header>
        {t('editComponentModal.title', 'Edit component "{{- name}}"', {
          name: component.name,
        })}
      </Header>
      <Content>
        <FormField label={t('editComponentModal.nameInputLabel', 'Name')}>
          <Input
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
            data-testid="EditComponentModal.Input"
          />
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo
          isVisible={isError && !isBadRequestError(error)}
          inlineErrorText={t('editComponentModal.error.inlineError', 'Renaming failed.')}
        />
        <Button
          data-testid="EditComponentModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting}
        >
          {t('default.button.cancel')}
        </Button>

        <ActionButton
          data-testid="EditComponentModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={formik.isSubmitting}
          succeeded={isSuccess}
        >
          {t('editComponentModal.submitButton', 'Save component')}
        </ActionButton>
      </Footer>
    </S.Form>
  );
};

export default EditComponentModal;
