import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

import { Content as ContentBase, Header } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 470px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  ${Header} {
    margin-bottom: 7px;
  }

  svg {
    min-width: 64px;
    height: 64px;
    margin-right: 16px;
    margin-left: -1px;
    margin-top: -4px;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const CloseButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.DEFAULT,
}))`
  &:last-child {
    position: relative;
    left: 14px;
  }
`;
