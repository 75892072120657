import type { LinkProps } from '../../../Link/Link';
import type { ButtonProps } from '../../Button';

import Button from '../../Button';

import * as S from './LinkButton.styled';

interface LinkButtonProps extends LinkProps {
  button?: Omit<ButtonProps, 'onClick' | 'type'>;
}

export const LinkButton = ({ button = {}, children, ...anchorProps }: LinkButtonProps) => (
  <S.LinkWrapper {...anchorProps}>
    <Button {...button} onClick={undefined} type="button" disabled={anchorProps.disabled}>
      {children}
    </Button>
  </S.LinkWrapper>
);
