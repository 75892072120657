import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgFile = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", className: "feather feather-file-text", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" }),
  /* @__PURE__ */ jsx("polyline", { points: "14 2 14 8 20 8" }),
  /* @__PURE__ */ jsx("line", { x1: 16, y1: 13, x2: 8, y2: 13 }),
  /* @__PURE__ */ jsx("line", { x1: 16, y1: 17, x2: 8, y2: 17 }),
  /* @__PURE__ */ jsx("polyline", { points: "10 9 9 9 8 9" })
] });
const ForwardRef = forwardRef(SvgFile);
export default ForwardRef;
