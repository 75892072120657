import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgBitbucket = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 443.43 63.27", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("style", { children: ".cls-1{fill:#253858;}.cls-2{fill:none;}.cls-3{fill:#2684ff;}.cls-4{fill:url(#New_Gradient_Swatch_1);}" }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "New_Gradient_Swatch_1", x1: 64.01, y1: 30.27, x2: 32.99, y2: 54.48, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { offset: 0.18, stopColor: "#0052cc" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#2684ff" })
    ] })
  ] }),
  /* @__PURE__ */ jsx("title", { children: "Bitbucket-blue" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "Blue", children: [
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M116.87,4.58c11.79,0,17.51,5.81,17.51,15.14,0,7-3.17,11.26-9.68,12.85,8.54,1.41,12.76,6.07,12.76,14.25,0,9.24-6.25,15.58-19.18,15.58H94.43V4.58Zm-14.52,7.57V29.39h13.46c7.48,0,10.56-3.43,10.56-9s-3.34-8.27-10.56-8.27Zm0,24.29v18h16.19c7.57,0,11-2.73,11-8.54,0-6.16-3.26-9.5-11.17-9.5Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M150.4,1.5a4.94,4.94,0,0,1,5.28,5.28,5.28,5.28,0,0,1-10.56,0A4.94,4.94,0,0,1,150.4,1.5Zm-3.87,16.9h7.57v44h-7.57Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M183,55a24,24,0,0,0,4.75-.62v7.3a18.19,18.19,0,0,1-5.1.7c-9.42,0-14-5.54-14-13.73V25.43h-7.13v-7h7.13V9.06H176v9.33h11.7v7H176V48.49C176,52.36,178.29,55,183,55Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M218.95,63.27c-6.42,0-11.35-2.9-14-8.62v7.74h-7.57V0H205V26.31c2.82-5.81,8.1-8.8,14.87-8.8,11.7,0,17.6,9.94,17.6,22.88C237.43,52.8,231.27,63.27,218.95,63.27ZM217.8,24.55C211,24.55,205,28.86,205,38.63v3.52c0,9.77,5.54,14.08,12,14.08,8.54,0,12.94-5.63,12.94-15.84C229.86,29.83,225.64,24.55,217.8,24.55Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M245.35,18.39h7.57V45c0,7.92,3.17,11.44,10.38,11.44,7,0,11.88-4.66,11.88-13.55V18.39h7.57v44h-7.57V55.17a15.43,15.43,0,0,1-13.9,8.1c-10.12,0-15.93-7-15.93-19.1Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M325.25,61.25c-2.64,1.41-6.69,2-10.74,2-15.66,0-23-9.5-23-23,0-13.29,7.3-22.79,23-22.79A23.26,23.26,0,0,1,325,19.62v7A22.17,22.17,0,0,0,315,24.55c-11.44,0-16.1,7.21-16.1,15.75s4.75,15.75,16.28,15.75a27.78,27.78,0,0,0,10-1.58Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M333.88,62.39V0h7.57V39l18.92-20.59h9.86L349.54,40,371.1,62.39H360.8L341.44,41.8V62.39Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M396.18,63.27c-16.46,0-23.67-9.5-23.67-23,0-13.29,7.39-22.79,20.77-22.79,13.55,0,19,9.42,19,22.79v3.43H380.16c1.06,7.48,5.9,12.32,16.28,12.32a39,39,0,0,0,13.38-2.37v7C406.21,62.57,400.67,63.27,396.18,63.27Zm-16.1-26h24.55c-.44-8.18-4.14-12.85-11.7-12.85C384.91,24.38,380.87,29.57,380.08,37.22Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M438.68,55a24,24,0,0,0,4.75-.62v7.3a18.19,18.19,0,0,1-5.1.7c-9.42,0-14-5.54-14-13.73V25.43h-7.13v-7h7.13V9.06h7.39v9.33h11.7v7h-11.7V48.49C431.73,52.36,434,55,438.68,55Z" }),
    /* @__PURE__ */ jsx("polygon", { className: "cls-2", points: "24.7 43.51 37.75 43.51 40.9 25.12 21.23 25.12 24.7 43.51" }),
    /* @__PURE__ */ jsx("path", { className: "cls-3", d: "M2,6.26A2,2,0,0,0,0,8.58L8.49,60.12a2.72,2.72,0,0,0,2.66,2.27H51.88a2,2,0,0,0,2-1.68L62.37,8.59a2,2,0,0,0-2-2.32ZM37.75,43.51h-13L21.23,25.12H40.9Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-4", d: "M59.67,25.12H40.9L37.75,43.51h-13L9.4,61.73a2.71,2.71,0,0,0,1.75.66H51.89a2,2,0,0,0,2-1.68Z" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgBitbucket);
export default ForwardRef;
