import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgNpm = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 117.92, height: 117.92, viewBox: "0 0 117.92 117.92", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "npm-icon" }),
  /* @__PURE__ */ jsx("g", { id: "b7e1866c-929f-46bb-90d0-fbe199e27b0d", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "e4b0f65a-ca63-4eee-a773-47de69e38a11", "data-name": "logosandtypes com", children: /* @__PURE__ */ jsx("path", { d: "M0,0V117.92H117.92V0ZM98.15,98.16H78.38V39.53H58.61V98.16H19.76V19.77H98.15Z", style: {
    fill: "#cb3837"
  } }) }) })
] });
const ForwardRef = forwardRef(SvgNpm);
export default ForwardRef;
