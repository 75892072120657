import { INTERNAL_SERVER_ERROR } from 'http-status';
import { complement, compose, isNil, join, filter, curry, isEmpty } from 'ramda';

import i18n from '~/translations';

import CommonToasts from './common';

export const ACTIONS = {
  STOP: 'stop',
  DELETE: 'delete',
  CLONE: 'clone',
  CREATE: 'create',
  RENAME: 'rename',
  UPDATE: 'update',
  START: 'start',
  STOP_RECORD: 'stopRecord',
  START_RECORD: 'startRecord',
  PASTE: 'paste',
  INSERT: 'insert',
  SPLIT: 'split',
};

export const ACTIONS_MESSAGES_MAP = {
  [ACTIONS.STOP]: {
    pending: i18n.t('internalServerErrorToast.action.stop.pending', 'Stopping'),
    error: i18n.t('internalServerErrorToast.action.stop.error', 'stopping'),
    success: i18n.t('internalServerErrorToast.action.stop.success', 'stopped'),
  },
  [ACTIONS.DELETE]: {
    pending: i18n.t('internalServerErrorToast.action.delete.pending', 'Deleting'),
    error: i18n.t('internalServerErrorToast.action.delete.error', 'deleting'),
    success: i18n.t('internalServerErrorToast.action.delete.success', 'deleted'),
  },
  [ACTIONS.CLONE]: {
    pending: i18n.t('internalServerErrorToast.action.clone.pending', 'Cloning'),
    error: i18n.t('internalServerErrorToast.action.clone.error', 'cloning'),
    success: i18n.t('internalServerErrorToast.action.clone.success', 'cloned'),
  },
  [ACTIONS.PASTE]: {
    pending: i18n.t('internalServerErrorToast.action.paste.pending', 'Pasting'),
    error: i18n.t('internalServerErrorToast.action.paste.error', 'pasting'),
    success: i18n.t('internalServerErrorToast.action.paste.success', 'pasted'),
  },
  [ACTIONS.CREATE]: {
    pending: i18n.t('internalServerErrorToast.action.create.pending', 'Creating'),
    error: i18n.t('internalServerErrorToast.action.create.error', 'creating'),
    success: i18n.t('internalServerErrorToast.action.create.success', 'created'),
  },
  [ACTIONS.INSERT]: {
    pending: i18n.t('internalServerErrorToast.action.insert.pending', 'Inserting'),
    error: i18n.t('internalServerErrorToast.action.insert.error', 'inserting'),
    success: i18n.t('internalServerErrorToast.action.insert.success', 'inserted'),
  },
  [ACTIONS.SPLIT]: {
    pending: i18n.t('internalServerErrorToast.action.split.pending', 'Splitting'),
    error: i18n.t('internalServerErrorToast.action.split.error', 'splitting'),
    success: i18n.t('internalServerErrorToast.action.split.success', 'splitted'),
  },
  [ACTIONS.RENAME]: {
    pending: i18n.t('internalServerErrorToast.action.rename.pending', 'Renaming'),
    error: i18n.t('internalServerErrorToast.action.rename.error', 'renaming'),
    success: i18n.t('internalServerErrorToast.action.rename.success', 'renamed'),
  },
  [ACTIONS.UPDATE]: {
    pending: i18n.t('internalServerErrorToast.action.update.pending', 'Updating'),
    error: i18n.t('internalServerErrorToast.action.update.error', 'updating'),
    success: i18n.t('internalServerErrorToast.action.update.success', 'updated'),
  },
  [ACTIONS.START]: {
    pending: i18n.t('internalServerErrorToast.action.start.pending', 'Starting'),
    error: i18n.t('internalServerErrorToast.action.start.error', 'starting'),
    success: i18n.t('internalServerErrorToast.action.start.success', 'started'),
  },
  [ACTIONS.STOP_RECORD]: {
    pending: i18n.t('internalServerErrorToast.action.stopRecord.pending', 'Stopping record of'),
    error: i18n.t('internalServerErrorToast.action.stopRecord.error', 'stopping record of'),
    success: i18n.t('internalServerErrorToast.action.stopRecord.success', 'stopping recorded'),
  },
  [ACTIONS.START_RECORD]: {
    pending: i18n.t('internalServerErrorToast.action.startRecord.pending', 'Starting record of'),
    error: i18n.t('internalServerErrorToast.action.startRecord.error', 'starting record of'),
    success: i18n.t('internalServerErrorToast.action.startRecord.success', 'recording started'),
  },
};

export const ENTITIES = {
  SUITE: 'suite',
  STEP: 'step',
  STEPS: 'steps',
  SCHEDULE: 'schedule',
  TEST: 'test',
  SUITES: 'suites',
  TESTS: 'tests',
  ALL_SUITES: 'allSuites',
  GROUP: 'group',
  PROJECT_SETTINGS: 'projectSettings',
};

export const ENTITIES_MESSAGES_MAP = {
  [ENTITIES.SUITE]: i18n.t('internalServerErrorToast.entity.suite', 'the suite'),
  [ENTITIES.STEP]: i18n.t('internalServerErrorToast.entity.step', 'the step'),
  [ENTITIES.SCHEDULE]: i18n.t('internalServerErrorToast.entity.schedule', 'the schedule'),
  [ENTITIES.STEPS]: i18n.t('internalServerErrorToast.entity.steps', 'steps'),
  [ENTITIES.TEST]: i18n.t('internalServerErrorToast.entity.test', 'the test'),
  [ENTITIES.SUITES]: i18n.t('internalServerErrorToast.entity.suites', 'suites'),
  [ENTITIES.TESTS]: i18n.t('internalServerErrorToast.entity.tests', 'tests'),
  [ENTITIES.ALL_SUITES]: i18n.t('internalServerErrorToast.entity.allSuites', 'all suites'),
  [ENTITIES.GROUP]: i18n.t('internalServerErrorToast.entity.group', 'the group'),
  [ENTITIES.PROJECT_SETTINGS]: i18n.t(
    'internalServerErrorToast.entity.projectSettings',
    'project settings',
  ),
};

const showInternalServerErrorFn = (entityId, actionId, error = {}, forceShow) => {
  if (
    isEmpty(error) ||
    (error.response && error.response.status >= INTERNAL_SERVER_ERROR) ||
    forceShow
  ) {
    const action = ACTIONS_MESSAGES_MAP[actionId].error;
    const entity = ENTITIES_MESSAGES_MAP[entityId];

    CommonToasts.showError({
      id: compose(join('/'), filter(complement(isNil)))([entity, action, error.status]),
      content: i18n.t(
        'internalServerErrorToast.base',
        'An error occurred while {{action}} {{entity}}',
        {
          action,
          entity,
        },
      ),
    });
  }
};

export const showInternalServerError = curry(showInternalServerErrorFn);

const shouldShowRequestError = (error, forceShow) =>
  isEmpty(error) || (error.response && error.response.status >= INTERNAL_SERVER_ERROR) || forceShow;

const showPendingRequestToastFn = (entityId, actionId) => {
  const action = ACTIONS_MESSAGES_MAP[actionId];
  const entity = ENTITIES_MESSAGES_MAP[entityId];

  const props = {
    id: compose(join('/'), filter(complement(isNil)))([entity, action]),
    content: i18n.t('pendingRequestToast.pendingMessage', '{{action}} {{entity}}...', {
      action: action.pending,
      entity,
    }),
    renderSuccess: (initialProps) =>
      CommonToasts.renderSuccessToast({
        ...initialProps,
        content: i18n.t('pendingRequestToast.successMessage', '{{entity, startCase}} {{action}}', {
          action: action.success,
          entity: entity.replace('the', '').trim(),
        }),
      }),
    renderError: (initialProps, content = '') =>
      CommonToasts.renderErrorToast({
        ...initialProps,
        content:
          content ||
          i18n.t(
            'pendingRequestToast.errorMessage',
            'An error occurred while {{action}} {{entity}}',
            {
              action: action.error,
              entity,
            },
          ),
      }),
  };

  const pendingToast = CommonToasts.showPendingToast(props);
  pendingToast.shouldShowRequestError = shouldShowRequestError;

  return pendingToast;
};

export const showPendingRequestToast = curry(showPendingRequestToastFn);
