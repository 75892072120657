import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Footer } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import {
  selectSubscription,
  selectActivePaymentMethod,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';

import { Container, Content } from './DowngradePlanModal.styled';

const DowngradePlanModal = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const subscription = useSelector(selectSubscription);
  const activePaymentMethod = useSelector(selectActivePaymentMethod);

  const subscribeRequestState = useActionState(OrganizationActions.subscribeRequest, {
    onSuccess: modal.hide,
  });

  const handleConfirmDowngrade = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.DOWNGRADE_SUBSCRIPTION, {
      [TRACK_EVENT_ARG_TYPE.SCHEDULED_SUBSCRIPTION_TYPE]: plan.split('_')[0],
    });
    dispatch(OrganizationActions.subscribeRequest(plan, activePaymentMethod.id, true));
  }, [dispatch, plan, activePaymentMethod.id]);

  return (
    <Container
      className={className}
      aria-label={t('downgradePlanModal.ariaLabel', 'Downgrade plan modal')}
    >
      <Content>
        <Icon name="errorTriangle" />
        <div>
          <Header>{t('downgradePlanModal.title', 'Caution!')}</Header>
          <p>
            <Trans
              i18nKey="downgradePlanModal.paragraph"
              defaults="You're going to lose access to schedule and you will not be able to run tests in the cloud. You will also lose access to the history of tests that were run more than 7 days ago."
            />
          </p>
          <p>
            {t(
              'downgradePlanModal.paragraph2',
              'Your account will be downgraded at the end of current billing period on {{ endDate, date }}.',
              { endDate: subscription.end },
            )}
          </p>
          <p>{t('downgradePlanModal.paragraph3', 'Are you sure?')}</p>
        </div>
      </Content>
      <Footer>
        <Button data-testid="ConfirmModal.CancelButton" onClick={modal.hide}>
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="ConfirmModal.ConfirmButton"
          onClick={handleConfirmDowngrade}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={subscribeRequestState.isLoading}
        >
          {t('downgradePlanModal.confirmButton', 'Downgrade')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DowngradePlanModal.defaultProps = {
  className: null,
};

DowngradePlanModal.propTypes = {
  className: PropTypes.string,
  plan: PropTypes.string.isRequired,
};

export default DowngradePlanModal;
