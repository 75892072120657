import * as Sentry from '@sentry/react';
import { all, spawn, call } from 'redux-saga/effects';

import coreSagas from './core/core.sagas';
import extensionSagas from './extension/extension.sagas';
import organizationSagas from './organization/organization.sagas';
import organizationUsersSagas from './organizationUsers/organizationUsers.sagas';
import profileSagas from './profile/profile.sagas';
import projectSagas from './project/project.sagas';
import suiteSagas from './suite/suite.sagas';
import suiteRunSagas from './suiteRun/suiteRun.sagas';
import testSagas from './test/test.sagas';
import testRunSagas from './testRun/testRun.sagas';
import userSagas from './user/user.sagas';
import variableSagas from './variable/variable.sagas';
import websocketSagas from './websocket/websocket.sagas';
// <-- IMPORT MODULE SAGA -->

export default function* rootSaga() {
  const sagas = [
    projectSagas,
    testSagas,
    testRunSagas,
    suiteSagas,
    suiteRunSagas,
    userSagas,
    websocketSagas,
    extensionSagas,
    coreSagas,
    organizationUsersSagas,
    organizationSagas,
    profileSagas,
    variableSagas,
    // <-- INJECT MODULE SAGA -->
  ];

  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line func-names
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      }),
    ),
  );
}
