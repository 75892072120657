import { useCallback, useMemo } from 'react';

import type { SwitchVariant } from './Switch.types';
import type { TooltipAnchor } from '../Tooltip/Tooltip.types';

import { noop } from '../../utils/toolbox';
import Icon from '../Icon';

import * as S from './Switch.styled';

interface SwitchProps {
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  disabledLabel?: string;
  label?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  tooltip?: string;
  tooltipAnchor?: TooltipAnchor;
  value?: boolean;
  variant?: SwitchVariant;
}

const Switch = (props: SwitchProps) => {
  const {
    className,
    variant = 'primary',
    label,
    tooltip,
    tooltipAnchor = 'left-center',
    value,
    onChange = noop,
    disabled,
    disabledLabel,
    'data-testid': dataTestId = 'Switch',
    name,
  } = props;

  const onClick = useCallback<React.MouseEventHandler<HTMLDivElement>>((event) => {
    event.stopPropagation();
  }, []);

  const labelText = !value && disabledLabel ? disabledLabel : label;

  const Toggle = useMemo<JSX.Element>(
    () => (
      <>
        <input
          checked={value}
          disabled={disabled}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <div className="track" />
        <span className="thumb" />
      </>
    ),
    [value, disabled, name, onChange],
  );

  return (
    <S.Container data-testid={dataTestId} onClick={onClick} className={className}>
      {labelText ? (
        <>
          <S.Switch variant={variant}>
            {Toggle}
            <span className="label">{labelText}</span>
          </S.Switch>

          {tooltip && (
            <S.Tooltip content={tooltip} anchor={tooltipAnchor} offset={2}>
              <Icon name="help" />
            </S.Tooltip>
          )}
        </>
      ) : (
        <S.Tooltip content={tooltip} anchor={tooltipAnchor} offset={2}>
          <S.Switch variant={variant}>{Toggle}</S.Switch>
        </S.Tooltip>
      )}
    </S.Container>
  );
};

export default Switch;
