import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCloseTab = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-close-tab" }),
  /* @__PURE__ */ jsx("g", { id: "ade6fa7d-2d56-424b-8ce4-0f5e7a9cb7fd", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "e0fdee2b-0015-4437-9a6e-56ef6e2634f1", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("line", { x1: 17.83, y1: 15.18, x2: 14.17, y2: 18.84, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 14.17, y1: 15.18, x2: 17.83, y2: 18.84, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("polyline", { points: "3 20.88 6.81 20.88 9.86 11.12 15.47 11.12 17.33 11.12 22.14 11.12 25.19 20.88 29 20.88", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgCloseTab);
export default ForwardRef;
