export const colors = {
  brand: '#461e56',
  primary: '#521ec9',
  primarySoft: '#8466CA',
  accent: '#ffcd1c',
  accentSoft: '#FFF8EA',

  primaryActive: '#6029df',
  primaryInactive: '#a489e2',
  common: {
    transparent: 'transparent',
    white: '#ffffff',
    black: '#000000',
    muted: '#f4f3fb',
  },
  background: {
    primary: '#E8E9FF',
    primary97: '#F4F3FB',
    primary99: '#F9F9FF',
    accent: '#FFF8EA',
    surface: '#FFFFFF',
    success: '#D1FBE2',
    failure: '#FFF0F0',
    neutral: '#FAFAFA',
    highlight: '#F4F3FB',
    highlightLight: '#F9F9FF',
  },
  text: {
    normal: '#2b2b2b',
    secondary: '#666666',
    subtle: '#7A7F86',
    lighter: '#8B8B8B',
    secondaryAllCaps: '#A2A1A1',
    emptyState: '#999999',
  },
  border: {
    normal: '#2B2B2B',
    dark: '#AAAAAA',
    important: '#CCC9C9',
    medium: '#E5E5E5',
    semi: '#EBEBEB',
    light: '#EFEFEF',
    superLight: '#F1F1F3',
  },
  neutrals: {
    '40': '#666666',
    '80': '#CCCBCB',
    '92': '#EAEAEA',
    '94': '#EFEFEF',
    '96': '#F5F5F5',
    '97': '#F7F7F7',
  },
  status: {
    success: '#4dbf8b',
    success90: '#D1FBE2',
    error: '#e95b5b',
    stopped: '#898989',
    warning: 'yellow',
    info: 'blue',
    debugging: '#ffcd1c',
    ignored: '#ffb610',
    initialized: '#999999',
    recording: '#EF2E51',
  },
  code: {
    blue: '#82aaff',
    violet: '#c792ea',
    yellow: '#d4c612',
  },
};

export type Colors = typeof colors;

/*
  Deprecated. Use theme.colors instead.
*/
/* eslint-disable etc/no-commented-out-code */
export const hexToRgba = (hex, alpha = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha})`;
  }
  throw new Error('Bad Hex');
};

export const COLOR = {
  TRANSPARENT: 'transparent',
  WHITE: '#ffffff',
  MUTED: '#f4f3fb',
  BLACK: '#000000',
  PRIMARY: colors.brand,
  SECONDARY: '#ffcd1c',
  SECONDARY_LIGHT: '#fff5d0',
  PRIMARY_LIGHT: '#521ec9',
  PRIMARY_LIGHT_ACTIVE: '#6029df',
  PRIMARY_LIGHT_INACTIVE: '#a489e2',
  PRIMARY_BORDER: '#7a5dbd',
  ERRORBGCOLOR: '#dc3545',
  BORDER_GRAY: '#dadada',
  SUCCESS: '#4dbf8b',
  SUCCESS_2: '#2fa674',
  WARNING: 'yellow',
  ERROR: '#e95b5b',
  STOPPED: '#898989',
  INFO: 'blue',
  SAMPLE_GRAY: '#dcdcdc',
  ORANGE: '#ffb610',
  // TestDetails, IconButton
  GRAY_1: '#e7e7e7',
  // TestDetails
  GRAY_2: '#cacaca',
  GRAY_3: '#e0e0e0',
  // Button,
  GRAY_7: '#b3b3b3',
  // Button,
  GRAY_8: '#9e9e9e',
  // Button,
  GRAY_9: '#eaeaea',
  // Button, Code, Dropdown, CopyButton, Input, TestDetails
  GRAY_10: '#788088',
  // Checkbox,
  GRAY_11: '#6d6d6d',
  // Checkbox,
  GRAY_12: colors.text.lighter,
  // ColumnHeader,
  GRAY_13: '#a2a1a1',
  // Dropdown,
  GRAY_15: '#5a5a5a',
  // Expander,
  GRAY_16: '#b4b4b4',
  // MakeComponent,
  GRAY_17: '#7e7e7e',
  // Menu,
  GRAY_18: '#efefef',
  // Menu,
  GRAY_19: '#696969',
  // Menu,
  GRAY_20: '#525252',
  // Menu, SelectGroup, ColumnHeader, StepState,
  GRAY_21: '#fafafa',
  // StepDetails,
  GRAY_22: '#e9e9e9',
  // EmptyStateText,
  GRAY_23: '#999999',
  // TestDetailsEmptyStateText,
  GRAY_24: '#cccccc',
  // Avatar
  GRAY_25: '#a5a3ac',
  GRAY_26: '#c7c7c7',
  GRAY_27: '#bdbdbd',
  GRAY_28: '#f7f7f7',
  GRAY_29: colors.neutrals['97'],
  GRAY_30: 'rgba(105, 105, 105, 0.16)',
  GRAY_31: '#adadad',
  GRAY_32: '#868686',
  GRAY_34: '#cccbcb',
  // Badge
  GRAY_35: '#ececec',
  // CopyButton, Tabs, Input, IconButton, CopyButton, Dropwdown, Paginate, MakeComponent, Modal
  GRAY_36: '#666666',
  GRAY_37: '#F1F1F3',
  GRAY_38: '#AAAAAA',
  GRAY_39: '#CCC9C9',
  GRAY_40: '#E5E5E5',
  DARK_GRAY: '#2b2b2b',
  // IconButton,
  DARK_GRAY_3: 'rgba(156, 156, 156, 0.14)',
  // Modal,
  DARK_GRAY_4: 'rgba(26, 27, 32, 0.3)',
  // Modal,
  DARK_GRAY_5: 'rgba(41, 45, 49, 0.39)',
  // Tooltip,
  DARK_GRAY_6: 'rgba(10, 10, 10, 0.9)',
  DARK_GRAY_7: 'rgba(30, 32, 59, 0.11)',
  // CopyButton,
  DARK_GREY_8: 'rgba(47, 46, 55, 0.08)',
  // Button,
  PRIMARY_3: colors.background.primary99,
  // Button,
  PRIMARY_4: '#4c28a0',
  // DataRow,
  PRIMARY_5: '#f9f9ff',
  PRIMARY_6: '#8466ca',
  // ErrorDetails,
  RED_1: '#fff0f0',
  // Code
  BLUE_1: '#82aaff',
  // Code
  VIOLET_1: '#c792ea',
  // Code
  YELLOW_1: '#d4c612',
  // Make component
  YELLOW_2: '#fffccc',
  YELLOW_3: '#b1a478',
};
