import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import FormField from '~/components/FormField';
import { Header, Footer, Content, ErrorInfo } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import { nameValidator, idValidator } from '~/utils/validators';

import { Form } from './EditGroupModal.styled';

const EditGroupEditSchema = Yup.object().shape({
  id: idValidator.required(),
  name: nameValidator,
});

const EditGroupCreateSchema = Yup.object().shape({
  name: nameValidator.optional().nullable(),
});

const EditGroupModal = ({ className, group, testId, stepsIds, onSubmit, atIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const isEdit = !!group.id;

  const labels = useMemo(() => {
    if (isEdit) {
      return {
        title: t('editGroupModal.edit.title', 'Edit group "{{name}}"', { name: group.name }),
        submitButton: t('editGroupModal.edit.submitButton', 'Save group'),
        namePlaceholder: t('editGroupModal.edit.nameInput.placeholder', 'Type name here'),
      };
    }

    return {
      title: t('editGroupModal.new.title', 'New group'),
      submitButton: t('editGroupModal.new.submitButton', 'Create group'),
      nameDescription: t(
        'editGroupModal.new.nameInput.description',
        'A unique group name will be generated automatically if you will not define it.',
      ),
      namePlaceholder: t(
        'editGroupModal.new.nameInput.placeholder',
        'Type name here or just click ENTER button',
      ),
    };
  }, [group.name, t, isEdit]);

  const initialValues = useMemo(
    () => ({
      id: group.id,
      name: group.name || null,
    }),
    [group],
  );

  const handleSubmit = useCallback(
    (values) => {
      if (isEdit) {
        dispatch(TestActions.renameGroupRequest(values.id, values.name || null));
      } else {
        dispatch(
          TestActions.createStepsGroupRequest(testId, values.name || null, stepsIds, atIndex),
        );
      }
    },
    [dispatch, isEdit, stepsIds, testId, atIndex],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: isEdit ? EditGroupEditSchema : EditGroupCreateSchema,
    onSubmit: handleSubmit,
  });

  const handleFailure = useCallback((stateErrors) => {
    if (stateErrors) {
      formik.setErrors(stateErrors);
    }
    formik.setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccess = useCallback(
    () => {
      formik.setSubmitting(false);
      modal.hide();
      if (onSubmit) {
        onSubmit(formik.values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, modal, onSubmit],
  );

  const stateParams = {
    reset: false,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
  };

  const renameRequestState = useActionState(TestActions.renameGroupRequest, stateParams);
  const createRequestState = useActionState(TestActions.createStepsGroupRequest, stateParams);

  useEffect(
    () => () => {
      renameRequestState.reset();
      createRequestState.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Form className={className} data-testid="EditGroupModal" onSubmit={formik.handleSubmit}>
      <Header>{labels.title}</Header>
      <Content>
        <FormField
          label={t('editGroupModal.nameInput.label', 'Name')}
          description={labels.nameDescription}
        >
          <Input
            name="name"
            placeholder={labels.namePlaceholder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
          />
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo
          isVisible={
            renameRequestState.hasInternalServerError || createRequestState.hasInternalServerError
          }
        />
        <Button
          data-testid="EditGroupModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="EditGroupModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={formik.isSubmitting}
        >
          {labels.submitButton}
        </ActionButton>
      </Footer>
    </Form>
  );
};

EditGroupModal.defaultProps = {
  className: null,
  onSubmit: null,
  stepsIds: [],
  testId: null,
  atIndex: null,
  group: {},
};

EditGroupModal.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  stepsIds: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string,
  atIndex: PropTypes.number,
  onSubmit: PropTypes.func,
};

export default EditGroupModal;
