import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import StepName from '~/components/StepName';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import { selectStep } from '~/modules/test/test.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Container, Content } from './DeleteStepsModal.styled';

const DeleteStepsModal = ({ testId, stepsIds, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const step = useSelector(selectStep(stepsIds?.[0]));

  const handleSuccess = useCallback(() => {
    modal.hide();
    onSuccess();
  }, [modal, onSuccess]);

  const removeRequestState = useActionState(TestActions.removeStepsRequest, {
    onSuccess: handleSuccess,
  });

  const handleDeleteConfirm = useCallback(() => {
    analytics.trackEvent(
      stepsIds?.length === 1 ? TRACK_EVENT_TYPE.DELETE_STEP : TRACK_EVENT_TYPE.BULK_DELETE_STEPS,
    );
    dispatch(TestActions.removeStepsRequest(testId, stepsIds));
  }, [dispatch, testId, stepsIds]);

  return (
    <Container>
      <Content>
        <DeleteIcon />
        <div>
          {stepsIds?.length === 1 && (
            <>
              <Header>
                <Trans i18nKey="deleteStepsModal.single.title" defaults="Delete step?" />
              </Header>
              <div>
                <Trans i18nKey="deleteStepsModal.single.description">
                  Are you sure you want to delete step "<StepName type={step?.type} />
                  "?
                </Trans>
              </div>
            </>
          )}
          {stepsIds?.length > 1 && (
            <>
              <Header>
                <Trans
                  i18nKey="deleteStepsModal.selected.title"
                  defaults="Delete {{ count }} steps?"
                  values={{ count: stepsIds.length }}
                />
              </Header>
              <p>
                <Trans
                  i18nKey="deleteStepsModal.selected.description"
                  defaults="Are you sure you want to delete {{ count }} steps? You <strong>can't undo this.</strong>"
                  values={{ count: stepsIds.length }}
                />
              </p>
            </>
          )}
          {!stepsIds && (
            <>
              <Header>
                <Trans i18nKey="deleteStepsModal.all.title" defaults="Delete all steps?" />
              </Header>
              <p>
                <Trans
                  i18nKey="deleteStepsModal.all.description"
                  defaults="Are you sure you want to delete all steps? You <strong>can't undo this.</strong>"
                />
              </p>
            </>
          )}
        </div>
      </Content>
      <Footer>
        <ErrorInfo isVisible={removeRequestState.hasInternalServerError} />
        <Button onClick={modal.hide}>{t('deleteStepsModal.cancel', 'Cancel')}</Button>
        <ActionButton
          onClick={handleDeleteConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={removeRequestState.isLoading}
        >
          {stepsIds
            ? t('deleteStepsModal.confirm', {
                defaultValue_one: 'Delete step',
                defaultValue_other: 'Delete {{count}} steps',
                count: stepsIds.length,
              })
            : t('deleteStepsModal.confirmAll', 'Delete all steps')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DeleteStepsModal.defaultProps = {
  className: null,
  stepsIds: null,
  onSuccess: Function.prototype,
};

DeleteStepsModal.propTypes = {
  className: PropTypes.string,
  stepsIds: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default DeleteStepsModal;
