import IconBase from '@bugbug/core/components/Icon';
import UnorganizedList from '@bugbug/core/components/UnorganizedList';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

import OrganizedList from '../OrganizedList';

const List = styled.div`
  ${UnorganizedList}, ${OrganizedList} {
    padding-left: 20px;
    margin: 5px 0 0;

    li {
      margin-bottom: 6px;
      line-height: inherit;
    }
  }

  ${UnorganizedList} {
    li:before {
      width: 5px;
      height: 5px;
      transform: translate(6px, 7px);
      background-color: currentColor;
    }
  }
`;

export const Container = styled(List)`
  margin: 12px 0 13px 4px;
`;

export const Content = styled.div`
  margin-top: 5px;
`;

export const ToggleButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none !important;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 11px;
  color: ${COLOR.PRIMARY_LIGHT};
  opacity: 0.7;

  &:hover {
    color: ${COLOR.PRIMARY_LIGHT_ACTIVE};
  }
`;

export const Icon = styled(IconBase)`
  width: 15px;
  margin-right: 3px;

  [aria-expanded='false'] {
    transform: rotate(270deg);
  }
`;
