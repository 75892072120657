import { loadLazy } from '~/utils/rendering';

const ChangeUserRole = loadLazy(() => import('~/components/modals/ChangeUserRoleModal'));
const CloneAlert = loadLazy(() => import('~/components/modals/CloneAlertModal'));
const CloneComponent = loadLazy(() => import('~/components/modals/CloneComponentModal'));
const CloneGroup = loadLazy(() => import('~/components/modals/CloneGroupModal'));
const CloneSchedule = loadLazy(() => import('~/components/modals/CloneScheduleModal'));
const CloneSuite = loadLazy(() => import('~/components/modals/CloneSuiteModal'));
const CloneTest = loadLazy(() => import('~/components/modals/CloneTestModal'));
const CreateStep = loadLazy(() => import('~/components/modals/CreateStepModal'));
const DeleteAlert = loadLazy(() => import('~/components/modals/DeleteAlertModal'));
const DeleteAllGroups = loadLazy(() => import('~/components/modals/DeleteAllGroupsModal'));
const DeleteComponent = loadLazy(() => import('~/components/modals/DeleteComponentModal'));
const DeleteGroup = loadLazy(() => import('~/components/modals/DeleteGroupModal'));
const DeleteProfile = loadLazy(() => import('~/components/modals/DeleteProfileModal'));
const DeleteProject = loadLazy(() => import('~/components/modals/DeleteProjectModal'));
const DeleteSchedule = loadLazy(() => import('~/components/modals/DeleteScheduleModal'));
const DeleteSteps = loadLazy(() => import('~/components/modals/DeleteStepsModal'));
const DeleteSuiteRuns = loadLazy(() => import('~/components/modals/DeleteSuiteRunsModal'));
const DeleteSuites = loadLazy(() => import('~/components/modals/DeleteSuitesModal'));
const DeleteTestPartials = loadLazy(() => import('~/components/modals/DeleteTestPartialsModal'));
const DeleteTestRuns = loadLazy(() => import('~/components/modals/DeleteTestRunsModal'));
const DeleteTests = loadLazy(() => import('~/components/modals/DeleteTestsModal'));
const DeleteUnconfirmedSteps = loadLazy(() =>
  import('~/components/modals/DeleteUnconfirmedStepsModal'),
);
const DeleteUser = loadLazy(() => import('~/components/modals/DeleteUserModal'));
const DeleteVariable = loadLazy(() => import('~/components/modals/DeleteVariableModal'));
const DowngradePlan = loadLazy(() => import('~/components/modals/DowngradePlanModal'));
const DownloadPdf = loadLazy(() => import('~/components/modals/DownloadPdfModal'));
const EditAlert = loadLazy(() => import('~/components/modals/EditAlertModal'));
const EditComponent = loadLazy(() => import('~/components/modals/EditComponentModal'));
const EditGroup = loadLazy(() => import('~/components/modals/EditGroupModal'));
const EditProfile = loadLazy(() => import('~/components/modals/EditProfileModal'));
const EditSchedule = loadLazy(() => import('~/components/modals/EditScheduleModal'));
const EditStep = loadLazy(() => import('~/components/modals/EditStepModal'));
const EditSuite = loadLazy(() => import('~/components/modals/EditSuiteModal'));
const EditTest = loadLazy(() => import('~/components/modals/EditTestModal'));
const EditVariable = loadLazy(() => import('~/components/modals/EditVariableModal'));
const GroupSplitConfirmationModal = loadLazy(() =>
  import('~/components/modals/GroupSplitConfirmationModal'),
);
const IncognitoTabsClose = loadLazy(() => import('~/components/modals/IncognitoTabsCloseModal'));
const InsertComponent = loadLazy(() => import('~/components/modals/InsertComponentModal'));
const InviteUser = loadLazy(() => import('~/components/modals/InviteUserModal'));
const MissingExtension = loadLazy(() => import('~/components/modals/MissingExtensionModal'));
const Project = loadLazy(() => import('~/components/modals/ProjectModal'));
const ReachedLimit = loadLazy(() => import('~/components/modals/ReachedLimitModal'));
const RunMetadata = loadLazy(() => import('~/components/modals/RunMetadataModal'));
const RunWithProfile = loadLazy(() => import('~/components/modals/RunWithProfileModal'));
const ShowComponentRelatedTests = loadLazy(() =>
  import('~/components/modals/ShowComponentRelatedTestsModal'),
);
const StartTrial = loadLazy(() => import('~/components/modals/StartTrialModal'));
const Suite = loadLazy(() => import('~/components/modals/SuiteModal'));
const SuiteCliCommands = loadLazy(() => import('~/components/modals/SuiteCliCommandsModal'));
const SupportRequestConfirmation = loadLazy(() =>
  import('~/components/modals/SupportRequestConfirmationModal'),
);
const SupportRequestSuccess = loadLazy(() =>
  import('~/components/modals/SupportRequestSuccessModal'),
);
const SwitchOrganizationModal = loadLazy(() =>
  import('~/components/modals/SwitchOrganizationModal'),
);
const TestCliCommands = loadLazy(() => import('~/components/modals/TestCliCommandsModal'));
const TestSettings = loadLazy(() => import('~/components/modals/TestSettingsModal'));
const UnavailableStep = loadLazy(() => import('~/components/modals/UnavailableStepModal'));
const UnconfirmedSteps = loadLazy(() => import('~/components/modals/UnconfirmedStepsModal'));
const UnsavedChanges = loadLazy(() => import('~/components/modals/UnsavedChangesModal'));
const UpdateAvailable = loadLazy(() => import('~/components/modals/UpdateAvailableModal'));
const Integration = loadLazy(() => import('~/components/modals/IntegrationModal'));
const InvalidComponentName = loadLazy(() =>
  import('~/components/modals/InvalidComponentNameModal'),
);
const FirstSuccessfulRun = loadLazy(() => import('~/components/modals/FirstSuccessfulRunModal'));

export const MODAL_COMPONENTS = {
  invalid_component_name: InvalidComponentName,
  upgrade_plan: ReachedLimit,
  project: Project,
  clone_alert: CloneAlert,
  clone_group: CloneGroup,
  clone_schedule: CloneSchedule,
  clone_test: CloneTest,
  clone_component: CloneComponent,
  delete_project: DeleteProject,
  delete_group: DeleteGroup,
  delete_alert: DeleteAlert,
  delete_schedule: DeleteSchedule,
  delete_suite_runs: DeleteSuiteRuns,
  delete_test_runs: DeleteTestRuns,
  delete_suites: DeleteSuites,
  delete_tests: DeleteTests,
  delete_component: DeleteComponent,
  clone_suite: CloneSuite,
  downgrade_plan: DowngradePlan,
  edit_group: EditGroup,
  schedule: EditSchedule,
  show_component_related_tests: ShowComponentRelatedTests,
  edit_suite: EditSuite,
  edit_component: EditComponent,
  suite_details: Suite,
  edit_test: EditTest,
  invite_user: InviteUser,
  run_metadata: RunMetadata,
  suite_cli_commands: SuiteCliCommands,
  test_cli_commands: TestCliCommands,
  test_settings: TestSettings,
  create_step: CreateStep,
  edit_step: EditStep,
  delete_steps: DeleteSteps,
  insert_component: InsertComponent,
  switch_organization: SwitchOrganizationModal,
  change_user_role: ChangeUserRole,
  delete_user: DeleteUser,
  delete_unconfirmed_steps: DeleteUnconfirmedSteps,
  start_trial: StartTrial,
  install_extension: MissingExtension,
  run_test_cloud_info: null,
  create_scheduler_info: null,
  delete_test_partials: DeleteTestPartials,
  delete_all_groups: DeleteAllGroups,
  group_split_confirmation: GroupSplitConfirmationModal,
  delete_variable: DeleteVariable,
  delete_profile: DeleteProfile,
  edit_alert: EditAlert,
  edit_profile: EditProfile,
  edit_variable: EditVariable,
  run_with_profile: RunWithProfile,
  unsaved_changes: UnsavedChanges,
  unavailable_step: UnavailableStep,
  unconfirmed_steps: UnconfirmedSteps,
  incognito_tabs_close: IncognitoTabsClose,
  support_request_confirmation: SupportRequestConfirmation,
  support_request_success: SupportRequestSuccess,
  update_available: UpdateAvailable,
  download_pdf_file: DownloadPdf,
  integration: Integration,
  first_successful_run: FirstSuccessfulRun,
};
