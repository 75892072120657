import { createApi } from '@reduxjs/toolkit/query/react';

import type { Alert } from '@bugbug/core/types/alerts';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Alerts';
export const alertsApi = createApi({
  reducerPath: 'alertsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/notifications/' }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getAlerts: builder.query<Alert[], string>({
      query: (projectId) => ({ params: { projectId }, url: '', method: 'get' }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id } as const)), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),
    createOrUpdateAlert: builder.mutation<Alert, { data; id?: string }>({
      query: ({ data, id }) => ({
        url: id ? `${id}/` : '',
        method: id ? 'PUT' : 'POST',
        data,
      }),
      invalidatesTags: [TAG],
    }),
    partialUpdateAlert: builder.mutation<Alert, { data: { isActive: boolean }; id: string }>({
      query: ({ data, id }) => ({
        url: `${id}/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [TAG],
    }),
    deleteAlert: builder.mutation<void, string>({
      query: (id) => ({
        url: `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG],
    }),
    cloneAlert: builder.mutation<Alert, string>({
      query: (id) => ({
        url: `${id}/clone/`,
        method: 'POST',
      }),
      invalidatesTags: [TAG],
    }),
    testAlert: builder.mutation<{ response: string }, Omit<Alert, 'id'>>({
      query: (data) => ({
        url: `try/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useGetAlertsQuery,
  useCreateOrUpdateAlertMutation,
  useDeleteAlertMutation,
  useCloneAlertMutation,
  usePartialUpdateAlertMutation,
  useTestAlertMutation,
} = alertsApi;
