import Button, { ActionButton } from '@bugbug/core/components/Button';
import Loader, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import { FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import * as Yup from 'yup';

import type { EditAlertFormValues } from './EditAlertFormValues.types';

import type { Alert, AlertActionType } from '@bugbug/core/types/alerts';
import type { Profile } from '@bugbug/core/types/profiles';
import type { RouteModalWithOptional } from '@bugbug/core/types/routes';
import type { Test } from '@bugbug/core/types/tests';
import { ErrorInfo, Header } from '~/components/modals/Modal';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { useCreateOrUpdateAlertMutation } from '~/modules/alerts';
import { ProfileActions, selectProfilesList } from '~/modules/profile';
import { selectSingleProjectId } from '~/modules/project/project.selectors';
import { useGetSchedulesQuery } from '~/modules/schedules';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { useGetSuitesQuery } from '~/modules/suites/suites.api';
import { TestActions } from '~/modules/test/test.redux';
import { selectTestsList } from '~/modules/test/test.selectors';
import { getExtractedErrorMessages, isInternalServerError } from '~/services/api/utils';
import toasts from '~/services/toasts';

import { buildAlertRequestData, getAlertFormValues } from './EditAlertModal.helpers';
import alertFormSchemas from './EditAlertModal.schema';
import * as S from './EditAlertModal.styled';
import EditAlertModalThenContent from './EditAlertModalThenContent';
import EditAlertModalWhen from './EditAlertModalWhenContent';

export const EditAlertModal: RouteModalWithOptional<Alert> = ({ data: alert }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const dispatch = useAppDispatch();

  const profilesRequestState = useActionState(ProfileActions.getListRequest, { reset: false });
  const testsRequestState = useActionState(TestActions.getListRequest, { reset: false });
  const projectId = useAppSelector(selectSingleProjectId);
  const tests = useAppSelector(selectTestsList) as Test[];
  const profiles = useAppSelector(selectProfilesList) as unknown as Profile[];
  const queryParams = useQueryString<{ action: AlertActionType }>();

  const [createOrUpdateAlert, createOrUpdateMutation] = useCreateOrUpdateAlertMutation();

  const { data: suites, ...suitesQuery } = useGetSuitesQuery({
    projectId,
    ordering: 'name',
    pagination: 'off',
  });

  const { data: schedules, ...schedulesQuery } = useGetSchedulesQuery({
    projectId,
    ordering: 'name',
  });

  const labels = alert
    ? {
        title: t('editAlertModal.edit.title', 'Alert settings'),
        create: t('editAlertModal.edit.success', 'Alert has been changed successfully.'),
        submitButton: t('editAlertModal.edit.submitButton', 'Save alert'),
      }
    : {
        title: t('editAlertModal.new.title', 'Set up an alert'),
        create: t('editAlertModal.new.success', 'Alert has been created successfully.'),
        submitButton: t('editAlertModal.new.submitButton', 'Create alert'),
      };

  const handleSubmit = useCallback(
    (values: EditAlertFormValues) => {
      createOrUpdateAlert({
        data: buildAlertRequestData(values),
        id: alert?.id,
      });
    },

    [createOrUpdateAlert, alert?.id],
  );

  const fetchData = useCallback(() => {
    dispatch(TestActions.getListRequest());
  }, [dispatch]);

  const handleRetry = useCallback(() => {
    fetchData();
    suitesQuery.refetch();
    schedulesQuery.refetch();
  }, [schedulesQuery, suitesQuery, fetchData]);

  const initialValues = useMemo<EditAlertFormValues>(
    () => getAlertFormValues(projectId, alert, queryParams.action),
    [projectId, alert, queryParams.action],
  );

  const getValidationSchema = useCallback<() => ReturnType<typeof Yup.lazy>>(
    () => Yup.lazy((values) => alertFormSchemas[values.action]),
    [],
  );

  const formik = useFormik<EditAlertFormValues>({
    initialValues,
    validationSchema: getValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useMount(fetchData);

  useEffect(() => {
    if (createOrUpdateMutation.isSuccess) {
      toasts.showSuccess({
        content: labels.create,
        id: 'editAlertModal',
      });
      modal.hide();
    }

    if (createOrUpdateMutation.isError) {
      formik.setErrors(getExtractedErrorMessages(createOrUpdateMutation.error));
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createOrUpdateMutation.isSuccess,
    createOrUpdateMutation.isError,
    createOrUpdateMutation.error,
    createOrUpdateMutation,
    labels.create,
  ]);

  const isLoading =
    suitesQuery.isLoading || schedulesQuery.isLoading || testsRequestState.isLoading;

  const isInitialDataError =
    isInternalServerError(suitesQuery.error || schedulesQuery.error) ||
    profilesRequestState.hasInternalServerError ||
    testsRequestState.hasInternalServerError;

  return (
    <FormikProvider value={formik}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Header>{labels.title}</Header>
        <S.Content>
          {/* Loading state */}
          {isLoading && (
            <LoaderFlexContainer>
              <Loader size="regular" />
            </LoaderFlexContainer>
          )}

          {/* Error state */}
          {!isLoading && isInitialDataError && <ServerErrorInfo isVisible onRetry={handleRetry} />}

          {/* Form */}
          {!isLoading && !isInitialDataError && (
            <>
              <EditAlertModalWhen
                suites={suites}
                schedules={schedules}
                profiles={profiles}
                tests={tests}
              />
              <EditAlertModalThenContent />
            </>
          )}
        </S.Content>

        <S.Footer>
          <ErrorInfo
            isVisible={
              profilesRequestState.hasInternalServerError ||
              isInternalServerError(
                createOrUpdateMutation.error || suitesQuery.error || schedulesQuery.error,
              )
            }
          />
          <Button onClick={modal.hide} disabled={formik.isSubmitting} type="button">
            {t('default.button.cancel')}
          </Button>
          {/*  @ts-expect-error `hide` type issue in case of passing extra redirect route */}
          <PaidFeature onUpgradeClick={() => modal.hide('subscription')}>
            {(isDisabled) => (
              <ActionButton
                disabled={isDisabled}
                pending={formik.isSubmitting}
                succeeded={createOrUpdateMutation.isSuccess}
                type="submit"
                variant="primary"
              >
                {labels.submitButton}
              </ActionButton>
            )}
          </PaidFeature>
        </S.Footer>
      </S.Form>
    </FormikProvider>
  );
};

export default EditAlertModal;
