import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDnd = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "drag" }),
  /* @__PURE__ */ jsx("g", { id: "b2108446-9b0e-4192-bab8-e85fb11dbaae", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "b0fe6c9c-39e4-4ff7-9081-c1c4b8634544", "data-name": "Layer 1", children: /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M9.76,24.06A7.71,7.71,0,0,1,7.48,18.8l-.16-8.74A2.41,2.41,0,0,1,8,8.3a2.43,2.43,0,0,1,2.57-.52,2.4,2.4,0,0,1,.7-1.58,2.45,2.45,0,0,1,2.83-.43,2.63,2.63,0,0,1,.44-.61A2.46,2.46,0,0,1,18,5.2a2.38,2.38,0,0,1,.48.66,2.5,2.5,0,0,1,3.57,2.21l.21,8.46.9-.91a2.35,2.35,0,0,1,3.4,0,2.46,2.46,0,0,1,0,3.47l-5,5A7.71,7.71,0,0,1,16,26.34l-1,0A7.49,7.49,0,0,1,9.76,24.06Zm.56-14.49a.74.74,0,0,0-1,0,.81.81,0,0,0-.23.57l.16,8.68A5.91,5.91,0,0,0,11,22.86,5.72,5.72,0,0,0,15,24.57l1,0a6,6,0,0,0,4.35-1.74l5-5a.76.76,0,0,0,0-1.06.68.68,0,0,0-1,0l-2.31,2.31a.84.84,0,0,1-1.2,0,.78.78,0,0,1-.25-.58L20.39,8.11a.82.82,0,0,0-.24-.55.71.71,0,0,0-1,0,.77.77,0,0,0-.23.57L19,15.82a.86.86,0,0,1-.26.65.91.91,0,0,1-.65.26.92.92,0,0,1-.61-.26.87.87,0,0,1-.26-.62L17.08,7a.82.82,0,0,0-.24-.55.71.71,0,0,0-1,0,.78.78,0,0,0-.24.58l.16,8.66a.86.86,0,0,1-.26.64.89.89,0,0,1-1.26,0,.83.83,0,0,1-.26-.61L13.81,8a.79.79,0,0,0-.24-.54.71.71,0,0,0-1,0A.78.78,0,0,0,12.3,8l.14,7.72a.85.85,0,0,1-.26.65.89.89,0,0,1-1.26,0,.84.84,0,0,1-.26-.62l-.11-5.61A.76.76,0,0,0,10.32,9.57Z" })
  ] }) }) })
] });
const ForwardRef = forwardRef(SvgDnd);
export default ForwardRef;
