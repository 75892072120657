import { useTranslation } from 'react-i18next';

import { RUN_ENV_LABEL } from './RunEnvironmentSection.constants';
import * as S from './RunEnvironmentSection.styled';

interface WindowSize {
  browserHeight?: number;
  browserWidth?: number;
  screenSizeType?: string;
}

interface RunEnvironmentSectionProps {
  className?: string;
  title?: string;
  data: {
    browserName?: string;
    browserVersion?: string;
    extensionVersion?: string;
    osName?: string;
    profileName?: string;
    runMode?: string;
    windowSize?: WindowSize[];
  } & WindowSize;
}

const RunEnvironmentSectionProps = ({ className, title, data }: RunEnvironmentSectionProps) => {
  const { t } = useTranslation();

  const isScreenDataAvailable =
    (data.screenSizeType && data.browserWidth && data.browserHeight) || data.windowSize;

  return (
    <S.Section className={className}>
      {title && (
        <S.Title>
          {title} <hr />
        </S.Title>
      )}

      {data.profileName && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.profile.label', 'Profile')}</S.Label>
          <S.Value>
            <span>{data.profileName}</span>
          </S.Value>
        </S.MetaData>
      )}

      {data.runMode && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.method.label', 'Method')}</S.Label>
          <S.Value>
            <span>{RUN_ENV_LABEL[data.runMode]}</span>
          </S.Value>
        </S.MetaData>
      )}

      {isScreenDataAvailable && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.screen.label', 'Screen')}</S.Label>
          {data.windowSize ? (
            <S.StackedValues>
              {data.windowSize.map(({ screenSizeType, browserWidth, browserHeight }) => (
                <S.Value key={`${screenSizeType}__${browserWidth}__${browserHeight}`}>
                  {t(
                    'runEnvironmentSection.screen.value',
                    '{{screenSizeType}} ({{width}}x{{height}})',
                    {
                      screenSizeType,
                      width: browserWidth,
                      height: browserHeight,
                    },
                  )}
                </S.Value>
              ))}
            </S.StackedValues>
          ) : (
            <S.Value>
              {t(
                'runEnvironmentSection.screen.value',
                '{{screenSizeType}} ({{width}}x{{height}})',
                {
                  screenSizeType: data.screenSizeType,
                  width: data.browserWidth,
                  height: data.browserHeight,
                },
              )}
            </S.Value>
          )}
        </S.MetaData>
      )}

      {data.extensionVersion && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.extension.label', 'Extension')}</S.Label>
          <S.Value>{data.extensionVersion}</S.Value>
        </S.MetaData>
      )}

      {data.osName && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.osName.label', 'OS')}</S.Label>
          <S.Value>{data.osName}</S.Value>
        </S.MetaData>
      )}

      {data.browserName && (
        <S.MetaData>
          <S.Label>{t('runEnvironmentSection.browser.label', 'Browser')}</S.Label>
          <S.Value>
            {data.browserName} {data.browserVersion}
          </S.Value>
        </S.MetaData>
      )}
    </S.Section>
  );
};

export default RunEnvironmentSectionProps;
