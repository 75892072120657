import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled, { createGlobalStyle } from 'styled-components';

import { Backdrop } from '~/components/modals/Modal';

export const Form = styled.form`
  width: ${({ twoColumn }) => (twoColumn ? 570 : 800)}px;
`;

export const VariablesList = styled.div``;

export const EmptyListState = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: ${COLOR.GRAY_23};
  line-height: 18px;
  font-weight: ${FONT_WEIGHT.REGULAR};
  justify-content: center;
  border: 1px solid ${COLOR.GRAY_18};
  border-radius: 4px;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const FormBackdropOverrides = createGlobalStyle`
  ${Backdrop} {
    padding-top: 30px;
  }
`;
