import produce from 'immer';
import { normalize } from 'normalizr';
import { createActions, createReducer } from 'reduxsauce';

import { getUserIdByEmail } from '~/modules/organizationUsers/organizationUsers.utils';
import { UserTypes } from '~/modules/user/user.redux';

import { organizationUsersListSchema } from './organizationUsers.schema';

export const { Types: OrganizationUsersTypes, Creators: OrganizationUsersActions } = createActions(
  {
    addRequest: ['data'],
    addFailure: ['error'],
    addSuccess: ['data'],
    updateRequest: ['data'],
    updateSuccess: ['data'],
    updateFailure: ['error'],
    removeRequest: ['email'],
    removeSuccess: ['email'],
    removeFailure: ['error'],
    getListRequest: ['sortBy', 'descOrder'],
    getListSuccess: ['data'],
    getListFailure: ['error'],
  },
  { prefix: 'ORGANIZATION_USERS/' },
);

export const INITIAL_STATE = {
  list: {
    users: {},
    order: [],
  },
};

const getListSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data, organizationUsersListSchema);
    draftState.list.users = data.entities.users;
    draftState.list.order = data.result;
  });

const updateSuccess = (state, action) =>
  produce(state, (draftState) => {
    const userId = getUserIdByEmail(action.data.email, draftState.list.users);
    draftState.list.users[userId].role = action.data.role;
  });

const removeSuccess = (state, action) =>
  produce(state, (draftState) => {
    const userId = getUserIdByEmail(action.email, draftState.list.users);

    if (userId) {
      delete draftState.list.users[userId];
      draftState.list.order = draftState.list.order.filter((v) => v !== userId);
    }
  });

const reset = (state) =>
  produce(state, (draftState) => {
    draftState.list.users = {};
    draftState.list.order = [];
  });

export const reducer = createReducer(INITIAL_STATE, {
  [OrganizationUsersTypes.GET_LIST_SUCCESS]: getListSuccess,
  [OrganizationUsersTypes.UPDATE_SUCCESS]: updateSuccess,
  [OrganizationUsersTypes.REMOVE_SUCCESS]: removeSuccess,
  //
  [UserTypes.LOGOUT_SUCCESS]: reset,
});
