import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { Project } from '@bugbug/core/types/projects';
import type { Step } from '@bugbug/core/types/steps';
import type { StepRun, TestRun } from '@bugbug/core/types/tests';
import type { User } from '@bugbug/core/types/users';
import urls, { reverse } from '~/views/urls';

import config from '../config';

const TAG = 'Core' as const;
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    requestSupport: builder.mutation<
      void,
      {
        description: string;
        userAllowedToRunTests: boolean;
        project: Project;
        user: User;
        failedStepRun: StepRun & { stepId: Step['id']; testRunId: TestRun['id'] };
        organizationId: string;
      }
    >({
      query: ({
        description,
        userAllowedToRunTests,
        organizationId,
        project,
        failedStepRun,
        user,
      }) => {
        const { stepId, testRunId, id: stepRunId } = failedStepRun;
        const viewUrl = reverse(urls.testRun, {
          organizationId,
          projectId: project.id,
          projectSlug: project.slug,
          testRunId,
        });

        const params: Record<string, string> = {
          u: '9',
          f: '9',
          s: '',
          c: '0',
          m: '0',
          act: 'sub',
          v: '2',
          or: 'd654bcf724620617e03b09dcfa13be88',
          email: user.email,
          'field[46]': `${config.WEBAPP_URL}${viewUrl}`,
          'field[43]': testRunId,
          'field[44]': stepRunId,
          'field[45]': stepId,
          'field[48]': description,
          'field[49]': userAllowedToRunTests.toString(),
          jsonp: 'true',
        };

        // Native AC encoding
        const paramsList: string[] = [];
        Object.entries(params).forEach(([key, value]) => {
          paramsList.push(`${key}=${encodeURIComponent(value)}`);
        });
        const encodedParams = paramsList.join('&').replace(/%0A/g, '\\n');

        return {
          url: `https://bugbug.activehosted.com/proc.php?${encodedParams}`,
          responseHandler: 'text',
        };
      },
    }),
  }),
});

export const { useRequestSupportMutation } = coreApi;
