import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { Tab as TabBase, Tabs as TabsBase } from '@mui/material';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Tabs = styled(TabsBase).attrs(() => ({
  scrollButtons: false,
  classes: {
    indicator: 'indicator',
  },
}))`
  && {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals[96]};
  }
  .indicator {
    background-color: ${COLOR.PRIMARY_LIGHT};
    height: 3px;
  }
`;

const getTabErrorStyles = styleWhenTrue(prop('hasError'), css``);

export const Tab = styled(TabBase).attrs(() => ({
  disableFocusRipple: true,
  disableRipple: true,
  classes: {
    selected: 'selected',
  },
}))`
  && {
    opacity: 1;
    outline: none;
    color: ${COLOR.DARK_GRAY};
    padding: 0;
    min-width: 0;
    text-transform: none;
    margin-right: 24px;
    height: 43px;
    letter-spacing: 0.65px;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 18px;
    transition: color 300ms ease;

    &:after {
      content: '';
      z-index: 1;
      background-color: ${COLOR.WHITE};
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 3px ${COLOR.ERROR} dotted;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 300ms ease;
    }

    &:last-child {
      margin-right: 0;
    }

    ${getTabErrorStyles};
  }

  &&.selected {
    color: ${COLOR.PRIMARY_LIGHT};
    ${getTabErrorStyles};
  }

  &&[aria-invalid='true'] {
    opacity: 1;
    color: ${COLOR.ERROR};

    &:after {
      opacity: 1;
    }
  }
`;

export const TabContent = styled.div`
  padding: 14px 0;
`;
