import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCloudRun = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 18 18", style: {
  enableBackground: "new 0 0 18 18"
}, xmlSpace: "preserve", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M9,4.5c2,0,3.7,1.4,4,3.3l0.2,1.1L14.4,9c1.2,0.1,2.1,1.1,2.1,2.2c0,1.2-1,2.2-2.2,2.2H4.5c-1.7,0-3-1.3-3-3 c0-1.5,1.1-2.8,2.7-3H5l0.4-0.7C6.1,5.4,7.5,4.5,9,4.5 M9,3C6.8,3,4.9,4.2,4,6c-2.3,0.2-4,2.2-4,4.5C0,13,2,15,4.5,15h9.8 c2.1,0,3.8-1.7,3.8-3.8c0-2-1.5-3.6-3.5-3.7C14,4.9,11.7,3,9,3z" }),
  /* @__PURE__ */ jsx("path", { fill: "currentColor", className: "st1", d: "M11.7,9L7.9,6.8C7.7,6.6,7.3,6.7,7.2,7c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3v4.5c0,0.3,0.2,0.5,0.5,0.5 c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1l3.8-2.2c0.2-0.1,0.3-0.5,0.2-0.7C11.8,9.1,11.7,9.1,11.7,9z" })
] });
const ForwardRef = forwardRef(SvgCloudRun);
export default ForwardRef;
