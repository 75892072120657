import styled from 'styled-components';

import Icon from '../Icon';

export const PaidFeatureContainer = styled.div`
  position: relative;

  > * {
    width: 100%;
  }
`;

export const UpgradeIcon = styled(Icon).attrs(() => ({
  name: 'upgrade',
}))`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.common.white};
  position: absolute;
  bottom: -6px;
  right: -3px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 3px;
  z-index: 1;
  cursor: pointer;
  border: none;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryActive};
  }
`;
