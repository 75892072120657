import produce from 'immer';
import { prop, path, isEmpty, isNil } from 'ramda';

export const getBaseActionName = (type, meta) => {
  const baseName = type.split(/(_REQUEST|_SUCCESS|_FAILURE|_RESET)$/)[0];
  if (!isEmpty(meta) && !isNil(meta)) {
    const { reqId } = meta;
    if (reqId) {
      return `${baseName}:${reqId}`;
    }
  }
  return baseName;
};

export const defaultState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  status: null,
  errors: null,
};

const INITIAL_STATE = {};

export default produce((draftState, action) => {
  if (!prop('type', action)) {
    return;
  }
  const baseActionName = getBaseActionName(action.type, action.meta);

  if (action.type.endsWith('_REQUEST')) {
    draftState[baseActionName] = { ...defaultState, isLoading: true };
  }
  if (!prop(baseActionName, draftState)) {
    return;
  }
  const isLoading = path([baseActionName, 'isLoading'], draftState);
  if (action.type.endsWith('_SUCCESS') && isLoading) {
    draftState[baseActionName].isSuccess = true;
    draftState[baseActionName].isLoading = false;
  } else if (action.type.endsWith('_FAILURE') && isLoading) {
    draftState[baseActionName].isFailure = true;
    draftState[baseActionName].isLoading = false;
    draftState[baseActionName].errors = action.error || {};
  } else if (action.type.endsWith('_RESET')) {
    draftState[baseActionName] = defaultState;
  }
}, INITIAL_STATE);
