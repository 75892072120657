import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import styled, { css } from 'styled-components';

import { BackButton } from '~/components/BackButton/BackButton';
import { Header as HeaderBase } from '~/components/Header/Header';

interface ContentStyledProps {
  centeredContent: boolean;
  withBackButton: boolean;
  wideContent?: boolean;
}

export const Header = styled(HeaderBase)``;

export const Content = styled.div<ContentStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 18px 20px 23px 34px;
  max-width: 1154px;

  ${({ wideContent }) =>
    wideContent &&
    css`
      padding: 18px 34px 23px 34px;
      max-width: 1920px;
    `}

  ${({ centeredContent }) =>
    centeredContent &&
    css`
      max-width: 1110px;
      padding: 71px 20px 0;
      margin: 0 auto;
      width: 100%;
    `}

  ${({ centeredContent, withBackButton }) =>
    centeredContent &&
    withBackButton &&
    css`
      padding-top: 32px;
    `}
`;

export const Container = styled.div`
  flex: 1 1 auto;
  flex-direction: column;
  user-select: auto;
  display: flex;
  box-sizing: content-box;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow-y: auto;
`;

export const GoBackLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.normal};
  opacity: 0.6;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 120px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryActive};
    opacity: 1;
    text-decoration: none;
  }
`;

export const GoBackLinkText = styled.span`
  position: relative;
  line-height: 20px;
  top: -2px;
`;

export const GoBackIcon = styled(Icon).attrs(() => ({
  name: 'arrowBackRounded',
}))`
  && {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`;

export const GoBackButton = styled(BackButton)`
  position: absolute;
  left: 26px;
  top: 22px;
  width: 30px;
  height: 30px;
  z-index: 1;

  svg {
    width: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
`;
