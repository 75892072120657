import memoize from 'lodash.memoize';
import { find, pathOr, prop, propOr, pipe, values, propEq, props } from 'ramda';
import { createSelector } from 'reselect';

export const selectOrganizationUsersDomain = prop('organizationUsers');

export const selectOrganizationUsersOrder = createSelector(
  selectOrganizationUsersDomain,
  pathOr([], ['list', 'order']),
);

export const selectOrganizationUsers = createSelector(
  selectOrganizationUsersDomain,
  pathOr({}, ['list', 'users']),
);

export const selectOrganizationUsersList = createSelector(
  selectOrganizationUsersOrder,
  selectOrganizationUsers,
  props,
);

export const selectOrganizationUser = memoize((userId) =>
  createSelector(selectOrganizationUsers, propOr(null, userId)),
);

export const selectOrganizationUserRoleByEmail = memoize((email) =>
  createSelector(selectOrganizationUsers, pipe(values, find(propEq('email', email)), prop('role'))),
);
