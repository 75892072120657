import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -8px;
  margin-top: 28px;
`;

export const Section = styled.div`
  width: 208px;
  padding: 0 8px;
`;

export const SectionTitle = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const SectionItems = styled.div`
  width: 100%;
  position: relative;
`;

export const SectionItem = styled.button`
  width: 100%;
  padding: 0 12px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${({ theme }) => theme.colors.neutrals[92]};
  border-radius: 10px;
  box-shadow: 0 2px 0 0 hsla(0, 0%, 77.7%, 0.1);
  margin-bottom: 8px;
  background: ${({ theme }) => theme.colors.common.white};
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:not(:disabled):hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    cursor: default;
  }

  ${styleWhenTrue(
    prop('selected'),
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `,
  )}
`;

export const SectionItemLabel = styled.span`
  display: block;
  flex: 1;
  position: relative;
  top: -2px;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.normal};

  ${styleWhenTrue(
    prop('disabled'),
    css`
      color: ${({ theme }) => theme.colors.text.emptyState};
    `,
  )}

  text-align: left;
`;

export const SectionItemIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  svg {
    ${styleWhenTrue(
      prop('disabled'),
      css`
        fill: ${({ theme }) => theme.colors.text.emptyState};
      `,
    )}

    width: 24px;
    height: 24px;
  }
`;
