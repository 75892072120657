import i18n from '~/translations';

export const externalLinks = [
  {
    title: i18n.t('firstSuccessfulRunModal.externalLinks.bestPractices.title', 'Best practices'),
    description: i18n.t(
      'firstSuccessfulRunModal.externalLinks.bestPractices.description',
      'Automate hundreds of tests',
    ),
    url: i18n.t('default.docs.bestPractices'),
  },
  {
    title: i18n.t('firstSuccessfulRunModal.externalLinks.components.title', 'Components'),
    description: i18n.t(
      'firstSuccessfulRunModal.externalLinks.components.description',
      'Save time by reusing similar test steps',
    ),
    url: i18n.t('default.docs.components'),
  },
  {
    title: i18n.t(
      'firstSuccessfulRunModal.externalLinks.variablesAndProfiles.title',
      'Variables & Profiles',
    ),
    description: i18n.t(
      'firstSuccessfulRunModal.externalLinks.variablesAndProfiles.description',
      'Test on multiple environments',
    ),
    url: i18n.t('default.docs.variables'),
  },
] as const;
