import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgInputType = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M9.72998 6.94001C10.1799 6.90094 10.6307 7.00453 11.0184 7.23608C11.4061 7.46763 11.711 7.81538 11.89 8.23001C12.0689 7.81538 12.3739 7.46763 12.7616 7.23608C13.1493 7.00453 13.6001 6.90094 14.05 6.94001", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M14.05 25.06C13.6001 25.0991 13.1493 24.9955 12.7616 24.7639C12.3739 24.5324 12.0689 24.1846 11.89 23.77C11.711 24.1846 11.4061 24.5324 11.0184 24.7639C10.6307 24.9955 10.1799 25.0991 9.72998 25.06", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M8.29998 22.04H3.97998V9.96002H8.29998", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M15.48 9.96002H28.02", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M28.02 22.04H15.48", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M28.02 9.96002V22.04", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M11.8899 8.23001V23.77", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M16 15.99H23.66", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })
] });
const ForwardRef = forwardRef(SvgInputType);
export default ForwardRef;
