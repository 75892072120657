import styled from 'styled-components';

export const Container = styled.section`
  flex: 1 1 auto;
  user-select: auto;
  display: flex;
  box-sizing: content-box;
  overflow: hidden;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
