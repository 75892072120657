import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgError = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", width: "24px", height: "24px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M16.1,23H7.9c-0.3,0-0.5-0.1-0.7-0.3l-5.9-5.9C1.1,16.7,1,16.4,1,16.1V7.9c0-0.3,0.1-0.5,0.3-0.7l5.9-5.9 C7.3,1.1,7.6,1,7.9,1h8.3c0.3,0,0.5,0.1,0.7,0.3l5.9,5.9C22.9,7.3,23,7.6,23,7.9v8.3c0,0.3-0.1,0.5-0.3,0.7l-5.9,5.9 C16.7,22.9,16.4,23,16.1,23z M8.3,21h7.5l5.3-5.3V8.3L15.7,3H8.3L3,8.3v7.5L8.3,21z" }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M12,13c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v4C13,12.6,12.6,13,12,13z" }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S12.6,17,12,17z" }) })
] });
const ForwardRef = forwardRef(SvgError);
export default ForwardRef;
