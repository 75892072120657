import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import type { AlertEventObject } from '@bugbug/core/types/alerts';
import type { Schedule } from '@bugbug/core/types/schedules';
import type { Suite } from '@bugbug/core/types/suites';
import type { Test } from '@bugbug/core/types/tests';
import FormField from '~/components/FormField';
import { splitByCamelCase } from '~/utils/text';

interface EditAlertModalWhenWhichSelectProps {
  suites?: Suite[];
  schedules?: Schedule[];
  tests?: Test[];
}

const EditAlertModalWhenWhichSelect = ({
  suites = [],
  schedules = [],
  tests = [],
}: EditAlertModalWhenWhichSelectProps) => {
  const { t } = useTranslation();
  const {
    getFieldProps,
    getFieldMeta,
    values: { event },
  } = useFormikContext<EditAlertFormValues>();

  let objects: Suite[] | Schedule[] | Test[];
  let objectsLabel;

  const eventObjectType = splitByCamelCase(event)[0] as AlertEventObject;

  switch (eventObjectType) {
    case 'suite':
      objects = suites;
      objectsLabel = t('editAlertModal.fields.type.objects.suites', 'Which suites');
      break;
    case 'schedule':
      objects = schedules;
      objectsLabel = t('editAlertModal.fields.type.objects.schedules', 'Which schedules');
      break;
    default:
      objects = tests;
      objectsLabel = t('editAlertModal.fields.type.objects.tests', 'Which tests');
  }

  return (
    <FormField label={objectsLabel}>
      <Select
        {...getFieldMeta('objects')}
        {...getFieldProps('objects')}
        showSearch
        selectAllOptionVisible
        multiple
        maxChips={3}
      >
        {objects.map((row) => (
          <SelectOption key={row.id} value={row.id} multiple>
            {row.name}
          </SelectOption>
        ))}
      </Select>
    </FormField>
  );
};

export default EditAlertModalWhenWhichSelect;
