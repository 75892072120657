import type {
  OrganizationSubscription,
  OrganizationSubscriptionSchedule,
} from '@bugbug/core/types/organizations';

const parsePrice = (price) => (parseFloat(price) || 0).toFixed(2).replace('.00', '');

export const formatPriceData = <
  T extends OrganizationSubscription | OrganizationSubscriptionSchedule,
>(
  subscription: T,
): T => ({
  ...subscription,
  price: parsePrice(subscription.price),
  pricePerMonth: parsePrice(subscription.pricePerMonth),
});
