import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import type { StepTypePickerType } from '~/components/StepTypePicker/StepTypePicker.constants';

import { Header } from '~/components/modals/Modal';
import StepTypePicker from '~/components/StepTypePicker';
import { STEP_TYPE } from '~/constants/step';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import * as S from './CreateStepModal.styled';

interface CreateStepModalProps {
  className?: string;
  initialTab: StepTypePickerType;
  atIndex?: number;
  groupId?: string;
  testId: string;
}

const CreateStepModal = (
  { className, initialTab, testId, groupId, atIndex }: CreateStepModalProps = {
    className: '',
    groupId: '',
    testId: '',
    atIndex: 0,
    initialTab: 'actions',
  },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => modal.hide(), [modal]);

  const { isLoading, reset } = useActionState(TestActions.createTemporaryStepRequest, {
    reset: false,
    onSuccess: handleSuccess,
  });

  useMount(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.START_STEP_CREATION);
  });

  useUnmount(() => {
    reset();
  });

  const handleChange = useCallback(
    (groupType, stepType, assertionProperty) => {
      analytics.trackEvent(
        stepType === STEP_TYPE.ASSERT ? 'new_assertion_added' : 'new_step_manually_added',
        {
          test_id: testId,
          ...(stepType === STEP_TYPE.ASSERT
            ? { assertion_type: groupType, assertion_name: assertionProperty }
            : {
                action_type: groupType,
                action_name: stepType,
              }),
        },
      );

      dispatch(
        TestActions.createTemporaryStepRequest(
          testId,
          groupId,
          stepType,
          atIndex,
          stepType === STEP_TYPE.ASSERT
            ? {
                assertionProperty,
              }
            : undefined,
        ),
      );
    },
    [atIndex, dispatch, groupId, testId],
  );

  const renderLoader = renderWhenTrue(() => (
    <S.LoaderWrapper>
      <Loader />
    </S.LoaderWrapper>
  ));

  return (
    <S.Container className={className} data-testid="CreateStepModal">
      <Header>{t('createStepModal.title', 'Add a new test step')}</Header>
      <S.Content>
        {renderLoader(isLoading)}
        <StepTypePicker initialTab={initialTab} onChange={handleChange} />
      </S.Content>
    </S.Container>
  );
};

export default CreateStepModal;
