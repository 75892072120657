import { schema } from 'normalizr';

const getStepId = (value) => value.frontId || value.stepId || value.id;

export const elementScreenshotSchema = new schema.Entity(
  'elementsScreenshots',
  {},
  {
    idAttribute: getStepId,
    processStrategy: (value) => value.screenshotData || value.screenshot,
  },
);

const stepSchema = new schema.Entity(
  'steps',
  {},
  {
    idAttribute: getStepId,
  },
);

const unconfirmedGroupSchema = new schema.Entity('unconfirmedGroups', {
  steps: [stepSchema],
});

export const groupSchema = new schema.Entity('groups', {
  steps: [stepSchema],
  unconfirmedGroups: [unconfirmedGroupSchema],
});

const testSchema = new schema.Entity('tests', {
  groups: [groupSchema],
  unconfirmedGroups: [unconfirmedGroupSchema],
});

export const testsListSchema = new schema.Array(testSchema);

export default testSchema;
