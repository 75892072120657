import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import * as Yup from 'yup';

import { nameValidator, idValidator, VALIDATION_MESSAGE } from '~/utils/validators';

const BaseSchema = Yup.object({
  name: nameValidator.max(DATA_RESTRICTIONS.PROFILE_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH),
  variables: Yup.array(
    Yup.object().shape({
      id: idValidator,
      key: Yup.string(),
      name: Yup.string(),
      type: Yup.string(),
      value: Yup.string()
        .transform((value) => value || null)
        .nullable()
        .default(null),
    }),
  ),
});

export const ProfileEditSchema = BaseSchema.shape({
  id: idValidator,
});
export const ProfileCreateSchema = BaseSchema.shape({});
