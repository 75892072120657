import { createApi } from '@reduxjs/toolkit/query/react';

import type { Component } from '@bugbug/core/types/components';
import type { Group } from '@bugbug/core/types/groups';
import type { Test } from '@bugbug/core/types/tests';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Test' as const;
export const testsApi = createApi({
  reducerPath: 'testsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/tests/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    unlinkComponent: builder.mutation<Group, { testId: Test['id']; componentId: Component['id'] }>({
      query: ({ testId, componentId }) => ({
        url: `${testId}/unlink-component/`,
        method: 'POST',
        data: {
          groupId: componentId,
        },
      }),
    }),
  }),
});

export const { useUnlinkComponentMutation } = testsApi;
