import styled from 'styled-components';

import { Content as ContentBase, Footer as FooterBase } from '~/components/modals/Modal';

export const Form = styled.form`
  max-width: 1215px;
  max-height: 65vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Content = styled(ContentBase)`
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
`;

export const Footer = styled(FooterBase)`
  && {
    margin-bottom: 6px;
    margin-right: 3px;
    margin-top: 0;
  }
`;

export const ScrollableContent = styled.div`
  overflow: auto;
  flex: 1;
  padding-bottom: 20px;
`;
