import dayjs from 'dayjs';
import startCase from 'lodash.startcase';
import { isNil } from 'ramda';

export default (value, format) => {
  switch (format) {
    case 'uppercase':
      return (value || '').toUpperCase();
    case 'regularCase':
      return startCase(value || '').toLowerCase();
    case 'startCase':
      return startCase(value || '');
    case 'date':
      return dayjs(value).format('DD MMM YYYY');
    case 'limit':
      if (isNil(value)) {
        return 'Unlimited';
      }
      return !value ? 'No' : value;
    default:
      return value;
  }
};
