import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Code = styled.pre`
  padding: 4px 8px;
  flex: 1;
  border: 1px none ${COLOR.BLACK};
  border-radius: 5px;
  background-color: #333;
  font-family: Inconsolata, monospace;
  color: ${COLOR.WHITE};
  margin-right: 4px;
  margin-bottom: 0;
`;
