import memoize from 'lodash.memoize';
import { pick, mergeDeepRight } from 'ramda';

import { STEP_SCHEMA } from './StepDetails.constants';

export const getInitialValues = memoize((step, currentValues = {}) => {
  const currentType = step.type;
  const currentStep = {
    ...mergeDeepRight(step, currentValues),
    type: step.type,
    isActive: step.isActive,
  };
  const schema = STEP_SCHEMA[currentType] || STEP_SCHEMA.DEFAULT;
  const requiredFields = Object.keys(schema.describe().fields);

  const initialValues = pick(requiredFields, currentStep);
  return schema.cast(initialValues);
});
