import * as T from '@bugbug/core/utils/toolbox';
import memoize from 'lodash.memoize';
import { pathOr, prop, pick, values, pipe, groupBy, props, uniq } from 'ramda';
import { createSelector } from 'reselect';

import type { ReduxState } from '../modules.types';
import type { Selector } from 'reselect';
import type { ValueOf } from 'type-fest';

import type { SuiteRun } from '@bugbug/core/types/suites';

const selectSuiteRunDomain: Selector<ReduxState, ReduxState['suiteRun']> = (state) =>
  state.suiteRun;

export const selectSuiteRunsOrder = createSelector(
  selectSuiteRunDomain,
  pathOr([], ['list', 'order']),
);

export const selectAddedSuiteRuns = createSelector(
  selectSuiteRunDomain,
  pathOr([], ['list', 'added']),
);

export const selectSuiteRunsList: Selector<
  ReduxState,
  ValueOf<ReduxState['suiteRun']['suitesRunsList']>[]
> = createSelector(selectSuiteRunDomain, (s) => Object.values(s.suitesRunsList ?? {}));

export const selectSuiteRunsMap: Selector<ReduxState, ReduxState['suiteRun']['suitesRuns']> =
  createSelector(selectSuiteRunDomain, pathOr({}, ['suitesRuns']));

export const selectTestRunsMap: Selector<ReduxState, ReduxState['suiteRun']['testsRuns']> =
  createSelector(selectSuiteRunDomain, pathOr({}, ['testsRuns']));

export const selectSuiteRun = memoize((suiteRunId) =>
  createSelector(selectSuiteRunsMap, (suiteRunsMap) => suiteRunsMap[suiteRunId] ?? {}),
);

export const selectSingleSuiteRunId: Selector<ReduxState, SuiteRun['id']> = createSelector(
  selectSuiteRunDomain,
  pathOr('', ['single', 'id']),
);

export const selectSingleSuiteRun: Selector<ReduxState, SuiteRun> = createSelector(
  [selectSingleSuiteRunId, selectSuiteRunsMap],
  (suiteRunId, suiteRunsMap) => suiteRunsMap[suiteRunId] ?? {},
);

export const selectSingleSuiteRunTestsOrder = createSelector(
  selectSingleSuiteRun,
  pathOr([], ['testsRuns']),
);

export const selectSingleSuiteRunTestList = createSelector(
  [selectSingleSuiteRunTestsOrder, selectTestRunsMap],
  pipe(pick, values),
);

export const selectSingleSuiteRunEnvData = createSelector(
  [selectSingleSuiteRun, selectTestRunsMap],
  (suiteRun, testRuns) => {
    if (T.isEmpty(suiteRun.testsRuns)) {
      return {};
    }

    const commonParts = pick(
      [
        'browserName',
        'browserVersion',
        'extensionVersion',
        'osName',
        'profileName',
        'runMode',
        'suiteName',
      ],
      testRuns[suiteRun.testsRuns[0]],
    );

    const windowSize = uniq(
      suiteRun.testsRuns.map((testRunId) =>
        pick(['browserHeight', 'browserWidth', 'screenSizeType'], testRuns[testRunId]),
      ),
    );

    return {
      ...commonParts,
      windowSize,
    };
  },
);

export const selectSuiteRunWithStats = memoize(
  (
    suiteRunId: SuiteRun['id'],
  ): Selector<ReduxState, SuiteRun & { [key: `${string}Count`]: number }> =>
    createSelector([selectSuiteRun(suiteRunId), selectTestRunsMap], (suiteRun, testRunsMap) => {
      const testRuns = props(suiteRun?.testsRuns ?? [], testRunsMap) ?? [];
      const statuses = groupBy(
        ({ status, isAutoRetried }) => (isAutoRetried ? 'autoRetried' : status),
        testRuns,
      );

      return {
        ...suiteRun,
        passedCount: suiteRun.passedCount ?? statuses.passed?.length ?? 0,
        failedCount: statuses.failed?.length ?? 0,
        errorCount: statuses.error?.length ?? 0,
        autoRetriedCount: statuses.autoRetried?.length ?? 0,
      };
    }),
);

export const selectCurrentPageParams = createSelector(
  selectSuiteRunDomain,
  pick(['descOrder', 'sortBy', 'pagination', 'query']),
);

export const selectSuiteRunsPagination = createSelector(selectSuiteRunDomain, prop('pagination'));
