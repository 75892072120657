import type { ScheduleTimeUnit } from '@bugbug/core/types/schedules';
import i18n from '~/translations';

export const TIME_UNIT_DATA: Record<ScheduleTimeUnit, { id: ScheduleTimeUnit; name: string }> = {
  everyMinute: {
    id: 'everyMinute',
    name: i18n.t('schedules.timeUnit.everyMinute', 'Every minute'),
  },
  everyFiveMinutes: {
    id: 'everyFiveMinutes',
    name: i18n.t('schedules.timeUnit.everyFiveMinutes', 'Every 5 minutes'),
  },
  hourly: {
    id: 'hourly',
    name: i18n.t('schedules.timeUnit.hour', 'Hourly'),
  },
  daily: {
    id: 'daily',
    name: i18n.t('schedules.timeUnit.day', 'Daily'),
  },
  weekly: {
    id: 'weekly',
    name: i18n.t('schedules.timeUnit.week', 'Weekly'),
  },
  monthly: {
    id: 'monthly',
    name: i18n.t('schedules.timeUnit.month', 'Monthly'),
  },
};

export const HOURS = [
  { id: '0', name: '00:00 - 01:00' },
  { id: '1', name: '01:00 - 02:00' },
  { id: '2', name: '02:00 - 03:00' },
  { id: '3', name: '03:00 - 04:00' },
  { id: '4', name: '04:00 - 05:00' },
  { id: '5', name: '05:00 - 06:00' },
  { id: '6', name: '06:00 - 07:00' },
  { id: '7', name: '07:00 - 08:00' },
  { id: '8', name: '08:00 - 09:00' },
  { id: '9', name: '09:00 - 10:00' },
  { id: '10', name: '10:00 - 11:00' },
  { id: '11', name: '11:00 - 12:00' },
  { id: '12', name: '12:00 - 13:00' },
  { id: '13', name: '13:00 - 14:00' },
  { id: '14', name: '14:00 - 15:00' },
  { id: '15', name: '15:00 - 16:00' },
  { id: '16', name: '16:00 - 17:00' },
  { id: '17', name: '17:00 - 18:00' },
  { id: '18', name: '18:00 - 19:00' },
  { id: '19', name: '19:00 - 20:00' },
  { id: '20', name: '20:00 - 21:00' },
  { id: '21', name: '21:00 - 22:00' },
  { id: '22', name: '22:00 - 23:00' },
  { id: '23', name: '23:00 - 00:00' },
];

export const DAYS_OF_WEEK = [
  { id: '1', name: 'Monday' },
  { id: '2', name: 'Tuesday' },
  { id: '3', name: 'Wednesday' },
  { id: '4', name: 'Thursday' },
  { id: '5', name: 'Friday' },
  { id: '6', name: 'Saturday' },
  { id: '0', name: 'Sunday' },
];
