/* eslint-disable react/jsx-props-no-spreading */
import Button from '@bugbug/core/components/Button';
import IconButton from '@bugbug/core/components/IconButton';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import {
  EmptyInfo,
  List,
  Description,
  Header,
  HeaderLeftContent,
  Item,
  ItemText,
  Title,
  Wrapper,
} from './TestsPickerList.styled';

const TestsPickerList = ({
  id,
  title,
  description,
  onHeaderButtonClick,
  headerButtonLabel,
  empty,
  emptyInfo,
  onItemClick,
  items,
  Icon,
  searchValue,
}) => {
  const matchNameSearchValue = (name) =>
    !isEmpty(searchValue) && name.toLowerCase().includes(searchValue.toLowerCase());

  return (
    <Wrapper data-testid="TestsPickerList">
      <Header>
        <HeaderLeftContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </HeaderLeftContent>
        <Button onClick={onHeaderButtonClick}>{headerButtonLabel}</Button>
      </Header>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <List ref={provided.innerRef} empty={empty} draggingOver={snapshot.isDraggingOver}>
            {empty && <EmptyInfo>{emptyInfo}</EmptyInfo>}
            {items.map(({ id: itemId, name: itemName }, index) => (
              <Draggable key={itemId} draggableId={itemId} index={index}>
                {(itemProvided, itemSnapshot) => (
                  <Item
                    ref={itemProvided.innerRef}
                    dragging={itemSnapshot.isDragging}
                    matching={matchNameSearchValue(itemName)}
                    {...itemProvided.draggableProps}
                    {...itemProvided.dragHandleProps}
                  >
                    <ItemText>{itemName}</ItemText>
                    <IconButton onClick={onItemClick(itemId)}>{Icon}</IconButton>
                  </Item>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Wrapper>
  );
};

TestsPickerList.defaultProps = {};

TestsPickerList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onHeaderButtonClick: PropTypes.func.isRequired,
  headerButtonLabel: PropTypes.string.isRequired,
  empty: PropTypes.bool.isRequired,
  emptyInfo: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  Icon: PropTypes.node.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default TestsPickerList;

/* eslint-enable react/jsx-props-no-spreading */
