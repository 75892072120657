class StoreRegistry {
  store = {};

  set(newStore) {
    this.store = newStore;
  }

  getState() {
    return this.store?.getState?.() ?? {};
  }

  dispatch(action) {
    this.store?.dispatch?.(action);
  }
}

export default new StoreRegistry();
