import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDropdownSelect = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-select-dropdown" }),
  /* @__PURE__ */ jsx("g", { id: "e2ac93f1-92fb-4ee0-9d6b-d350a832b147", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "a4aee08f-4d40-486f-b52f-f16ca755908d", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("polyline", { points: "16.3 22.04 3.98 22.04 3.98 9.96 16.3 9.96", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 15.48, y1: 9.96, x2: 28.02, y2: 9.96, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 28.02, y1: 22.04, x2: 15.48, y2: 22.04, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 28.02, y1: 9.96, x2: 28.02, y2: 22.04, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsx("polyline", { points: "23.74 15.2 22 16.94 20.26 15.2", style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 8.42, y1: 15.99, x2: 16.08, y2: 15.99, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgDropdownSelect);
export default ForwardRef;
