import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgIconDragArea = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 5, height: 30, viewBox: "0 0 5 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 4, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 8, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 12, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 16, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 20, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 24, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 }),
  /* @__PURE__ */ jsx("rect", { y: 28, width: 5, height: 2, rx: 1, fill: "#100003", fillOpacity: 0.36 })
] });
const ForwardRef = forwardRef(SvgIconDragArea);
export default ForwardRef;
