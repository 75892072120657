import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { Suite } from '@bugbug/core/types/suites';
import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteActions } from '~/modules/suite/suite.redux';

interface CloneSuiteModalProps {
  className?: string;
  shouldNavigate?: boolean;
  id: Suite['id'];
}

const CloneSuiteModal = ({ className, id, shouldNavigate = false }: CloneSuiteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const cloneRequestState = useActionState(SuiteActions.cloneRequest, {
    onSuccess: modal.hide,
  });

  const handleCloneConfirm = useCallback(
    () => dispatch(SuiteActions.cloneRequest(id, shouldNavigate)),
    [dispatch, id, shouldNavigate],
  );

  return (
    <ConfirmModal
      hasInternalServerError={cloneRequestState.hasInternalServerError}
      className={className}
      title={t('cloneSuiteModal.title', 'Do you want to duplicate this suite?')}
      pending={cloneRequestState.isLoading}
      onConfirm={handleCloneConfirm}
      onCancel={modal.hide}
    />
  );
};
CloneSuiteModal.displayName = 'CloneSuiteModal';

export default CloneSuiteModal;
