import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Content as ContentBase, Header } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 470px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  ${Header} {
    margin-bottom: 7px;
  }

  svg {
    color: ${COLOR.GRAY_34};
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: -6px;
    margin-top: -4px;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
