import { ActionButton } from '@bugbug/core/components/Button';
import * as T from '@bugbug/core/utils/toolbox';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import FormField from '~/components/FormField';
import { useTestAlertMutation } from '~/modules/alerts';
import { getErrorMessage, getExtractedErrorMessages } from '~/services/api/utils';
import { ellipsis } from '~/utils/text';

import { buildAlertRequestData } from '../../EditAlertModal.helpers';

import * as S from './EditAlertModalActionTrigger.styled';

const EditAlertModalActionTrigger = () => {
  const { t } = useTranslation();
  const { values, setErrors, validateForm } = useFormikContext<EditAlertFormValues>();
  const [sendSampleWebhook, { isLoading, isSuccess, error, reset, data, isError }] =
    useTestAlertMutation();
  const errorRef = useRef<HTMLSpanElement>(null);

  const extractedError = useMemo(() => getExtractedErrorMessages(error), [error]);

  const handleTestClick = useCallback(async () => {
    const result = await validateForm();

    if (T.isEmpty(result)) {
      sendSampleWebhook(buildAlertRequestData(values));
    } else {
      const firstInvalidField = Object.keys(result)[0];
      document.getElementsByName(firstInvalidField)[0]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sendSampleWebhook, values, validateForm]);

  useUpdateEffect(() => {
    if (!isLoading) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  useUpdateEffect(reset, [values.action]);

  useEffect(() => {
    if (isError) {
      setErrors(getExtractedErrorMessages(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  const testTriggerResult = data?.response ?? extractedError?.invalidTryResponse ?? '';
  const croppedResult = ellipsis(testTriggerResult, 500);
  const successMessage = t('editAlertModal.testSection.successMessage', '200 OK');

  return (
    <S.TestSection>
      <FormField
        label={t('editAlertModal.testSection.label', 'Verify if your alert works')}
        description={t(
          'editAlertModal.testSection.description',
          'We will use example data to check if your alert works.',
        )}
      >
        <ActionButton onClick={handleTestClick} variant="primary" bordered pending={isLoading}>
          {t('editAlertModal.testSection.button', 'Send test alert')}
        </ActionButton>
      </FormField>
      <S.TestResult succeeded={isSuccess} failed={isError}>
        {isError && <span>{t('editAlertModal.testSection.title.failed', 'Failed')}</span>}
        {isSuccess && <span>{t('editAlertModal.testSection.success', 'Success')}</span>}
        {(isSuccess || error) && (
          <span ref={errorRef}>
            {t('editAlertModal.testSection.status', 'Response: {{ statusMessage }}', {
              statusMessage: error ? getErrorMessage(error) : successMessage,
            })}
          </span>
        )}
        {croppedResult && (
          <S.MoreSection storageKey="alertTestTriggerResultExpanded" defaultExpanded={false}>
            {croppedResult}
          </S.MoreSection>
        )}
      </S.TestResult>
    </S.TestSection>
  );
};

export default EditAlertModalActionTrigger;
