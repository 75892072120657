import i18n from '~/translations';

import toasts from './common';

export const showLoginError = () => {
  const content = i18n.t(
    'notifications.login.error',
    'An unexpected error occurred while trying to log in',
  );

  toasts.showError({ content });
};

export const showSignUpError = () => {
  const content = i18n.t(
    'notifications.signUp.error',
    'An unexpected error occurred while creating new account',
  );

  toasts.showError({ content });
};

export const showResendError = () => {
  const content = i18n.t(
    'notifications.resend.error',
    'An unexpected error occurred while sending an e-mail',
  );

  toasts.showError({ content });
};

export const showResendSuccess = () => {
  const content = i18n.t('notifications.resend.confirm', 'Verfication e-mail sent');
  toasts.showSuccess({ content });
};
