import styled from 'styled-components';

export const OrganizedList = styled.ol`
  list-style: none;
  padding-left: 27px;
  position: relative;
  counter-reset: item;

  li {
    margin-bottom: 6px;
    line-height: 19.5px;
  }

  li:before {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    transform: translate(0, 2px);
    content: counter(item) '. ';
    counter-increment: item;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
