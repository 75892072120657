import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAlertsArrow = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 178, height: 13, viewBox: "0 0 178 13", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_5060_72855)", children: [
    /* @__PURE__ */ jsx("circle", { cx: 10.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 13.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 15.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 18.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 20.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 23.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 25.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 28.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 30.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 33.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 35.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 38.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 40.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 43.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 45.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 48.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 50.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 53.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 55.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 58.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 60.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 63.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 65.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 68.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 70.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 73.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 75.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 78.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 80.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 83.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 85.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 88.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 90.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 93.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 95.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 98.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 100.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 103.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 105.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 108.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 110.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 113.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 115.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 118.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 120.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 123.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 125.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 128.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 130.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 133.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 135.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 138.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 140.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 143.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 145.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 148.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 150.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 153.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 155.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 158.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 160.75, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("circle", { cx: 163.25, cy: 6.5, r: 0.75, fill: "#8466CA" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M162.307 2.50781L166.294 6.49512L162.297 10.4922H163.711L167.355 6.84867L167.708 6.49512L167.355 6.14156L163.721 2.50781H162.307Z", fill: "#8466CA" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_5060_72855", children: /* @__PURE__ */ jsx("rect", { width: 160, height: 10, fill: "white", transform: "translate(9 1.5)" }) }) })
] });
const ForwardRef = forwardRef(SvgAlertsArrow);
export default ForwardRef;
