import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const List = styled.div`
  margin-top: 6px;
  height: calc(100vh - 290px);
  overflow-y: auto;
  border: 2px dashed ${COLOR.TRANSPARENT};

  ${styleWhenTrue(
    prop('empty'),
    css`
      border-color: ${COLOR.GRAY_9};
    `,
  )}

  ${styleWhenTrue(
    prop('draggingOver'),
    css`
      border-radius: 3px;
      border: 2px solid ${COLOR.PRIMARY_LIGHT};
    `,
  )}
`;

export const Title = styled.span`
  line-height: 15px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const Description = styled.p`
  margin: 3px 0 0;
  color: ${COLOR.GRAY_19};
  line-height: 16px;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 3px;
  padding: 2px 6px 2px 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${COLOR.GRAY_39};
  border-radius: 4px;
  cursor: grab;
  min-height: 36px;
  background-color: ${COLOR.WHITE};

  ${styleWhenTrue(
    prop('matching'),
    css`
      &:after {
        display: block;
        pointer-events: none;
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid ${COLOR.PRIMARY_BORDER};
        border-radius: 4px;
        content: '';
      }
    `,
  )}

  ${styleWhenTrue(
    prop('dragging'),
    css`
      background-color: ${COLOR.GRAY_28};
    `,
  )}

  &:hover {
    background-color: ${COLOR.GRAY_28};
  }
`;

export const ItemText = styled.span`
  line-height: 15px;
  font-weight: 500;
  width: 100%;
  padding-right: 50px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const EmptyInfo = styled.span`
  display: block;
  top: 0;
  left: 0;
  text-align: center;
  max-width: 200px;
  color: #999;
  line-height: 18px;
  font-weight: 400;
  padding: 54px 8px 38px;
  margin: 0 auto;
`;
