import styled from 'styled-components';
import theme from 'styled-theming';

import type { SwitchVariant } from './Switch.types';

import { COLOR } from '../../theme/colors';
import { THEME_MODE } from '../../theme/modes';
import BaseTooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  width: fit-content;
  padding: 2px;

  .switch-wrapper {
    display: flex;
    align-items: center;
  }

  svg {
    margin-bottom: 5px;
  }
`;

export const Tooltip = styled(BaseTooltip).attrs(() => ({ wrapperClassName: 'switch-wrapper' }))``;

const backgroundColor = theme.variants<'variant', SwitchVariant>('mode', 'variant', {
  primary: { [THEME_MODE.LIGHT]: COLOR.PRIMARY_LIGHT },
  secondary: { [THEME_MODE.LIGHT]: COLOR.SECONDARY },
});

export const Switch = styled.label`
  position: relative;
  display: inline-flex;
  height: 18px;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;

  input {
    opacity: 0;
    top: 0;
    cursor: inherit;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
    left: -100%;
    width: 300%;
  }

  .track {
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${COLOR.GRAY_2};
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 34px;
    width: 32px;
  }

  .thumb {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    left: 3px;
    bottom: 3px;
    background-color: ${COLOR.WHITE};
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 50%;
  }

  input:checked ~ .track {
    background-color: ${backgroundColor};
  }

  input:checked ~ .thumb {
    transform: translateX(14px);
  }

  .label {
    cursor: pointer;
    padding-bottom: 1px;
    padding-right: 7px;
    padding-left: 10px;
    margin-top: -2px;
    font-size: inherit;
    letter-spacing: -0.13px;
    line-height: 1.5;
  }

  input:disabled {
    ~ .track {
      pointer-events: none;
      cursor: default;
      background-color: #e1e1e1;
    }

    ~ .thumb {
      background-color: #bdbdbd;
    }

    ~ .label {
      pointer-events: none;
      cursor: default;
      color: rgba(0, 0, 0, 0.38);
    }

    &:checked ~ .thumb {
      background-color: ${COLOR.WHITE};
    }
  }
`;
