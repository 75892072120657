import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isFinishedStatus } from '@bugbug/core/types/base';
import produce from 'immer';
import { normalize } from 'normalizr';
import { omit, without, prop, is, pick } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { SuiteRunTypes } from '~/modules/suiteRun/suiteRun.redux';

import { suiteSchema, suiteListSchema } from './suite.schema';

export const { Types: SuiteTypes, Creators: SuiteActions } = createActions(
  {
    getSingleRequest: ['id'],
    getSingleSuccess: ['suite'],
    getSingleFailure: ['error'],
    getListRequest: ['query', 'sortBy', 'descOrder'],
    getListSuccess: ['suites'],
    getListFailure: ['error'],
    cloneRequest: ['id', 'shouldNavigate'],
    cloneSuccess: ['data'],
    cloneFailure: ['error'],
    createRequest: ['data'],
    createSuccess: ['data'],
    createFailure: ['error'],
    removeRequest: ['id'],
    removeSuccess: ['id'],
    removeFailure: ['error'],
    removeSelectedRequest: ['suitesIds'],
    removeSelectedSuccess: ['suitesIds'],
    removeSelectedFailure: ['error'],
    removeAllRequest: [],
    removeAllSuccess: [],
    removeAllFailure: ['error'],
    updateRequest: ['id', 'data'],
    updateSuccess: ['data'],
    updateFailure: ['error'],
    runRequest: ['id', 'options'],
    runSuccess: ['suiteRunId'],
    runFailure: ['error'],
    runSelectedRequest: ['suitesIds', 'params'],
    runSelectedSuccess: [],
    runSelectedFailure: ['error'],
    stopRequest: ['ids', 'refreshPageState'],
    stopSuccess: ['data'],
    stopFailure: ['error'],
    getTestsRequest: [],
    getTestsSuccess: ['tests'],
    getTestsFailure: ['error'],
    updatedSuccess: ['results'],
    reset: [],
    resetSingle: [],
  },
  { prefix: 'SUITE/' },
);

export const INITIAL_STATE = {
  single: {},
  list: {
    suites: {},
    order: [],
  },
  testList: [],
  testListTotalCount: 0,
};

const getSingleSuccess = (state, { suite }) =>
  produce(state, (draftState) => {
    const data = normalize(suite, suiteSchema);
    draftState.single = data.entities;
    draftState.single.id = data.result;
  });

const getListSuccess = (state, { suites }) =>
  produce(state, (draftState) => {
    const data = normalize(suites, suiteListSchema);
    draftState.list.suites = data.entities.suites || {};
    draftState.list.order = data.result || [];
  });

const removeSuitesByIds = (state, suitesIds = []) =>
  produce(state, (draftState) => {
    const allTestsSuiteId = prop('id', Object.values(draftState.list.suites).find(prop('all')));
    const suites = without([allTestsSuiteId], suitesIds);
    draftState.list.suites = omit(suites, draftState.list.suites);
    draftState.list.order = without(suites, draftState.list.order);
  });

const removeSuccess = (state, { id }) => removeSuitesByIds(state, [id]);

const removeSelectedSuccess = (state, { suitesIds }) => removeSuitesByIds(state, suitesIds);

const removeAllSuccess = (state) => removeSuitesByIds(state, state.list.order);

const createSuccess = (state, { data }) =>
  produce(state, (draftState) => {
    draftState.list.order = [...state.list.order, data.id];
    draftState.list.suites[data.id] = data;
  });

const getTestsSuccess = (state, { tests }) =>
  produce(state, (draftState) => {
    draftState.testList = tests;
  });

const updateSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data, suiteSchema);
    const suiteData = data.entities.suites[action.data.id];
    if (draftState.single.id && draftState.single.id === action.data.id) {
      draftState.single.suites[action.data.id] = suiteData;
    }
    draftState.list.suites[action.data.id] = suiteData;
  });

const suiteRunUpdated = (state, { results = [] }) =>
  produce(state, (draftState) => {
    for (let index = 0; index < results.length; index += 1) {
      const suiteRun = results[index];
      if (
        suiteRun.isTriggeredByCurrentUser &&
        draftState.list.order.includes(suiteRun.suiteSourceId)
      ) {
        const newResultState = pick(['id', 'status', 'started'], suiteRun);
        if (isFinishedStatus(suiteRun.status)) {
          draftState.list.suites[suiteRun.suiteSourceId].lastResult = newResultState;
          draftState.list.suites[suiteRun.suiteSourceId].lastUnfinishedUserRun = null;
        } else {
          draftState.list.suites[suiteRun.suiteSourceId].lastUnfinishedUserRun = newResultState;
        }
      }
    }
  });

const suiteRunStopped = (state, { data }) =>
  produce(state, (draftState) => {
    const getId = prop('suiteId');
    const suiteIds = (is(String, getId(data)) ? [data] : data)?.map(getId) || draftState.list.order;
    for (let index = 0; index < suiteIds.length; index += 1) {
      const suiteId = suiteIds[index];
      if (draftState.list.suites && draftState.list.suites[suiteId]) {
        draftState.list.suites[suiteId].lastResult = {
          ...(draftState.list.suites[suiteId].lastResult || {}),
          status: RUN_STATUS.STOPPED,
        };
      }
    }
  });

const stopSuccess = (state, { data }) => suiteRunStopped(state, { data });

const resetSingle = (state) =>
  produce(state, (draftState) => {
    draftState.single = {};
  });

export const reducer = createReducer(INITIAL_STATE, {
  [SuiteTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [SuiteTypes.GET_LIST_SUCCESS]: getListSuccess,
  [SuiteTypes.REMOVE_SUCCESS]: removeSuccess,
  [SuiteTypes.REMOVE_SELECTED_SUCCESS]: removeSelectedSuccess,
  [SuiteTypes.REMOVE_ALL_SUCCESS]: removeAllSuccess,
  [SuiteTypes.CREATE_SUCCESS]: createSuccess,
  [SuiteTypes.GET_TESTS_SUCCESS]: getTestsSuccess,
  [SuiteTypes.UPDATE_SUCCESS]: updateSuccess,
  [SuiteTypes.STOP_SUCCESS]: stopSuccess,
  [SuiteTypes.RESET_SINGLE]: resetSingle,
  [SuiteTypes.UPDATED_SUCCESS]: suiteRunUpdated,
  //
  [SuiteRunTypes.STOPPED]: suiteRunStopped,
  [SuiteRunTypes.STOPPED_MULTIPLE]: suiteRunStopped,
});
