import RadioButton, { HorizontalRadioGroup } from '@bugbug/core/components/RadioButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Field, ContainerTooltip } from './SecretToggleField.styled';

export const SecretToggleField = ({
  name,
  onChange,
  value,
  disabled = false,
  tooltipDisabled = false,
}) => {
  const { t } = useTranslation();

  const isOn = `${value}` === 'true';

  const Wrapper =
    disabled && !tooltipDisabled
      ? (props) => (
          <ContainerTooltip
            {...props}
            content={t(
              'secretToggleField.disabledHelperText',
              'Disabled because you entered a variable',
            )}
          />
        )
      : React.Fragment;

  return (
    <Wrapper>
      <Field
        label={t('secretToggleField.label', 'Text contains a password or secret?')}
        helperText={t(
          'secretToggleField.helperText',
          "Hide the value for added security. Collaborators won't see it when editing tests, but can still reveal it during test runs.",
        )}
      >
        <HorizontalRadioGroup>
          <RadioButton
            disabled={disabled}
            name={name}
            onChange={onChange}
            value="false"
            checked={!isOn}
          >
            {t('secretToggleField.no', 'No')}
          </RadioButton>
          <RadioButton
            disabled={disabled}
            name={name}
            onChange={onChange}
            value="true"
            checked={isOn}
          >
            {t('secretToggleField.yes', 'Yes')}
          </RadioButton>
        </HorizontalRadioGroup>
      </Field>
    </Wrapper>
  );
};
