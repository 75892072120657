import Select, { SELECT_ALL_OPTION_VALUE, SelectOption } from '@bugbug/core/components/Select';
import Switch from '@bugbug/core/components/Switch';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../EditAlertFormValues.types';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Schedule } from '@bugbug/core/types/schedules';
import type { Suite } from '@bugbug/core/types/suites';
import type { Test } from '@bugbug/core/types/tests';
import FormField from '~/components/FormField';
import ScrollShadow from '~/components/ScrollShadow';
import { splitByCamelCase } from '~/utils/text';

import EditAlertActionPicker from '../EditAlertActionPicker';
import {
  METHODS_OPTIONS,
  STATUS_OPTIONS,
  RUN_BY_OPTIONS,
  ALERT_EVENTS,
} from '../EditAlertModal.constants';
import { ScrollableContent } from '../EditAlertModal.styled';

import * as S from './EditAlertModalWhenContent.styled';
import EditAlertModalWhenWhichSelect from './EditAlertModalWhenWhichSelect';

interface EditAlertModalWhenContentProps {
  suites?: Suite[];
  schedules?: Schedule[];
  tests?: Test[];
  profiles?: Profile[];
}

const allOptions = [SELECT_ALL_OPTION_VALUE];

const EditAlertModalWhenContent = ({
  suites = [],
  schedules = [],
  tests = [],
  profiles = [],
}: EditAlertModalWhenContentProps) => {
  const { t } = useTranslation();
  const {
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    values: { event },
  } = useFormikContext<EditAlertFormValues>();

  const [whenType, whenEvent] = splitByCamelCase(event);
  const isStatusDisabled = whenEvent === 'Started';
  const isScheduleSelect = whenType === 'schedule';

  useEffect(() => {
    if (isStatusDisabled) {
      setFieldValue('status', allOptions);
    }
  }, [isStatusDisabled, setFieldValue]);

  useEffect(() => {
    if (isScheduleSelect) {
      setFieldValue('runMode', allOptions);
      setFieldValue('triggeredBy', allOptions);
      setFieldValue('runProfiles', allOptions);
    }
  }, [isScheduleSelect, setFieldValue]);

  return (
    <S.Content>
      <EditAlertActionPicker
        {...getFieldMeta('event')}
        {...getFieldProps('event')}
        items={ALERT_EVENTS}
        label={t('editAlertModal.whenPicker.label', 'When:')}
      />

      <ScrollableContent>
        <ScrollShadow />
        <EditAlertModalWhenWhichSelect suites={suites} schedules={schedules} tests={tests} />

        {!isStatusDisabled && (
          <FormField label={t('editAlertModal.fields.result.label', 'Results')}>
            <Select
              {...getFieldMeta('status')}
              {...getFieldProps('status')}
              multiple
              selectAllOptionVisible
            >
              {STATUS_OPTIONS.map(({ value, label }) => (
                <SelectOption key={value} value={value} multiple>
                  {label}
                </SelectOption>
              ))}
            </Select>
          </FormField>
        )}
        {!isScheduleSelect && (
          <>
            <FormField label={t('editAlertModal.fields.runMode.label', 'Methods')}>
              <Select
                {...getFieldMeta('runMode')}
                {...getFieldProps('runMode')}
                multiple
                selectAllOptionVisible
              >
                {METHODS_OPTIONS.map(({ value, label }) => (
                  <SelectOption key={value} value={value} multiple>
                    {label}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
            <FormField
              label={t('editAlertModal.fields.runProfiles.label', 'Profile')}
              labelId="runProfiles"
            >
              <Select
                {...getFieldMeta('runProfiles')}
                {...getFieldProps('runProfiles')}
                multiple
                aria-labelledby="runProfiles"
                selectAllOptionVisible
              >
                {profiles.map((profile) => (
                  <SelectOption key={profile.id} value={profile.id} multiple>
                    {profile.name}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
          </>
        )}
        {!isScheduleSelect && (
          <FormField
            label={t('editAlertModal.fields.triggeredBy.label', 'Run by')}
            labelId="triggeredBy"
          >
            <Select
              {...getFieldMeta('triggeredBy')}
              {...getFieldProps('triggeredBy')}
              aria-labelledby="triggeredBy"
              multiple
              selectAllOptionVisible
            >
              {RUN_BY_OPTIONS.map(({ value, label }) => (
                <SelectOption key={value} value={value} multiple>
                  {label}
                </SelectOption>
              ))}
            </Select>
          </FormField>
        )}
        <Switch
          {...getFieldMeta('isActive')}
          {...getFieldProps('isActive')}
          label={t('editAlertModal.fields.enabled.label', 'Enabled')}
          tooltip={t(
            'editAlertModal.fields.enabled.tooltip',
            'You can disable this alert without permanently deleting it',
          )}
          tooltipAnchor="right-center"
        />
      </ScrollableContent>
    </S.Content>
  );
};

export default EditAlertModalWhenContent;
