import { v5 as uuidv5 } from 'uuid';

import config from '~/modules/config';

import { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_TYPE } from './constants';

const isEnabled = !!config.APP_GTM_ID;

let gtagGlobals = {};

const setup = () => {
  window.gtagLayer = window.gtagLayer || [];
  window.gtag = function gtag() {
    /* eslint-disable-next-line prefer-rest-params */
    window.gtagLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', config.APP_GA_ID);
};

const setGlobalEventData = (data = {}) => {
  gtagGlobals = {
    ...gtagGlobals,
    ...data,
  };
};

const initUserSession = (userId) => {
  const userIdHash = uuidv5(userId, config.APP_GTM_HASH);
  window.gtag('config', config.APP_GA_ID, { user_id: userIdHash });

  setGlobalEventData({
    user_id: userIdHash,
    user_id_for_reports: userIdHash,
  });
};

const setUserProfile = (profile) => {
  setGlobalEventData({
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_METHOD]: profile.user.origin,
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_DATE]: profile.user.dateJoined,
  });
};

const trackEvent = (eventName, data = {}) => {
  window.gtag('event', eventName, {
    ...gtagGlobals,
    ...data,
  });
};

const trackModalView = (modalName) => {
  trackEvent(`${TRACK_EVENT_TYPE.MODAL_VIEW}__${modalName?.toLowerCase()}`);
};

const clearSession = () => {
  gtagGlobals = {};

  if (window.gtmLayer) {
    /* eslint-disable-next-line func-names */
    window.gtmLayer.push(function () {
      this.reset();
    });
  }
  if (window.gtagLayer) {
    /* eslint-disable-next-line func-names */
    window.gtagLayer.push(function () {
      this.reset();
    });
  }
};

export default {
  isEnabled,
  setup,
  setUserProfile,
  initUserSession,
  setGlobalEventData,
  trackEvent,
  trackModalView,
  clearSession,
};
