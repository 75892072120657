import { createContext, useContext } from 'react';

import type { ReactNode } from 'react';

type TimeZoneContextValue = string | null;

const TimeZoneContext = createContext<TimeZoneContextValue>(null);

export const TimeZoneProvider = ({
  value,
  children,
}: {
  value: TimeZoneContextValue;
  children: ReactNode;
}) => <TimeZoneContext.Provider value={value}>{children}</TimeZoneContext.Provider>;

export const useTimeZoneContext = () => useContext(TimeZoneContext);
