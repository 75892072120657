import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import UpgradePlanBase, * as UpgradePlanS from '~/components/UpgradePlan';

export const PendingStateContainer = styled.div`
  ${mixins.layout.flexColumnCenter}
  position: relative;
  height: 100%;

  p {
    margin-top: 10px;
  }
`;

export const UpgradePlan = styled(UpgradePlanBase)`
  > div > div {
    padding: 0;
  }

  ${UpgradePlanS.Content} {
    justify-content: flex-start;
    align-items: flex-start;
  }

  ${UpgradePlanS.Icon} {
    display: none;
  }
`;
