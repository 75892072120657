import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import type React from 'react';

import { Header, Footer, ErrorInfo } from '../../Modal.styled';

import { Container } from './ConfirmModal.styled';

export interface ConfirmModalProps {
  className?: string;
  hasInternalServerError?: boolean;
  internalServerErrorMessage?: string;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  pending?: boolean;
  succeeded?: boolean;
  title: string | React.ReactNode;
}

const ConfirmModal = ({
  className,
  hasInternalServerError,
  internalServerErrorMessage,
  onCancel = R.identity,
  onConfirm,
  pending,
  succeeded,
  title,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Container className={className} data-testid="ConfirmModal">
      <Header>{title}</Header>
      <Footer>
        <ErrorInfo
          isVisible={hasInternalServerError}
          inlineErrorText={internalServerErrorMessage}
        />
        <Button data-testid="ConfirmModal.CancelButton" onClick={onCancel}>
          {t('confirmModal.defaultCancelLabel', 'No')}
        </Button>
        <ActionButton
          data-testid="ConfirmModal.ConfirmButton"
          onClick={onConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={pending}
          succeeded={succeeded}
        >
          {t('confirmModal.defaultConfirmLabel', 'Yes')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

export default ConfirmModal;
