import { pathEq } from 'ramda';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import api from '~/services/api';
import Toast from '~/services/toasts';
import i18n from '~/translations';

import { selectCurrentOrganizationId } from '../organization/organization.selectors';

import { UPDATE_ERRORS } from './organizationUsers.constants';
import { OrganizationUsersTypes, OrganizationUsersActions } from './organizationUsers.redux';

export function* addUser({ data }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    yield call(api.organizations.addUser, organizationId, data);
    yield put(OrganizationUsersActions.addSuccess());
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(Toast.showError, {
        content: i18n.t(
          'organizationUsers.add.failure.defaultError',
          'An unexpected error has occurred while adding new user',
        ),
      });
    }
    yield put(OrganizationUsersActions.addFailure(error));
  }
}

export function* addUserSuccess() {
  yield call(Toast.showSuccess, {
    content: i18n.t(
      'organizationUsers.add.success.toastContent',
      'User has been invited successfully',
    ),
  });
}

export function* updateUser({ data }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    const { data: responseData } = yield call(
      api.organizations.updateUserRole,
      organizationId,
      data,
    );
    yield put(OrganizationUsersActions.updateSuccess(responseData));
  } catch (e) {
    yield put(OrganizationUsersActions.updateFailure(e));
  }
}

export function* updateUserFailure({ error }) {
  const { content } =
    UPDATE_ERRORS.find(({ code }) => pathEq(['response', 'data', 0, 'code'], code, error)) || {};

  if (!content) {
    yield call(Toast.showError, {
      content: i18n.t(
        'organizationUsers.update.failure.defaultError',
        'An unexpected error has occurred while updating user',
      ),
    });
    return;
  }

  yield call(Toast.showError, { content });
}

export function* removeUser({ email }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    yield call(api.organizations.removeUser, organizationId, email);
    yield put(OrganizationUsersActions.removeSuccess(email));
  } catch (e) {
    yield put(OrganizationUsersActions.removeFailure(e));
  }
}

export function* getList({ sortBy, descOrder }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    const params = {
      ordering: descOrder ? `-${sortBy}` : sortBy,
    };
    const response = yield call(api.organizations.getUsersList, organizationId, params);
    yield put(OrganizationUsersActions.getListSuccess(response.data));
  } catch (e) {
    yield put(OrganizationUsersActions.getListFailure(e));
  }
}

export default function* OrganizationUsersSagas() {
  yield all([
    yield takeLatest(
      [OrganizationUsersTypes.GET_LIST_REQUEST, OrganizationUsersTypes.ADD_SUCCESS],
      getList,
    ),
    yield takeLatest(OrganizationUsersTypes.ADD_REQUEST, addUser),
    yield takeLatest(OrganizationUsersTypes.ADD_SUCCESS, addUserSuccess),
    yield takeLatest(OrganizationUsersTypes.UPDATE_REQUEST, updateUser),
    yield takeLatest(OrganizationUsersTypes.UPDATE_FAILURE, updateUserFailure),
    yield takeLatest(OrganizationUsersTypes.REMOVE_REQUEST, removeUser),
  ]);
}
