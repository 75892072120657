import produce from 'immer';
import { normalize } from 'normalizr';
import { omit } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { userSchema } from './user.schema';

export const { Types: UserTypes, Creators: UserActions } = createActions(
  {
    getSingleRequest: [],
    getSingleSuccess: ['data'],
    getFlagsSuccess: ['flags'],
    getSingleFailure: ['error'],
    loginRequest: ['payload'],
    loginSuccess: ['payload'],
    loginFailure: ['error'],
    registerRequest: ['data'],
    registerSuccess: [],
    registerFailure: ['error'],
    confirmEmailRequest: ['data'],
    confirmEmailSuccess: [],
    confirmEmailFailure: ['error'],
    passwordResetRequest: ['data'],
    passwordResetSuccess: [],
    passwordResetFailure: ['error'],
    passwordResetConfirmRequest: ['data'],
    passwordResetConfirmSuccess: [],
    passwordResetConfirmFailure: ['error'],
    passwordChangeRequest: ['data'],
    passwordChangeSuccess: [],
    passwordChangeFailure: ['error'],
    logoutRequest: [],
    logoutSuccess: [],
    logoutFailure: ['error'],
    updateSettingsRequest: ['settings', 'meta'],
    updateSettingsSuccess: ['settings', 'meta'],
    updateSettingsFailure: ['error', 'meta'],
    updateDataRequest: ['data'],
    updateDataSuccess: ['data'],
    updateDataFailure: ['error'],
    updateOnboardingProfileRequest: ['data'],
    updateOnboardingProfileSuccess: ['data'],
    updateOnboardingProfileFailure: ['error'],
    updateOnboardingOrganizationRequest: ['name'],
    updateOnboardingOrganizationSuccess: [],
    updateOnboardingOrganizationFailure: ['error'],
    skipOnboardingStepRequest: ['meta'],
    skipOnboardingStepSuccess: ['onboardingStep', 'meta'],
    skipOnboardingStepFailure: ['error', 'meta'],
    updateMarketingConsentRequest: ['isAllowedEmailMarketing'],
    updateMarketingConsentSuccess: [],
    updateMarketingConsentFailure: [],
    getSocialAuthUrlRequest: ['data'],
    getSocialAuthUrlFailure: ['error'],
    getSocialAccessTokenRequest: ['data'],
    getSocialAccessTokenFailure: ['error'],
    setSettings: ['data'],
    loggedOut: [],
    startListeningForLogout: [],
    stopListeningForLogout: [],
    getRegistrationDataRequest: ['key'],
    getRegistrationDataSuccess: ['data'],
    getRegistrationDataFailure: [],
    resendActivationEmailRequest: ['email', 'shouldShowSuccessToast'],
    resendActivationEmailSuccess: [],
    resendActivationEmailFailure: ['error'],
    redirectOnboardingRequest: [],
    redirectOnboardingSuccess: [],
    redirectOnboardingFailure: ['error'],
    clearRegistrationData: [],
    setTutorialVisibilityRequest: ['visible', 'meta'],
    setTutorialVisibilitySuccess: ['visible', 'meta'],
    setTutorialVisibilityFailure: ['error', 'visible', 'meta'],
    refreshAnalyticsGlobals: [],
    clearSessionSuccess: [],
    //
    flagsChanged: ['data'],
  },
  { prefix: 'USER/' },
);

const INITIAL_STATE = {
  token: '',
  isImpersonated: false,
  single: {},
  flags: {},
  settings: {},
  registrationEmail: null,
  registrationOrganizationName: null,
};

const loginSuccess = (state, { payload: { token, isImpersonated } }) =>
  produce(state, (draftState) => {
    draftState.token = token;
    draftState.isImpersonated = isImpersonated || false;
  });

const loginFailure = (state) => ({ ...state, token: '' });

const logoutSuccess = (state) => ({ ...state, token: '' });

const logoutFailure = (state) => ({ ...state, token: '' });

const registerSuccess = (state) => ({ ...state });

const registerFailure = (state) => ({ ...state });

const setSettings = (state, action) => ({
  ...state,
  settings: {
    ...action.data,
  },
});

const getSingleSuccess = (state, action) =>
  produce(state, (draftState) => {
    const { entities, result } = normalize(action.data, userSchema);
    draftState.single = omit(['organizations'], entities.users[result]);
  });

const getFlagsSuccess = (state, action) =>
  produce(state, (draftState) => {
    draftState.flags = action.flags;
  });

const getSingleFailure = (state) =>
  produce(state, (draftState) => {
    draftState.token = '';
  });

const loggedOut = (state) =>
  produce(state, (draftState) => {
    Object.assign(draftState, INITIAL_STATE);
  });

const updateSettingsSuccess = (state, { settings }) =>
  produce(state, (draftState) => {
    draftState.settings = { ...draftState.settings, ...settings };
  });

const getRegistrationDataSuccess = (state, action) =>
  produce(state, (draftState) => {
    draftState.registrationEmail = action.data.email;
    draftState.registrationOrganizationName = action.data.organization.name;
  });

const clearRegistrationData = (state) =>
  produce(state, (draftState) => {
    draftState.registrationEmail = null;
    draftState.registrationOrganizationName = null;
  });

const updateOnboardingProfileSuccess = (state, { data }) =>
  produce(state, (draftState) => {
    draftState.single = data;
  });

const skipOnboardingStepSuccess = (state, { onboardingStep }) =>
  produce(state, (draftState) => {
    draftState.single.onboardingStep = onboardingStep;
  });

const setTutorialVisibility = (state, { visible }) =>
  produce(state, (draftState) => {
    draftState.settings.hideTutorial = !visible;
  });

const flagsChanged = (state, { data }) => getFlagsSuccess(state, { flags: data });

export const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.LOGIN_SUCCESS]: loginSuccess,
  [UserTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [UserTypes.LOGOUT_FAILURE]: logoutFailure,
  [UserTypes.LOGIN_FAILURE]: loginFailure,
  [UserTypes.REGISTER_SUCCESS]: registerSuccess,
  [UserTypes.REGISTER_FAILURE]: registerFailure,
  [UserTypes.UPDATE_DATA_SUCCESS]: getSingleSuccess,
  [UserTypes.UPDATE_SETTINGS_SUCCESS]: updateSettingsSuccess,
  [UserTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [UserTypes.GET_FLAGS_SUCCESS]: getFlagsSuccess,
  [UserTypes.GET_SINGLE_FAILURE]: getSingleFailure,
  [UserTypes.SET_SETTINGS]: setSettings,
  [UserTypes.LOGGED_OUT]: loggedOut,
  [UserTypes.GET_REGISTRATION_DATA_SUCCESS]: getRegistrationDataSuccess,
  [UserTypes.CLEAR_REGISTRATION_DATA]: clearRegistrationData,
  [UserTypes.UPDATE_ONBOARDING_PROFILE_SUCCESS]: updateOnboardingProfileSuccess,
  [UserTypes.SKIP_ONBOARDING_STEP_SUCCESS]: skipOnboardingStepSuccess,
  [UserTypes.SET_TUTORIAL_VISIBILITY_SUCCESS]: setTutorialVisibility,
  [UserTypes.SET_TUTORIAL_VISIBILITY_FAILURE]: setTutorialVisibility,
  [UserTypes.CLEAR_SESSION_SUCCESS]: loggedOut,
  //
  [UserTypes.FLAGS_CHANGED]: flagsChanged,
});
