import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgSwitch = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 25, viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_4502_17267)", children: [
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.58 3.08197C15.9055 2.75653 16.4331 2.75653 16.7585 3.08197L20.0919 6.4153C20.4173 6.74074 20.4173 7.26838 20.0919 7.59381L16.7585 10.9271C16.4331 11.2526 15.9055 11.2526 15.58 10.9271C15.2546 10.6017 15.2546 10.0741 15.58 9.74863L18.3241 7.00456L15.58 4.26048C15.2546 3.93504 15.2546 3.40741 15.58 3.08197Z", fill: "#2B2B2B" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.83073 7.83757C7.16769 7.83757 6.5318 8.10096 6.06296 8.5698C5.59412 9.03864 5.33073 9.67452 5.33073 10.3376V12.0042C5.33073 12.4645 4.95763 12.8376 4.4974 12.8376C4.03716 12.8376 3.66406 12.4645 3.66406 12.0042V10.3376C3.66406 9.2325 4.10305 8.17269 4.88445 7.39129C5.66585 6.60988 6.72566 6.1709 7.83073 6.1709H19.4974C19.9576 6.1709 20.3307 6.54399 20.3307 7.00423C20.3307 7.46447 19.9576 7.83757 19.4974 7.83757H7.83073Z", fill: "#2B2B2B" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.41998 14.749C8.74542 15.0744 8.74542 15.602 8.41998 15.9275L5.67591 18.6715L8.41998 21.4156C8.74542 21.7411 8.74542 22.2687 8.41998 22.5941C8.09455 22.9196 7.56691 22.9196 7.24147 22.5941L3.90814 19.2608C3.5827 18.9354 3.5827 18.4077 3.90814 18.0823L7.24147 14.749C7.56691 14.4235 8.09455 14.4235 8.41998 14.749Z", fill: "#2B2B2B" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.4974 12.8379C19.9576 12.8379 20.3307 13.211 20.3307 13.6712V15.3379C20.3307 16.443 19.8917 17.5028 19.1103 18.2842C18.3289 19.0656 17.2691 19.5046 16.1641 19.5046H4.4974C4.03716 19.5046 3.66406 19.1315 3.66406 18.6712C3.66406 18.211 4.03716 17.8379 4.4974 17.8379H16.1641C16.8271 17.8379 17.463 17.5745 17.9318 17.1057C18.4007 16.6368 18.6641 16.0009 18.6641 15.3379V13.6712C18.6641 13.211 19.0372 12.8379 19.4974 12.8379Z", fill: "#2B2B2B" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_4502_17267", children: /* @__PURE__ */ jsx("rect", { width: 20, height: 20, fill: "white", transform: "translate(2 2.83789)" }) }) })
] });
const ForwardRef = forwardRef(SvgSwitch);
export default ForwardRef;
