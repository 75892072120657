import { useEffect } from 'react';
import useUnmount from 'react-use/lib/useUnmount';

import type { PrintContent } from './PrintableDocument.types';

import { usePrint } from './PrintableDocument.helpers';

export const PrintableHeader = ({ title, subTitle, description }: PrintContent) => {
  const printContext = usePrint();

  useEffect(() => {
    printContext.setContent({
      title,
      subTitle,
      description,
    });
  }, [title, subTitle, description, printContext]);

  useUnmount(() => {
    printContext.setContent({
      title: null,
      subTitle: null,
      description: null,
    });
  });

  return null;
};
