import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUrl = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-url-2" }),
  /* @__PURE__ */ jsx("g", { id: "ab61612b-a615-41cd-92b5-013590fe668f", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "bd6641ab-9237-4935-b7e2-b53f504d5e41", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("path", { d: "M9,10.43h2V17.3a5.16,5.16,0,0,1-.28,1.8A3.35,3.35,0,0,1,10,20.35a3.11,3.11,0,0,1-1.19.74,4.33,4.33,0,0,1-1.52.25,4,4,0,0,1-2.95-1,3.89,3.89,0,0,1-1-2.82V10.43h2.1v6.71a2.82,2.82,0,0,0,.44,1.78,1.68,1.68,0,0,0,1.39.55,1.5,1.5,0,0,0,1.33-.59A3.06,3.06,0,0,0,9,17.14Z" }),
      /* @__PURE__ */ jsx("path", { d: "M12.71,10.54l.76-.12.81-.08.79-.05h.71a8,8,0,0,1,1.47.14,4.2,4.2,0,0,1,1.31.5,2.59,2.59,0,0,1,.91.95,2.86,2.86,0,0,1,.35,1.49,4.26,4.26,0,0,1-.13,1.14,3.45,3.45,0,0,1-.36.85,2.46,2.46,0,0,1-.56.64,5.89,5.89,0,0,1-.71.48l2.59,4.69H18.23L16.07,17H14.81v4.2h-2.1Zm3.22,1.61h-.64a2.37,2.37,0,0,0-.48.06v3.11h.83a2.41,2.41,0,0,0,1.42-.39,1.52,1.52,0,0,0,.55-1.32,1.37,1.37,0,0,0-.43-1.08A1.78,1.78,0,0,0,15.93,12.15Z" }),
      /* @__PURE__ */ jsx("path", { d: "M27.3,16.79h1.9v4.37h-7V10.43h2.1v8.86H27.3Z" })
    ] })
  ] }) })
] });
const ForwardRef = forwardRef(SvgUrl);
export default ForwardRef;
