import styled from 'styled-components';

import { Content as ContentBase, Header } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 515px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  ${Header} {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const InputContainer = styled.div`
  margin-top: 45px;

  & p {
    font-weight: 500;
    margin-bottom: 8px;
  }
`;
