import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Footer } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import * as S from './UnconfirmedStepsModal.styled';

const UnconfirmedStepsModal = ({ className = '' }) => {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <S.Container
      className={className}
      aria-label={t('unconfirmedStepsModal.ariaLabel', 'Please confirm the new recorded steps')}
    >
      <S.Content>
        <Icon name="info" />
        <div>
          <Header>
            {t('unconfirmedStepsModal.title', 'Please confirm the new recorded steps')}
          </Header>
          <p>
            {t(
              'unconfirmedStepsModal.description',
              'You have recorded new test steps but you have not confirmed them yet. You need to confirm or discard these new steps before you can run the test.',
            )}
          </p>
        </div>
      </S.Content>
      <Footer>
        <Button onClick={modal.hide} bordered variant={BUTTON_VARIANT.PRIMARY}>
          {t('unconfirmedStepsModal.return', 'Go back')}
        </Button>
      </Footer>
    </S.Container>
  );
};

export default UnconfirmedStepsModal;
