import { RUN_STATUS } from '@bugbug/core/constants/status';
import compareVersions from 'compare-versions';
import { propEq, cond, equals, prop, T, always, indexBy, last, pipe } from 'ramda';

import { STEP_TYPE, STEP_SCROLL_TO_TYPE } from '~/constants/step';
import config from '~/modules/config';
import i18n from '~/translations';

import { ellipsis } from './text';

export const isFinalStatus = (status) =>
  [RUN_STATUS.FAILED, RUN_STATUS.PASSED, RUN_STATUS.STOPPED].includes(status);

export const getFileNameFromUrl = (url) => {
  if (!url?.startsWith('http')) {
    return url;
  }
  const nameUrl = new URL(url);
  const urlWithoutQuery = `${nameUrl.origin}${nameUrl.pathname}`;
  return decodeURI(last(urlWithoutQuery.split('/')).replace(/\?/g, ''));
};

export const getStepParams = (step, modifiers = true) => {
  if (!step) {
    return '';
  }

  const getPasswordOrValue = () => {
    if (
      modifiers &&
      (step.hasSecretValue || propEq('type', 'password', step.tagAttributes || {}))
    ) {
      return i18n.t('stepParams.type.protectedContent', 'Protected content');
    }
    return step.value;
  };

  const getScrollParams = () =>
    cond([
      [
        equals(STEP_SCROLL_TO_TYPE.EDGE),
        always(
          i18n.t('stepParams.scroll.edge', '{{ target }} to {{ edge }} position', {
            edge: step.scrollEdge,
            target: step.scrollInside,
          }),
        ),
      ],
      [
        equals(STEP_SCROLL_TO_TYPE.UNTIL_NEXT_STEP_ELEMENT_IS_VISIBLE),
        always(
          i18n.t(
            'stepParams.scroll.untilNextElementIsVisible',
            '{{ target }} until element from next step is visible',
            {
              target: step.scrollInside,
            },
          ),
        ),
      ],
      [
        T,
        always(
          i18n.t('stepParams.scroll.coords', '{{ target }} to x: {{ x }}, y: {{ y }}', {
            x: step.scrollX,
            y: step.scrollY,
            target: step.scrollInside,
          }),
        ),
      ],
    ])(step.scrollTo);

  return cond([
    [equals(STEP_TYPE.TYPE), getPasswordOrValue],
    [
      equals(STEP_TYPE.ASSERT),
      always({
        property: step.assertionProperty,
        type: step.assertionType,
        expectedValue: ellipsis(step.assertionExpectedValue),
      }),
    ],
    [equals(STEP_TYPE.CHANGE), getPasswordOrValue],
    [
      equals(STEP_TYPE.SELECT),
      always({
        value: step.value?.replaceAll('\n', ', '),
        type: step.selectType,
      }),
    ],
    [equals(STEP_TYPE.GOTO), always(prop('url', step))],
    [equals(STEP_TYPE.NEW_TAB), always(prop('url', step))],
    [equals(STEP_TYPE.UPLOAD_FILE), () => pipe(prop('value'), getFileNameFromUrl)(step)],
    [equals(STEP_TYPE.SCROLL), getScrollParams],
    [
      equals(STEP_TYPE.SWITCH_CONTEXT),
      always({
        tab: step.tabNo,
        frame: step.frameLocation,
      }),
    ],
    [equals(STEP_TYPE.ANSWER_PROMPT), always(prop('value', step))],
    [equals(STEP_TYPE.SET_LOCAL_VARIABLE), always(prop('localVariableName', step))],
    [T, always('')],
  ])(step.type);
};

export const getPageScreenshotUrl = (screenshotPath) => {
  if (screenshotPath) {
    return config.MEDIA_URL + screenshotPath;
  }
  return '';
};

export const getScreenshotUrl = (url) => {
  if (!url) {
    return '';
  }

  if (url.startsWith('data') || url.startsWith('http')) {
    return url;
  }

  return config.MEDIA_URL + url;
};

export const getGroupName = (name, stepsLength) => {
  if (name) {
    return name;
  }
  return i18n.t('default.group.name', { count: stepsLength });
};

export const convertListToObject = indexBy(prop('id'));

export const getSelectedPartialsLabel = (steps, groups) => {
  const partials = [];

  if (groups) {
    partials.push(
      i18n.t('default.selected.groups', {
        defaultValue_one: '{{count}} group',
        defaultValue_other: '{{count}} groups',
        count: groups,
      }),
    );
  }

  if (steps) {
    partials.push(
      i18n.t('default.selected.steps', {
        defaultValue_one: '{{count}} step',
        defaultValue_other: '{{count}} steps',
        count: steps,
      }),
    );
  }

  return partials.join(i18n.t('default.selected.separator', ' and '));
};

export const getWholeNumberOrDecimal = (value) => Number(Number(value).toFixed(2));

export const decodeHTML = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const hasAtMostVersion = (currentVersion, expectedVersion) =>
  // if current version is lower than / equal to requested
  currentVersion && compareVersions(currentVersion, expectedVersion) !== 1;
