import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 16px;
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  color: ${COLOR.PRIMARY_LIGHT};
`;

export const TrashRemoveIcon = styled(Icon).attrs(() => ({
  name: 'trashRemove',
}))`
  width: 15px;
  height: 15px;
`;
