import Beta from '~/components/Beta';
import { EXPERIMENTAL_STEPS, STEP_TYPE_LABEL } from '~/constants/step';

import { Container } from './StepName.styled';

// TODO: migrate to TS
const StepName = ({ className = '', type = '', assertType = '' }) => {
  const label = STEP_TYPE_LABEL[assertType || type];

  return (
    <Container className={className}>
      {label} {EXPERIMENTAL_STEPS.includes(type) && <Beta />}
    </Container>
  );
};

export default StepName;
