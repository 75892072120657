import { COLOR } from '@bugbug/core/theme/colors';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  min-width: 450px;
`;

export const Label = styled.div``;

export const Entries = styled.ul`
  padding: 1px 0 0;
  border: 1px solid ${COLOR.BORDER_GRAY};
  border-right: 0;
  border-left: 0;
  overflow-y: auto;
  height: min-content;
  max-height: 302px;

  &:empty {
    border: none;
  }
`;

export const Entry = styled.li<{ disabled?: boolean }>`
  list-style: none;
  margin: -1px 0;
  padding: 15px 10px;
  border-top: 1px solid ${COLOR.TRANSPARENT};
  border-bottom: 1px solid ${COLOR.BORDER_GRAY};
  cursor: pointer;
  color: ${COLOR.DARK_GRAY};

  &:last-child {
    border-bottom-color: ${COLOR.TRANSPARENT};
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: ${COLOR.PRIMARY_5};
    border-color: ${COLOR.BORDER_GRAY};
  }

  &:focus {
    outline-color: ${COLOR.PRIMARY_LIGHT};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${COLOR.GRAY_28};
      color: ${COLOR.GRAY_32};
      cursor: default;
      border-top-color: ${COLOR.BORDER_GRAY};
      opacity: 0.5;
      cursor: not-allowed;

      span {
        margin-left: 10px;
      }
    `}
`;
