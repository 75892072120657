import Button, { ActionButton } from '@bugbug/core/components/Button';
import * as T from '@bugbug/core/utils/toolbox';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Integration } from '@bugbug/core/types/integrations';
import type { RouteModalWithRequired } from '@bugbug/core/types/routes';
import ScrollShadow from '~/components/ScrollShadow';
import { INTEGRATIONS_LIST, INTEGRATION_LOGO } from '~/constants/integrations';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import {
  selectIsIntegrationsActiveInProject,
  useDisconnectMutation,
  useLazyGetAuthUrlQuery,
} from '~/modules/integrations';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState';
import analytics from '~/services/analytics';
import toasts from '~/services/toasts';
import IntegrationStatus from '~/views/Integrations/IntegrationStatus';

import { INTEGRATION_DETAILS } from './IntegrationModal.constants';
import * as S from './IntegrationModal.styled';

const IntegrationModal: RouteModalWithRequired<Pick<Integration, 'type' | 'projectId'>> = ({
  data: integration,
}) => {
  const { t } = useTranslation();
  const [getAuthUrl, getAuthUrlState] = useLazyGetAuthUrlQuery();
  const [disconnect, disconnectState] = useDisconnectMutation();
  const { params, routeName } = useAppRoutes('integration');
  const dispatch = useAppDispatch();
  const modal = useModal();

  const isConnected = useAppSelector(
    selectIsIntegrationsActiveInProject(params.projectId, integration.type),
  );

  const setIntegrationTrigger = useCallback(() => {
    dispatch(
      UIStateActions.setIntegrationTrigger({
        routeName,
        params: { ...params, type: integration.type },
      }),
    );
  }, [dispatch, integration.type, params, routeName]);

  const handleConnect = useCallback(async () => {
    analytics.trackEvent(`${integration.type}_connect_clicked`, {});

    const integrationConfig = INTEGRATIONS_LIST.find((i) => i.type === integration.type);
    if (integrationConfig?.isNative && integrationConfig.externalConnectionUrl) {
      setIntegrationTrigger();
      window.open(integrationConfig.externalConnectionUrl, '_blank');
      return;
    }

    const authUrl = await getAuthUrl(integration);
    if (authUrl.data) {
      setIntegrationTrigger();
      window.location.replace(authUrl.data);
    }
  }, [getAuthUrl, integration, setIntegrationTrigger]);

  const handleDisconnect = useCallback(() => {
    disconnect(integration);
  }, [disconnect, integration]);

  useEffect(() => {
    if (disconnectState.isSuccess) {
      toasts.showSuccess({
        content: t('integrationModal.disconnectSuccess', '{{integrationName}} disconnected', {
          integrationName: T.capitalize(integration.type),
        }),
      });
      modal.hide();
    }

    if (disconnectState.error) {
      toasts.showError({
        content: t('integrationModal.disconnectError', 'Disconnecting integration failed'),
      });
    }
  }, [disconnectState.isSuccess, disconnectState.error, t, modal, integration.type]);

  useEffect(() => {
    if (getAuthUrlState.isError) {
      toasts.showError({
        content: t(
          'integrationModal.getAuthError',
          'Unexpected error occurred while getting auth url',
        ),
      });
    }
  }, [getAuthUrlState.isError, t]);

  const Logo = INTEGRATION_LOGO[integration.type];
  const { Details, proceedText } = INTEGRATION_DETAILS[integration.type];

  return (
    <S.Content>
      <header>
        <Logo height="35" aria-label="integration logo" />
        <div>
          <IntegrationStatus type={integration.type} projectId={integration.projectId} />
          {isConnected ? (
            <ActionButton
              onClick={handleDisconnect}
              variant="primary"
              pending={disconnectState.isLoading}
            >
              {t('integrationModal.header.disconnectButton', 'Disconnect')}
            </ActionButton>
          ) : (
            <Button onClick={handleConnect} variant="primary">
              {proceedText}
            </Button>
          )}
        </div>
      </header>
      <section>
        <ScrollShadow />
        <S.ScrollableContent>
          <Details />
        </S.ScrollableContent>
      </section>
    </S.Content>
  );
};

export default IntegrationModal;
