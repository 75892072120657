import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUpload = (props, ref) => /* @__PURE__ */ jsx("svg", { width: 24, height: 24, xmlns: "http://www.w3.org/2000/svg", className: "feather feather-upload", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: 1.5, stroke: "currentColor", fill: "none", role: "img", ref, ...props, children: /* @__PURE__ */ jsxs("g", { children: [
  /* @__PURE__ */ jsx("title", { children: "Layer 1" }),
  /* @__PURE__ */ jsxs("g", { stroke: "null", id: "svg_4", children: [
    /* @__PURE__ */ jsx("path", { stroke: "null", id: "svg_1", d: "m19.81482,14.60494l0,3.47325a1.73663,1.73663 0 0 1 -1.73663,1.73663l-12.15638,0a1.73663,1.73663 0 0 1 -1.73663,-1.73663l0,-3.47325" }),
    /* @__PURE__ */ jsx("polyline", { stroke: "null", id: "svg_2", points: "16.341564178466797,8.526748657226562 12,4.185185432434082 7.658436298370361,8.526748657226562 " }),
    /* @__PURE__ */ jsx("line", { stroke: "null", id: "svg_3", y2: 14.60494, x2: 12.12864, y1: 4.18519, x1: 12.12864 })
  ] })
] }) });
const ForwardRef = forwardRef(SvgUpload);
export default ForwardRef;
