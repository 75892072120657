import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { resettableReducer } from 'reduxsauce';

import actionStateReducer from './actionState/actionState.redux';
import { alertsApi } from './alerts';
import componentsReducer, { componentsApi } from './components';
import { coreApi } from './core/core.api';
import { reducer as coreReducer } from './core/core.redux';
import { reducer as extensionReducer } from './extension/extension.redux';
import { groupsApi } from './groups';
import { integrationsApi } from './integrations';
import { reducer as organizationReducer } from './organization/organization.redux';
import { reducer as organizationUsersReducer } from './organizationUsers/organizationUsers.redux';
import { reducer as profileReducer, ProfileTypes } from './profile/profile.redux';
import { ProjectTypes, reducer as projectReducer } from './project/project.redux';
import { projectsApi } from './projects/projects.api';
import { schedulesApi } from './schedules';
import { reducer as suiteReducer, SuiteTypes } from './suite/suite.redux';
import { reducer as suiteRunReducer, SuiteRunTypes } from './suiteRun/suiteRun.redux';
import { suitesApi } from './suites/suites.api';
import { reducer as testReducer, TestTypes } from './test/test.redux';
import { reducer as testRunReducer, TestRunTypes } from './testRun/testRun.redux';
import { testsApi } from './tests';
import uiStateReducer from './uiState';
import { reducer as userReducer, UserTypes } from './user/user.redux';
import { reducer as variableReducer, VariableTypes } from './variable/variable.redux';
import { reducer as websocketReducer } from './websocket/websocket.redux';

const resettableTest = resettableReducer(TestTypes.RESET);
const resettableTestRun = resettableReducer(TestRunTypes.RESET);
const resettableSuite = resettableReducer(SuiteTypes.RESET);
const resettableSuiteRun = resettableReducer(SuiteRunTypes.RESET);
const resettableProject = resettableReducer(ProjectTypes.RESET);
const resettableActionState = resettableReducer(UserTypes.LOGOUT_SUCCESS);
const resettableVariableState = resettableReducer(VariableTypes.RESET);
const resettableProfileState = resettableReducer(ProfileTypes.RESET);

const uiStateConfig = {
  key: 'uiState',
  storage,
  blacklist: ['menuItems', 'headline'],
};

const organizationStateConfig = {
  key: 'organizationState',
  storage,
  whitelist: ['currentOrganizationId'],
};

const userStateConfig = {
  key: 'user',
  storage,
  whitelist: ['token'],
};

export default (history) => ({
  router: connectRouter(history),
  user: persistReducer(userStateConfig, userReducer),
  project: resettableProject(projectReducer),
  test: resettableTest(testReducer),
  testRun: resettableTestRun(testRunReducer),
  suite: resettableSuite(suiteReducer),
  suiteRun: resettableSuiteRun(suiteRunReducer),
  extension: extensionReducer,
  websocket: websocketReducer,
  uiState: persistReducer(uiStateConfig, uiStateReducer),
  actionState: resettableActionState(actionStateReducer),
  core: coreReducer,
  organizationUsers: organizationUsersReducer,
  organization: persistReducer(organizationStateConfig, organizationReducer),
  profile: resettableProfileState(profileReducer),
  variable: resettableVariableState(variableReducer),
  component: componentsReducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  [schedulesApi.reducerPath]: schedulesApi.reducer,
  [suitesApi.reducerPath]: suitesApi.reducer,
  [componentsApi.reducerPath]: componentsApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [testsApi.reducerPath]: testsApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
});
