import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ModalSize } from '../modals.types';

import useModal from '~/hooks/useModal';

import { Container, Backdrop, CloseButton } from './Modal.styled';

interface ModalProps {
  className?: string;
  children: React.ReactNode;
  size?: ModalSize;
}

const Modal = ({ className, children, size = 'normal' }: ModalProps) => {
  const modal = useModal();
  const { t } = useTranslation();

  const handleClick = useCallback((event) => event.stopPropagation(), []);

  return (
    <Backdrop data-testid="Backdrop" size={size}>
      <Container
        role="dialog"
        className={className}
        data-testid="Modal"
        onClick={handleClick}
        size={size}
      >
        <CloseButton
          aria-label={t('modal.closeButton', 'Close')}
          data-testid="Modal.CloseButton"
          onClick={modal.hide}
          iconName="close"
        />
        {children}
      </Container>
    </Backdrop>
  );
};

export default Modal;
