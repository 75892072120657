import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgUserCircle = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 20 20", role: "img", ref, ...props, children: /* @__PURE__ */ jsx("g", { transform: "translate(-58.324 -216.324)", children: /* @__PURE__ */ jsx("g", { transform: "translate(58.324 216.324)", children: /* @__PURE__ */ jsxs("g", { transform: "translate(0)", children: [
  /* @__PURE__ */ jsxs("g", { transform: "translate(0)", fill: "#fff", stroke: "#461e56", strokeWidth: 1.5, children: [
    /* @__PURE__ */ jsx("circle", { cx: 10, cy: 10, r: 10, stroke: "none" }),
    /* @__PURE__ */ jsx("circle", { cx: 10, cy: 10, r: 9.25, fill: "none" })
  ] }),
  /* @__PURE__ */ jsxs("g", { transform: "translate(4.117 3.812)", children: [
    /* @__PURE__ */ jsx("path", { d: "M14.4,14.4V12.934A2.934,2.934,0,0,0,11.469,10H5.6a2.934,2.934,0,0,0-2.934,2.934V14.4", transform: "translate(-2.667 -1.197)", fill: "none", stroke: "#461e56", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5 }),
    /* @__PURE__ */ jsx("path", { d: "M11.2,4.934A2.934,2.934,0,1,1,8.268,2,2.934,2.934,0,0,1,11.2,4.934Z", transform: "translate(-2.399 -2)", fill: "none", stroke: "#461e56", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5 })
  ] })
] }) }) }) });
const ForwardRef = forwardRef(SvgUserCircle);
export default ForwardRef;
