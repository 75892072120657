import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import type { EditAlertActionPickerFutureItem } from '../EditAlertActionPicker/EditAlertActionPicker.types';

import {
  IconPlaceholder,
  IconWrapper,
} from '../EditAlertActionPicker/EditAlertActionPicker.styled';

import * as S from './EditAlertModalSelectComingSoon.styled';

interface EditAlertModalSelectComingSoonProps {
  items: EditAlertActionPickerFutureItem[];
}

const EditAlertModalSelectComingSoon = ({ items }: EditAlertModalSelectComingSoonProps) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Title>
        <IconPlaceholder />
        {t('editAlertModal.then.comingSoon.label', 'Coming soon')}:{' '}
      </S.Title>
      {items.map(({ label, icon }) => (
        <S.DropdownItem key={label} disabled>
          <IconPlaceholder />
          <IconWrapper disabled>
            <Icon name={icon} />
          </IconWrapper>{' '}
          {label}
        </S.DropdownItem>
      ))}
    </S.Container>
  );
};

export default EditAlertModalSelectComingSoon;
