import { schema } from 'normalizr';
import { prop } from 'ramda';

export const subscriptionSchema = new schema.Entity(
  'subscriptions',
  {},
  {
    idAttribute: prop('plan'),
  },
);

export const subscriptionsListSchema = new schema.Array(subscriptionSchema);

export const organizationEntitySchema = new schema.Entity('organizations', {});

export const organizationListSchema = new schema.Array(organizationEntitySchema);
