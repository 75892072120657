import type { ProjectFeatureFlags } from '@bugbug/core/types/projects';

export const isFeatureFlagSetToFalse = (
  featureFlags: ProjectFeatureFlags,
  featureFlag?: string,
): boolean => {
  if (!featureFlag) {
    return false;
  }

  return !featureFlags[featureFlag];
};
