/* eslint-disable react/jsx-props-no-spreading */

import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormField from '~/components/FormField';
import TestsPicker from '~/components/TestsPicker';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { SELECT_STATE } from '~/modules/constans';
import { selectIsFreePlan } from '~/modules/organization';
import { selectParallelTestsLimit } from '~/modules/organization/organization.selectors';
import { selectProfilesList } from '~/modules/profile/profile.selectors';

import { FIELDS_NAMES } from './SuiteContent.constants';
import { Container, Column, Link } from './SuiteContent.styled';

const SuiteDetails = ({ availableTests, searchValue }) => {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta } = useFormikContext();
  const parallelTestsLimit = useSelector(selectParallelTestsLimit);
  const profiles = useSelector(selectProfilesList);
  const isFreePlan = useSelector(selectIsFreePlan);

  const modal = useModal();
  const { push } = useAppRoutes('suite');

  const handleOnUpgrade = useCallback(() => {
    modal.hide();
    push('subscription');
  }, [modal, push]);

  const handleManageProfiles = useCallback(() => {
    modal.hide();
    push('profiles');
  }, [modal, push]);

  const ProfileDescription = (
    <Trans i18nKey="suiteDetails.profile.description">
      Which profile do you want to use when running this suite from a schedule or API?{' '}
      <Link type="button" onClick={handleManageProfiles}>
        Manage profiles
      </Link>
    </Trans>
  );

  return (
    <Container data-testid="SuiteDetails">
      <Column scrollable>
        <FormField label={t('suiteDetails.nameInputLabel', 'Suite name')}>
          <Input
            {...getFieldProps(FIELDS_NAMES.NAME)}
            {...getFieldMeta(FIELDS_NAMES.NAME)}
            fullWidth
          />
        </FormField>
        <FormField
          label={t('suiteDetails.autoAddNewTestsInputLabel', 'Auto add new tests')}
          description={t(
            'suiteDetails.autoAddNewTestsDescription',
            'Automatically add all new tests to this suite, except tests that you excluded manually',
          )}
        >
          <Select
            {...getFieldProps(FIELDS_NAMES.AUTO_ADD_NEW_TESTS)}
            {...getFieldMeta(FIELDS_NAMES.AUTO_ADD_NEW_TESTS)}
            fullWidth
          >
            <SelectOption value={SELECT_STATE.ENABLED}>{t('default.select.enabled')}</SelectOption>
            <SelectOption value={SELECT_STATE.DISABLED}>
              {t('default.select.disabled')}
            </SelectOption>
          </Select>
        </FormField>
        <FormField
          label={t('suiteDetails.autoRetryLabel', 'Auto-retry failed cloud tests')}
          description={
            <>
              {t(
                'suiteDetails.autoRetryDescription',
                'Prevent random "failed" notifications automatically retrying flaky tests.',
                { parallelTestsLimit },
              )}{' '}
              {isFreePlan ? (
                <Link onClick={handleOnUpgrade}>
                  {t('suiteDetails.autoRetryUpgradePlan', 'Upgrade')}
                </Link>
              ) : (
                <Link to={t('default.docs.autoRetry')}>
                  {t('suiteDetails.autoRetryLearnMore', 'Learn more')}
                </Link>
              )}
            </>
          }
        >
          <Select
            {...getFieldProps(FIELDS_NAMES.AUTO_RETRY)}
            {...getFieldMeta(FIELDS_NAMES.AUTO_RETRY)}
            fullWidth
            disabled={isFreePlan}
          >
            <SelectOption value={0}>{t('default.select.disabled')}</SelectOption>
            <SelectOption value={1}>{t('suiteDetails.autoRetry.oneTime', '1 time')}</SelectOption>
            <SelectOption value={2}>{t('suiteDetails.autoRetry.twoTimes', '2 times')}</SelectOption>
            <SelectOption value={3}>
              {t('suiteDetails.autoRetry.threeTimes', '3 times')}
            </SelectOption>
            <SelectOption value={4}>
              {t('suiteDetails.autoRetry.fourTimes', '4 times')}
            </SelectOption>
            <SelectOption value={5}>
              {t('suiteDetails.autoRetry.fiveTimes', '5 times')}
            </SelectOption>
          </Select>
        </FormField>
        <FormField
          label={t('suiteDetails.parallelCloudRunsLabel', 'Parallel cloud runs')}
          description={
            <>
              {parallelTestsLimit > 1
                ? t(
                    'suiteDetails.parallelClodRunsDescription',
                    'When running tests in the cloud, they will run asynchronously. You can run {{parallelTestsLimit}} tests in parallel at the same time. ',
                    { parallelTestsLimit },
                  )
                : t(
                    'suiteDetails.parallelClodRunsUpgrade',
                    'You can significantly speed up cloud testing by running up to 32 tests simultaneously. ',
                  )}
              <Link onClick={handleOnUpgrade}>
                {t('suiteDetails.parallelClodRunsUpgradePlan', 'Upgrade')}
              </Link>
            </>
          }
        >
          <Select
            {...getFieldProps(FIELDS_NAMES.RUN_IN_PARALLEL)}
            {...getFieldMeta(FIELDS_NAMES.RUN_IN_PARALLEL)}
            fullWidth
            disabled={parallelTestsLimit <= 1}
          >
            <SelectOption value={SELECT_STATE.ENABLED}>{t('default.select.enabled')}</SelectOption>
            <SelectOption value={SELECT_STATE.DISABLED}>
              {t('default.select.disabled')}
            </SelectOption>
          </Select>
        </FormField>
        <FormField
          label={t('suiteDetails.profile.label', 'Profile')}
          description={ProfileDescription}
        >
          <Select {...getFieldProps(FIELDS_NAMES.PROFILE)} {...getFieldMeta(FIELDS_NAMES.PROFILE)}>
            {profiles.map((profile) => (
              <SelectOption key={profile.id} value={profile.id}>
                {profile.name}
              </SelectOption>
            ))}
          </Select>
        </FormField>
      </Column>
      <Column>
        <TestsPicker
          {...getFieldProps(FIELDS_NAMES.TESTS)}
          availableTests={availableTests}
          searchValue={searchValue}
        />
      </Column>
    </Container>
  );
};

SuiteDetails.defaultProps = {
  className: null,
  searchValue: '',
};

SuiteDetails.propTypes = {
  searchValue: PropTypes.string,
  className: PropTypes.string,
  availableTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SuiteDetails;
/* eslint-enable react/jsx-props-no-spreading */
