import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgClearInput = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-input-clear" }),
  /* @__PURE__ */ jsx("g", { id: "f28aac72-c425-48a2-85ac-0690a6467a56", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "bed980b4-a839-4814-b5dc-23a8580e84e5", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("path", { d: "M9.73,6.94a2.15,2.15,0,0,1,2.16,1.29,2.15,2.15,0,0,1,2.16-1.29", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M14.05,25.06a2.15,2.15,0,0,1-2.16-1.29,2.15,2.15,0,0,1-2.16,1.29", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("polyline", { points: "8.3 22.04 3.98 22.04 3.98 9.96 8.3 9.96", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 15.48, y1: 9.96, x2: 28.02, y2: 9.96, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 28.02, y1: 22.04, x2: 15.48, y2: 22.04, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 28.02, y1: 9.96, x2: 28.02, y2: 22.04, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 11.89, y1: 8.23, x2: 11.89, y2: 23.77, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 24.1, y1: 13.33, x2: 18.67, y2: 18.77, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 18.67, y1: 13.33, x2: 24.1, y2: 18.77, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgClearInput);
export default ForwardRef;
