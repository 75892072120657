import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgJs = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-js" }),
  /* @__PURE__ */ jsx("g", { id: "b995880f-63ec-47ad-9b06-7039d08ed8ff", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "b416e287-eca0-47fe-92f5-020f9b51d9ac", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("path", { d: "M11.5,9.42h2.4V18c0,3.62-1.76,4.83-4.36,4.83a6.64,6.64,0,0,1-2-.31l.3-1.94a4.67,4.67,0,0,0,1.48.24c1.36,0,2.2-.62,2.2-2.9Z" }),
      /* @__PURE__ */ jsx("path", { d: "M16.47,20a6.63,6.63,0,0,0,3.18.84c1.53,0,2.39-.72,2.39-1.8s-.67-1.58-2.33-2.18C17.54,16,16.15,14.88,16.15,13c0-2.15,1.8-3.77,4.65-3.77A6.91,6.91,0,0,1,24,9.87l-.57,1.94a5.68,5.68,0,0,0-2.63-.63c-1.52,0-2.17.81-2.17,1.59,0,1,.76,1.48,2.52,2.15,2.28.87,3.38,2,3.38,3.9,0,2.12-1.6,3.95-5,3.95a7.64,7.64,0,0,1-3.55-.83Z" })
    ] })
  ] }) })
] });
const ForwardRef = forwardRef(SvgJs);
export default ForwardRef;
