import * as T from '@bugbug/core/utils/toolbox';
import memoize from 'lodash.memoize';
import { createSelector } from 'reselect';

import type { NativeIntegration } from '@bugbug/core/types/integrations';
import type { Project } from '@bugbug/core/types/projects';

import { integrationsApi } from './integrations.api';

export const selectActiveIntegrationsInProject = memoize((projectId: Project['id']) =>
  createSelector(integrationsApi.endpoints.getActiveIntegrations.select(projectId), (result) => {
    const activeIntegrations = result?.data;
    if (activeIntegrations) {
      return T.keyBy('type', activeIntegrations);
    }
    return {};
  }),
);

export const selectIsIntegrationsActiveInProject = (
  projectId: Project['id'],
  integrationType: NativeIntegration,
) =>
  createSelector(
    selectActiveIntegrationsInProject(projectId),
    (activeIntegrations) => !!activeIntegrations[integrationType],
  );

export const selectIntegrationError = (
  projectId: Project['id'],
  integrationType: NativeIntegration,
) =>
  createSelector(
    selectActiveIntegrationsInProject(projectId),
    T.path([integrationType, 'errorCode']),
  );
