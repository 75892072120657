export const SOCIAL_AUTH_STATE = 'socialAuthState';

export const SOCIAL_AUTH_RETURN_URL = 'socialAuthReturnUrl';

export const AUTH_PROVIDER = {
  GITHUB: 'github',
  GOOGLE: 'google',
};

export const BROADCAST_EVENTS = {
  LOGOUT: 'logout',
};
