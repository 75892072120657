import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  border-radius: 5px;

  ${styleWhenTrue(
    prop('error'),
    css`
      border: 2px solid ${COLOR.ERROR};
    `,
  )}
`;

export const Dot = styled.button.attrs(() => ({ type: 'button' }))`
  outline: none;
  padding: 0;
  margin: 0 4px;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${COLOR.BORDER_GRAY};
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${styleWhenTrue(
    prop('selected'),
    css`
      color: ${COLOR.WHITE};
      background-color: ${COLOR.PRIMARY_LIGHT_ACTIVE};
    `,
  )}
`;
