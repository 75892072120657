import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';

const DeleteSuiteRunsModal = ({ className, suiteRunsIds, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = () => {
    onDelete();
    modal.hide();
  };

  const removeRequestState = useActionState(SuiteRunActions.removeRequest, {
    onSuccess: handleSuccess,
  });

  const handleDeleteConfirm = useCallback(() => {
    dispatch(SuiteRunActions.removeRequest(suiteRunsIds));
  }, [dispatch, suiteRunsIds]);

  const title = suiteRunsIds
    ? t('deleteSuiteRunsModal.selected.title', {
        defaultValue_one: 'Do you want to remove this suite run?',
        defaultValue_other: 'Do you want to remove these suite runs?',
        count: suiteRunsIds.length,
      })
    : t('deleteSuiteRunsModal.all.title', 'Do you want to remove all suite runs?');

  return (
    <ConfirmModal
      className={className}
      title={title}
      pending={removeRequestState.isLoading}
      onConfirm={handleDeleteConfirm}
      hasInternalServerError={removeRequestState.hasInternalServerError}
      onCancel={modal.hide}
    />
  );
};

DeleteSuiteRunsModal.defaultProps = {
  className: null,
  suiteRunsIds: null,
  onDelete: Function.prototype,
};

DeleteSuiteRunsModal.propTypes = {
  className: PropTypes.string,
  suiteRunsIds: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
};

export default DeleteSuiteRunsModal;
