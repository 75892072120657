import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { selectSingleProjectId } from '~/modules/project/project.selectors';
import api from '~/services/api';
import toasts from '~/services/toasts';
import i18n from '~/translations';

import { VariableTypes, VariableActions } from './variable.redux';

export function* getListRequest({ query }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    const { data: variables } = yield call(api.variables.getList, { query, project_id: projectId });

    yield put(VariableActions.getListSuccess(variables));
  } catch (error) {
    yield put(VariableActions.getListFailure(error));
  }
}

export function* createRequest({ variable }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    const { data: createdVariables } = yield call(api.variables.create, {
      ...variable,
      project_id: projectId,
    });

    yield put(VariableActions.createSuccess(createdVariables));

    yield call(toasts.showSuccess, {
      content: i18n.t('variable.createSuccessInfo', 'Variable has been created successfully.'),
    });
  } catch (error) {
    yield put(VariableActions.createFailure(error));
  }
}

export function* updateRequest({ id, variable }) {
  try {
    const { data: updatedVariables } = yield call(api.variables.update, id, variable);
    yield put(VariableActions.updateSuccess(updatedVariables));
    yield call(toasts.showSuccess, {
      content: i18n.t('variable.updateSuccessInfo', 'Variable has been updated successfully.'),
    });
  } catch (error) {
    yield put(VariableActions.updateFailure(error));
  }
}

export function* deleteRequest({ id }) {
  try {
    yield call(api.variables.remove, id);
    yield put(VariableActions.deleteSuccess(id));
    yield call(toasts.showSuccess, {
      content: i18n.t('variable.deleteSuccessInfo', 'Variable has been deleted successfully.'),
    });
  } catch (error) {
    yield put(VariableActions.deleteFailure(error));
  }
}

export default function* variableSagas() {
  yield all([
    yield takeLatest(VariableTypes.GET_LIST_REQUEST, getListRequest),
    yield takeLatest(VariableTypes.CREATE_REQUEST, createRequest),
    yield takeLatest(VariableTypes.UPDATE_REQUEST, updateRequest),
    yield takeLatest(VariableTypes.DELETE_REQUEST, deleteRequest),
  ]);
}
