import type { NativeIntegration, ThirdPartyIntegration } from '@bugbug/core/types/integrations';
import type { IntegrationFeatureFlag } from '@bugbug/core/types/projects';
import config from '~/modules/config';
import i18n from '~/translations';

import Bitbucket from '~/images/platforms/bitbucket.svg?react';
import Github from '~/images/platforms/github.svg?react';
import Gitlab from '~/images/platforms/gitlab.svg?react';
import Jira from '~/images/platforms/jira.svg?react';
import Slack from '~/images/platforms/slack.svg?react';
import Teams from '~/images/platforms/teams.svg?react';
import Trello from '~/images/platforms/trello.svg?react';
import Zapier from '~/images/platforms/zapier.svg?react';

export const INTEGRATION_LOGO: Record<
  NativeIntegration | ThirdPartyIntegration,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  slack: Slack,
  teams: Teams,
  github: Github,
  gitlab: Gitlab,
  bitbucket: Bitbucket,
  zapier: Zapier,
  trello: Trello,
  jira: Jira,
};

interface NativeIntegrationItem {
  type: NativeIntegration;
  description: string;
  isNative: true;
  isPaid?: boolean;
  featureFlag?: IntegrationFeatureFlag;
  docsUrl?: undefined;
  width?: number;
  height?: number;
  externalConnectionUrl?: string;
}

interface ThirdPartyIntegrationItem {
  type: ThirdPartyIntegration;
  description: string;
  docsUrl: string;
  isNative?: false;
  isPaid?: boolean;
  width?: number;
  height?: number;
}

type IntegrationItem = NativeIntegrationItem | ThirdPartyIntegrationItem;

export const INTEGRATIONS_LIST: IntegrationItem[] = [
  {
    type: 'teams',
    description: i18n.t(
      'integrations.teams.description',
      'Instant notifications for seamless E2E test execution and issue management.',
    ),
    isNative: true,
    isPaid: true,
    featureFlag: 'teamsIntegration',
    externalConnectionUrl: config.TEAMS_APP_URL,
  },
  {
    type: 'slack',
    description: i18n.t(
      'integrations.slack.description',
      'Real-time updates for up-to-the-minute E2E testing and issue tracking.',
    ),
    isNative: true,
    isPaid: true,
    featureFlag: 'slackIntegration',
  },
  {
    type: 'github',
    description: i18n.t(
      'integrations.github.description',
      'Supercharge your E2E testing with GitHub Actions, ensuring flawless code quality and faster deployments.',
    ),
    isNative: true,
    isPaid: true,
    externalConnectionUrl: 'https://github.com/marketplace/actions/bugbug-cloud-runner',
  },
  {
    type: 'gitlab',
    docsUrl: i18n.t(
      'integrations.links.gitlab',
      'https://docs.bugbug.io/integrations/gitlab',
    ) as string,
    description: i18n.t(
      'integrations.gitlab.description',
      'Seamlessly incorporate E2E testing into your CI/CD pipeline with GitLab, guaranteeing robust and efficient code releases.',
    ),
  },
  {
    type: 'bitbucket',
    docsUrl: i18n.t(
      'integrations.links.bitbucket',
      'https://docs.bugbug.io/integrations/bitbucket',
    ) as string,
    description: i18n.t(
      'integrations.bitbucket.description',
      'Elevate your testing capabilities with Bitbucket Pipelines, providing automated E2E testing for code stability and collaboration.',
    ),
  },
  {
    type: 'zapier',
    height: 43,
    docsUrl: i18n.t(
      'integrations.links.zapier',
      'https://docs.bugbug.io/integrations/zapier',
    ) as string,
    description: i18n.t(
      'integrations.zapier.description',
      'Automate E2E testing workflows across apps, ensuring a smoother testing process and faster bug fixes.',
    ),
  },
  {
    type: 'trello',
    docsUrl: i18n.t(
      'integrations.links.trello',
      'https://docs.bugbug.io/integrations/trello',
    ) as string,
    description: i18n.t(
      'integrations.trello.description',
      'Instantly create Trello cards for failed test suites, streamlining issue tracking and resolution in your E2E testing workflow.',
    ),
  },
  {
    type: 'jira',
    docsUrl: i18n.t(
      'integrations.links.jira',
      'https://docs.bugbug.io/integrations/jira',
    ) as string,
    description: i18n.t(
      'integrations.jira.description',
      'Automatically generate Jira tickets upon test suite failures, ensuring swift issue management and seamless software quality control.',
    ),
  },
];
