import Loader from '@bugbug/core/components/Loader';

import type { ToastProps } from '../../Toast';

import Toast from '../../Toast';

const LoadingToast = ({ content }: Pick<ToastProps, 'content'>) => (
  <Toast Icon={<Loader />} content={content} />
);

export default LoadingToast;
