import LinkBase from '@bugbug/core/components/Link';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 35px;
`;

export const Column = styled.div`
  height: calc(100vh - 252px);

  ${styleWhenTrue(
    prop('scrollable'),
    css`
      overflow-y: auto;
    `,
  )}
`;

export const Link = styled(LinkBase)`
  color: ${COLOR.PRIMARY_LIGHT};
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;
