import styled from 'styled-components';

import { Content as ContentBase } from '~/components/modals/Modal';

export const Container = styled.div`
  max-width: 540px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 15px;

  svg {
    color: rgb(250, 205, 29);
    width: 136px;
    height: 74px;
    margin-left: -5px;
    margin-right: 20px;
  }

  p:last-child {
    margin: 0;
  }
`;
