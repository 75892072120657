import mixins from '@bugbug/core/theme/mixins';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import styled from 'styled-components';

export const IntegrationStatusBadge = styled.div<{ variant: 'success' | 'warning' }>`
  ${mixins.layout.flexRowCenter};
  padding: 5px 14px 5px 10px;
  border-radius: 20px;
  gap: 5px;

  svg {
    width: 20px;
  }

  ${styleWhenTrue(T.propEq('variant', 'success'), mixins.typography.successMessage)}
  ${styleWhenTrue(T.propEq('variant', 'warning'), mixins.typography.warningMessage)}
`;
