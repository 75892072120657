import { loadStripe } from '@stripe/stripe-js';
import { push } from 'connected-react-router';
import { omit, path } from 'ramda';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import config from '~/modules/config';
import { UserActions } from '~/modules/user/user.redux';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import api from '~/services/api';
import toasts from '~/services/toasts';
import { idValidator } from '~/utils/validators';
import urls, { reverse } from '~/views/urls';

import { OrganizationActions, OrganizationTypes } from './organization.redux';
import {
  selectCurrentOrganizationId,
  selectOrganizations,
  selectOrganizationsList,
  selectSubscription,
} from './organization.selectors';

export function* createRequest({ data }) {
  yield call(analytics.trackEvent, TRACK_EVENT_TYPE.CREATE_ORGANIZATION, {
    [TRACK_EVENT_ARG_TYPE.NEXT_ORGANIZATION_NAME]: data.name,
  });
  try {
    const { data: organization } = yield call(api.organizations.create, data);
    yield put(OrganizationActions.createSuccess(organization));
    yield put(OrganizationActions.switchOrganizationRequest(organization.id, true));
  } catch (error) {
    yield put(OrganizationActions.createFailure(error));
  }
}

export function* updateOrganization({ data }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    const preparedData = omit(['address'], data);

    if (data.address) {
      preparedData.addresses = [{ ...data.address, type: 'BILLING' }];
    }
    const { data: updatedData } = yield call(
      api.organizations.updateOrganization,
      organizationId,
      preparedData,
    );

    if (data.address) {
      /*
        Previously this extra dispatch wasn't necessary but it was causing a bug
        after introducing the new Upgrade banners.
        A backend started to return taxPercentage on subscriptions list.
        taxPercentage is related with organization's country, so we need to update
        the subscription list after updating the organization's address to have valid taxPercentage and prices.

        Related issue: DEV-3168
      */
      yield put(OrganizationActions.getSubscriptionsRequest());
    }

    yield put(OrganizationActions.updateOrganizationSuccess(updatedData));
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.organization.showOrganizationUpdateError);
    }
    yield put(OrganizationActions.updateOrganizationFailure(error));
  }
}

export function* updateOrganizationName({ name }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    yield call(api.organizations.updateOrganizationName, organizationId, { name });
    yield put(OrganizationActions.updateOrganizationNameSuccess(name));
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.organization.showOrganizationUpdateError);
    }
    yield put(OrganizationActions.updateOrganizationNameFailure(error));
  }
}

export function* switchOrganizationRequest({ currentOrganizationId, isNew = false }) {
  try {
    if (!idValidator.isValidSync(currentOrganizationId)) {
      return;
    }

    let orgId = currentOrganizationId;
    const organizations = yield select(selectOrganizations);

    if (!isNew && orgId && !organizations[orgId]) {
      const organizationsList = yield select(selectOrganizationsList);
      orgId = path([0, 'id'], organizationsList);
    }

    yield put(push(reverse(urls.projectsList, { organizationId: orgId })));
    yield put(OrganizationActions.switchOrganizationSuccess(orgId));
    yield put(UserActions.refreshAnalyticsGlobals());
  } catch (error) {
    yield put(OrganizationActions.switchOrganizationFailure(error));
  }
}

export function* getSubscriptions() {
  try {
    const { data } = yield call(api.organizations.getSubscriptions);
    yield put(OrganizationActions.getSubscriptionsSuccess(data.results));
  } catch (error) {
    yield put(OrganizationActions.getSubscriptionsFailure(error));
  }
}

export function* requestSubscriptionChange({ plan, paymentMethodId }) {
  const { data } = yield call(api.organizations.addSubscription, { plan, paymentMethodId });

  if (data?.clientSecret) {
    const stripe = yield call(loadStripe, config.STRIPE_KEY);
    // confirm 3d secure
    const { error } = yield call(stripe.confirmCardPayment, data.clientSecret, {
      payment_method: paymentMethodId,
    });
    if (error) {
      // eslint-disable-next-line no-throw-literal
      throw [error];
    }
  }

  return data;
}

export function* subscribe({ plan, paymentMethodId }) {
  try {
    const [data] = yield all([
      take([OrganizationTypes.SUBSCRIPTION_CHANGED_SUCCESS]),
      call(requestSubscriptionChange, { plan, paymentMethodId }),
    ]);

    const isScheduledChange = !data.subscription?.isTrial && !!data.subscriptionSchedule;
    if (isScheduledChange) {
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.COMPLETE_PAYMENT);
    }
    yield put(OrganizationActions.subscribeSuccess(data.subscription, data.subscriptionSchedule));
  } catch (error) {
    yield put(OrganizationActions.subscribeFailure(error));
  }
}

export function* cancelSubscription() {
  try {
    const subscription = yield select(selectSubscription);

    yield call(api.organizations.cancelSubscription);
    yield put(OrganizationActions.cancelSubscriptionSuccess());
    yield call(
      toasts.organization.showCancelSubscriptionSuccess,
      subscription.planType,
      subscription.periodType,
    );
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.organization.showOrganizationUpdateError);
    }
    yield put(OrganizationActions.cancelSubscriptionFailure(error));
  }
}

export function* getInvoices() {
  try {
    const { data } = yield call(api.organizations.getInvoices);
    yield put(OrganizationActions.getInvoicesSuccess(data));
  } catch (error) {
    yield put(OrganizationActions.getInvoicesFailure(error));
  }
}

export function* updateDefaultCard({ paymentMethodId }) {
  try {
    const { data } = yield call(api.organizations.updateDefaultCard, { paymentMethodId });
    yield put(OrganizationActions.updateDefaultCardSuccess(data));
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.organization.showOrganizationUpdateError);
    }
    yield put(OrganizationActions.updateDefaultCardFailure(error));
  }
}

export function* startTrialRequest() {
  try {
    const { data } = yield call(api.organizations.startTrial);
    yield put(OrganizationActions.startTrialSuccess(data));
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.organization.showStartTrialError);
    }
    yield put(OrganizationActions.startTrialFailure(error));
  }
}

export function* subscriptionChanged({ data }) {
  const { organizationId, subscription, subscriptionSchedule } = data;
  yield put(
    OrganizationActions.subscriptionChangedSuccess(
      organizationId,
      subscription,
      subscriptionSchedule,
    ),
  );
  yield put(UserActions.refreshAnalyticsGlobals());
}

export default function* userSagas() {
  yield all([
    yield takeLatest(OrganizationTypes.CREATE_REQUEST, createRequest),
    yield takeLatest(OrganizationTypes.UPDATE_ORGANIZATION_REQUEST, updateOrganization),
    yield takeLatest(OrganizationTypes.UPDATE_ORGANIZATION_NAME_REQUEST, updateOrganizationName),
    yield takeLatest(OrganizationTypes.SUBSCRIBE_REQUEST, subscribe),
    yield takeLatest(OrganizationTypes.GET_INVOICES_REQUEST, getInvoices),
    yield takeLatest(OrganizationTypes.UPDATE_DEFAULT_CARD_REQUEST, updateDefaultCard),
    yield takeLatest(OrganizationTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
    yield takeLatest(OrganizationTypes.GET_SUBSCRIPTIONS_REQUEST, getSubscriptions),
    yield takeLatest(OrganizationTypes.SWITCH_ORGANIZATION_REQUEST, switchOrganizationRequest),
    yield takeLatest(OrganizationTypes.START_TRIAL_REQUEST, startTrialRequest),
    yield takeLatest(OrganizationTypes.SUBSCRIPTION_CHANGED, subscriptionChanged),
  ]);
}
