import copy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SideEffect } from '../../types/utils';

import * as Icons from '../../theme/icons';

import * as S from './CopyButton.styled';

interface CopyButtonProps {
  className?: string;
  small?: boolean;
  value: string;
  onCopy?: SideEffect;
}

const CopyButton = (
  { className, value, small, onCopy }: CopyButtonProps = {
    className: '',
    small: false,
    value: '',
    onCopy: undefined,
  },
) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const messageTimeout = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleClick = useCallback(() => {
    copy(value);
    onCopy?.();
    setIsCopied(true);

    messageTimeout.current = setTimeout(() => setIsCopied(false), 2000);
  }, [value, onCopy]);

  useEffect(
    () => () => {
      if (messageTimeout.current) {
        clearTimeout(messageTimeout.current);
      }
    },
    [],
  );

  const Icon = isCopied ? Icons.CheckIcon : Icons.CopyIcon;

  if (small) {
    return (
      <S.IconButton
        className={className}
        data-testid="CopyButton"
        onClick={handleClick}
        disabled={isCopied}
      >
        <Icon />
      </S.IconButton>
    );
  }

  const label = isCopied
    ? t('copyButton.message', 'Copied!')
    : t('copyButton.label', 'Copy to clipboard');

  return (
    <S.Button
      className={className}
      data-testid="CopyButton"
      Icon={isCopied ? Icons.CheckIcon : Icons.CopyIcon}
      onClick={handleClick}
      disabled={isCopied}
    >
      {label}
    </S.Button>
  );
};

export default CopyButton;
