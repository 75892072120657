import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgScroll = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-scroll" }),
  /* @__PURE__ */ jsx("g", { id: "bc1722e3-f568-42ee-9c10-0aa7b51d1633", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "a3267049-fce1-4f8a-85f6-acca67c38f89", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("polyline", { points: "12.97 19.7 16 22.73 19.03 19.7", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("polyline", { points: "19.03 12.29 16 9.27 12.97 12.29", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { x: 9.39, y: 3.95, width: 13.21, height: 24.11, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgScroll);
export default ForwardRef;
