import { RUN_TRIGGER, RUN_ENV } from '~/modules/constans';
import i18n from '~/translations';

export const getTriggerLabel = (key, args) => {
  const labels = {
    [RUN_TRIGGER.USER]: i18n.t('runMetadataSection.run.trigger.user', 'User {{email}}', args),
    [RUN_TRIGGER.API]: i18n.t('runMetadataSection.run.trigger.api', 'API'),
    [RUN_TRIGGER.SCHEDULER]: i18n.t('runMetadataSection.run.trigger.schedule', 'Scheduler'),
    [RUN_TRIGGER.CLI]: i18n.t('runMetadataSection.run.trigger.cli', 'CLI'),
    [RUN_TRIGGER.GITHUB]: i18n.t('runMetadataSection.run.trigger.github', 'Github'),
  };
  return labels[key];
};

export const RUN_ENV_LABEL = {
  [RUN_ENV.LOCAL]: i18n.t('default.run.env.local'),
  [RUN_ENV.SERVER]: i18n.t('default.run.env.cloud'),
};
