import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  padding: 18px 27px 27px;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 7px 50px -3px rgba(47, 46, 55, 0.08), 0 1px 2px 0 rgba(30, 32, 59, 0.11);
  position: relative;
`;
