import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgActionVariablesNew = (props, ref) => /* @__PURE__ */ jsxs("svg", { viewBox: "0 0 32 32", height: "20px", width: "20px", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M7.43353 25.4879L7.76294 23.9571C7.43999 23.97 7.17517 23.886 6.96848 23.7052C6.76825 23.5308 6.61 23.2983 6.49374 23.0076C6.37748 22.717 6.29674 22.3908 6.25153 22.0291C6.21277 21.6738 6.1934 21.3218 6.1934 20.973V19.3938C6.1934 18.6252 6.00609 17.947 5.63146 17.3592C5.25684 16.7714 4.66907 16.3225 3.86815 16.0125C4.66261 15.7025 5.24715 15.2503 5.62177 14.6561C6.00286 14.0619 6.1934 13.3804 6.1934 12.6118V11.0326C6.1934 10.6838 6.19986 10.3318 6.21278 9.97654C6.23215 9.61483 6.29028 9.28865 6.38717 8.99799C6.48405 8.70734 6.63907 8.47481 6.85222 8.30042C7.06537 8.11957 7.36894 8.0356 7.76294 8.04852L7.43353 6.52742C6.807 6.52742 6.26768 6.64691 5.81555 6.8859C5.36341 7.12488 4.99848 7.43168 4.72074 7.80631C4.38487 8.23906 4.13943 8.74932 3.98441 9.33709C3.8294 9.9184 3.75189 10.4836 3.75189 11.0326V12.6118C3.75189 13.4838 3.55166 14.1071 3.1512 14.4817C2.75074 14.8563 2.14036 15.0436 1.32007 15.0436V16.9813C2.14036 16.9813 2.75074 17.1687 3.1512 17.5433C3.55166 17.9179 3.75189 18.5347 3.75189 19.3938V20.973C3.75189 21.4833 3.81971 22.0032 3.95535 22.5329C4.09745 23.069 4.32351 23.5599 4.63354 24.0055C4.9242 24.4318 5.30205 24.7838 5.7671 25.0616C6.23861 25.3458 6.79409 25.4879 7.43353 25.4879Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { d: "M13.3504 25.4879L13.6798 23.9571C13.3569 23.97 13.0921 23.886 12.8854 23.7052C12.6851 23.5308 12.5269 23.2983 12.4106 23.0076C12.2944 22.717 12.2136 22.3908 12.1684 22.0291C12.1297 21.6738 12.1103 21.3218 12.1103 20.973V19.3938C12.1103 18.6252 11.923 17.947 11.5484 17.3592C11.1737 16.7714 10.586 16.3225 9.78504 16.0125C10.5795 15.7025 11.164 15.2503 11.5387 14.6561C11.9197 14.0619 12.1103 13.3804 12.1103 12.6118V11.0326C12.1103 10.6838 12.1167 10.3318 12.1297 9.97654C12.149 9.61483 12.2072 9.28865 12.3041 8.99799C12.4009 8.70734 12.556 8.47481 12.7691 8.30042C12.9823 8.11957 13.2858 8.0356 13.6798 8.04852L13.3504 6.52742C12.7239 6.52742 12.1846 6.64691 11.7324 6.8859C11.2803 7.12488 10.9154 7.43168 10.6376 7.80631C10.3018 8.23906 10.0563 8.74932 9.9013 9.33709C9.74629 9.9184 9.66878 10.4836 9.66878 11.0326V12.6118C9.66878 13.4838 9.46855 14.1071 9.06809 14.4817C8.66763 14.8563 8.05725 15.0436 7.23696 15.0436V16.9813C8.05725 16.9813 8.66763 17.1687 9.06809 17.5433C9.46855 17.9179 9.66878 18.5347 9.66878 19.3938V20.973C9.66878 21.4833 9.7366 22.0032 9.87224 22.5329C10.0143 23.069 10.2404 23.5599 10.5504 24.0055C10.8411 24.4318 11.2189 24.7838 11.684 25.0616C12.1555 25.3458 12.711 25.4879 13.3504 25.4879Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { d: "M24.5665 25.4879L24.2371 23.9571C24.56 23.97 24.8248 23.886 25.0315 23.7052C25.2317 23.5308 25.39 23.2983 25.5063 23.0076C25.6225 22.717 25.7033 22.3908 25.7485 22.0291C25.7872 21.6738 25.8066 21.3218 25.8066 20.973V19.3938C25.8066 18.6252 25.9939 17.947 26.3685 17.3592C26.7432 16.7714 27.3309 16.3225 28.1318 16.0125C27.3374 15.7025 26.7528 15.2503 26.3782 14.6561C25.9971 14.0619 25.8066 13.3804 25.8066 12.6118V11.0326C25.8066 10.6838 25.8001 10.3318 25.7872 9.97654C25.7678 9.61483 25.7097 9.28865 25.6128 8.99799C25.5159 8.70734 25.3609 8.47481 25.1478 8.30042C24.9346 8.11957 24.6311 8.0356 24.2371 8.04852L24.5665 6.52742C25.193 6.52742 25.7323 6.64691 26.1845 6.8859C26.6366 7.12488 27.0015 7.43168 27.2793 7.80631C27.6151 8.23906 27.8606 8.74932 28.0156 9.33709C28.1706 9.9184 28.2481 10.4836 28.2481 11.0326V12.6118C28.2481 13.4838 28.4483 14.1071 28.8488 14.4817C29.2493 14.8563 29.8596 15.0436 30.6799 15.0436V16.9813C29.8596 16.9813 29.2493 17.1687 28.8488 17.5433C28.4483 17.9179 28.2481 18.5347 28.2481 19.3938V20.973C28.2481 21.4833 28.1803 22.0032 28.0447 22.5329C27.9026 23.069 27.6765 23.5599 27.3665 24.0055C27.0758 24.4318 26.6979 24.7838 26.2329 25.0616C25.7614 25.3458 25.2059 25.4879 24.5665 25.4879Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { d: "M18.6496 25.4879L18.3202 23.9571C18.6431 23.97 18.9079 23.886 19.1146 23.7052C19.3149 23.5308 19.4731 23.2983 19.5894 23.0076C19.7056 22.717 19.7864 22.3908 19.8316 22.0291C19.8703 21.6738 19.8897 21.3218 19.8897 20.973V19.3938C19.8897 18.6252 20.077 17.947 20.4516 17.3592C20.8263 16.7714 21.414 16.3225 22.215 16.0125C21.4205 15.7025 20.836 15.2503 20.4613 14.6561C20.0803 14.0619 19.8897 13.3804 19.8897 12.6118V11.0326C19.8897 10.6838 19.8833 10.3318 19.8703 9.97654C19.851 9.61483 19.7928 9.28865 19.6959 8.99799C19.5991 8.70734 19.444 8.47481 19.2309 8.30042C19.0177 8.11957 18.7142 8.0356 18.3202 8.04852L18.6496 6.52742C19.2761 6.52742 19.8154 6.64691 20.2676 6.8859C20.7197 7.12488 21.0846 7.43168 21.3624 7.80631C21.6982 8.23906 21.9437 8.74932 22.0987 9.33709C22.2537 9.9184 22.3312 10.4836 22.3312 11.0326V12.6118C22.3312 13.4838 22.5315 14.1071 22.9319 14.4817C23.3324 14.8563 23.9427 15.0436 24.763 15.0436V16.9813C23.9427 16.9813 23.3324 17.1687 22.9319 17.5433C22.5315 17.9179 22.3312 18.5347 22.3312 19.3938V20.973C22.3312 21.4833 22.2634 22.0032 22.1278 22.5329C21.9857 23.069 21.7596 23.5599 21.4496 24.0055C21.1589 24.4318 20.7811 24.7838 20.316 25.0616C19.8445 25.3458 19.289 25.4879 18.6496 25.4879Z", fill: "currentColor" })
] });
const ForwardRef = forwardRef(SvgActionVariablesNew);
export default ForwardRef;
