import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import * as Yup from 'yup';

import type { ScheduleFormValues } from './EditScheduleModal.types';

import type { Timezone } from '@bugbug/core/types/geography';
import { VALIDATION_MESSAGE, idValidator, nameValidator } from '~/utils/validators';

export const ScheduleSchema = Yup.object<ScheduleFormValues>().shape({
  id: idValidator.optional(),
  name: nameValidator
    .max(DATA_RESTRICTIONS.SCHEDULE_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH)
    .ensure(),
  suites: Yup.array().of(idValidator.required()).required().ensure(),
  minute: Yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  hour: Yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  dayOfMonth: Yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  dayOfWeek: Yup.string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .transform((value) => {
      if (!value) {
        return '*';
      }

      return value;
    }),
  enabled: Yup.boolean()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .default(false)
    .when('suites', {
      is: (suites) => !suites?.length,
      then: (schema) => schema.transform(() => false),
    }),
  runProfileId: Yup.string().required(),
  project: Yup.string().required(),
  timezone: Yup.string<Timezone>().required(),
});
