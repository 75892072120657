import * as T from '@bugbug/core/utils/toolbox';
import httpStatus, {
  BAD_REQUEST,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  NOT_FOUND,
  PAYMENT_REQUIRED,
} from 'http-status';

import type { SerializedError } from '@reduxjs/toolkit';
import type { extractErrorLabelsAndParams } from '~/utils/apiErrorHandler';

export type ApiResponseStatus = number;

export interface RtkErrorResponse {
  status?: ApiResponseStatus;
  data?: ReturnType<typeof extractErrorLabelsAndParams>;
}

export const isApiError = (error?: unknown): error is RtkErrorResponse => T.has('status', error);

const isErrorWithData = (error?: unknown): error is RtkErrorResponse => T.has('data', error);

type StatusFlagCheck = (error?: unknown) => boolean;

export const isInternalServerError: StatusFlagCheck = (error) =>
  isApiError(error) && getStatusNumber(error.status) >= INTERNAL_SERVER_ERROR;

export const isBadRequestError: StatusFlagCheck = (error) =>
  isApiError(error) && getStatusNumber(error.status) >= BAD_REQUEST;

export const isForbiddenError: StatusFlagCheck = (error) =>
  isApiError(error) && getStatusNumber(error.status) === FORBIDDEN;

export const isNotFoundError: StatusFlagCheck = (error) =>
  isApiError(error) && getStatusNumber(error.status) === NOT_FOUND;

export const isPaymentRequiredError: StatusFlagCheck = (error) =>
  isApiError(error) && getStatusNumber(error.status) === PAYMENT_REQUIRED;

export const getExtractedErrors = (error?: unknown) => (isErrorWithData(error) ? error.data : {});

export const getExtractedErrorMessages = (error?: unknown) => {
  const errors = getExtractedErrors(error);
  return T.mapValues(({ message }) => message, errors);
};

export const getExtractedErrorParams = (error?: unknown) => {
  const errors = getExtractedErrors(error);
  return T.mapValues(({ params }) => params, errors);
};

const getStatusNumber = (status?: ApiResponseStatus) => Number(status ?? 0);

export const getStatusMessage = (status?: ApiResponseStatus) => {
  if (!status) {
    return 'Unknown error';
  }

  return [status, httpStatus[status]].filter(Boolean).join(' ');
};

export const getErrorMessage = (error: RtkErrorResponse | SerializedError) => {
  if (!isApiError(error)) {
    return error.message ?? 'Unexpected error';
  }

  return getStatusMessage(error.status);
};
