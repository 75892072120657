import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useMount, useUnmount } from 'react-use';

import { SCROLL_VISIBILITY_BOUNDRY } from './ScrollShadow.constants';
import { Container } from './ScrollShadow.styled';

const eventParams = { passive: true };

const ScrollShadow = ({ className }) => {
  const shadowRef = useRef();
  const [visible, setVisible] = useState(false);

  const handleVisibility = useCallback((event) => {
    setVisible(event.target.scrollTop > SCROLL_VISIBILITY_BOUNDRY);
  }, []);

  useMount(() => {
    shadowRef.current.parentNode.addEventListener('scroll', handleVisibility, eventParams);
  });

  useUnmount(() => {
    if (shadowRef.current) {
      shadowRef.current.parentNode.removeEventListener('scroll', handleVisibility, eventParams);
    }
  });

  return <Container ref={shadowRef} className={className} visible={visible} />;
};

ScrollShadow.defaultProps = {
  className: null,
};

ScrollShadow.propTypes = {
  className: PropTypes.string,
};

export default ScrollShadow;
