import { createApi } from '@reduxjs/toolkit/query/react';

import type { Component } from '@bugbug/core/types/components';
import type { Test } from '@bugbug/core/types/tests';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Component' as const;
export const componentsApi = createApi({
  reducerPath: 'componentsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/components/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // POST /components/<id>/clone
    cloneComponent: builder.mutation<Component, Component['id']>({
      query: (id) => ({
        url: `${id}/clone/`,
        method: 'POST',
      }),

      invalidatesTags: [TAG],
    }),

    // DELETE /components/<id>/
    deleteComponent: builder.mutation<Component, Component['id']>({
      query: (id) => ({
        url: `${id}/`,
        method: 'DELETE',
      }),

      invalidatesTags: [TAG],
    }),

    // GET /components/<id>/
    getComponent: builder.query<Component, Component['id']>({
      query: (id) => ({
        url: `${id}/`,
        method: 'GET',
      }),

      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id }, TAG] : [{ type: TAG, id: 'ELEMENT' }],
    }),

    // GET /components/
    getComponents: builder.query<Component[], string>({
      query: (projectId) => ({
        method: 'get',
        params: { projectId },
        url: '',
      }),

      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id })), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),

    // GET /components/<id>/related-tests/
    getRelatedTests: builder.query<Test[], Component['id']>({
      query: (id) => ({
        url: `${id}/related-tests/`,
        method: 'GET',
      }),
    }),

    // POST /components/<id>/rename
    renameComponent: builder.mutation<Component, Pick<Component, 'id' | 'name'>>({
      query: ({ id, name }) => ({
        url: `${id}/rename/`,
        method: 'POST',
        data: { name },
      }),

      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useCloneComponentMutation,
  useDeleteComponentMutation,
  useGetComponentQuery,
  useGetComponentsQuery,
  useGetRelatedTestsQuery,
  useRenameComponentMutation,
} = componentsApi;
