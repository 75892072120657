import { COLOR, CloseIcon as CloseIconComponent } from '@bugbug/core/theme';
import { ToastContainer as ToastContainerComponent, toast, cssTransition } from 'react-toastify';
import styled, { keyframes } from 'styled-components';

const FadedSlide = cssTransition({
  enter: 'fadedSlideIn',
  exit: 'fadedSlideOut',
});

const fadedSlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadedSlideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const Container = styled.div`
  padding: 12px 60px 12px 46px;
  flex: 1;
`;

export const ToastContainer = styled(ToastContainerComponent).attrs(() => ({
  pauseOnHover: true,
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
  transition: FadedSlide,
  limit: 5,
}))`
  bottom: 14px !important;
  left: 23px !important;

  .Toastify__toast {
    background-color: #333;
    color: ${COLOR.WHITE};
    box-shadow: 0 10px 50px -4px rgba(51, 51, 51, 0.41);
    min-height: 44px;
    border-radius: 6px;
    width: 260px;
    font-size: 14px;
    position: relative;
    margin-bottom: 10px;
    padding: 0;
    transition: background-color 200ms ease-in-out;
  }

  .Toastify__toast.fadedSlideIn {
    animation-name: ${fadedSlideIn};
  }
  .Toastify__toast.fadedSlideOut {
    animation-name: ${fadedSlideOut};
  }

  .Toastify__toast-body {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast--success {
    background-color: ${COLOR.SUCCESS_2};
  }

  .Toastify__toast--error {
    background-color: ${COLOR.ERROR};
  }
`;

export const Content = styled.div`
  flex: none;
  margin-top: -2px;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 11px;
  top: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CloseIcon = styled(CloseIconComponent)`
  g {
    color: ${COLOR.WHITE};
  }
`;

export const CloseButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: absolute;
  right: 5px;
  top: 3px;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
`;
