import { useSelector } from 'react-redux';

import type { Project, ProjectFeatureFlags } from '@bugbug/core/types/projects';
import { selectSingleProject } from '~/modules/project/project.selectors';

const useFeatureFlags = (): ProjectFeatureFlags => {
  const project = useSelector(selectSingleProject) as Project;

  return project.featureFlags;
};

export default useFeatureFlags;
