import api from './common';

export const getUser = () => api.get('/auth/user/');

export const updateUserData = (data) => api.patch('/auth/user/', data);

export const getUserFlags = () => api.get('/auth/user/flags/');

export const updateOnboardingData = (data) => api.patch('/auth/user/onboarding/', data);

export const login = (data) => api.post('/auth/login/', data);

export const logout = () => api.post('/auth/logout/');

export const register = (data) => api.post('/auth/registration/', data);

export const confirmEmail = (data) => api.post('/auth/registration/verify-email/', data);

export const resendActivationEmail = (email) =>
  api.post('/auth/registration/resend-confirmation/', { email });

export const resetPassword = (data) => api.post('/auth/password/reset/', data);

export const confirmPasswordReset = (data) => api.post('/auth/password/reset/confirm/', data);

export const changePassword = (data) => api.post('/auth/password/change/', data);

export const getSocialAuthUrl = (provider) => api.post(`/auth/social/${provider}/auth-server/`);

export const getSocialAccessToken = (provider, data) =>
  api.post(`/auth/social/${provider}/login/`, data);

export const getRegistrationData = (params) => api.get('/auth/registration/get-data/', { params });
