import * as T from '@bugbug/core/utils/toolbox';
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';

import type { Grouping } from '@bugbug/core/types/groups';
import type { Test } from '@bugbug/core/types/tests';

const selectDomain = (state: RootState) => state.uiState;

export const selectUIStateForComponent = T.memoize((componentName: string) =>
  createSelector(selectDomain, T.pathOr({}, ['components', componentName])),
);

export const selectIntegrationTrigger = createSelector(selectDomain, T.prop('integrationTrigger'));

export const selectCollapsedGroups = T.memoize((testId: Test['id']) =>
  createSelector(selectDomain, T.pathOr([], ['collapsedGroups', testId])),
);

export const selectIsGroupCollapsed = T.memoize(
  (groupId: Grouping['id'], testId: Test['id']) =>
    createSelector(selectCollapsedGroups(testId), (groupsIds: Grouping['id'][]) =>
      groupsIds.includes(groupId),
    ),
  T.joinAllArgs,
);

export const selectRecentlySelectedTabs = createSelector(
  selectDomain,
  (s) => s.recentlySelectedTabs,
);

export const selectProjectSettingsTab = createSelector(
  selectRecentlySelectedTabs,
  (s) => s.projectSettings,
);

export const selectRunsHistoryTab = createSelector(
  selectRecentlySelectedTabs,
  (s) => s.runsHistory,
);

export const selectVariablesTab = createSelector(selectRecentlySelectedTabs, (s) => s.variables);
