import { SELECT_ALL_OPTION_VALUE } from '@bugbug/core/components/Select';

import type { EditAlertActionPickerItem } from './EditAlertActionPicker';
import type { EditAlertActionPickerFutureItem } from './EditAlertActionPicker/EditAlertActionPicker.types';
import type { AlertActionFormValues, EditAlertFormValues } from './EditAlertFormValues.types';

import type { SelectOptionItem } from '@bugbug/core/components/Select/Select.types';
import type {
  AlertActionType,
  AlertActionWebhookConfig,
  AlertEvent,
} from '@bugbug/core/types/alerts';
import type { RunStatus } from '@bugbug/core/types/base';
import { RUN_ENV_LABEL } from '~/components/RunMetadataSection/RunMetadataSection.constants';
import { ACTIONS_DATA } from '~/constants/alerts';
import { RUN_TRIGGER } from '~/modules/constans';
import i18n from '~/translations';

export const RUN_BY_OPTIONS: SelectOptionItem[] = [
  {
    label: i18n.t('default.run.trigger.user', {
      name: i18n.t('default.run.trigger.emptyUser', 'a user'),
    }),
    value: RUN_TRIGGER.USER,
  },
  { value: RUN_TRIGGER.API, label: i18n.t('default.run.trigger.api') },
  { value: RUN_TRIGGER.SCHEDULER, label: i18n.t('default.run.trigger.scheduler') },
];
export const METHODS_OPTIONS: SelectOptionItem[] = Object.entries(RUN_ENV_LABEL).map(
  ([value, label]) => ({ value, label }),
);

export const STATUS_OPTIONS: SelectOptionItem<RunStatus, string>[] = [
  {
    value: 'passed',
    label: i18n.t('default.run.status.passed'),
  },
  {
    value: 'failed',
    label: i18n.t('default.run.status.failed'),
  },
  {
    value: 'error',
    label: i18n.t('default.run.status.internalError', 'BugBug internal error'),
  },
];

export const ALERT_EVENTS: EditAlertActionPickerItem<AlertEvent>[] = [
  {
    value: 'testStarted',
    label: i18n.t('editAlertModal.edit.when.testStarted', 'Test started'),
  },
  {
    value: 'testFinished',
    label: i18n.t('editAlertModal.edit.when.testFinished', 'Test finished'),
  },
  {
    value: 'suiteStarted',
    label: i18n.t('editAlertModal.edit.when.suiteStarted', 'Suite started'),
  },
  {
    value: 'suiteFinished',
    label: i18n.t('editAlertModal.edit.when.suiteFinished', 'Suite finished'),
  },
  {
    value: 'scheduleStarted',
    label: i18n.t('editAlertModal.edit.when.scheduledStarted', 'Schedule started'),
  },
  {
    value: 'scheduleFinished',
    label: i18n.t('editAlertModal.edit.runWhen.scheduleFinished', 'Schedule finished'),
  },
];

export const ALERT_ACTIONS: EditAlertActionPickerItem<AlertActionType>[] = [
  {
    value: 'sendEmail',
    ...ACTIONS_DATA.sendEmail,
  },
  {
    value: 'sendWebhook',
    ...ACTIONS_DATA.sendWebhook,
  },
  {
    value: 'sendSlackMessage',
    ...ACTIONS_DATA.sendSlackMessage,
    featureFlag: 'slackIntegration',
  },
  {
    value: 'sendTeamsMessage',
    ...ACTIONS_DATA.sendTeamsMessage,
    featureFlag: 'teamsIntegration',
  },
];

export const FUTURE_ALERT_ACTIONS: EditAlertActionPickerFutureItem[] = [
  {
    label: i18n.t('editAlertModal.then.comingSoon.options.jira', 'Create JIRA ticket'),
    icon: 'jira',
    value: '',
  },
  {
    label: i18n.t('editAlertModal.then.comingSoon.options.discord', 'Discord message'),
    icon: 'discord',
    value: '',
  },
];

export const REQUEST_METHODS: AlertActionWebhookConfig['method'][] = ['POST', 'GET'];

export const REQUEST_METHODS_OPTIONS = REQUEST_METHODS.map((value) => ({ value, label: value }));

export const FORM_ACTION_INITIAL_VALUE: Record<AlertActionType, AlertActionFormValues> = {
  sendEmail: {
    action: 'sendEmail',
    users: [SELECT_ALL_OPTION_VALUE],
    recipientType: 'all',
    extraEmails: '',
  },
  sendWebhook: {
    action: 'sendWebhook',
    method: 'POST',
    url: '',
    body: '',
  },
  sendSlackMessage: {
    action: 'sendSlackMessage',
    method: 'channelMessage',
    name: '',
    channelId: '',
  },
  sendTeamsMessage: {
    action: 'sendTeamsMessage',
    channelId: '',
    name: '',
    teamName: '',
  },
};

export const FORM_INITIAL_VALUE: EditAlertFormValues = {
  projectId: '',
  isActive: true,
  event: 'suiteFinished',
  objects: [SELECT_ALL_OPTION_VALUE],
  runMode: [SELECT_ALL_OPTION_VALUE],
  triggeredBy: [SELECT_ALL_OPTION_VALUE],
  status: ['passed', 'failed'],
  runProfiles: [SELECT_ALL_OPTION_VALUE],
  ...FORM_ACTION_INITIAL_VALUE.sendEmail,
};
