import produce from 'immer';
import { normalize } from 'normalizr';
import { omit, without } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { variableListSchema } from './variable.schema';

export const { Types: VariableTypes, Creators: VariableActions } = createActions(
  {
    getListRequest: ['query'],
    getListSuccess: ['variables'],
    getListFailure: ['error'],
    createRequest: ['variable'],
    createSuccess: ['variables'],
    createFailure: ['error'],
    updateRequest: ['id', 'variable'],
    updateSuccess: ['variables'],
    updateFailure: ['error'],
    deleteRequest: ['id'],
    deleteSuccess: ['id'],
    deleteFailure: ['error'],
    reset: null,
  },
  { prefix: 'VARIABLE/' },
);

export const INITIAL_STATE = {
  variables: {},
  order: [],
};

export const getListSuccess = (state, { variables }) =>
  produce(state, (draft) => {
    const data = normalize(variables, variableListSchema);
    draft.variables = data.entities.variables;
    draft.order = data.result;
  });

export const createSuccess = (state, { variables = [] }) =>
  produce(state, (draft) => {
    if (variables.length) {
      const variable = variables[0];
      draft.variables[variable.id] = variable;
      draft.order.unshift(variable.id);
    }
  });

export const updateSuccess = (state, { variables = [] }) =>
  produce(state, (draft) => {
    if (variables.length) {
      const variable = variables[0];
      draft.variables[variable.id] = variable;
    }
  });

export const deleteSuccess = (state, { id }) =>
  produce(state, (draft) => {
    draft.variables = omit([id], draft.variables);
    draft.order = without([id], draft.order);
  });

export const reducer = createReducer(INITIAL_STATE, {
  [VariableTypes.GET_LIST_SUCCESS]: getListSuccess,
  [VariableTypes.CREATE_SUCCESS]: createSuccess,
  [VariableTypes.UPDATE_SUCCESS]: updateSuccess,
  [VariableTypes.DELETE_SUCCESS]: deleteSuccess,
});
