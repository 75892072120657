import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

const DeleteUnconfirmedStepsModal = ({ groupId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const stateParams = useMemo(
    () => ({
      reset: false,
      reqId: groupId,
    }),
    [groupId],
  );

  const { isLoading, hasInternalServerError, reset } = useActionState(
    TestActions.removeUnconfirmedGroupRequest,
    {
      ...stateParams,
      onSuccess: modal.hide,
    },
  );

  const handleConfirm = useCallback(() => {
    dispatch(TestActions.removeUnconfirmedGroupRequest(groupId, stateParams));
  }, [dispatch, groupId, stateParams]);

  useUnmount(() => {
    reset();
  });

  return (
    <ConfirmModal
      title={t('deleteUnconfirmedStepsModal.title', 'Are you sure to delete recorded steps?')}
      pending={isLoading}
      onConfirm={handleConfirm}
      onCancel={modal.hide}
      hasInternalServerError={hasInternalServerError}
    />
  );
};

DeleteUnconfirmedStepsModal.defaultProps = {};

DeleteUnconfirmedStepsModal.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default DeleteUnconfirmedStepsModal;
