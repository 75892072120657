import { pathOr, prop, isEmpty, path, pipe, propOr, indexBy } from 'ramda';
import { createSelector } from 'reselect';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';

export const selectProjectDomain = prop('project');

export const selectProjects = createSelector(selectProjectDomain, pathOr({}, ['list', 'projects']));

export const selectProjectList = createSelector(selectProjectDomain, (state) =>
  state.list.order.map((projectId) => state.list.projects[projectId]),
);

export const selectSingleProject = createSelector(selectProjectDomain, (state) =>
  pathOr({}, ['single', 'projects', state.single.id], state),
);

export const selectSingleProjectConditionByType = createSelector(
  selectSingleProject,
  pipe(path(['settings', 'waitingConditions']), indexBy(prop('type'))),
);

export const selectSingleProjectId = createSelector(selectProjectDomain, path(['single', 'id']));

export const selectProjectSlug = createSelector(selectSingleProject, prop('slug'));

export const selectProjectSettings = createSelector(selectSingleProject, propOr({}, 'settings'));

export const selectProjectTimezone = createSelector(selectProjectSettings, prop('timezone'));

export const selectProjectScreenSizeByType = (type) =>
  createSelector(selectProjectSettings, (settings) => {
    if (type === SCREEN_RESOLUTION_TYPE.MOBILE) {
      return { width: settings.mobileBrowserWidth, height: settings.mobileBrowserHeight };
    }
    return { width: settings.browserWidth, height: settings.browserHeight };
  });

export const selectProjectApiToken = createSelector(selectProjectSettings, prop('apiToken'));

export const selectProjectHomepageUrl = createSelector(selectSingleProject, prop('homepageUrl'));

export const selectProjectSelectorMethods = createSelector(
  selectSingleProject,
  pathOr([], ['settings', 'selectorMethods']),
);

export const selectProjectWaitingConditions = createSelector(
  selectSingleProject,
  pathOr([], ['settings', 'waitingConditions']),
);

export const selectProjectBySlug = (slug) =>
  createSelector(
    selectProjectList,
    (projects) => projects.find((project) => project.slug === slug) || {},
  );

export const selectClipboard = createSelector(selectProjectDomain, (state) =>
  !isEmpty(state.clipboard) ? state.clipboard : {},
);

export const selectIsClipboardRecording = createSelector(selectClipboard, (clipboard) =>
  isEmpty(clipboard) ? false : clipboard.tests[clipboard.id].isRecording,
);

export const selectProjectById = (projectId) =>
  createSelector(
    selectProjectList,
    (projects) => projects.find((project) => project.id === projectId) || {},
  );
