import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 450px;
`;

export const Results = styled.ul`
  padding: 1px 0 0;
  margin: 20px 0 0;
  border: 1px solid ${COLOR.BORDER_GRAY};
  border-right: 0;
  border-left: 0;
  overflow-y: auto;
  height: min-content;
  max-height: 302px;

  &:empty {
    border: none;
  }
`;

export const Result = styled.li`
  list-style: none;
  margin: -1px 0;
  padding: 15px 10px;
  border-top: 1px solid ${COLOR.TRANSPARENT};
  border-bottom: 1px solid ${COLOR.BORDER_GRAY};
  cursor: pointer;
  color: ${COLOR.DARK_GRAY};

  &:last-child {
    border-bottom-color: ${COLOR.TRANSPARENT};
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: ${COLOR.PRIMARY_5};
    border-color: ${COLOR.BORDER_GRAY};
  }

  &:focus {
    outline-color: ${COLOR.PRIMARY_LIGHT};
  }

  &[disabled] {
    background: ${COLOR.GRAY_28};
    color: #808080;
    border-top-color: ${COLOR.BORDER_GRAY};
    opacity: 0.5;
    cursor: not-allowed;

    span {
      margin-left: 10px;
    }
  }
`;
export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))`
  margin: 20px 0 0 0;
`;
export const CreateIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  font-size: 23px;
  color: ${COLOR.PRIMARY_LIGHT};
  width: 23px;
  max-height: 23px;
  margin-right: 2px;
  top: 1px;
  position: relative;
`;
