import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Header, Footer } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import { Container, Content } from './SupportRequestSuccessModal.styled';

const SupportRequestSuccessModal = ({ className }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const email = t('default.email');
  const supportUrl = `mailto:${t('default.email')}`;
  return (
    <Container
      className={className}
      aria-label={t('supportRequestSuccessModal.ariaLabel', "We've received your request")}
    >
      <Content>
        <Icon name="checkCircle" />
        <div>
          <Header>{t('supportRequestSuccessModal.title', "We've received your request")}</Header>
          <p>
            <Trans i18nKey="supportRequestSuccessModal.description" values={{ email }}>
              We have received the details of your failed test. We will look into this as quickly as
              possible and suggest a way to fix it. Usually it takes 1-2 days. If you have
              additional questions contact us at{' '}
              <Link to={supportUrl}>
                <span>{{ email }}</span>
              </Link>
            </Trans>
          </p>
        </div>
      </Content>
      <Footer>
        <Button onClick={modal.hide} variant={BUTTON_VARIANT.PRIMARY}>
          {t('supportRequestSuccessModal.return', 'Done')}
        </Button>
      </Footer>
    </Container>
  );
};

export default SupportRequestSuccessModal;
