import * as T from '@bugbug/core/utils/toolbox';
import { groupBy, prop, propOr, props } from 'ramda';
import { createSelector } from 'reselect';

import { VARIABLE_GROUP } from '~/constants/variables';

export const selectVariableDomain = prop('variable');

export const selectVariables = createSelector(selectVariableDomain, propOr({}, 'variables'));

export const selectVariable = T.memoize((testId) =>
  createSelector(selectVariables, propOr(null, testId)),
);

export const selectOrder = createSelector(selectVariableDomain, propOr([], 'order'));

export const selectVariablesList = createSelector(selectOrder, selectVariables, props);

const selectGroupedVariablesList = createSelector(
  selectVariablesList,
  groupBy(({ isSystem }) => (isSystem ? VARIABLE_GROUP.SYSTEM : VARIABLE_GROUP.CUSTOM)),
);

export const selectBuiltInVariablesList = createSelector(
  selectGroupedVariablesList,
  propOr([], VARIABLE_GROUP.SYSTEM),
);

export const selectCustomVariablesList = createSelector(
  selectGroupedVariablesList,
  propOr([], VARIABLE_GROUP.CUSTOM),
);
