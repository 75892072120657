import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgIntegrations = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 24 24", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", className: "feather feather-git-pull-request", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("circle", { cx: 18, cy: 18, r: 3, fill: "none" }),
  /* @__PURE__ */ jsx("circle", { cx: 6, cy: 6, r: 3, fill: "none" }),
  /* @__PURE__ */ jsx("path", { d: "M13 6h3a2 2 0 0 1 2 2v7", fill: "none" }),
  /* @__PURE__ */ jsx("line", { fill: "none", x1: 6, y1: 9, x2: 6, y2: 21 })
] });
const ForwardRef = forwardRef(SvgIntegrations);
export default ForwardRef;
