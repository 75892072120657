import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgContext = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-context" }),
  /* @__PURE__ */ jsx("g", { id: "b3ac8449-a335-4a4c-bf7b-ba3f39d6ce57", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "e03cb393-a854-4ef8-aebb-21cafa340704", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("rect", { x: 5, y: 6, width: 22, height: 20, rx: 1, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 5, y1: 10, x2: 27, y2: 10, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("polyline", { points: "13 20 12 14 18 15", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("polyline", { points: "18 15 16.5 16.5 16.5 16.5 20 20 18 22 14.5 18.5 14.5 18.5 13 20", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgContext);
export default ForwardRef;
