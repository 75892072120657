import * as Yup from 'yup';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import { testNameValidator, idValidator } from '~/utils/validators';

export const TestEditSchema = Yup.object().shape({
  groupId: idValidator.notRequired(),
  testId: idValidator.notRequired(),
  name: testNameValidator,
  runProfileId: idValidator,
  screenSizeType: Yup.string().oneOf(Object.values(SCREEN_RESOLUTION_TYPE)),
});

export const TestCreateSchema = Yup.object().shape({
  groupId: idValidator,
  testId: idValidator,
  name: testNameValidator.optional().nullable(),
  runProfileId: idValidator,
  screenSizeType: Yup.string().oneOf(Object.values(SCREEN_RESOLUTION_TYPE)),
});
