import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgJira = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 583.68 75.76", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("style", { children: ".cls-1{fill:#253858;}.cls-2{fill:#2684ff;}.cls-3{fill:url(#linear-gradient);}.cls-4{fill:url(#linear-gradient-2);}" }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "linear-gradient", x1: 34.64, y1: 15.35, x2: 19, y2: 30.99, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { offset: 0.18, stopColor: "#0052cc" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#2684ff" })
    ] }),
    /* @__PURE__ */ jsx("linearGradient", { id: "linear-gradient-2", x1: 38.78, y1: 60.28, x2: 54.39, y2: 44.67, xlinkHref: "#linear-gradient" })
  ] }),
  /* @__PURE__ */ jsx("title", { children: "Jira Software-blue" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "Blue", children: [
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M109.28,9h7.92V49.2c0,10.65-4.66,18-15.58,18a28.14,28.14,0,0,1-9.5-1.5V58a22.19,22.19,0,0,0,8.18,1.5c6.69,0,9-4,9-9.77Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M131.63,5.91a4.94,4.94,0,0,1,5.28,5.28,5.28,5.28,0,1,1-10.56,0A4.94,4.94,0,0,1,131.63,5.91Zm-3.87,16.9h7.57v44h-7.57Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M146.76,22.8h7.39v7.74c2.55-5.19,7-8.89,15.58-8.36v7.39c-9.68-1-15.58,1.94-15.58,11.26v26h-7.39Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M205.19,58.88c-2.82,5.81-8.1,8.8-14.87,8.8-11.7,0-17.6-9.94-17.6-22.88,0-12.41,6.16-22.88,18.48-22.88,6.42,0,11.35,2.9,14,8.62V22.8h7.57v44h-7.57Zm-12.85,1.76c6.78,0,12.85-4.31,12.85-14.08V43c0-9.77-5.54-14.08-12-14.08-8.54,0-12.94,5.63-12.94,15.84C180.29,55.36,184.51,60.64,192.35,60.64Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M257.55,67.68c-11.18,0-16.37-2.2-20.86-4.49V55.1c5.37,2.82,13.64,4.84,21.29,4.84,8.71,0,12-3.43,12-8.54s-3.17-7.83-14.17-10.47c-13-3.17-18.83-7.66-18.83-17.16,0-9,6.86-15.66,20.33-15.66,8.36,0,13.73,2,17.69,4.22v7.92a35.52,35.52,0,0,0-18-4.4c-7.57,0-12.06,2.64-12.06,7.92,0,4.75,3.7,7.13,13.9,9.68,12.23,3.08,19.1,7,19.1,17.42C277.88,60.9,271.9,67.68,257.55,67.68Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M284.57,44.71c0-13.2,7.74-22.79,20.94-22.79s20.77,9.59,20.77,22.79-7.66,23-20.77,23S284.57,57.91,284.57,44.71Zm7.39,0c0,8.36,4.14,15.93,13.55,15.93s13.38-7.57,13.38-15.93S314.84,29,305.51,29,292,36.35,292,44.71Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M345.55,22.8H356.9v7H345.55v37h-7.39v-37H331v-7h7.13V18c0-8.18,4.58-13.73,14-13.73a18.19,18.19,0,0,1,5.1.7v7a25,25,0,0,0-4.75-.44c-4.66,0-7,2.73-7,6.69Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M382.51,59.41a24,24,0,0,0,4.75-.62v7.3a18.15,18.15,0,0,1-5.1.7c-9.42,0-14-5.54-14-13.73V29.84H361v-7h7.13V13.47h7.39V22.8h11.7v7h-11.7V52.9C375.56,56.77,377.85,59.41,382.51,59.41Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M389.38,22.8h8.36l12.67,37.31L423.25,22.8h7.22l12.85,37.31L456,22.8h8.36l-16.19,44h-9.68l-7.57-21.29-4-13.55-4,13.55L415.25,66.8h-9.68Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M498.93,58.88c-2.82,5.81-8.1,8.8-14.87,8.8-11.7,0-17.6-9.94-17.6-22.88,0-12.41,6.16-22.88,18.48-22.88,6.42,0,11.35,2.9,14,8.62V22.8h7.57v44h-7.57Zm-12.85,1.76c6.78,0,12.85-4.31,12.85-14.08V43c0-9.77-5.54-14.08-12-14.08C478.43,29,474,34.59,474,44.8,474,55.36,478.25,60.64,486.09,60.64Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M517.94,22.8h7.39v7.74c2.55-5.19,7-8.89,15.58-8.36v7.39c-9.68-1-15.58,1.94-15.58,11.26v26h-7.39Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M567.57,67.68c-16.46,0-23.67-9.5-23.67-23,0-13.29,7.39-22.79,20.77-22.79,13.55,0,19,9.42,19,22.79v3.43H551.56c1.06,7.48,5.9,12.32,16.28,12.32a39,39,0,0,0,13.38-2.38v7C577.61,67,572.06,67.68,567.57,67.68Zm-16.1-26H576c-.44-8.18-4.14-12.85-11.7-12.85C556.31,28.79,552.26,34,551.47,41.63Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-2", d: "M72.4,35.76,39.8,3.16,36.64,0h0L12.1,24.54h0L.88,35.76A3,3,0,0,0,.88,40L23.3,62.42,36.64,75.76,61.18,51.22l.38-.38L72.4,40A3,3,0,0,0,72.4,35.76ZM36.64,49.08l-11.2-11.2,11.2-11.2,11.2,11.2Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-3", d: "M36.64,26.68A18.86,18.86,0,0,1,36.56.09L12.05,24.59,25.39,37.93,36.64,26.68Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-4", d: "M47.87,37.85,36.64,49.08a18.86,18.86,0,0,1,0,26.68h0L61.21,51.19Z" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgJira);
export default ForwardRef;
