import produce from 'immer';
import { mergeDeepRight } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

export const GET_INCOGNITO_WINDOWS_STATUS_RESULT = 'GET_INCOGNITO_WINDOWS_STATUS_RESULT';
export const UPDATE_EXTENSION_SETTINGS = 'UPDATE_EXTENSION_SETTINGS';

export const { Types: ExtensionTypes, Creators: ExtensionActions } = createActions(
  {
    updateActiveConnections: ['data'],
    setWebsocketId: ['websocketId'],
    connectWithWebappRequested: [],
    openSettingsRequested: [],
    closeWindowsRequested: [],
    closeWindowsSucceeded: [],
    closeWindowsFailed: [],
    startHeartbeatRequested: [],
    stopHeartbeatRequested: [],
    updateSettingsRequested: ['settings'],
    updateSettingsFailed: [],
    updateSettingsSucceeded: ['settings'],
    pong: [],
    registerWebAppSessionRequested: [],
    unregisterWebAppSessionRequested: [],
    unregisterWebAppSessionSucceeded: ['hasActiveIncognitoWindows'],
    getIncognitoWindowsStatusRequest: ['meta'],
    getIncognitoWindowsStatusSuccess: ['meta'],
    getIncognitoWindowsStatusFailure: ['meta'],
    setIncognitoWindowsStatus: ['hasActiveIncognitoWindows'],
  },
  { prefix: 'EXTENSION/' },
);

export const INITIAL_STATE = {
  activeConnections: [], // TODO: deprecated; to remove
  websocketId: null, // TODO: deprecated; to remove
  hasActiveIncognitoWindows: false,
  settings: {
    isPending: true,
    isDetected: false,
    isConnectedWithServer: false,
  },
};

const updateActiveConnections = (state, action) =>
  produce(state, (draftState) => {
    draftState.activeConnections = (action.data || []).map((connection) => ({
      ...connection,
      isActive: connection.websocketId === state.websocketId,
    }));
  });

const setIncognitoWindowsStatus = (state, { hasActiveIncognitoWindows }) =>
  produce(state, (draftState) => {
    draftState.hasActiveIncognitoWindows = hasActiveIncognitoWindows;
  });

const updateSettingsSucceeded = (state, { settings }) =>
  produce(state, (draftState) => {
    draftState.settings = mergeDeepRight(draftState.settings, settings);
  });

export const reducer = createReducer(INITIAL_STATE, {
  [ExtensionTypes.UPDATE_ACTIVE_CONNECTIONS]: updateActiveConnections,
  [ExtensionTypes.SET_INCOGNITO_WINDOWS_STATUS]: setIncognitoWindowsStatus,
  [ExtensionTypes.UPDATE_SETTINGS_SUCCEEDED]: updateSettingsSucceeded,
});
