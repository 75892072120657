import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { getSelectedPartialsLabel } from '~/utils/misc';

import { Container, Content } from './DeleteTestPartialsModal.styled';

const DeleteTestPartialsModal = (props) => {
  const { testId, steps, groups, onSuccess } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => {
    modal.hide();
    onSuccess();
  }, [modal, onSuccess]);

  const removeRequestState = useActionState(TestActions.removePartialsRequest, {
    onSuccess: handleSuccess,
  });

  const handleDeleteConfirm = useCallback(() => {
    analytics.trackEvent(
      steps.length === 1 && !groups.length
        ? TRACK_EVENT_TYPE.DELETE_STEP
        : TRACK_EVENT_TYPE.BULK_DELETE_STEPS,
    );
    dispatch(TestActions.removePartialsRequest(testId, steps, groups));
  }, [dispatch, testId, steps, groups]);

  const partialsLabel = getSelectedPartialsLabel(steps.length, groups.length);

  return (
    <Container>
      <Content>
        <DeleteIcon />
        <div>
          <Header>
            {t('deleteTestPartialsModal.title', 'Delete {{partialsLabel}}?', {
              partialsLabel,
            })}
          </Header>
          <p>
            <Trans
              i18nKey="deleteStepsModal.description"
              defaults="Are you sure you want to delete {{ partialsLabel }}? You <strong>can't undo this.</strong>"
              values={{ partialsLabel }}
            />
          </p>
        </div>
      </Content>
      <Footer>
        <ErrorInfo isVisible={removeRequestState.hasInternalServerError} />
        <Button onClick={modal.hide}>{t('deleteStepsModal.cancel', 'Cancel')}</Button>
        <ActionButton
          onClick={handleDeleteConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={removeRequestState.isLoading}
        >
          {t('deleteTestPartialsModal.button', 'Delete {{partialsLabel}}', {
            partialsLabel,
          })}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DeleteTestPartialsModal.defaultProps = {
  className: null,
  onSuccess: Function.prototype,
  steps: [],
  groups: [],
};

DeleteTestPartialsModal.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.string),
  groups: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string.isRequired,
};

export default DeleteTestPartialsModal;
