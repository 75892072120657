import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

const DeleteGroupModal = ({ className, test, group, permanent, onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => {
    modal.hide();
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  const removeGroupRequestState = useActionState(TestActions.removeGroupRequest, {
    onSuccess: handleSuccess,
    reqId: group.id,
  });

  const removeGroupRelationRequestState = useActionState(TestActions.removeGroupRelationRequest, {
    onSuccess: handleSuccess,
    reqId: group.id,
  });

  const handleRemoveFromTest = useCallback(() => {
    dispatch(TestActions.removeGroupRelationRequest(test.id, group.id, { reqId: group.id }));
  }, [dispatch, test.id, group]);

  const handleDeletePermanently = useCallback(() => {
    dispatch(TestActions.removeGroupRequest(group.id, { reqId: group.id }));
  }, [dispatch, group]);

  const confirmationProps = permanent
    ? {
        pending: removeGroupRequestState.isLoading,
        onConfirm: handleDeletePermanently,
      }
    : {
        pending: removeGroupRelationRequestState.isLoading,
        onConfirm: handleRemoveFromTest,
      };

  return (
    <ConfirmModal
      className={className}
      onCancel={modal.hide}
      hasInternalServerError={
        removeGroupRelationRequestState.hasInternalServerError ||
        removeGroupRequestState.hasInternalServerError
      }
      title={t('deleteGroupModal.relation.title', 'Do you want to remove "{{groupName}}"?', {
        groupName: group.name,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...confirmationProps}
    />
  );
};

DeleteGroupModal.defaultProps = {
  className: null,
  test: {},
  permanent: false,
  onSubmit: Function.prototype,
};

DeleteGroupModal.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  test: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  permanent: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default DeleteGroupModal;
