import styled from 'styled-components';

import { COLOR } from '../../../../theme';
import * as Icons from '../../../../theme/icons';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2px 8px 4px 8px;
  height: 23px;
  background: ${COLOR.GRAY_9};
  border-radius: 100px;
  font-size: 13px;
  line-height: 146%;
  color: ${COLOR.DARK_GRAY};
`;

export const RemoveIcon = styled(Icons.CloseIcon)`
  cursor: pointer;
  color: ${COLOR.PRIMARY_LIGHT};
  margin-left: 10px;
  width: 8px;
  height: 8px;
  margin-top: 2px;
`;
