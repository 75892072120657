import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FormField from '~/components/FormField';
import { Header, Footer, Content } from '~/components/modals/Modal';
import ScreenSizeDropdown from '~/components/ScreenSizeDropdown';
import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest } from '~/modules/test/test.selectors';
import { testNameValidator } from '~/utils/validators';

import { Container } from './TestSettingsModal.styled';

const FORM_FIELD = {
  NAME: 'name',
  SCREEN_SIZE: 'screenSizeType',
};

const TestSettingsSchema = Yup.object().shape({
  [FORM_FIELD.NAME]: testNameValidator.ensure(),
  [FORM_FIELD.SCREEN_SIZE]: Yup.string().oneOf(Object.values(SCREEN_RESOLUTION_TYPE)),
});

const TestSettingsModal = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const test = useSelector(selectSingleTest);

  const initialValues = useMemo(
    () => TestSettingsSchema.cast(pick(Object.values(FORM_FIELD), test)),
    [test],
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(TestActions.updateRequest(test.id, values));
    },
    [dispatch, test.id],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: TestSettingsSchema,
    onSubmit: handleSubmit,
  });

  const handleSuccess = useCallback(() => {
    modal.hide();
    formik.setSubmitting(false);
  }, [formik, modal]);

  const handleFailure = useCallback(() => {
    formik.setSubmitting(false);
  }, [formik]);

  useActionState(TestActions.updateRequest, {
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  });

  return (
    <Container className={className} data-testid="TestSettingsModal" onSubmit={formik.handleSubmit}>
      <Header>{t('testSettingsModal.title', 'Test settings')}</Header>
      <Content>
        <FormField label={t('testSettingsModal.name.label', 'Name')}>
          <Input
            {...formik.getFieldProps(FORM_FIELD.NAME)}
            data-hj-allow
            error={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
          />
        </FormField>
        <FormField label={t('testSettingsModal.screenSizeType.label', 'Screen size')}>
          <ScreenSizeDropdown
            {...formik.getFieldProps(FORM_FIELD.SCREEN_SIZE)}
            onSettingsClick={modal.hide}
          />
        </FormField>
      </Content>
      <Footer>
        <Button onClick={modal.hide} disabled={formik.isSubmitting}>
          {t('default.button.cancel')}
        </Button>
        <ActionButton variant={BUTTON_VARIANT.PRIMARY} type="submit" pending={formik.isSubmitting}>
          {t('default.button.save')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

TestSettingsModal.defaultProps = {
  className: null,
};

TestSettingsModal.propTypes = {
  className: PropTypes.string,
};

export default TestSettingsModal;
