import { push } from 'connected-react-router';
import { all, put, select, takeLatest, call } from 'redux-saga/effects';

import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import {
  selectProjectBySlug,
  selectSingleProjectId,
  selectProjectSlug,
} from '~/modules/project/project.selectors';
import { selectLocation } from '~/modules/router.selectors';
import { TestActions } from '~/modules/test/test.redux';
import { UIStateActions } from '~/modules/uiState';
import { selectUIStateForComponent } from '~/modules/uiState/uiState.selectors';
import { selectHasNotCreatedProject } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import api from '~/services/api';
import { showInternalServerError, ENTITIES } from '~/services/toasts/internalServerError';
import { PROJECT_SETTINGS_TAB } from '~/views/ProjectSettings/ProjectSettings.constants';
import urls, { reverse } from '~/views/urls';

import { ProjectActions, ProjectTypes } from './project.redux';

export const showProjectSettingsError = showInternalServerError(ENTITIES.PROJECT_SETTINGS);

export function* getList() {
  try {
    const response = yield call(api.projects.getList, { page_size: 50 });
    yield put(ProjectActions.getListSuccess(response.data));
  } catch (e) {
    yield put(ProjectActions.getListFailure(e));
  }
}

export function* getSingle({ id }) {
  try {
    const { data: responseData } = yield call(api.projects.get, id);
    yield put(ProjectActions.getSingleSuccess(responseData));
  } catch (error) {
    yield put(ProjectActions.getSingleFailure(error));
  }
}

export function* getSingleSuccess() {
  try {
    yield put(TestActions.getDefaultStepsParamsRequest());
  } catch (error) {
    yield put(ProjectActions.getSingleFailure(error));
  }
}

export function* updateGeneralSettings({ name, settings }) {
  try {
    const params = {
      name,
      ...settings,
    };

    const projectId = yield select(selectSingleProjectId);
    const projectSlug = yield select(selectProjectSlug);
    const { data: responseData } = yield call(
      api.projects.updateGeneralSettings,
      projectId,
      params,
    );
    yield put(ProjectActions.updateGeneralSettingsSuccess(name, settings));
    if (responseData.slug !== projectSlug) {
      const organizationId = yield select(selectCurrentOrganizationId);
      const urlParams = {
        projectId: responseData.projectId,
        projectSlug: responseData.slug,
        organizationId,
        settingsTabSlug: PROJECT_SETTINGS_TAB.GENERAL,
      };
      yield put(push(reverse(urls.projectSettings, urlParams)));
    }
  } catch (error) {
    yield put(ProjectActions.updateGeneralSettingsFailure(error));
  }
}

export function* updateWindowAndBrowserSettings({ settings }) {
  try {
    const projectId = yield select(selectSingleProjectId);

    const { data: updatedSettings } = yield call(
      api.projects.updateWindowAndBrowserSettings,
      projectId,
      settings,
    );
    yield put(ProjectActions.updateWindowAndBrowserSettingsSuccess(updatedSettings));
  } catch (error) {
    yield put(ProjectActions.updateWindowAndBrowserSettingsFailure(error));
  }
}

export function* updateSelectorsSettings({ settings }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    yield call(api.projects.updateSelectorsSettings, projectId, settings);
    yield put(ProjectActions.updateSelectorsSettingsSuccess(settings));
  } catch (error) {
    yield put(ProjectActions.updateSelectorsSettingsFailure(error));
  }
}

export function* updateWaitingConditionsSettings({ settings }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    yield call(api.projects.updateWaitingConditionsSettings, projectId, settings);
    yield put(ProjectActions.updateWaitingConditionsSettingsSuccess(settings));
  } catch (error) {
    yield put(ProjectActions.updateWaitingConditionsSettingsFailure(error));
  }
}

function* updateSettings({ payload }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    yield call(api.projects.updateSettings, projectId, payload);
    yield put(ProjectActions.updateSettingsSuccess(payload));
  } catch (error) {
    yield put(ProjectActions.updateSettingsFailure(error));
  }
}

function* addProject({ data }) {
  try {
    const organizationId = yield select(selectCurrentOrganizationId);
    const hasNotCreatedProject = yield select(selectHasNotCreatedProject);
    if (hasNotCreatedProject) {
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.FIRST_NEW_PROJECT);
    }
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.NEW_PROJECT);

    const { data: responseData } = yield call(api.projects.create, data);
    yield put(ProjectActions.addSuccess());
    yield put(ProjectActions.getListRequest());
    yield put(
      push(
        reverse(urls.project, {
          projectId: responseData.id,
          projectSlug: responseData.slug,
          organizationId,
        }),
      ),
    );
  } catch (e) {
    yield put(ProjectActions.addFailure(e));
  }
}

export function* cloneProject({ id }) {
  try {
    const { data } = yield call(api.projects.clone, id);
    yield put(ProjectActions.cloneSuccess(id, data));
  } catch (e) {
    yield put(ProjectActions.cloneFailure(e));
  }
}

function* removeProject({ id }) {
  try {
    const location = yield select(selectLocation);
    yield call(api.projects.remove, id);
    yield put(ProjectActions.removeSuccess(id));
    if (location.pathname !== urls.projectsList) {
      const organizationId = yield select(selectCurrentOrganizationId);
      yield put(push(reverse(urls.projectsList, { organizationId })));
    }
  } catch (e) {
    yield put(ProjectActions.removeFailure(e));
  }
}

function* getClipboard() {
  try {
    const projectId = yield select(selectSingleProjectId);
    const { data: responseData } = yield call(api.projects.getProjectClipboard, projectId);
    yield put(ProjectActions.getClipboardSuccess(responseData));
  } catch (e) {
    yield put(ProjectActions.getClipboardFailure(e));
  }
}

function* cleanClipboard({ slug }) {
  try {
    const project = yield select(selectProjectBySlug(slug));
    const { data: responseData } = yield call(api.projects.cleanClipboard, project.id);
    yield put(ProjectActions.cleanClipboardSuccess(responseData));
  } catch (error) {
    yield put(ProjectActions.cleanClipboardFailure(error));
  }
}

function* removeClipboardGroup({ slug }) {
  try {
    const project = yield select(selectProjectBySlug(slug));
    yield call(api.projects.removeClipboardGroup, project.id);
    yield put(ProjectActions.removeClipboardGroupSuccess(project.id));
  } catch (e) {
    yield put(ProjectActions.removeClipboardGroupFailure(e));
  }
}

function* update({ id, data }) {
  try {
    const { data: responseData } = yield call(api.projects.update, id, data);
    yield put(ProjectActions.updateSuccess(responseData));
  } catch (error) {
    yield put(ProjectActions.updateFailure(error));
  }
}

function* updatePartial({ id, data }) {
  try {
    const { data: responseData } = yield call(api.projects.updatePartial, id, data);
    yield put(ProjectActions.updateSuccess(responseData));
  } catch (error) {
    yield put(ProjectActions.updateFailure(error));
  }
}

function* clipboardNewStepsSet() {
  const componentName = 'Clipboard';
  const uiState = select(selectUIStateForComponent(componentName));
  if (!uiState.isOpen) {
    yield put(UIStateActions.setUIState({ componentName, data: { isOpen: true } }));
  }
}

export default function* projectSagas() {
  yield all([
    yield takeLatest(ProjectTypes.ADD_REQUEST, addProject),
    yield takeLatest(ProjectTypes.CLONE_REQUEST, cloneProject),
    yield takeLatest(ProjectTypes.CLEAN_CLIPBOARD, cleanClipboard),
    yield takeLatest(ProjectTypes.CLIPBOARD_NEW_STEPS_SET, clipboardNewStepsSet),
    yield takeLatest(ProjectTypes.GET_CLIPBOARD, getClipboard),
    yield takeLatest(ProjectTypes.GET_LIST_REQUEST, getList),
    yield takeLatest(ProjectTypes.GET_SINGLE_REQUEST, getSingle),
    yield takeLatest(ProjectTypes.GET_SINGLE_SUCCESS, getSingleSuccess),
    yield takeLatest(ProjectTypes.REMOVE_REQUEST, removeProject),
    yield takeLatest(ProjectTypes.REMOVE_CLIPBOARD_GROUP, removeClipboardGroup),
    yield takeLatest(ProjectTypes.UPDATE_PARTIAL_REQUEST, updatePartial),
    yield takeLatest(ProjectTypes.UPDATE_REQUEST, update),
    yield takeLatest(ProjectTypes.UPDATE_SETTINGS_REQUEST, updateSettings),
    yield takeLatest(ProjectTypes.UPDATE_GENERAL_SETTINGS_REQUEST, updateGeneralSettings),
    yield takeLatest(
      ProjectTypes.UPDATE_WINDOW_AND_BROWSER_SETTINGS_REQUEST,
      updateWindowAndBrowserSettings,
    ),
    yield takeLatest(ProjectTypes.UPDATE_SELECTORS_SETTINGS_REQUEST, updateSelectorsSettings),
    yield takeLatest(
      ProjectTypes.UPDATE_WAITING_CONDITIONS_SETTINGS_REQUEST,
      updateWaitingConditionsSettings,
    ),
  ]);
}
