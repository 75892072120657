import { createApi } from '@reduxjs/toolkit/query/react';

import type { ProjectWithOrganization } from '@bugbug/core/types/projects';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'projects' as const;
export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/projects/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // GET /projects/all/
    getProjectsInAllOrgs: builder.query<ProjectWithOrganization[], void>({
      query: () => ({
        url: 'all/',
        method: 'GET',
      }),

      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id })), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),
  }),
});

export const { useGetProjectsInAllOrgsQuery } = projectsApi;
