import queryString from 'query-string';
import { useRef, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useQueryString from './useQueryString';

const useUpdateQueryString = () => {
  const history = useHistory();
  const location = useLocation();
  const cachedQueryParams = useRef({});
  const locationPathname = useRef(null);
  const queryParams = useQueryString();

  useEffect(() => {
    cachedQueryParams.current = queryParams;
    locationPathname.current = location.pathname;
  }, [location.pathname, queryParams]);

  return useCallback(
    (newParams = {}) => {
      const newQuery = {
        ...cachedQueryParams.current,
        ...newParams,
      };

      history.replace(`${locationPathname.current}?${queryString.stringify(newQuery)}`);
    },

    [history],
  );
};

export default useUpdateQueryString;
