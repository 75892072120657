import CheckboxBase from '@bugbug/core/components/Checkbox';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import { Content as ContentBase, Header } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 470px;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 16px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  ${Header} {
    margin-bottom: 7px;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const Question = styled.p`
  font-weight: ${FONT_WEIGHT.MEDIUM};
  margin-top: 16px;
`;
