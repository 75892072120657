import * as T from '@bugbug/core/utils/toolbox';
import { nanoid } from 'nanoid';
import { io } from 'socket.io-client';

export const WEBSOCKET_CLOSED_REASON = {
  INVALID_TOKEN: 'INVALID_TOKEN',
  UNKNOWN: 'UNKNOWN',
  CHANNEL_CLOSED: 'CHANNEL_CLOSED',
  SELENIUM_DISCONNECTED: 'SELENIUM_DISCONNECTED',
  OFFLINE: 'OFFLINE',
  WEBAPP_INACTIVE: 'WEBAPP_INACTIVE',
  RUNTIME_ERROR: 'RUNTIME_ERROR',
};

class WebsocketConnection {
  connection = null;

  isConnectingPending = false;

  connectionPromise = null;

  createConnection = async (url, token, orgId) => {
    this.connection = io(`${url}?token=${token}&organization_id=${orgId}`, {
      path: '/ws/webapp/',
      transports: ['websocket'],
    });
    this.closeReason = null;
    this.connectionPromise = new Promise((resolve) => {
      this.connection.on('connect', () => {
        resolve(true);
      });
      this.connection.on('connect_error', () => {
        resolve(false);
      });
    });
    return this.connection;
  };

  sendMessage = (eventName, data = {}) => {
    if (this.connection) {
      this.connection.emit(
        eventName,
        {
          reqId: nanoid(8),
          data,
        },
        // We need to wait for the server to confirm the message was received (ack)
        T.noop,
      );
    }
  };

  close = (reason = WEBSOCKET_CLOSED_REASON.UNKNOWN) => {
    if (this.connection) {
      this.closeReason = reason;
      this.connection.close();
    }
  };

  clearConnection = () => {
    this.connection = null;
    this.connectionPromise = null;
  };

  isOpen = async () => {
    await this.connectionPromise;
    return this.connection?.connected ?? false;
  };
}

export default new WebsocketConnection();
