import api from '~/services/api/common';

export const getDefaultParams = () => api.get('/steps/default-params/');

export const updateActivation = (testId, stepsIds, isActive) =>
  api.patch('/steps/switch-state/', {
    testId,
    stepsIds,
    isActive,
  });
