import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header<{ centeredContent?: boolean; noBorder?: boolean }>`
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  ${({ theme, centeredContent }) =>
    centeredContent &&
    css`
      min-height: 51px;
      border-bottom: 2px solid ${theme.colors.neutrals['97']};
      margin-bottom: 32px;
      padding-bottom: 8px;
    `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      min-height: 31px;
      border-bottom: 0;
      margin-bottom: 23px;
      padding-bottom: 0;
    `}

  &:empty {
    min-height: auto;
    margin: 0;
  }
`;
