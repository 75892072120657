import type { IconName } from '@bugbug/core/types/icons';
import type { TestRunScreenSize } from '@bugbug/core/types/tests';

export const SCREEN_RESOLUTION_TYPE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  CUSTOM: 'custom',
} as const;

export const SCREEN_RESOLUTION_ICON_NAME: Record<TestRunScreenSize, IconName> = {
  [SCREEN_RESOLUTION_TYPE.DESKTOP]: 'desktop',
  [SCREEN_RESOLUTION_TYPE.MOBILE]: 'mobile',
  [SCREEN_RESOLUTION_TYPE.CUSTOM]: 'desktop',
} as const;

export const SCREEN_RESOLUTION_LABEL = {
  [SCREEN_RESOLUTION_TYPE.DESKTOP]: 'Desktop',
  [SCREEN_RESOLUTION_TYPE.MOBILE]: 'Mobile',
  [SCREEN_RESOLUTION_TYPE.CUSTOM]: 'Custom',
} as const;
