import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCalendar = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", className: "feather feather-calendar", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 3, y: 4, width: 18, height: 18, rx: 2, ry: 2 }),
  /* @__PURE__ */ jsx("line", { x1: 16, y1: 2, x2: 16, y2: 6 }),
  /* @__PURE__ */ jsx("line", { x1: 8, y1: 2, x2: 8, y2: 6 }),
  /* @__PURE__ */ jsx("line", { x1: 3, y1: 10, x2: 21, y2: 10 })
] });
const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
