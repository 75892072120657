import type { Modal } from '~/components/modals/modals.types';

import type { Route } from '@bugbug/core/types/routes';
import type { SideEffect } from '@bugbug/core/types/utils';

export type ModalSize = 'normal' | 'wide' | 'full-screen' | 'tutorial';

export interface ModalContextProps {
  show: ModalRenderer;
  showWide: ModalRenderer;
  showFullscreen: ModalRenderer;
  showTutorial: ModalRenderer;
  showCustom: CustomModalRenderer;
  hide: SideEffect;
}

export interface ModalConfig {
  size?: ModalSize;
  onHide?: SideEffect<Route>;
}

export type ModalRenderer = <T extends Modal>(
  type: T,
  props?: Record<string, unknown>,
  config?: ModalConfig,
) => void;

export interface ActiveModal {
  type?: Modal;
  props?: Record<string, unknown>;
  render?: () => React.ReactNode;
  config?: ModalConfig;
}

export type CustomModalRenderer = SideEffect<ActiveModal['render']>;

export type HideCallback = (arg?: MouseEvent | Route) => void;

export function isMouseEvent(arg?: MouseEvent | Route): arg is MouseEvent {
  return !!arg && typeof arg === 'object' && 'button' in arg;
}
