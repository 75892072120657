import * as Tb from '@bugbug/core/utils/toolbox';
import * as Sentry from '@sentry/react';
import { reverse } from 'named-urls';
import queryString from 'query-string';
import { useCallback } from 'react';
import { matchPath, useHistory } from 'react-router';

import type { History } from 'history';
import type { ReverseParams } from 'named-urls';

import type { Route, RouteParams } from '@bugbug/core/types/routes';
import urls from '~/views/urls';

import useRouteParams from './useRouteParams';

type RouteState = Record<string, string | boolean | number>;

type AppRouteFn = <T extends Route>(
  routeName: T,
  params?: Partial<RouteParams<T>>,
  queryParams?: Record<string, string | undefined>,
  state?: RouteState,
) => void;

export interface UseAppRoutesReturn<T extends Route> {
  getRouteUrl: (...args: Parameters<AppRouteFn>) => string;
  getAbsoluteRouteUrl: (...args: Parameters<AppRouteFn>) => string;
  push: AppRouteFn;
  replace: AppRouteFn;
  goBack: History['goBack'];
  routeName: T;
  pathname: string;
  params: RouteParams<T>;
  state?: RouteState;
  isRoute: (route: Route) => boolean;
}

const useAppRoutes = <T extends Route>(currentRouteName: T): UseAppRoutesReturn<T> => {
  const history = useHistory<RouteState>();
  const routeParams = useRouteParams(currentRouteName);

  const getRouteUrl = useCallback<UseAppRoutesReturn<T>['getRouteUrl']>(
    (routeName, params?, queryParams?) => {
      const template = urls[routeName];
      const urlParams: RouteParams<T> = { ...routeParams, ...params };
      const url = reverse(template.replace('([^/]*)', ''), urlParams as ReverseParams);
      const parseQueryParams = queryParams ? `?${queryString.stringify(queryParams)}` : '';

      if (url.includes('/:')) {
        Sentry.captureMessage('Invalid URL params', {
          level: 'debug',
          contexts: {
            urlCreator: {
              url,
              params,
            },
          },
        });
      }

      return `${url}${parseQueryParams}`;
    },
    [routeParams],
  );

  const getAbsoluteRouteUrl = useCallback<UseAppRoutesReturn<T>['getAbsoluteRouteUrl']>(
    (...args) => `${window.location.origin}${getRouteUrl(...args)}`,
    [getRouteUrl],
  );

  const push = useCallback<UseAppRoutesReturn<T>['push']>(
    (routeName, params?, queryParams?) => {
      const routeUrl = getRouteUrl(routeName, params, queryParams);
      history.push(routeUrl);
    },
    [getRouteUrl, history],
  );

  const replace = useCallback<UseAppRoutesReturn<T>['replace']>(
    (routeName, params?, queryParams?, state?): void => {
      const routeUrl = getRouteUrl(routeName, params, queryParams);
      history.replace(routeUrl, state);
    },
    [getRouteUrl, history],
  );

  const isRoute = useCallback<UseAppRoutesReturn<T>['isRoute']>(
    (route) =>
      !!matchPath(history.location.pathname, {
        path: urls[route],
      }),
    [history.location],
  );

  return {
    routeName: currentRouteName,
    getRouteUrl,
    getAbsoluteRouteUrl,
    push,
    replace,
    params: routeParams,
    goBack: history.goBack,
    state: Tb.path(['location', 'state'], history) ?? {},
    pathname: history.location.pathname,
    isRoute,
  };
};

export default useAppRoutes;
