import { complement, either, isEmpty, pathOr, compose, prop, both, propEq } from 'ramda';

import { ASSERTION_PROPERTY, STEP_TYPE } from '~/constants/step';

export const isTrueFalseAssertion = (property) =>
  pathOr(false, [property, 'isTrueFalse'], ASSERTION_PROPERTY);

export const isDocumentAssertion = (property) =>
  pathOr(false, [property, 'isDocumentAssertion'], ASSERTION_PROPERTY);

export const isPropNotEmpty = (propName) => compose(complement(isEmpty), prop(propName));
export const isBasicAuthVisible = both(
  propEq(STEP_TYPE.GOTO),
  either(isPropNotEmpty('username'), isPropNotEmpty('password')),
);
