import Link from '@bugbug/core/components/Link';
import { Trans } from 'react-i18next';

import OrganizedList from '~/components/OrganizedList';
import useAppRoutes from '~/hooks/useAppRoutes';

import { TwoColumnContent } from './IntegrationModal.styled';

export const TeamsDetails = () => {
  const { params, getRouteUrl } = useAppRoutes('integration');
  return (
    <TwoColumnContent>
      <article>
        <p>
          <Trans i18nKey="integrationModal.details.teams.introduction">
            <b>BugBug's integration with Microsoft Teams</b> is crafted to take your team's testing
            and issue management to unprecedented heights. By leveraging the direct link with
            Microsoft Teams, you can capitalize on real-time updates and enhance your alerts with
            essential details, ensuring your end-to-end (E2E) testing and issue tracking are more
            efficient than ever. Here's what you can achieve with BugBug integration:
          </Trans>
        </p>
        <Trans i18nKey="integrationModal.details.teams.list">
          <OrganizedList>
            <li>
              <b>Detailed Notification Content</b>: Say goodbye to vague notifications. BugBug
              enriches your Microsoft Teams messages with detailed information about test runs,
              suite executions, and scheduled tests. This clarity gives your team the context needed
              to quickly identify issues and address them without delay.
            </li>
            <li>
              <b>User-Centric Alerts:</b> With BugBug, alerting the right people at the right time
              is straightforward. You will have the flexibility to customize how notifications are
              spread within Microsoft Teams.
            </li>
            <li>
              <b>Instantaneous Updates</b> Stay in the know with BugBug's seamless Microsoft Teams
              integration. As soon as a test fails or a new issue is detected, your team will be
              immediately informed. This promptness facilitates a rapid reaction, helping you to
              expedite issue resolution.
            </li>
            <li>
              <b>Boosted Efficiency and Productivity:</b> Integrate BugBug with your Microsoft Teams
              workflow for optimized testing procedures and heightened productivity. Gain timely
              insights, make informed decisions faster, and maintain a high standard of software
              quality—all within the familiar interface of Microsoft Teams.
            </li>
          </OrganizedList>
        </Trans>
      </article>
      <aside>
        <h2>What you can do with this integration?</h2>
        <div>
          <Link to={getRouteUrl('newAlert', params, { action: 'sendTeamsMessage' })}>
            Setup Teams message alert
          </Link>
        </div>
      </aside>
    </TwoColumnContent>
  );
};
