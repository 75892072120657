import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import { empty } from 'ramda';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import i18n from '~/translations';
import { interpolateXY } from '~/utils/spring';

import {
  Container,
  ErrorText,
  Icon,
  RetryButton,
  TextWrapper,
  Content,
} from './ServerErrorInfo.styled';

const ServerErrorInfo = ({
  className,
  inline,
  isVisible,
  onRetry,
  inlineErrorText,
  errorText,
  extraContentStyle,
  hideRetryButton,
  hideErrorText,
}) => {
  const defaultAnimationState = {
    pointerEvents: 'none',
    opacity: 0,
    iconX: inline ? 30 : 0,
    iconY: inline ? 0 : 60,
    textX: inline ? 45 : 0,
    textY: inline ? 0 : 75,
  };

  const animatedValues = useSpring(
    isVisible
      ? { pointerEvents: 'auto', opacity: 1, iconX: 0, iconY: 0, textX: 0, textY: 0 }
      : defaultAnimationState,
  );

  if (!isVisible) {
    return null;
  }

  const renderRetryButton = renderWhenTrue(() => (
    <RetryButton data-testid="ServerErrorInfo-Button" type="button" onClick={() => onRetry()}>
      {i18n.t('serverErrorInfo.retry', 'Try again')}
    </RetryButton>
  ));

  const renderErrorText = renderWhenTrue(() => (
    <ErrorText>{inline ? inlineErrorText : errorText}</ErrorText>
  ));

  return (
    <ThemeProvider theme={{ inline, hideErrorText, extraContentStyle }}>
      <Container
        className={className}
        style={{ pointerEvents: animatedValues.pointerEvents }}
        data-testid="ServerErrorInfo"
      >
        <Content style={{ opacity: animatedValues.opacity }}>
          <Icon
            style={{
              transform: interpolateXY({ x: animatedValues.iconX, y: animatedValues.iconY }),
            }}
          />
          <TextWrapper
            style={{
              transform: interpolateXY({ x: animatedValues.textX, y: animatedValues.textY }),
            }}
          >
            {renderErrorText(!hideErrorText)}
            {renderRetryButton(!hideRetryButton)}
          </TextWrapper>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

ServerErrorInfo.defaultProps = {
  errorText: i18n.t('serverErrorInfo.title', 'An unexpected error occurred.'),
  inlineErrorText: i18n.t('serverErrorInfo.inlineTitle', 'Loading failed.'),
  className: null,
  isVisible: false,
  onRetry: empty,
  inline: false,
  hideRetryButton: false,
  hideErrorText: false,
  extraContentStyle: null,
};

ServerErrorInfo.propTypes = {
  errorText: PropTypes.string,
  inlineErrorText: PropTypes.string,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  hideRetryButton: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  inline: PropTypes.bool,
  onRetry: PropTypes.func,
  extraContentStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ServerErrorInfo;
