import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAddComponent = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 24, height: 24, fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M8 9.15109L4.92445 6.07555L8 3L11.0755 6.07555L8 9.15109ZM11.9245 13.0755L8.84891 10L11.9245 6.92445L15 10L11.9245 13.0755ZM4.07555 13.0755L1 10L4.07555 6.92445L7.15109 10L4.07555 13.0755ZM8 17L4.92445 13.9245L8 10.8489L11.0755 13.9245L8 17Z" }),
  /* @__PURE__ */ jsx("path", { d: "M16 13H18V21H16V13Z" }),
  /* @__PURE__ */ jsx("path", { d: "M21 16V18L13 18L13 16L21 16Z" })
] });
const ForwardRef = forwardRef(SvgAddComponent);
export default ForwardRef;
