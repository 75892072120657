import { END, eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';

import config from '~/modules/config';
import { CoreActions, CoreTypes } from '~/modules/core/core.redux';
import { ExtensionTypes } from '~/modules/extension/extension.redux';
import Logger from '~/modules/logger';
import { selectCurrentOrganizationId } from '~/modules/organization';
import { OrganizationTypes } from '~/modules/organization/organization.redux';
import { ProfileTypes } from '~/modules/profile/profile.redux';
import { ProjectTypes } from '~/modules/project/project.redux';
import { SuiteRunTypes } from '~/modules/suiteRun/suiteRun.redux';
import { TestTypes } from '~/modules/test/test.redux';
import { TestRunTypes } from '~/modules/testRun/testRun.redux';
import { UserTypes } from '~/modules/user/user.redux';
import { selectUserToken } from '~/modules/user/user.selectors';
import websocketConnection from '~/services/websocketConnection/websocketConnection';

import { WebsocketActions, WebsocketTypes } from './websocket.redux';

const logger = Logger.get('websocket/saga');

let reconnect = true;

window.addEventListener('beforeunload', () => {
  reconnect = false;
});

window.addEventListener('offline', () => {
  reconnect = false;
});

const allowedCommands = [
  ExtensionTypes.UPDATE_ACTIVE_CONNECTIONS,
  TestTypes.UPDATE_STEP,
  TestTypes.UPDATE_STEPS,
  TestTypes.UPDATE_STEP_STATUS,
  TestTypes.UPDATE_RUNNING_STATUS,
  TestTypes.SET_RECORDING_STATE,
  TestTypes.NEW_STEPS_SET,
  TestTypes.STEP_ELEMENT_SCREENSHOT_RECORDED,
  TestTypes.STEP_ELEMENT_SCREENSHOT_UPDATED,
  TestTypes.STEPS_IS_HIDDEN_MODIFIED,
  TestTypes.UPDATE_SUITE_RUN_STATUS,
  TestTypes.SET_IS_SINGLE_LOADING,
  TestTypes.CHANGED,
  TestRunTypes.UPDATED,
  TestRunTypes.UPDATED_MULTIPLE,
  TestRunTypes.STOPPED,
  TestRunTypes.STOPPED_MULTIPLE,
  TestRunTypes.STEP_RUN_RESULT_UPDATED,
  TestRunTypes.STEP_RUN_WINDOW_SCREENSHOT_UPDATED,
  TestRunTypes.STEP_RUN_COVERING_ELEMENT_SCREENSHOT_UPDATED,
  SuiteRunTypes.UPDATED,
  SuiteRunTypes.STOPPED,
  SuiteRunTypes.STOPPED_MULTIPLE,
  ProjectTypes.UPDATED,
  ProjectTypes.CLONED,
  ProfileTypes.UPDATED,
  UserTypes.SET_SETTINGS,
  UserTypes.LOGGED_OUT,
  UserTypes.FLAGS_CHANGED,
  WebsocketTypes.SET_CHANNEL_NAME,
  CoreTypes.ERROR,
  CoreTypes.PONG,
  OrganizationTypes.SUBSCRIPTION_CHANGED,
  OrganizationTypes.SUBSCRIPTION_PAYMENT_FAILED,
];

export const filterWebsocketCommands = (type) => {
  if (!allowedCommands.includes(type)) {
    throw Error(`Missing ${type} in allowedCommands`);
  }
};

function createEventChannel(mySocket) {
  return eventChannel((emit) => {
    const onOpen = () => {
      emit(CoreActions.startHeartbeatRequested());
      emit(WebsocketActions.onopen());
    };
    const onError = (event) => logger.debug('Websocket error', event);
    const onMessage = (eventName, eventData) => {
      filterWebsocketCommands(eventName);
      const { data } = eventData;
      logger.debug('Emit', eventName, data);
      emit({ type: eventName, data });
    };
    const onClose = () => {
      emit(CoreActions.stopHeartbeatRequested());
      emit(END);
    };

    mySocket.on('connect', onOpen);
    mySocket.on('disconnect', onClose);
    mySocket.on('connect_error', onError);
    mySocket.onAny(onMessage);

    return () => {
      mySocket.close();
    };
  });
}
function* initializeWebSocketsChannel(token, organizationId) {
  try {
    const websocket = yield call(
      websocketConnection.createConnection,
      config.API_WS_URL,
      token,
      organizationId,
    );
    const channel = yield call(createEventChannel, websocket);
    while (true) {
      const message = yield take(channel);
      yield put(message);
      logger.debug('New message from WebSocket', message);
    }
  } finally {
    const currentToken = yield select(selectUserToken);
    const currentOrganizationId = yield select(selectCurrentOrganizationId);
    if (reconnect && currentToken && currentOrganizationId) {
      logger.debug('Websocket connection closed! Reconnecting...');
      yield delay(1000);
      yield put(WebsocketActions.connectRequested(token, currentOrganizationId, 'channel close'));
    } else {
      logger.debug('Closing websocket connection permanently.');
    }
  }
}

function* connectRequested({ token, currentOrganizationId, invokeSource }) {
  logger.debug('Connect requested by:', invokeSource);
  if (yield call(websocketConnection.isOpen)) {
    return;
  }
  yield call(initializeWebSocketsChannel, token, currentOrganizationId);
}

function* closeRequested() {
  if (yield call(websocketConnection.isOpen)) {
    yield call(websocketConnection.close);
  }
}

function* reconnectRequested({ token, currentOrganizationId }) {
  if (yield call(websocketConnection.isOpen)) {
    yield call(websocketConnection.close);
    yield call(websocketConnection.clearConnection);
  }

  yield put(WebsocketActions.connectRequested(token, currentOrganizationId, 'reconnect'));
  yield put(WebsocketActions.reconnectSucceeded());
}

function* sendRequested({ eventName, data }) {
  yield call(websocketConnection.sendMessage, eventName, data);
}

export default function* websocketSagas() {
  yield all([
    yield takeLatest(WebsocketTypes.CONNECT_REQUESTED, connectRequested),
    yield takeEvery(WebsocketTypes.SEND_REQUESTED, sendRequested),
    yield takeLatest(WebsocketTypes.CLOSE_REQUESTED, closeRequested),
    yield takeLatest(WebsocketTypes.RECONNECT_REQUESTED, reconnectRequested),
  ]);
}
