import difference from 'lodash/fp/difference';
import xor from 'lodash/fp/xor';

export { mapObjIndexed } from 'ramda';
/*
  Previously we were using single import, but Webpack loads lodash as amd module as well (lodash is always set to window._), so we have to ignore it,
  because then while loading content scripts - our lodash version will override the lodash/underscore used on client page.
  The bug is not happening in vite (forces es modules). It's a webpack/lodash specific issue.
  Issue: DEV-3219

  TODO: Enable swc options to convert lodash import/exports after upgrading swc to latest version
  https://modernjs.dev/builder/en/plugins/plugin-swc.html#extensionslodash
*/
export { xor, difference };
export { default as capitalize } from 'lodash/fp/capitalize';
export { default as complement } from 'lodash/fp/complement';
export { default as debounce } from 'lodash.debounce';
export { default as defaultTo } from 'lodash/fp/defaultTo';
export { default as differenceWith } from 'lodash/fp/differenceWith';
export { default as equals } from 'lodash/fp/equals';
export { default as get } from 'lodash/fp/get';
export { default as groupBy } from 'lodash/fp/groupBy';
export { default as has } from 'lodash/fp/has';
export { default as isEmpty } from 'lodash/fp/isEmpty';
export { default as isError } from 'lodash/fp/isError';
export { default as isEqual } from 'lodash/fp/isEqual';
export { default as isPlainObject } from 'lodash/fp/isPlainObject';
export { default as isString } from 'lodash/fp/isString';
export { default as join } from 'lodash/fp/join';
export { default as kebabCase } from 'lodash/fp/kebabCase';
export { default as keyBy } from 'lodash/fp/keyBy';
export { default as keys } from 'lodash/fp/keys';
export { default as mapValues } from 'lodash/fp/mapValues';
export { default as memoize } from 'lodash.memoize';
export { default as merge } from 'lodash/fp/merge';
export { default as omit } from 'lodash/fp/omit';
export { default as path } from 'lodash/fp/path';
export { default as pathOr } from 'lodash/fp/pathOr';
export { default as pick } from 'lodash/fp/pick';
export { default as pickBy } from 'lodash/fp/pickBy';
export { default as pipe } from 'lodash/fp/pipe';
export { default as prop } from 'lodash/fp/prop';
export { default as propEq } from 'lodash/fp/propEq';
export { default as propOr } from 'lodash/fp/propOr';
export { default as pull } from 'lodash/fp/pull';
export { default as range } from 'lodash/fp/range';
export { default as reverse } from 'lodash/fp/reverse';
export { default as sortBy } from 'lodash/fp/sortBy';
export { default as throttle } from 'lodash.throttle';
export { default as unset } from 'lodash/fp/unset';
export { default as values } from 'lodash/fp/values';
export { default as uniqBy } from 'lodash/fp/uniqBy';

export const noop = () => undefined;

export const identity = <T>(value: T) => value;

export const joinAllArgs = (...args: unknown[]): string => args.join('.');

export const removeArrayItem = <T>(state: T[], bit: T): T[] => difference(state, [bit]);

export const toggleArrayItem = <T>(state: T[], bit: T): T[] => xor(state, [bit]);
