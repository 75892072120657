import EmptyState from '@bugbug/core/components/EmptyState';
import Loader from '@bugbug/core/components/Loader';
import SearchInput from '@bugbug/core/components/SearchInput';
import { KEY_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { is, cond, equals } from 'ramda';
import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useSelector, useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import { Header, Content } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectSearchComponentsData,
  selectComponentsUsedInTest,
} from '~/modules/test/test.selectors';

import {
  Container,
  Results,
  Result,
  LoaderContainer,
  ErrorInfo,
} from './InsertComponentModal.styled';

const InsertComponentModal = ({ className, testId, atIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const searchInputRef = useRef();
  const resultsRef = useRef();
  const groups = useSelector(selectSearchComponentsData);
  const [query, setQuery] = useState('');
  const selectedComponentIndex = useRef();
  const usedComponents = useSelector(selectComponentsUsedInTest);

  const searchComponentsRequestState = useActionState(TestActions.searchComponentsRequest, {
    reset: false,
  });
  const insertGroupRequestState = useActionState(TestActions.insertGroupRequest, {
    onSuccess: modal.hide,
    reset: false,
  });

  const handleQueryChange = useCallback(
    (event) => {
      setQuery(event.target.value);
      dispatch(TestActions.searchComponentsRequest(event.target.value.toLowerCase()));
    },
    [dispatch],
  );

  useMount(() => {
    dispatch(TestActions.searchComponentsRequest(query.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query]);

  useUnmount(() => {
    insertGroupRequestState?.reset();
    searchComponentsRequestState?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query]);

  const refresh = useCallback(() => {
    insertGroupRequestState?.reset();
    searchComponentsRequestState?.reset();
    dispatch(TestActions.searchComponentsRequest(query.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testId, query]);

  const results = useMemo(
    () => groups.filter(({ name }) => (name || '').toLowerCase().includes(query.toLowerCase())),
    [groups, query],
  );

  const handleSubmit = useCallback(() => {
    if (is(Number, selectedComponentIndex.current)) {
      const group = results[selectedComponentIndex.current];
      const isUsed = usedComponents.includes(group.id);
      if (!isUsed) {
        dispatch(TestActions.insertGroupRequest(testId, group.id, atIndex));
      }
    }
  }, [testId, dispatch, atIndex, results, usedComponents]);

  const handleResultClick = useCallback(
    (index) => (event) => {
      selectedComponentIndex.current = index;
      if (!event.target.disabled) {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const handleNavigation = useCallback((key, event) => {
    const isDownPressed = key === KEY_BINDINGS.ARROW_DOWN;
    let nextElement;

    if (event.target === searchInputRef.current) {
      nextElement = resultsRef.current.children.item(0);
    } else {
      nextElement = isDownPressed
        ? event.target.nextElementSibling
        : event.target.previousElementSibling;
    }

    (nextElement || searchInputRef.current).focus();
    selectedComponentIndex.current = nextElement ? nextElement.value : null;
  }, []);

  const handleKeyEvent = cond([
    [equals(KEY_BINDINGS.ENTER), handleSubmit],
    [equals(KEY_BINDINGS.ARROW_DOWN), handleNavigation],
    [equals(KEY_BINDINGS.ARROW_UP), handleNavigation],
  ]);

  const renderResults = () => (
    <Results data-testid="InsertComponentModal.Results" ref={resultsRef}>
      {results.map(({ name, id }, index) => {
        const isUsed = usedComponents.includes(id);
        return (
          <Result
            value={index}
            tabIndex={index + 1}
            key={id}
            onClick={handleResultClick(index)}
            disabled={isUsed}
          >
            {name}
            {isUsed && <span>{t('insertComponentModal.inUsed', '(Already in use)')}</span>}
          </Result>
        );
      })}
    </Results>
  );

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ));

  const hasServerError =
    insertGroupRequestState.hasInternalServerError ||
    searchComponentsRequestState.hasInternalServerError;

  return (
    <Container className={className} data-testid="InsertComponentModal">
      <Header>{t('insertComponentModal.title', 'Insert existing component')}</Header>
      <Content>
        {hasServerError && <ErrorInfo isVisible onRetry={refresh} />}
        {!hasServerError &&
          !searchComponentsRequestState.isLoading &&
          !query &&
          !results.length && (
            <EmptyState
              isVisible
              inline
              text={t(
                'insertComponentModal.placeholder',
                "You don't have any components yet. Components are groups of steps that are shared across multiple tests. When you edit a component you change all related tests at once.",
              )}
            />
          )}
        {!hasServerError && (
          <KeyboardEventHandler
            handleKeys={[KEY_BINDINGS.ARROW_DOWN, KEY_BINDINGS.ARROW_UP, KEY_BINDINGS.ENTER]}
            onKeyEvent={handleKeyEvent}
          >
            <SearchInput ref={searchInputRef} onChange={handleQueryChange} fullWidth autoFocus />
            {renderResults(!!query && !!results.length && !searchComponentsRequestState.isLoading)}
            {query && !results.length && !searchComponentsRequestState.isLoading && (
              <EmptyState
                isVisible
                inline
                text={t('insertComponentModal.noData', 'No components were found')}
              />
            )}
            {renderLoader(searchComponentsRequestState.isLoading)}
          </KeyboardEventHandler>
        )}
      </Content>
    </Container>
  );
};

InsertComponentModal.defaultProps = {
  className: null,
  atIndex: undefined,
};

InsertComponentModal.propTypes = {
  className: PropTypes.string,
  atIndex: PropTypes.number,
  testId: PropTypes.string.isRequired,
};

export default InsertComponentModal;
