import { pathOr } from 'ramda';

import { selectLocation } from '~/modules/router.selectors';

const stringValueOf = (param) => (state) => {
  const location = selectLocation(state);

  return pathOr('', ['params', param], location);
};

export const selectImpersonateTokenFromUrl = stringValueOf('impersonate_token');
export const selectTestRunIdFromUrl = stringValueOf('testRunId');

export default selectTestRunIdFromUrl;
