import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { Step } from '@bugbug/core/types/steps';

type ComponentsState = {
  currentStep?: Step;
};

const initialState: ComponentsState = {};

const componentsSlice = createSlice({
  name: 'components',
  initialState,

  reducers: {
    resetCurrentStep(state) {
      state.currentStep = undefined;
    },

    setCurrentStep(state, action: PayloadAction<Step>) {
      state.currentStep = state.currentStep?.id !== action.payload.id ? action.payload : undefined;
    },
  },
});

export const ComponentsActions = componentsSlice.actions;

export default componentsSlice.reducer;
