import Icon from '@bugbug/core/components/Icon';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import useAppRoutes from '~/hooks/useAppRoutes';
import { selectSingleProject } from '~/modules/project/project.selectors';

import { ResolutionItem, Resolution, Settings } from './ScreenSizeDropdown.styled';

const ScreenSizeDropdown = ({ onSettingsClick = Function.prototype, ...props }) => {
  const { t } = useTranslation();
  const project = useSelector(selectSingleProject);
  const appRoutes = useAppRoutes('testsListEdit');
  const handleSettings = useCallback(
    (event) => {
      event.preventDefault();
      onSettingsClick();
      appRoutes.push('projectScreenSizes');
    },
    [appRoutes, onSettingsClick],
  );

  return (
    <Select {...props}>
      <SelectOption value={SCREEN_RESOLUTION_TYPE.DESKTOP}>
        <ResolutionItem>
          <Icon name="desktop" />
          {t('screenSizeDropdown.screenSizeType.desktop', 'Desktop')}
          <Resolution>
            {project.settings.browserWidth}x{project.settings.browserHeight}
          </Resolution>
        </ResolutionItem>
      </SelectOption>
      <SelectOption value={SCREEN_RESOLUTION_TYPE.MOBILE}>
        <ResolutionItem>
          <Icon name="mobile" />
          {t('screenSizeDropdown.screenSizeType.mobile', 'Mobile')}
          <Resolution>
            {project.settings.mobileBrowserWidth}x{project.settings.mobileBrowserHeight}
          </Resolution>
        </ResolutionItem>
      </SelectOption>
      <Settings onClick={handleSettings}>
        <Icon name="settingsSmaller" />
        {t('screenSizeDropdown.settings', 'Change default screen sizes')}
      </Settings>
    </Select>
  );
};

export default ScreenSizeDropdown;
