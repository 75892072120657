import { createActions, createReducer } from 'reduxsauce';

export const websocketPrefix = 'WEBSOCKET/';

export const { Types: WebsocketTypes, Creators: WebsocketActions } = createActions(
  {
    connectRequested: ['token', 'currentOrganizationId', 'invokeSource'],
    reconnectRequested: ['token', 'currentOrganizationId'],
    reconnectSucceeded: [],
    onopen: [],
    sendRequested: ['eventName', 'data'],
    closeRequested: [],
    setChannelName: ['data'],
  },
  { prefix: websocketPrefix },
);

export const INITIAL_STATE = {
  channelName: '',
};

const setChannelNameHandler = (state, action) => ({
  ...state,
  channelName: action.data.channelName,
});

export const reducer = createReducer(INITIAL_STATE, {
  [WebsocketTypes.SET_CHANNEL_NAME]: setChannelNameHandler,
});
