import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Code, { Snippet, Function, Argument, Line } from '~/components/Code';
import FormField from '~/components/FormField';

const CodeField = ({ functionArguments, description, ...props }) => {
  const { t } = useTranslation();

  const CodeWrapper = useMemo(
    () =>
      /* eslint-disable-next-line react/no-unstable-nested-components, react/display-name */
      ({ children }) =>
        (
          <Snippet>
            <Line>
              <Function>async function</Function>(
              {functionArguments.map((arg) => (
                <Argument key={arg}>{arg}</Argument>
              ))}
              {') {'}
            </Line>
            <Line>{children}</Line>
            <Line>{'}'}</Line>
          </Snippet>
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormField
      label={t('codeField.label', 'JavaScript code')}
      labelId="codeField"
      description={description}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Code {...props} Wrapper={CodeWrapper} aria-labelledby="codeField" />
    </FormField>
  );
};

CodeField.defaultProps = {
  className: null,
  functionArguments: [],
  ...Code.defaultProps,
};

CodeField.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  functionArguments: PropTypes.arrayOf(PropTypes.string),
  ...Code.propTypes,
};

export default CodeField;
