import { RUN_STATUS } from '@bugbug/core/constants/status';
import memoize from 'lodash.memoize';
import { propOr, prop, path, pathOr, pick, pipe, values, isEmpty, find, propEq } from 'ramda';
import { createSelector } from 'reselect';

import { selectTestRunIdFromUrl } from '~/modules/misc.selectors';
import { getScreenshotUrl } from '~/utils/misc';
import { getRelatedTest } from '~/utils/runs';

const selectTestRunDomain = (state) => state.testRun;

export const selectTestRunsMap = createSelector(selectTestRunDomain, propOr({}, 'testsRuns'));

export const selectIsFirstTestRun = createSelector(selectTestRunDomain, prop('isFirstTestRun'));

export const selectSingleTestRunId = createSelector(selectTestRunDomain, path(['single', 'id']));

export const selectTestRun = memoize(
  (testRunId, ignoreFallback) =>
    createSelector(
      selectTestRunsMap,
      selectTestRunIdFromUrl,
      selectSingleTestRunId,
      (testRuns, testRunIdFromUrl, singleTestRunId) => {
        if (ignoreFallback && !testRunId) {
          return {};
        }
        const id = testRunId || testRunIdFromUrl || singleTestRunId;
        return propOr({}, id, testRuns);
      },
    ),
  (testRunId, ignoreFallback) => `${testRunId}.${ignoreFallback}`,
);

export const selectSingleTestRun = createSelector(
  selectTestRunsMap,
  selectSingleTestRunId,
  (testRuns, singleTestRunId) => propOr({}, singleTestRunId, testRuns),
);

export const selectElementsScreenshots = createSelector(
  selectTestRunDomain,
  pathOr({}, ['elementsScreenshots']),
);

export const selectElementScreenshot = memoize((id) =>
  createSelector(selectElementsScreenshots, prop(id)),
);

export const selectSingleTestRunTestArchive = createSelector(
  selectTestRunDomain,
  selectSingleTestRun,
  (state, testRun) => {
    const relatedTestId = getRelatedTest(testRun);
    if (!relatedTestId || isEmpty(testRun)) {
      return {};
    }
    return state.results[state.single.id].tests[relatedTestId];
  },
);

export const selectTestRunGroups = createSelector(selectTestRunDomain, (state) =>
  pathOr({}, ['results', state.single.id, 'groups'], state),
);

export const selectTestRunGroupsIds = createSelector(selectTestRunGroups, (testRunGroups) =>
  Object.keys(testRunGroups),
);

export const selectTestRunSteps = createSelector(
  selectTestRunDomain,
  selectSingleTestRunId,
  (state, singleTestRunId) => pathOr(null, ['results', singleTestRunId, 'steps'], state),
);

export const selectTestRunStep = memoize((stepId) =>
  createSelector(selectTestRunSteps, propOr(null, [stepId])),
);

export const selectTestRunStatus = memoize((testRunId) =>
  createSelector(selectTestRun(testRunId), prop('status')),
);

export const selectTestRunGroup = (groupId) =>
  createSelector(selectTestRunGroups, propOr(null, groupId));

export const selectTestRunsOrder = createSelector(
  selectTestRunDomain,
  pathOr([], ['list', 'order']),
);

export const selectAddedTestRuns = createSelector(
  selectTestRunDomain,
  pathOr([], ['list', 'added']),
);

export const selectCurrentPageParams = createSelector(
  selectTestRunDomain,
  pick(['descOrder', 'sortBy', 'pagination', 'query']),
);

export const selectTestRunList = createSelector(
  selectTestRunsOrder,
  selectTestRunsMap,
  pipe(pick, values),
);

export const selectTestRunListPagination = createSelector(selectTestRunDomain, prop('pagination'));

export const selectTestRunResult = memoize((testRunId) =>
  createSelector(selectTestRunDomain, selectTestRunIdFromUrl, (state, testRunIdFromUrl) => {
    const id = testRunId || testRunIdFromUrl || state.single.id;
    return pathOr({}, ['results', id], state);
  }),
);

export const selectStepRuns = createSelector(selectTestRunResult(), (testRunResult) =>
  propOr({}, 'stepsRuns', testRunResult),
);

export const selectStepRun = memoize((stepId) =>
  createSelector(selectStepRuns, (testRunResult) => propOr({}, stepId, testRunResult)),
);

export const selectCurrentStepRun = createSelector(
  selectTestRunResult(),
  pipe(pathOr({}, ['stepsRuns']), values, find(propEq('status', RUN_STATUS.RUNNING))),
);

export const selectStepRunScreenshot = memoize((stepId) =>
  createSelector(selectStepRun(stepId), ({ screenshot }) => getScreenshotUrl(screenshot)),
);

export const selectStepRunExecutionUrl = memoize((stepId) =>
  createSelector(selectStepRun(stepId), ({ executionUrl }) => executionUrl),
);

export const selectFailedStepRunWithStep = createSelector(
  selectTestRun(),
  selectTestRunResult(),
  (testRun, testRunResult) => {
    if (![RUN_STATUS.FAILED, RUN_STATUS.ERROR].includes(testRun.status)) {
      return {};
    }

    const failedStepRunId = testRun.stepsRuns.find((stepRunId) =>
      [RUN_STATUS.FAILED, RUN_STATUS.ERROR].includes(testRunResult?.stepsRuns[stepRunId]?.status),
    );

    /*
      TODO: Verify incorrect state
      Failed test run without result object
      Related issue: DEV-3515
    */
    const failedStepRun = testRunResult?.stepsRuns?.[failedStepRunId];

    if (!failedStepRun) {
      return {};
    }

    const failedStep = testRunResult.steps[failedStepRun.stepId];

    if (!failedStep) {
      return {};
    }

    return {
      failedStepRun,
      step: failedStep,
    };
  },
);
