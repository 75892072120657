import Button from '@bugbug/core/components/Button';
import { useTranslation, Trans } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import type { Component } from '@bugbug/core/types/components';
import type { ComponentDetailsRouteParams } from '@bugbug/core/types/routes';
import { Header, Footer, WarningIcon } from '~/components/modals/Modal';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';

import * as S from './InvalidComponentNameModal.styled';

interface InvalidComponentNameModalProps {
  data: ComponentDetailsRouteParams & {
    name: Component['name'];
  };
}

const InvalidComponentNameModal = ({ data }: InvalidComponentNameModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const appRoutes = useAppRoutes('test');
  const { name } = data;

  const handleShowExistingComponent: MouseEventHandler<HTMLButtonElement> = () => {
    const url = appRoutes.getRouteUrl('component', data);
    window.open(url, '_blank');
  };

  return (
    <S.Container>
      <S.Content>
        <WarningIcon />
        <div>
          <Header>
            {t('invalidComponentNameModal.title', 'Component with the same name already exists')}
          </Header>

          <Trans i18nKey="invalidComponentNameModal.description" shouldUnescape>
            A component named "{{ name }}" already exists.
            <Button
              variant="primary"
              onClick={handleShowExistingComponent}
              iconName="openWindow"
              bordered
            >
              Show existing component
            </Button>
            You can't create another component with the same name. Before making the component,
            please <b>change your group name</b> to something unique.
          </Trans>
        </div>
      </S.Content>

      <Footer>
        <Button onClick={modal.hide}>{t('default.button.close')}</Button>
      </Footer>
    </S.Container>
  );
};
export default InvalidComponentNameModal;
