import i18n from '~/translations';

export const FORM_FIELD = {
  NAME: 'name',
  VARIABLES: 'variables',
  DEFAULT_VALUE: 'defaultValue',
  OVERRIDDEN_VALUE: 'value',
};

export const MULTI_VALUE_FORM_COLUMNS = [
  {
    label: i18n.t('editProfileModal.value.column.name', 'Variable name'),
    key: FORM_FIELD.NAME,
    isLabel: true,
  },
  {
    label: i18n.t('editProfileModal.value.column.default', 'Default value'),
    key: FORM_FIELD.DEFAULT_VALUE,
  },
  {
    label: i18n.t('editProfileModal.value.column.overriden', 'Overridden value'),
    key: FORM_FIELD.OVERRIDDEN_VALUE,
  },
];

export const MULTI_VALUE_FORM_COLUMNS_FOR_DEFAULT = MULTI_VALUE_FORM_COLUMNS.slice(0, -1);
