export const VARIABLE_TYPE = {
  EVALUATE: 'evaluate',
  ELEMENT: 'element',
  VALUE: 'value',
};

export const VARIABLE_GROUP = {
  SYSTEM: 'system',
  CUSTOM: 'custom',
};
