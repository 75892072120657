import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface Query<T = string> {
  [key: string]: T;
}

const useQueryString = <T = Partial<Query>,>(): T => {
  const { search } = useLocation();

  return useMemo(() => queryString.parse(search), [search]) as T;
};

export default useQueryString;
