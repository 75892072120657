import type { ProjectSettingsTab } from '@bugbug/core/types/projects';
import i18n from '~/translations';

export const PROJECT_SETTINGS_TAB: Record<string, ProjectSettingsTab> = {
  GENERAL: 'general',
  BROWSER: 'browser',
  SELECTORS: 'selectors',
  WAITING_CONDITIONS: 'waiting-conditions',
  SCREEN_SIZES: 'screen-sizes',
} as const;

export const PROJECT_SETTINGS_TAB_LABEL: Record<ProjectSettingsTab, string> = {
  general: i18n.t('projectSettings.tabs.general.label', 'General'),
  browser: i18n.t('projectSettings.tabs.browser.label', 'Browser'),
  selectors: i18n.t('projectSettings.tabs.selectors.label', 'Selectors'),
  'waiting-conditions': i18n.t(
    'projectSettings.tabs.waitingConditions.label',
    'Waiting conditions',
  ),
  'screen-sizes': i18n.t('projectSettings.tabs.screenSizes.label', 'Screen sizes'),
} as const;
