import type { AlertActionType } from '@bugbug/core/types/alerts';
import type { IconName } from '@bugbug/core/types/icons';
import i18n from '~/translations';

export const ACTIONS_DATA: Record<AlertActionType, { label: string; icon: IconName }> = {
  sendEmail: {
    label: i18n.t('editAlertModal.action.sendEmail.label', 'Send email notification'),
    icon: 'email',
  },
  sendWebhook: {
    label: i18n.t('editAlertModal.action.sendWebhook.label', 'Send webhook'),
    icon: 'webhook',
  },
  sendSlackMessage: {
    label: i18n.t('editAlertModal.action.sendSlackMessage.label', 'Send Slack message'),
    icon: 'slack',
  },
  sendTeamsMessage: {
    label: i18n.t('editAlertModal.action.sendTeamsMessage.label', 'Send Teams message'),
    icon: 'teams',
  },
};
