import { schema } from 'normalizr';

import { getProcessedSuiteRun } from '~/utils/runs';

const testRunSchema = new schema.Entity('testsRuns', {});

const suiteRunSchema = new schema.Entity(
  'suitesRuns',
  {
    testsRuns: [testRunSchema],
  },
  {
    processStrategy: (suiteRun) => ({
      ...suiteRun,
      ...getProcessedSuiteRun(suiteRun),
    }),
  },
);

export const suitesRunsListSchema = new schema.Array(suiteRunSchema);
export default suiteRunSchema;
