import Icon from '@bugbug/core/components/Icon';
import styled from 'styled-components';

export const Wrapper = styled.a`
  padding: 8px 16px;
  border: 2px solid ${(p) => p.theme.colors.border.semi};
  border-radius: 8px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.2s ease-in;

  &:hover {
    text-decoration: none;
    border-color: ${(p) => p.theme.colors.primary};
  }
`;

export const Title = styled.p`
  margin: 0;
  color: ${(p) => p.theme.colors.primary};
`;

export const Description = styled.p`
  margin: 0;
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const ExternalLinkIcon = styled(Icon).attrs({
  name: 'externalLink',
})`
  color: ${(p) => p.theme.colors.primary};
  height: 16px;
  width: 16px;
`;
