import Icon from '@bugbug/core/components/Icon';
import IconButton from '@bugbug/core/components/IconButton';
import { FONT_WEIGHT, COLOR, hexToRgba } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { propEq } from 'ramda';
import styled, { css } from 'styled-components';

import type { ModalSize } from '../modals.types';

import ServerErrorInfo from '~/components/ServerErrorInfo';

export const Backdrop = styled.div.attrs(() => ({
  className: 'backdrop',
}))<{ size: ModalSize }>`
  background-color: ${hexToRgba(COLOR.BLACK, 0.2)};
  overflow: auto;
  padding: 18vh 8px 30px;
  background-color: ${COLOR.DARK_GRAY_4};
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;

  ${styleWhenTrue(
    propEq('size', 'full-screen'),
    css`
      padding-top: 30px;
    `,
  )}

  ${styleWhenTrue(
    propEq('size', 'tutorial'),
    css`
      padding-top: 8vh;
    `,
  )}
`;

export const Container = styled.div<{ size: ModalSize }>`
  border-radius: 5px;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 14px 40px -9px ${COLOR.DARK_GRAY_5};
  position: relative;
  padding: 25px;
  min-width: 400px;
  pointer-events: all;
  display: flex;
  flex-direction: column;

  ${styleWhenTrue(
    propEq('size', 'wide'),
    css`
      max-width: 860px;
      min-width: 816px;
    `,
  )}

  ${styleWhenTrue(
    propEq('size', 'full-screen'),
    css`
      max-width: 1200px;
      min-width: 816px;
      height: 100%;
      width: 100%;
    `,
  )}

  ${styleWhenTrue(
    propEq('size', 'tutorial'),
    css`
      max-width: 820px;
      width: 100%;
    `,
  )}
`;

export const Header = styled.h3`
  font-size: 18px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  color: ${COLOR.BLACK};
  line-height: 24px;
  margin-top: -3px;
  margin-bottom: 0;
  padding-right: 37px;
  padding-bottom: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex: none;
`;

export const Content = styled.div`
  position: relative;
  padding-top: 7px;
  flex: 1;
`;

export const Footer = styled.div`
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: none;

  ${Content} + &, ${Content} & {
    border-top: 1px solid ${COLOR.GRAY_35};
    padding-top: 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;

  svg {
    width: 11px;
    height: 11px;
    min-width: auto;
  }
`;

export const ErrorInfo = styled(ServerErrorInfo).attrs(() => ({
  inline: true,
  hideRetryButton: true,
}))`
  position: relative;
`;

export const InfoIcon = styled(Icon).attrs(() => ({
  name: 'info',
}))`
  && {
    color: ${COLOR.GRAY_34};
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: -6px;
    margin-top: -4px;
  }
`;

export const HelpIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  && {
    color: ${COLOR.GRAY_34};
    min-width: 62px;
    height: 62px;
    margin-right: 20px;
    margin-top: -4px;
  }
`;

export const CheckCircleIcon = styled(Icon).attrs(() => ({
  name: 'checkCircle',
}))`
  && {
    fill: ${COLOR.SUCCESS};
    min-width: 64px;
    height: 64px;
    margin-right: 16px;
    margin-left: -1px;
    margin-top: -4px;
  }
`;

export const DeleteIcon = styled(Icon).attrs(() => ({
  name: 'delete',
}))`
  && {
    color: ${COLOR.GRAY_34};
    min-width: 62px;
    height: 62px;
    margin-right: 20px;
    margin-top: -4px;
  }
`;

export const WarningIcon = styled(Icon).attrs(() => ({
  name: 'errorTriangle',
}))`
  && {
    color: ${({ theme }) => theme.colors.accent};
    min-width: 48px;
    height: 48px;
    margin-right: 25px;
    margin-top: -4px;
  }
`;
