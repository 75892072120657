import { schema } from 'normalizr';
import { prop, omit } from 'ramda';

export const userSchema = new schema.Entity(
  'users',
  {
    idAttribute: prop('email'),
  },
  {
    processStrategy: omit('organizations'),
  },
);
