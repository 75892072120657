import 'react-toastify/dist/ReactToastify.css';

import IconComponent from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { ToastOptions } from 'react-toastify';

import { Container, IconContainer, CloseIcon, CloseButton, Content } from './Toast.styled';

export interface ToastProps {
  Icon?: React.ReactNode;
  onClose?: ToastOptions['onClose'];
  redirectUrl?: string;
  content: React.ReactNode;
}

const Toast = (props: ToastProps) => {
  const { Icon, onClose, redirectUrl, content } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const handleClick = () => {
    if (redirectUrl) {
      history.push(redirectUrl);
    }
  };

  const handleClose = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();
      onClose?.(props);
    },
    [onClose, props],
  );

  return (
    <Container data-testid="Toast" onClick={handleClick}>
      <IconContainer>{Icon ?? <IconComponent name="info" />}</IconContainer>
      <Content>{content}</Content>
      {onClose && (
        <CloseButton onClick={handleClose} aria-label={t('toast.closeButton', 'Close')}>
          <CloseIcon />
        </CloseButton>
      )}
    </Container>
  );
};

export default Toast;
