import * as T from '@bugbug/core/utils/toolbox';
import setWith from 'lodash/setWith';
import format from 'string-format';

import type { AxiosError } from 'axios';

import type { APIError, APIErrorResponse } from '@bugbug/core/types/api';
import i18n from '~/translations';

export const errorsMessages = {
  authorizationError: i18n.t('backendErrorsMap.authorizationError', 'Authorization error.'),
  userAlreadyExists: i18n.t(
    'backendErrorsMap.userAlreadyExists',
    'The user {email} is already registered in the system as a regular account.',
  ),
  userAlreadyExistsGoogle: i18n.t(
    'backendErrorsMap.userAlreadyExistsGoogle',
    'The user {email} is already connected via Google account.',
  ),
  userAlreadyExistsGithub: i18n.t(
    'backendErrorsMap.userAlreadyExistsGithub',
    'The user {email} is already connected via GitHub account.',
  ),
  userEmailAlreadyExists: i18n.t(
    'backendErrorsMap.userEmailAlreadyExists',
    'User is already registered with this e-mail address.',
  ),
  componentNameNotUnique: i18n.t(
    'backendErrorsMap.componentNameNotUnique',
    'This name is already used by another component in your project.',
  ),
  testNameNotUnique: i18n.t(
    'backendErrorsMap.testNameNotUnique',
    'This name is already used by another test in your project.',
  ),
};

const parseMessageAndParams = (error: APIError) => {
  const message: string = errorsMessages[error.code]
    ? format(errorsMessages[error.code], error.params || {})
    : error.message;

  return { message, params: error.params };
};

const getErrorsFromPlainObject = (extracted, errorItem, getMessage) => {
  const nonFieldErrors = 'nonFieldErrors';

  Object.keys(errorItem).forEach((errorKey) => {
    if (T.isPlainObject(errorItem[errorKey])) {
      getErrorsFromPlainObject(extracted, errorItem[errorKey], getMessage);
    } else {
      errorItem[errorKey].forEach((error: APIError) => {
        setWith(
          extracted,
          `${errorKey !== nonFieldErrors ? errorKey : error.code}`,
          getMessage(error),
          Object,
        );
      });
    }
  });
};

export const extractErrorLabelsAndParams = (
  errorResponse: AxiosError<APIErrorResponse> | APIErrorResponse | Record<string, APIError>[],
): Record<APIError['code'], ReturnType<typeof parseMessageAndParams>> | Record<string, never> => {
  const extracted: ReturnType<typeof extractErrorLabelsAndParams> = {};
  const responseData = T.isError(errorResponse)
    ? T.pathOr([], ['response', 'data'], errorResponse)
    : errorResponse;

  if (!Array.isArray(responseData)) {
    return {};
  }

  if (responseData[0] && responseData[0].code && T.isString(responseData[0].code)) {
    extracted[responseData[0].code] = parseMessageAndParams(responseData[0]);
    return extracted;
  }

  responseData.forEach((errorItem) => {
    if (T.isPlainObject(errorItem)) {
      getErrorsFromPlainObject(extracted, errorItem, parseMessageAndParams);
    }
  });

  return extracted;
};

const parseMessage = (error: APIError): string => {
  const message = errorsMessages[error.code]
    ? format(errorsMessages[error.code], error.params || {})
    : error.message;

  return message;
};

/**
 * @deprecated Use rtk-toolkit instead (it uses extractErrorLabelsAndParams under the hood)
 */
export const extractErrors = (
  errorResponse: AxiosError<APIErrorResponse> | APIErrorResponse | Record<string, APIError>[],
): Record<APIError['code'], ReturnType<typeof parseMessage>> | Record<string, never> => {
  const extracted: ReturnType<typeof extractErrors> = {};
  const responseData = T.isError(errorResponse)
    ? T.pathOr([], ['response', 'data'], errorResponse)
    : errorResponse;

  if (!Array.isArray(responseData)) {
    return {};
  }

  if (responseData[0] && responseData[0].code && T.isString(responseData[0].code)) {
    extracted[responseData[0].code] = parseMessage(responseData[0]);
    return extracted;
  }

  responseData.forEach((errorItem) => {
    if (T.isPlainObject(errorItem)) {
      getErrorsFromPlainObject(extracted, errorItem, parseMessage);
    }
  });

  return extracted;
};
