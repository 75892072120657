import queryString from 'query-string';
import { propOr } from 'ramda';
import { createSelector } from 'reselect';

export const selectRouterDomain = (state) => state.router;

export const selectLocation = createSelector(selectRouterDomain, (router) => {
  const location = propOr({}, 'location', router);
  const search = propOr('', 'search', location);
  return { ...location, params: queryString.parse(search) };
});
