import memoize from 'lodash.memoize';
import { cond, startsWith, always } from 'ramda';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import type { TooltipElementStyledProps } from './Tooltip.types';

import { COLOR } from '../../theme/colors';
import { THEME_MODE } from '../../theme/modes';

import { TOOLTIP_ARROW_SIZE, TOOLTIP_VARIANT } from './Tooltip.constants';

const getTooltipTransforms = memoize(
  (
    anchor: TooltipElementStyledProps['anchor'] = 'bottom-center',
    offset = 0,
  ): Record<string, string> =>
    cond([
      [
        startsWith('top'),
        always({
          container: `translate(0, -${TOOLTIP_ARROW_SIZE + offset}px)`,
          arrow: 'translateX(-50%)',
        }),
      ],
      [
        startsWith('bottom'),
        always({
          container: `translate(0, ${TOOLTIP_ARROW_SIZE + offset}px)`,
          arrow: 'translateX(-50%) scale(-1)',
        }),
      ],
      [
        startsWith('left'),
        always({
          container: `translate(-${TOOLTIP_ARROW_SIZE + offset}px, 0)`,
          arrow: 'translate(-25%, -50%) rotate(-90deg)',
        }),
      ],
      [
        startsWith('right'),
        always({
          container: `translate(${TOOLTIP_ARROW_SIZE + offset}px, 0)`,
          arrow: 'translate(25%, -50%) rotate(90deg)',
        }),
      ],
    ])(anchor),
);

const getContainerTransform = (props: Partial<TooltipElementStyledProps>): string =>
  getTooltipTransforms(props.anchor, props.offset).container;

const getArrowTransform = (props: Partial<TooltipElementStyledProps>): string =>
  getTooltipTransforms(props.anchor).arrow;

export const Container = styled.div<Partial<TooltipElementStyledProps>>`
  transform: ${getContainerTransform};
  max-width: 350px;
  z-index: 20;
`;

export const Trigger = styled.div`
  width: fit-content;
`;

const contentStyles = theme.variants('mode', 'variant', {
  [TOOLTIP_VARIANT.DARK]: {
    [THEME_MODE.LIGHT]: css`
      background-color: ${COLOR.DARK_GRAY_6};
      color: ${COLOR.WHITE};
      padding: 4px 14px 8px;
      line-height: 18px;
      border-radius: 4px;
    `,
  },
  [TOOLTIP_VARIANT.LIGHT]: {
    [THEME_MODE.LIGHT]: css`
      background-color: ${COLOR.WHITE};
      color: #9c9c9c;
      box-shadow: 0 7px 50px -3px rgba(47, 46, 55, 0.08), 0 4px 8px 0 rgba(47, 46, 55, 0.39);
      font-size: 12px;
      line-height: 14px;
      padding: 6px 8px 5px;
      border-radius: 5px;
      max-width: 160px;
    `,
  },
});

export const Content = styled.div`
  ${contentStyles}
`;

const arrowStyles = theme.variants('mode', 'variant', {
  [TOOLTIP_VARIANT.DARK]: {
    [THEME_MODE.LIGHT]: css`
      transform: ${getArrowTransform};
    `,
  },
  [TOOLTIP_VARIANT.LIGHT]: {
    [THEME_MODE.LIGHT]: css`
      display: none;
    `,
  },
});

export const ArrowContainer = styled.div<Partial<TooltipElementStyledProps>>`
  ${arrowStyles};
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${TOOLTIP_ARROW_SIZE}px ${TOOLTIP_ARROW_SIZE}px 0 ${TOOLTIP_ARROW_SIZE}px;
  border-color: ${COLOR.DARK_GRAY_6} ${COLOR.TRANSPARENT} ${COLOR.TRANSPARENT} ${COLOR.TRANSPARENT};
`;

export const PointerEventsCatcher = styled.div`
  pointer-events: all;

  > * {
    pointer-events: none;
  }
`;
