import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '../../../../theme';
import { IndeterminateIcon } from '../../../Checkbox/Checkbox.styled';

import * as S from './SelectOption.styled';

const SelectOption = (props) => {
  const {
    className,
    borderBottom,
    active,
    onClick,
    children,
    value = children,
    disabled,
    render,
    small,
    label,
    multiple,
  } = props;
  const content = children || label || value;
  const ariaLabel = label || value;
  const defaultActiveIcon = !multiple ? (
    <CheckIcon />
  ) : (
    <S.CheckmarkContainer small={small}>
      <S.CheckBoxIcon />
      <IndeterminateIcon />
    </S.CheckmarkContainer>
  );
  const defaultInactiveIcon = !multiple ? (
    <S.IconPlaceholder />
  ) : (
    <S.CheckmarkContainer small={small}>
      <IndeterminateIcon />
    </S.CheckmarkContainer>
  );
  const Icon = active ? defaultActiveIcon : defaultInactiveIcon;

  const renderDefault = () => (
    <>
      {Icon}
      <S.Wrapper>
        <span>{content}</span>
      </S.Wrapper>
    </>
  );

  return (
    <S.Option
      value={value}
      onClick={onClick}
      className={className}
      data-testid="SelectOption"
      disabled={disabled}
      active={active}
      aria-selected={active}
      aria-label={ariaLabel}
      small={small}
      borderBottom={borderBottom}
    >
      {render ? render(props, Icon) : renderDefault()}
    </S.Option>
  );
};

SelectOption.displayName = 'SelectOption';

SelectOption.defaultProps = {
  className: null,
  active: false,
  disabled: false,
  value: null,
  label: '',
  children: null,
  onClick: Function.prototype,
  small: true,
  render: null,
  multiple: false,
  borderBottom: false,
};

SelectOption.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  render: PropTypes.func,
  multiple: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

export default SelectOption;
