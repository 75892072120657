import * as R from 'ramda';
import spacetime from 'spacetime';

import type { DateInTimezone, TimezoneOption, TimezoneWithUTC } from './useTimezone.types';
import type { Timezone } from '../../types/geography';
import type { Spacetime } from 'spacetime';

import {
  NUMERIC_FORMAT,
  TIMEZONES_WITH_LABELS,
  USER_FRIENDLY_FORMAT,
} from './useTimezone.constants';

const uppercaseUTC = R.when<Timezone, TimezoneWithUTC>(R.equals<Timezone>('Utc'), R.always('UTC'));

const goto = (timezone: Timezone | TimezoneWithUTC): Spacetime =>
  spacetime.now('utc').goto(timezone);

export const getOffsetString = (tzDate: Spacetime): string => {
  const tzOffset = tzDate.format('{offset}');
  const offset = tzOffset === 'Z' ? '+0:00' : tzOffset.replace(/^(-|\+)?0?/, '$1');

  return `UTC${offset}`;
};

export const collectData = (tzDate: Spacetime, isUserTZFallback = false): DateInTimezone => {
  const offset = getOffsetString(tzDate);

  return {
    isUserTZFallback,
    numeric: `${tzDate.format(NUMERIC_FORMAT)} ${offset}`,
    userFriendly: `${tzDate.format(USER_FRIENDLY_FORMAT)} ${offset}`,
    utcOffset: offset,
  };
};

export const TIMEZONE_OPTIONS = (Object.entries(TIMEZONES_WITH_LABELS) as Array<[Timezone, string]>)
  .reduce((obj, [key, value]) => {
    const tzDate = goto(key);
    const tz = tzDate.timezone();

    const offsetString = getOffsetString(tzDate);
    const label = `(${offsetString}) ${value}`;

    obj.push({
      label,
      offset: tz.current.offset,
      value: uppercaseUTC(key) as TimezoneWithUTC,
    });

    return obj;
  }, [] as TimezoneOption[])
  .sort((a, b) => a.offset - b.offset);

export const TIMEZONES_VALUES = (Object.keys(TIMEZONES_WITH_LABELS) as Timezone[]).map(
  uppercaseUTC,
) as TimezoneWithUTC[];

export const getTimezoneOffset = R.pipe(goto, getOffsetString);
