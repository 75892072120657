import type { AnalyticsService, AnalyticsServiceMethods } from './types';

import ga from './ga';
import mixpanel from './mixpanel';

export * from './constants';

const trackingServices: AnalyticsService[] = [mixpanel, ga];

const callForEachService =
  <T extends keyof AnalyticsServiceMethods>(methodName: T) =>
  (...args: Parameters<AnalyticsServiceMethods[T]>) => {
    trackingServices.forEach((service) => {
      if (service.isEnabled && service[methodName]) {
        const method = service[methodName] as (
          ...a: Parameters<AnalyticsServiceMethods[T]>
        ) => void;
        method(...args);
      }
    });
  };

export default {
  setup: callForEachService('setup'),
  setUserProfile: callForEachService('setUserProfile'),
  initUserSession: callForEachService('initUserSession'),
  setGlobalEventData: callForEachService('setGlobalEventData'),
  trackEvent: callForEachService('trackEvent'),
  trackModalView: callForEachService('trackModalView'),
  clearSession: callForEachService('clearSession'),
};
