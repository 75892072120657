import type { AlertSlackMessageMethod } from '@bugbug/core/types/alerts';
import i18n from '~/translations';

interface SlackReceiver {
  label: string;
  value: AlertSlackMessageMethod;
  placeholder: string;
  leadingAdornment: string;
}

export const SLACK_RECEIVER: Record<AlertSlackMessageMethod, SlackReceiver> = {
  directMessage: {
    label: i18n.t('editAlertModal.slack.fields.name.directMessage.label', 'Recipient'),
    value: 'directMessage',
    placeholder: i18n.t(
      'editAlertModal.slack.fields.name.directMessage.placeholder',
      'Choose a user',
    ),
    leadingAdornment: i18n.t(
      'editAlertModal.slack.fields.name.directMessage.leadingAdornment',
      '@',
    ),
  },
  channelMessage: {
    label: i18n.t('editAlertModal.slack.fields.name.channelMessage.label', 'Channel name'),
    value: 'channelMessage',
    placeholder: i18n.t(
      'editAlertModal.slack.fields.name.channelMessage.placeholder',
      'Choose a channel',
    ),
    leadingAdornment: i18n.t(
      'editAlertModal.slack.fields.name.channelMessage.leadingAdornment',
      '#',
    ),
  },
};
