import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useActionState from '~/hooks/useActionState';
import { ExtensionActions } from '~/modules/extension/extension.redux';
import { selectExtensionSettings } from '~/modules/extension/extension.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';

const useExtensionState = () => {
  const incognitoWindowsPromiseResolve = useRef(Function.prototype);
  const incognitoWindowsPromiseId = useRef(uuid());
  const dispatch = useDispatch();
  const project = useSelector(selectSingleProject);
  const extensionSettings = useSelector(selectExtensionSettings);
  const hasIncognitoAccess = !!extensionSettings.isAllowedIncognitoAccess;
  const isIncognitoModeRequired = project.settings && !!project.settings.incognitoMode;
  const isDisabled = useMemo(
    () => !extensionSettings.isDetected || (!hasIncognitoAccess && isIncognitoModeRequired),
    [extensionSettings.isDetected, hasIncognitoAccess, isIncognitoModeRequired],
  );

  const { reset } = useActionState(ExtensionActions.getIncognitoWindowsStatusRequest, {
    reset: false,
    onSuccess: () => {
      incognitoWindowsPromiseResolve.current(true);
      reset();
    },
    onFailure: () => {
      incognitoWindowsPromiseResolve.current(false);
      reset();
    },
    reqId: incognitoWindowsPromiseId.current,
  });

  const hasActiveIncognitoWindows = useCallback(
    () =>
      new Promise((resolve) => {
        if (!project.settings.incognitoMode) {
          resolve(false);
        } else {
          incognitoWindowsPromiseResolve.current = resolve;
          dispatch(
            ExtensionActions.getIncognitoWindowsStatusRequest({
              reqId: incognitoWindowsPromiseId.current,
            }),
          );
        }
      }),
    [dispatch, project?.settings?.incognitoMode],
  );

  return {
    settings: extensionSettings,
    isDisabled,
    isConnected: !!extensionSettings.isDetected,
    isConnectedWithServer: extensionSettings.isConnectedWithServer,
    isActive: !isDisabled,
    hasIncognitoAccess,
    isPending: !!extensionSettings.isPending,
    hasActiveIncognitoWindows,
  };
};

export default useExtensionState;
