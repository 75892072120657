import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgActionFileDownloaded = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.92417 3.67417C5.27581 3.32254 5.75272 3.125 6.25 3.125H11.25C11.4158 3.125 11.5747 3.19085 11.6919 3.30806L15.4419 7.05806C15.5592 7.17527 15.625 7.33424 15.625 7.5V15C15.625 15.4973 15.4275 15.9742 15.0758 16.3258C14.7242 16.6775 14.2473 16.875 13.75 16.875H6.25C5.75272 16.875 5.27581 16.6775 4.92417 16.3258C4.57254 15.9742 4.375 15.4973 4.375 15V5C4.375 4.50272 4.57254 4.02581 4.92417 3.67417ZM6.25 4.375C6.08424 4.375 5.92527 4.44085 5.80806 4.55806C5.69085 4.67527 5.625 4.83424 5.625 5V15C5.625 15.1658 5.69085 15.3247 5.80806 15.4419C5.92527 15.5592 6.08424 15.625 6.25 15.625H13.75C13.9158 15.625 14.0747 15.5592 14.1919 15.4419C14.3092 15.3247 14.375 15.1658 14.375 15V7.75888L10.9911 4.375H6.25Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.25 3.125C11.5952 3.125 11.875 3.40482 11.875 3.75V6.875H15C15.3452 6.875 15.625 7.15482 15.625 7.5C15.625 7.84518 15.3452 8.125 15 8.125H11.25C10.9048 8.125 10.625 7.84518 10.625 7.5V3.75C10.625 3.40482 10.9048 3.125 11.25 3.125Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 9.375C10.3452 9.375 10.625 9.65482 10.625 10V13.75C10.625 14.0952 10.3452 14.375 10 14.375C9.65482 14.375 9.375 14.0952 9.375 13.75V10C9.375 9.65482 9.65482 9.375 10 9.375Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.375 14.0625V10H10.625V14.0625H9.375Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.3169 11.4341C12.561 11.6782 12.561 12.0739 12.3169 12.318L10.4419 14.193C10.1979 14.4371 9.80214 14.4371 9.55806 14.193C9.31398 13.9489 9.31398 13.5532 9.55806 13.3091L11.4331 11.4341C11.6771 11.1901 12.0729 11.1901 12.3169 11.4341Z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.68306 11.4341C7.43898 11.6782 7.43898 12.0739 7.68306 12.318L9.55806 14.193C9.80214 14.4371 10.1979 14.4371 10.4419 14.193C10.686 13.9489 10.686 13.5532 10.4419 13.3091L8.56694 11.4341C8.32286 11.1901 7.92714 11.1901 7.68306 11.4341Z", fill: "currentColor" })
] });
const ForwardRef = forwardRef(SvgActionFileDownloaded);
export default ForwardRef;
