import { RUN_STATUS } from '@bugbug/core/constants/status';
import produce from 'immer';
import { normalize } from 'normalizr';
import { omit, without, is, mergeDeepRight, path, pick, prop } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { PAGE_SIZE } from '~/modules/constans';
import { TestRunTypes } from '~/modules/testRun/testRun.redux';

import suiteRunSchema, { suitesRunsListSchema } from './suiteRun.schema';

export const { Types: SuiteRunTypes, Creators: SuiteRunActions } = createActions(
  {
    getSingleRequest: ['id'],
    getSingleSuccess: ['suiteRun'],
    getSingleFailure: ['error'],
    clearSingle: [],
    getListRequest: ['page', 'query', 'sortBy', 'descOrder'],
    getListSuccess: ['suiteRuns', 'query', 'sortBy', 'descOrder'],
    getListFailure: ['error'],
    removeRequest: ['ids'],
    removeSuccess: ['ids'],
    removeFailure: ['error'],
    stopRequest: ['ids'],
    stopSuccess: ['ids'],
    stopFailure: ['error'],
    reset: [],
    updateStatusSucceeded: ['suiteRunId', 'status'],
    removeTestRuns: ['suiteRunId', 'testRunIds'],
    addUpdated: ['data'],
    // from backend
    updated: ['data'],
    stopped: ['data'],
    stoppedMultiple: ['data'],
  },
  { prefix: 'SUITERUN/' },
);

export const INITIAL_STATE = {
  suitesRuns: {},
  suitesRunsList: {},
  testsRuns: {},
  single: {},
  list: {
    order: [],
    added: [],
  },
  query: '',
  descOrder: true,
  sortBy: '',
  pagination: {
    currentPage: 1,
    pageSize: PAGE_SIZE,
    totalCount: 0,
  },
};

const update = (state, action) =>
  produce(state, (draft) => {
    const {
      result: id,
      entities: { suitesRuns },
    } = normalize(omit(['testsRuns'], action.data), suiteRunSchema);

    if (!draft.suitesRuns[id]) {
      draft.list.added.push(id);
    }

    draft.suitesRuns[id] = mergeDeepRight(
      draft.suitesRuns[id],
      omit(['testsRuns'], suitesRuns[id]),
    );

    draft.suitesRunsList[id] = mergeDeepRight(draft.suitesRunsList[id] ?? {}, suitesRuns[id]);
  });

const getSingleSuccess = (state, action) =>
  produce(state, (draft) => {
    const {
      result: id,
      entities: { suitesRuns, testsRuns },
    } = normalize(action.suiteRun, suiteRunSchema);
    draft.single = { id };
    draft.suitesRuns[id] = suitesRuns[id];

    draft.testsRuns = {
      ...draft.testsRuns,
      ...testsRuns,
    };
  });

const getListSuccess = (state, { suiteRuns, query, sortBy, descOrder }) =>
  produce(state, (draft) => {
    const data = normalize(suiteRuns.results, suitesRunsListSchema);
    draft.list = {
      order: data.result,
      added: [],
    };

    /*
      Suite runs list is stored separately because its entities don't have a relation with a suite
      Suite run details remain stored in suitesRuns as Record<id, SuiteRun>
      Related issue: DEV-3118
    */
    draft.suitesRunsList = data.entities.suitesRuns ?? {};
    draft.query = query;
    draft.sortBy = sortBy;
    draft.descOrder = descOrder;
    draft.pagination.totalCount = suiteRuns.count;
    draft.pagination.currentPage = suiteRuns.page;
  });

const removeSuccess = (state, { ids }) =>
  produce(state, (draft) => {
    const removedSuiteRunsIds = ids || draft.list.order;
    draft.suitesRuns = omit(removedSuiteRunsIds, draft.suitesRuns);
    draft.list.order = without(removedSuiteRunsIds, draft.list.order);
    draft.pagination.totalCount -= removedSuiteRunsIds.length;
  });

const updateStatusSucceeded = (state, { suiteRunId, status }) =>
  produce(state, (draft) => {
    if (draft.suitesRuns[suiteRunId]) {
      draft.suitesRuns[suiteRunId].status = status;
      draft.suitesRunsList[suiteRunId].status = status;
    }
  });

const stopSuccess = (state, { ids }) =>
  produce(state, (draft) => {
    const suiteRunsIds = ids || draft.list.order;
    for (let index = 0; index < suiteRunsIds.length; index += 1) {
      const suiteRunId = suiteRunsIds[index];

      if (draft.suitesRuns[suiteRunId]) {
        draft.suitesRuns[suiteRunId].status = RUN_STATUS.STOPPED;
      }
    }
  });

const stopped = (state, action) => {
  const getId = prop('suiteRunId');
  const suiteRunsIds = (is(String, getId(action.data)) ? [action.data] : action.data)?.map(getId);
  return stopSuccess(state, {
    ids: suiteRunsIds.length > state.list.order.length ? null : suiteRunsIds,
  });
};

const clearSingle = (state) =>
  produce(state, (draft) => {
    draft.single = INITIAL_STATE.single;
  });

const removeTestRuns = (state, { ids }) =>
  produce(state, (draft) => {
    if (draft.single.id) {
      const removedTestRunsIds = ids || draft.suitesRuns[draft.single.id].testsRuns;

      draft.testsRuns = omit(removedTestRunsIds, draft.testsRuns);
      draft.suitesRuns[draft.single.id].testsRuns = without(
        removedTestRunsIds,
        draft.suitesRuns[draft.single.id].testsRuns,
      );
    }
  });

const updateTestRunStatus = (state, action) =>
  produce(state, (draft) => {
    if (draft.single.id) {
      const testRunId = path(['id'], action.data);

      if (draft.testsRuns[testRunId]) {
        draft.testsRuns[testRunId] = {
          ...draft.testsRuns[testRunId],
          ...pick(['status', 'duration', 'started', 'created'], action.data),
        };
      }
    }
  });

const stopTestRuns = (state, { ids }) =>
  produce(state, (draft) => {
    if (draft.single.id) {
      const testsRunsIds = ids || draft.suitesRuns[draft.single.id].testsRuns;

      for (let i = 0; i < testsRunsIds.length; i += 1) {
        const testRunId = testsRunsIds[i];
        draft.testsRuns[testRunId].status = RUN_STATUS.STOPPED;
      }
    }
  });

export const reducer = createReducer(INITIAL_STATE, {
  [SuiteRunTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [SuiteRunTypes.CLEAR_SINGLE]: clearSingle,
  [SuiteRunTypes.GET_LIST_SUCCESS]: getListSuccess,
  [SuiteRunTypes.REMOVE_SUCCESS]: removeSuccess,
  [SuiteRunTypes.STOP_SUCCESS]: stopSuccess,
  [SuiteRunTypes.ADD_UPDATED]: update,
  [SuiteRunTypes.STOPPED]: stopped,
  [SuiteRunTypes.STOPPED_MULTIPLE]: stopped,
  [SuiteRunTypes.UPDATE_STATUS_SUCCEEDED]: updateStatusSucceeded,
  [TestRunTypes.ADD_UPDATED]: updateTestRunStatus,
  //
  [TestRunTypes.STOP_SUCCESS]: stopTestRuns,
  [TestRunTypes.REMOVE_SUCCESS]: removeTestRuns,
});
