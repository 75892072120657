import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: sticky;
  width: 100%;
  height: 1px;
  box-shadow: 0 1px 3px 0px rgba(47, 46, 55, 0);
  margin-top: -1px;
  top: 0;
  flex: none;
  z-index: 1;
  transition: box-shadow 250ms ease-in-out;
  pointer-events: none;

  ${styleWhenTrue(
    prop('visible'),
    css`
      box-shadow: 0 1px 3px 0px rgba(47, 46, 55, 0.27);
    `,
  )}
`;
