import api from './common';

export const getList = (params) => api.get(`/suites/`, { params });

export const get = (id) => api.get(`/suites/${id}/`);

export const create = (data) => api.post('/suites/', data);

export const remove = (id) => api.delete(`/suites/${id}/`);

export const removeSelected = (data) => api.delete(`/suites/delete/`, { data });

export const removeAll = (data) => api.delete(`/suites/delete/`, { data });

export const update = (id, data) => api.patch(`/suites/${id}/`, data);

export const clone = (id) => api.post(`/suites/${id}/clone/`);

export const getTests = (params) => api.get('/tests/', { params });

export const run = (id, data) => api.post(`/suites/${id}/start-running/`, data);

export const runSelected = (data) => api.post('/suites/start-running/', data);

export const stop = (id, data) => api.post(`/suites/${id}/stop-running/`, data);

export const stopSelected = (data) => api.post('/suites/stop-running/', data);

export const stopAll = (data) => api.post('/suites/stop-running/', data);
