import { BroadcastChannel } from 'broadcast-channel';

export class Broadcaster {
  channel;

  channelName;

  constructor(channelName) {
    this.channelName = channelName;
  }

  connect = () => {
    if (this.channel) {
      this.close();
    }

    this.channel = new BroadcastChannel(this.channelName);
    return this;
  };

  onMessage = (callback) => {
    // eslint-disable-next-line func-names
    this.channel.onmessage = function (message) {
      callback(message);
    };
    return this;
  };

  emit = (message) => {
    this.channel.postMessage(message);
    return this;
  };

  close = () => {
    this.channel.close();
    return this;
  };
}

export const LogoutBroadcaster = new Broadcaster('logout');
