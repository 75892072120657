import api from './common';

export const getList = (params) => api.get('/groups/', { params });

export const searchComponents = (params) => api.get('/groups/search-component/', { params });

export const create = (data) => api.post('/groups/', data);

export const split = (id, data) => api.post(`/groups/${id}/split/`, data);

export const remove = (id) => api.delete(`/groups/${id}/`);

export const update = (id, data) => api.patch(`/groups/${id}/`, data);

export const rename = (id, name) => api.post(`/groups/${id}/rename/`, { name });

export const clone = (id, data) => api.post(`/groups/${id}/clone/`, data);

export const createStep = (id, data) => api.post(`/groups/${id}/steps/`, data);

export const removeUnconfirmed = (id) => api.delete(`/unconfirmed-groups/${id}/`);

export const confirmUnconfirmed = (id) => api.post(`/unconfirmed-groups/${id}/confirm/`);
