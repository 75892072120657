export const splitByCamelCase = (value: string): string[] =>
  value.replace(/([A-Z])/g, ' $1')?.split(' ');

export const camelCaseToTileCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const ellipsis = (text: string, limit = 120): string => {
  if (!text || text.length < limit + 1) {
    return text;
  }
  return `${text.substring(0, limit)}...`;
};
