import { Trans } from 'react-i18next';

import OrganizedList from '~/components/OrganizedList';

export const GitHubDetails = () => (
  <article>
    <p>
      <Trans i18nKey="integrationModal.details.github.introduction">
        <b>GitHub Action for BugBug Cloud</b> streamlines testing into your CI pipeline, providing
        real-time feedback within your repository. Get to know its most important features:
      </Trans>
    </p>
    <Trans i18nKey="integrationModal.details.github.list">
      <OrganizedList>
        <li>
          <b>Seamless Automation:</b> GitHub Actions allow for automated workflows directly within
          GitHub repositories. By integrating with BugBug Cloud, you can seamlessly incorporate
          testing into your CI pipeline.
        </li>
        <li>
          <b>Real-time Feedback:</b> Receive instant feedback on test results directly within your
          GitHub repository. This immediate feedback loop enables developers to quickly identify and
          address issues, leading to faster iterations and more reliable software releases.
        </li>
        <li>
          <b>Utilization of BugBug Cloud:</b> Extensive testing capabilities without the hassle of
          setting up and managing additional infrastructure. This means developers can focus more on
          writing code and less on configuring testing environments.
        </li>
        <li>
          <b>Scalability:</b> Allow developers to execute tests across multiple environments and
          configurations effortlessly.
        </li>
        <li>
          <b>Consistent Testing Environment:</b> Ensure that tests are executed in a consistent and
          controlled environment.
        </li>
      </OrganizedList>
    </Trans>
    <p>
      <Trans i18nKey="integrationModal.details.github.summary">
        By seamlessly integrating testing into the CI/CD process, developers can ensure that only
        high-quality, thoroughly tested code is deployed to production environments, reducing the
        risk of introducing regressions or bugs.
      </Trans>
    </p>
  </article>
);
