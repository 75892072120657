import { createApi } from '@reduxjs/toolkit/query/react';

import type { Component } from '@bugbug/core/types/components';
import type { Group } from '@bugbug/core/types/groups';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Group' as const;
export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/groups/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // PATCH /groups/<id>/
    switchToComponent: builder.mutation<Component, Group['id']>({
      query: (id) => ({
        url: `${id}/`,
        method: 'PATCH',
        data: {
          isComponent: true,
        },
      }),
    }),
  }),
});

export const { useSwitchToComponentMutation } = groupsApi;
