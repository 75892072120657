import Icon from '@bugbug/core/components/Icon';
import { LoaderFlexContainer as LoaderFlexContainerBase } from '@bugbug/core/components/Loader';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import { Content as ContentBase } from '~/components/modals/Modal';
import StepperBase from '~/components/Stepper';

export const Container = styled.div`
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Headline = styled.h2`
  margin-bottom: 16px;
  color: ${COLOR.BLACK};
  font-size: 16px;
  line-height: 1.5em;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  text-align: center;
`;

export const Description = styled.p`
  margin-bottom: 30px;
  text-align: center;
`;

export const Stepper = styled(StepperBase)`
  && {
    max-width: 450px;
    width: 100%;
    height: auto;
    padding: 16px 0 20px;
    margin-bottom: 26px;
  }
`;

export const Content = styled(ContentBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 33px;
`;

export const StoreIcon = styled(Icon).attrs(() => ({
  name: 'chromeStore',
}))`
  margin-top: 24px;
  margin-bottom: 18px;
  width: 80px;
`;

export const CheckIcon = styled(Icon).attrs(() => ({
  name: 'checkCircle',
}))`
  color: ${COLOR.SUCCESS};
  width: 96px;
  height: 64px;
  margin-bottom: 10px;
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'externalLink',
}))`
  width: 18px;
  margin-bottom: 2px;
  margin-left: 4px;
`;

export const Image = styled.img`
  margin-top: 32px;
  align-self: center;
  overflow: hidden;
`;

export const LoaderFlexContainer = styled(LoaderFlexContainerBase)`
  margin-top: 35px;

  p {
    margin-top: 16px;
  }
`;
