import type { ScheduleFormValues } from './EditScheduleModal.types';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Project } from '@bugbug/core/types/projects';
import type { Schedule, ScheduleTimeUnit, ScheduleTimer } from '@bugbug/core/types/schedules';
import { convertHourValueBetweenTimezones } from '~/utils/dates';

import { ScheduleSchema } from './EditScheduleModal.schema';

export const getDefaultTimerConfig = (
  timeUnit: ScheduleTimeUnit,
): Omit<ScheduleTimer, 'timezone'> => {
  const randomHour = Math.floor(Math.random() * 23).toString();
  const randomDayOfMonth = Math.floor(1 + Math.random() * 27).toString();
  const randomDayOfWeek = Math.floor(1 + Math.random() * 4).toString();

  const defaultConfigs: Record<ScheduleTimeUnit, Omit<ScheduleTimer, 'timezone'>> = {
    everyMinute: {
      minute: '*/1',
      dayOfWeek: '*',
      dayOfMonth: '*',
      hour: '*',
    },
    everyFiveMinutes: {
      minute: '*/5',
      dayOfWeek: '*',
      dayOfMonth: '*',
      hour: '*',
    },
    hourly: {
      minute: '0',
      dayOfWeek: '*',
      dayOfMonth: '*',
      hour: '*',
    },
    daily: {
      minute: '0',
      dayOfWeek: '*',
      dayOfMonth: '*',
      hour: randomHour,
    },
    weekly: {
      minute: '0',
      dayOfWeek: randomDayOfWeek,
      dayOfMonth: '*',
      hour: randomHour,
    },
    monthly: {
      minute: '0',
      dayOfWeek: '*',
      dayOfMonth: randomDayOfMonth,
      hour: randomHour,
    },
  };

  return defaultConfigs[timeUnit];
};

export const getScheduleRequestData = (
  formValues: ScheduleFormValues,
): Omit<Schedule, 'description' | 'created' | 'lastRun' | 'user' | 'id'> => ({
  ...formValues,
  hour:
    formValues.hour !== '*'
      ? convertHourValueBetweenTimezones(formValues.hour, formValues.timezone, 'Utc')
      : formValues.hour,
});

export const getScheduleFormValues = (
  project: Project,
  currentProfile: Profile,
  schedule?: Schedule,
): ScheduleFormValues => {
  const defaults = getDefaultTimerConfig('weekly');
  const sourceHour = schedule?.hour || defaults.hour;
  const hour =
    sourceHour !== '*'
      ? convertHourValueBetweenTimezones(sourceHour, 'Utc', project.settings.timezone)
      : sourceHour;

  return {
    ...ScheduleSchema.cast({
      ...getDefaultTimerConfig('weekly'),
      ...schedule,
      hour,
      suites: schedule?.suites,
      name: schedule?.name,
      runProfileId: currentProfile.id,
      project: project.id,
      timezone: project.settings.timezone,
    }),
  };
};
