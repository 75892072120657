import api from './common';

const baseUrl = '/run-profiles/';

export const getList = (params) => api.get(baseUrl, { params });

export const create = (data) => api.post(baseUrl, data);

export const update = (id, data) => api.patch(`${baseUrl}${id}/`, data);

export const remove = (id) => api.delete(`${baseUrl}${id}/`);
