import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgBreakpoint = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 13.04, height: 51.33, viewBox: "0 0 13.04 51.33", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "breakpoint-ding" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "Layer_1-2", "data-name": "Layer 1", children: /* @__PURE__ */ jsx("polygon", { points: "7 0 0 25.67 7 51.33 13.04 51.33 13.04 0 7 0", style: {
    fill: "#ffcd1c"
  } }) }) })
] });
const ForwardRef = forwardRef(SvgBreakpoint);
export default ForwardRef;
