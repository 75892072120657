import * as Yup from 'yup';

import type { EditAlertFormValues } from './EditAlertFormValues.types';

import {
  VALIDATION_MESSAGE,
  optionsListValidator,
  urlWithVariablesValidator,
} from '~/utils/validators';

import { ALERT_ACTIONS, REQUEST_METHODS } from './EditAlertModal.constants';

const sharedRules = {
  id: Yup.string().optional(),
  projectId: Yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  isActive: Yup.bool(),
  event: Yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  status: optionsListValidator,
  runMode: optionsListValidator,
  triggeredBy: optionsListValidator,
  objects: optionsListValidator,
  runProfiles: optionsListValidator,
  action: Yup.string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .oneOf(Object.values(ALERT_ACTIONS).map(({ value }) => value))
    .default('sendEmail'),
};

const EmailConfigSchema = Yup.object<EditAlertFormValues>().shape({
  ...sharedRules,
  users: Yup.array().min(1, VALIDATION_MESSAGE.REQUIRED),
  recipientType: Yup.string().oneOf(['all', 'selected']).default('all'),
  extraEmails: Yup.string().test({
    name: 'emails',
    test(value) {
      if (!value) {
        return true;
      }
      const areEmailsValid = value
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email)
        .every((email) => Yup.string().email().isValidSync(email));
      return (
        !!areEmailsValid ||
        this.createError({
          message: VALIDATION_MESSAGE.EMAIL,
        })
      );
    },
  }),
});

const WebhookConfigSchema = Yup.object<EditAlertFormValues>().shape({
  ...sharedRules,
  method: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).oneOf(REQUEST_METHODS).default('POST'),
  url: urlWithVariablesValidator.ensure(),
  body: Yup.string().optional().ensure(),
});

const SlackConfigSchema = Yup.object<EditAlertFormValues>().shape({
  ...sharedRules,
  method: Yup.string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .oneOf(['directMessage', 'channelMessage'])
    .default('channelMessage'),
  name: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).ensure(),
  channelId: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).ensure(),
});

const TeamsConfigSchema = Yup.object<EditAlertFormValues>().shape({
  ...sharedRules,
  teamName: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).ensure(),
  name: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).ensure(),
  channelId: Yup.string().required(VALIDATION_MESSAGE.REQUIRED).ensure(),
});

const schemasByActionType: Record<EditAlertFormValues['action'], Yup.AnyObjectSchema> = {
  sendEmail: EmailConfigSchema,
  sendWebhook: WebhookConfigSchema,
  sendSlackMessage: SlackConfigSchema,
  sendTeamsMessage: TeamsConfigSchema,
};

export default schemasByActionType;
