import { useCallback } from 'react';

import type { SideEffect } from '../../../../types/utils';
import type { SelectOptionItem } from '../../Select.types';
import type { MouseEventHandler } from 'react';

import * as S from './SelectOptionChipsRow.styled';

interface SelectOptionChipsRowProps {
  onDelete: SideEffect<SelectOptionItem>;
  item: SelectOptionItem;
}

const SelectOptionChipsRow = ({ onDelete, item }: SelectOptionChipsRowProps) => {
  const handleClick = useCallback<MouseEventHandler<SVGGElement>>(
    (event) => {
      event.stopPropagation();

      onDelete(item);
    },
    [onDelete, item],
  );

  return (
    <S.Container key={item.value}>
      {item.label || item.value} <S.RemoveIcon onClick={handleClick} />
    </S.Container>
  );
};

export default SelectOptionChipsRow;
