import type { IntegrationError } from '@bugbug/core/types/integrations';
import i18n from '~/translations';

export const INTEGRATION_ERROR_MESSAGE: Record<IntegrationError | 'DEFAULT', string> = {
  INTEGRATION_DOES_NOT_EXIST: i18n.t(
    'integrations.errors.integrationDoesNotExist',
    'Integration seems to be deleted on the 3rd party provider. Please reconnect it to continue using it.',
  ),
  DEFAULT: i18n.t(
    'integrations.errors.default',
    'Something went wrong with the integration. Please reconnect it to continue using it.',
  ),
} as const;
