import { useTranslation } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import Tooltip from '../Tooltip';

import * as S from './PaidFeature.styled';

interface PaidFeatureProps {
  className?: string;
  children: (isDisabled: boolean) => JSX.Element;
  onUpgradeClick?: MouseEventHandler<SVGSVGElement>;
  freePlan?: boolean;
}

const PaidFeature = ({ className, freePlan, onUpgradeClick, children }: PaidFeatureProps) => {
  const { t } = useTranslation();

  return freePlan ? (
    <S.PaidFeatureContainer className={className}>
      <Tooltip
        content={t('paidFeature.tooltip.message', 'You need to upgrade to use this feature')}
      >
        {children(freePlan)}
      </Tooltip>
      <S.UpgradeIcon onClick={onUpgradeClick} />
    </S.PaidFeatureContainer>
  ) : (
    children(!!freePlan)
  );
};

export default PaidFeature;
