/* eslint-disable react/no-unescaped-entities */
import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import PropTypes from 'prop-types';
import { cond, equals, omit } from 'ramda';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { Step, StepLabel } from '~/components/Stepper';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { useTrackCustomPageView } from '~/hooks/useTrackCustomPageView';
import config from '~/modules/config';
import { QUERY_ACTION } from '~/modules/constans';
import { ExtensionActions } from '~/modules/extension/extension.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { setCookie } from '~/utils/cookies';
import { reverse } from '~/views/urls';

import { ASSETS, EXTENSION_STEPS, EXTENSION_STEPS_LIST } from './MissingExtensionModal.constants';
import {
  Container,
  Stepper,
  Content,
  Headline,
  Description,
  StoreIcon,
  CheckIcon,
  LinkIcon,
  Image,
  LoaderFlexContainer,
} from './MissingExtensionModal.styled';

const MissingExtensionModal = ({ className }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { isConnected, hasIncognitoAccess, isPending } = useExtensionState();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useQueryString();
  const [active, setActive] = useState(0);

  useMount(() => {
    history.replace(
      reverse(
        history.location.pathname,
        {},
        {
          ...queryParams,
          action: QUERY_ACTION.INSTALL_EXTENSION,
        },
      ),
    );
  });

  const setActionInitiatorCookie = useCallback(() => {
    setCookie({
      name: 'bugbugExtSetupInitiator',
      value: window.location.href,
      expiresInHours: 0.5,
    });
  }, []);

  const trackCustomPageView = useTrackCustomPageView();

  useLayoutEffect(() => {
    if (!isPending && isConnected && hasIncognitoAccess) {
      setActive(EXTENSION_STEPS.SUCCESS.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.EXTENSION_SUCCESS);
      trackCustomPageView('Ready Extension Page');
    } else if (!isPending && isConnected && !hasIncognitoAccess) {
      setActive(EXTENSION_STEPS.INCOGNITO.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.MODAL_VIEW_EXT_INCOGNITO);
      trackCustomPageView('Enable Incognito Page');
    } else if (!isPending) {
      setActive(EXTENSION_STEPS.INSTALL.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.MODAL_VIEW_EXT_INSTALL);
      trackCustomPageView('Install Extension Page');
    } else {
      setActive(null);
    }
  }, [isConnected, isPending, hasIncognitoAccess, setActionInitiatorCookie, trackCustomPageView]);

  useUpdateEffect(() => {
    if (!isPending && [EXTENSION_STEPS.INCOGNITO.id, EXTENSION_STEPS.INSTALL.id].includes(active)) {
      setActionInitiatorCookie();
    }
  }, [active, isPending]);

  useUnmount(() => {
    history.replace(reverse(history.location.pathname, {}, omit(['action'], queryParams)));
  });

  const handleGoToStore = () => {
    window.open(config.EXTENSION_URL);
  };

  const handleGoToSettings = () => {
    dispatch(ExtensionActions.openSettingsRequested());
  };

  const renderStep = cond([
    [
      equals(EXTENSION_STEPS.INSTALL.id),
      () => (
        <>
          <StoreIcon />
          <Headline>
            <Trans i18nKey="missingExtensionModal.install.headline">
              To record or run tests you need to install BugBug Chrome Extension
            </Trans>
          </Headline>
          <Description>
            <Trans i18nKey="missingExtensionModal.install.description">
              After installing go back to this tab. We will automatically recognize when the
              extension is installed.
            </Trans>
          </Description>
          <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleGoToStore}>
            {t('missingExtensionModal.install.button', 'Go to Chrome Web Store')}
            <LinkIcon />
          </Button>
        </>
      ),
    ],
    [
      equals(EXTENSION_STEPS.INCOGNITO.id),
      () => (
        <>
          <Headline>
            <Trans i18nKey="missingExtensionModal.incognito.headline">
              Please enable "Allow in incognito" in the extension details
            </Trans>
          </Headline>
          <Description>
            <Trans i18nKey="missingExtensionModal.incognito.description">
              BugBug runs tests in an incognito window to safely clear cookies, cache and session
              before each test. <br />
              Go to BugBug <Link onClick={handleGoToSettings}>extension settings</Link>, scroll down
              and enable "Allow in incognito".
            </Trans>
          </Description>
          <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleGoToSettings}>
            {t('missingExtensionModal.incognito.button', 'Go to extension settings')}
            <LinkIcon />
          </Button>
          <Image src={ASSETS.IMAGE.src} alt={ASSETS.IMAGE.alt} />
        </>
      ),
    ],
    [
      equals(EXTENSION_STEPS.SUCCESS.id),
      () => (
        <>
          <CheckIcon />
          <Headline>
            {t('missingExtensionModal.success.headline', 'BugBug Extension is ready')}
          </Headline>
          <Description>
            {t(
              'missingExtensionModal.success.description',
              'Now you can record and run automated tests.',
            )}
          </Description>
          <Button variant={BUTTON_VARIANT.PRIMARY} onClick={modal.hide}>
            {t('default.button.continue')}
          </Button>
        </>
      ),
    ],
  ]);

  return (
    <Container
      className={className}
      aria-label={t('missingExtensionModal.ariaLabel', 'Install the extension modal')}
    >
      <Content>
        {!isPending ? (
          <>
            <Stepper activeStep={active}>
              {EXTENSION_STEPS_LIST.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.completed ? step.label : step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStep(active)}
          </>
        ) : (
          <LoaderFlexContainer>
            <Loader />
            <p>
              {!isConnected
                ? t('missingExtensionModal.loader.waitingForExt', 'Waiting for browser extension')
                : t(
                    'missingExtensionModal.loader.waitForServer',
                    'The extension is trying to connect to the server',
                  )}
            </p>
          </LoaderFlexContainer>
        )}
      </Content>
    </Container>
  );
};

MissingExtensionModal.defaultProps = {
  className: null,
};

MissingExtensionModal.propTypes = {
  className: PropTypes.string,
};

export default MissingExtensionModal;
