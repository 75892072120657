/* eslint-disable @typescript-eslint/no-explicit-any */
import { always, equals, ifElse } from 'ramda';

import type { Arity0Fn, Pred, Arity1Fn } from 'ramda';
import type { ThemedStyledProps } from 'styled-components';

/**
 * @deprecated Use regular js condition instead (eg. `condition && <Comp/>`)
 */
export const renderWhen = (pred: Pred, fn: Arity0Fn): Arity1Fn => ifElse(pred, fn, always(null));

/**
 * @deprecated Use regular js condition instead (eg. `condition && <Comp/>`)
 */
export const renderWhenTrue = (fn: Arity0Fn): Arity1Fn => renderWhen(equals(true), fn);

/**
 * @deprecated Use regular js condition instead (eg. `condition && <Comp/>`)
 */
export const renderWhenTrueOtherwise = (fn: Arity0Fn, otherwise: Arity0Fn): Arity1Fn =>
  ifElse(equals(true) as Pred, fn, otherwise);

/**
 * @deprecated Use regular js condition instead (eg. `condition && <Comp/>`)
 */
export const styleWhenTrue =
  (pred: Pred, style: any) => (props: ThemedStyledProps<unknown, unknown>) =>
    renderWhen(equals(true) as (...args: any) => boolean, always(style))(pred(props));
