import i18n from '~/translations';
import { getRelatedSuite } from '~/utils/runs';
import { urlValidator } from '~/utils/validators';
import urls, { reverse } from '~/views/urls';

import toasts from './common';

const getRedirectUrl = (suiteRun, organizationId) => {
  const suite = getRelatedSuite(suiteRun);
  const url = reverse(urls.suiteRun, {
    projectId: suite?.projectId,
    projectSlug: suite?.projectSlug,
    suiteRunId: suiteRun.id,
    organizationId,
  });

  return urlValidator.isValidSync(`${window.location.href}${url}`) ? url : '';
};

export const showSuiteStarted = (suiteRun, organizationId) => {
  const redirectUrl = getRedirectUrl(suiteRun, organizationId);
  const content = i18n.t('notifications.suite.started', 'Suite started #{{ sequence }}', {
    sequence: suiteRun.sequence,
  });

  toasts.show({ content, redirectUrl });
};

export const showSuitesStarted = (suitesIds) => {
  toasts.show({
    content: i18n.t('notifications.suites.multiple.started', '{{count}} suites started', {
      count: suitesIds.length,
    }),
  });
};

export const showSuitesStopped = (suiteRunsIds) => {
  const content = !suiteRunsIds
    ? i18n.t('notifications.suites.allStopped', 'All suite runs have been stopped')
    : i18n.t('notifications.suites.stopped', {
        defaultValue_one: 'Suite run has been stopped',
        defaultValue_other: '{{count}} suite runs have been stopped',
        count: suiteRunsIds.length,
      });
  const id = suiteRunsIds ? suiteRunsIds.join('.') : 'all';

  toasts.show({ content, id });
};

export const showSuitePassed = (suiteRun, organizationId) => {
  const redirectUrl = getRedirectUrl(suiteRun, organizationId);
  const content = i18n.t('notifications.suites.passed', 'Suite passed #{{ sequence }}', {
    sequence: suiteRun.sequence,
  });

  toasts.showSuccess({ content, redirectUrl });
};

export const showSuiteFailed = (suiteRun, organizationId) => {
  const redirectUrl = getRedirectUrl(suiteRun, organizationId);
  const content = i18n.t('notifications.suites.failed', 'Suite failed #{{ sequence }}', {
    sequence: suiteRun.sequence,
  });

  toasts.showError({ content, redirectUrl });
};

export const showSuiteError = (suiteRun, organizationId) => {
  const redirectUrl = getRedirectUrl(suiteRun, organizationId);
  const content = i18n.t('notifications.suites.error', 'Suite error #{{ sequence }}', {
    sequence: suiteRun.sequence,
  });

  toasts.showError({ content, redirectUrl });
};
