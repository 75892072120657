import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCart = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", width: 20, height: 20, viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("circle", { cx: 9, cy: 21, r: 2 }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("circle", { cx: 20, cy: 21, r: 2 }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { d: "M9.6,17c-1.4,0-2.6-1-2.9-2.4L5,6.2c0,0,0-0.1,0-0.1L4.2,2H1C0.4,2,0,1.6,0,1s0.4-1,1-1h4c0.5,0,0.9,0.3,1,0.8L6.8,5H23 c0.3,0,0.6,0.1,0.8,0.4C24,5.6,24,5.9,24,6.2l-1.6,8.4c-0.3,1.4-1.6,2.4-3,2.4L9.6,17C9.7,17,9.6,17,9.6,17z M7.2,7l1.4,7.2 c0.1,0.5,0.5,0.8,1,0.8h9.7c0.5,0,0.9-0.3,1-0.8L21.8,7H7.2z" }) })
] });
const ForwardRef = forwardRef(SvgCart);
export default ForwardRef;
