import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import * as Yup from 'yup';

import { Header, Content, Footer, ErrorInfo } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { ProjectActions } from '~/modules/project/project.redux';
import { addProtocolWhenNotExists } from '~/utils/url';
import * as validators from '~/utils/validators';

import { FormField } from './ProjectModal.styled';

const NewProjectSchema = Yup.object().shape({
  name: validators.nameValidator.max(
    DATA_RESTRICTIONS.PROJECT_NAME_MAX_LENGTH,
    validators.VALIDATION_MESSAGE.MAX_LENGTH,
  ),
  homepageUrl: validators.urlValidator.optional().nullable(),
});

const ProjectModal = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const isEditMode = !!project.id;

  const labels = useMemo(() => {
    if (isEditMode) {
      return {
        title: t('projectModal.edit.title', 'Edit project'),
        submitButton: t('projectModal.edit.submitButton', 'Update'),
      };
    }
    return {
      title: t('projectModal.new.title', 'New project'),
      submitButton: t('projectModal.new.submitButton', 'Create project'),
    };
  }, [t, isEditMode]);

  const handleAddNewProject = useCallback(
    (values) => {
      dispatch(ProjectActions.addRequest(values));
    },
    [dispatch],
  );

  const handleSubmitForm = (formValues) => {
    const values = {
      ...formValues,
      homepageUrl: addProtocolWhenNotExists(formValues.homepageUrl || null),
    };
    return isEditMode
      ? dispatch(ProjectActions.updateRequest(project.id, values))
      : handleAddNewProject(values);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setErrors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: isEditMode ? project.name : '',
      homepageUrl: isEditMode ? project.homepageUrl : null,
    },
    validationSchema: NewProjectSchema,
    onSubmit: handleSubmitForm,
  });

  const handleFailure = useCallback((stateErrors) => {
    if (stateErrors) {
      setErrors(stateErrors);
    }
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateParams = {
    reset: false,
    onSuccess: modal.hide,
    onFailure: handleFailure,
  };
  const updateRequestState = useActionState(ProjectActions.updateRequest, stateParams);
  const addRequestState = useActionState(ProjectActions.addRequest, stateParams);

  useUnmount(() => {
    if (isEditMode) {
      updateRequestState.reset();
      return;
    }

    addRequestState.reset();
  });

  return (
    <form noValidate data-testid="ProjectModal" onSubmit={handleSubmit}>
      <Header>{labels.title}</Header>
      <Content>
        <FormField
          label={t('projectModal.form.name.label', 'Project name')}
          description={t(
            'projectModal.form.name.description',
            "What is the name of the website you're going to test?",
          )}
        >
          <Input
            data-hj-allow
            type="text"
            name="name"
            placeholder={t('projectModal.form.name.placeholder', 'Example Airlines')}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={touched.name && errors.name}
            autoFocus
          />
        </FormField>
        <FormField
          label={t('projectModal.form.homepageUrl.label', 'Homepage URL')}
          description={t(
            'projectModal.form.homepageUrl.description',
            'What is the main web domain for this website?',
          )}
        >
          <Input
            data-hj-allow
            type="text"
            name="homepageUrl"
            placeholder={t(
              'projectModal.form.homepageUrl.placeholder',
              'https://exampleairlines.com',
            )}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.homepageUrl}
            error={touched.homepageUrl && errors.homepageUrl}
          />
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo
          isVisible={
            updateRequestState.hasInternalServerError || addRequestState.hasInternalServerError
          }
        />
        <Button
          data-testid="ProjectModal.cancelButton"
          onClick={modal.hide}
          disabled={updateRequestState.isLoading || addRequestState.isLoading}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={updateRequestState.isLoading || addRequestState.isLoading}
        >
          {labels.submitButton}
        </ActionButton>
      </Footer>
    </form>
  );
};

ProjectModal.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    homepageUrl: PropTypes.string,
  }),
};

ProjectModal.defaultProps = {
  project: {},
};
export default ProjectModal;
