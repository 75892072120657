import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgKeyBold = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("style", { type: "text/css", children: "\n	.st0{fill:none;}\n	.st1{fill:currentColor;}\n" }),
  /* @__PURE__ */ jsx("path", { className: "st0", d: "M0,0h24v24H0V0z" }),
  /* @__PURE__ */ jsx("path", { className: "st1", d: "M12.6,10c-0.8-2.3-3-4-5.6-4c-3.3,0-6,2.7-6,6s2.7,6,6,6c2.6,0,4.8-1.7,5.6-4H17v4h4v-4h2v-4H12.6z M7,14 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S8.1,14,7,14z" })
] });
const ForwardRef = forwardRef(SvgKeyBold);
export default ForwardRef;
