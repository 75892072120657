import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Content = styled.div`
  border-right: 1px solid ${COLOR.GRAY_18};
  width: 461px;
  overflow: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > * {
    padding-right: 45px;
  }
`;
