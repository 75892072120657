import styled from 'styled-components';

import { Content as ContentBase, Header } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 515px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  ${Header} {
    margin-bottom: 10px;
  }

  > div > div {
    display: flex;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
