import Input from '@bugbug/core/components/Input';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import memoize from 'lodash.memoize';
import { is } from 'ramda';
import { memo, useMemo } from 'react';

import { Container, Headers, Row, Rows } from './MultiValueFormTable.styled';

const MultiValueFormTable = memo(
  ({
    className,
    value = [],
    columns = [],
    onChange = Function.prototype,
    onBlur = Function.prototype,
    getValueProps = () => ({}),
    readOnly = false,
    error = '',
  }) => {
    const getChangeHandler = useMemo(
      () => memoize((index) => (event) => onChange(event, index)),
      [onChange],
    );

    return (
      <>
        <Container className={className}>
          <Headers>
            <Row>
              {columns.map((column) => (
                <div key={column.key}>{column.label}</div>
              ))}
            </Row>
          </Headers>
          <Rows>
            {value.map((row, rowIndex) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Row key={rowIndex} twoColumn={readOnly}>
                {columns.map((column) => {
                  const rowValue = row[column.key];
                  if (column.isLabel) {
                    return <div key={column.key}>{rowValue}</div>;
                  }
                  return (
                    <div key={column.key}>
                      <Input
                        disabled={readOnly}
                        value={rowValue}
                        onBlur={onBlur}
                        onChange={getChangeHandler(rowIndex)}
                        error={error?.[rowIndex]?.value}
                        {...getValueProps(rowValue, column.key, row)}
                      />
                    </div>
                  );
                })}
              </Row>
            ))}
          </Rows>
        </Container>
        {is(String, error) ? <ErrorMessage>{error}</ErrorMessage> : null}
      </>
    );
  },
);

MultiValueFormTable.displayName = 'MultiValueFormTable';

export default MultiValueFormTable;
