/* eslint-disable react/jsx-props-no-spreading */
import { toast } from 'react-toastify';

import type { PendingToastFn, ToastFn } from './toasts.types';
import type { ToastProps } from '~/components/Toast/Toast';

import BaseToast, { SuccessToast, ErrorToast, LoadingToast } from '~/components/Toast';

const renderSuccessToast = (props) => <SuccessToast {...props} />;

const renderErrorToast = (props) => <ErrorToast {...props} />;

const showToast: ToastFn = ({ id, autoClose = 5000, ...props }) =>
  toast(<BaseToast {...props} />, {
    toastId: id,
    autoClose,
  });

const showPendingToast: PendingToastFn = ({
  id,
  autoClose = 5000,
  renderSuccess,
  renderError,
  ...props
}) => {
  const toastId = toast(<LoadingToast {...props} />, {
    toastId: id,
    autoClose: false,
  });

  const dismiss = () => toast.dismiss(toastId);

  return {
    dismiss,
    success: (...args) => {
      toast.update(toastId, {
        render: () => (renderSuccess ? renderSuccess(props, ...args) : renderSuccessToast(props)),
        type: 'success',
        autoClose,
      });
    },
    error: (...args) => {
      toast.update(toastId, {
        render: () =>
          renderError
            ? renderError(props, ...args, dismiss)
            : renderErrorToast({ ...props, onClose: dismiss }),
        type: 'error',
        autoClose,
      });
    },
  };
};

const showSuccessToast = ({
  id,
  ...props
}: Pick<ToastProps, 'content'> & { id?: string; redirectUrl?: string }) =>
  toast.success(renderSuccessToast(props), {
    toastId: id,
  });

const showErrorToast = ({
  id,
  autoClose = 5000,
  ...props
}: ToastProps & { id?: string; autoClose?: number }) =>
  toast.error(({ closeToast }) => renderErrorToast({ ...props, onClose: closeToast }), {
    autoClose,
    toastId: id,
  });

export default {
  show: showToast,
  showError: showErrorToast,
  showSuccess: showSuccessToast,
  dismiss: toast.dismiss,
  showPendingToast,
  renderSuccessToast,
  renderErrorToast,
};
