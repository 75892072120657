import IncognitoSrc from '~/images/bugbug-incognito-enable-screen-handwritten.png';
import i18n from '~/translations';

export const EXTENSION_STEPS = {
  INSTALL: {
    id: 0,
    label: i18n.t('missingExtensionModal.install.label', 'Install extension'),
  },
  INCOGNITO: {
    id: 1,
    label: i18n.t('missingExtensionModal.incognito.label', 'Enable incognito'),
  },
  SUCCESS: {
    id: 2,
  },
};

export const EXTENSION_STEPS_LIST = [EXTENSION_STEPS.INSTALL, EXTENSION_STEPS.INCOGNITO];

export const ASSETS = {
  IMAGE: {
    alt: i18n.t(
      'missingExtensionModal.incognito.image.alt',
      'Go to BugBug extension settings, scroll down and enable "Allow in incognito"',
    ),
    src: IncognitoSrc,
  },
};
