import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgInputUnchecked = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 33, height: 33, viewBox: "0 0 33 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("circle", { cx: 10.9469, cy: 9.0226, r: 6.0226, stroke: "black", strokeWidth: 2 }),
  /* @__PURE__ */ jsx("path", { d: "M21.8486 9.02258H29.5086", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M21.8486 24.6831H29.5086", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M8.15503 20.009H13.7386V18.009H8.15503V20.009ZM6.25503 21.909C6.25503 20.8597 7.10569 20.009 8.15503 20.009V18.009C6.00112 18.009 4.25503 19.7551 4.25503 21.909H6.25503ZM6.25503 27.1445V21.909H4.25503V27.1445H6.25503ZM8.15503 29.0445C7.10569 29.0445 6.25503 28.1939 6.25503 27.1445H4.25503C4.25503 29.2984 6.00112 31.0445 8.15503 31.0445V29.0445ZM13.7386 29.0445H8.15503V31.0445H13.7386V29.0445ZM15.6386 27.1445C15.6386 28.1939 14.7879 29.0445 13.7386 29.0445V31.0445C15.8925 31.0445 17.6386 29.2984 17.6386 27.1445H15.6386ZM15.6386 21.909V27.1445H17.6386V21.909H15.6386ZM13.7386 20.009C14.7879 20.009 15.6386 20.8597 15.6386 21.909H17.6386C17.6386 19.7551 15.8925 18.009 13.7386 18.009V20.009ZM6.15503 21.909C6.15503 20.8044 7.05046 19.909 8.15503 19.909V17.909C5.94589 17.909 4.15503 19.6999 4.15503 21.909H6.15503ZM6.15503 27.1445V21.909H4.15503V27.1445H6.15503ZM8.15503 29.1445C7.05046 29.1445 6.15503 28.2491 6.15503 27.1445H4.15503C4.15503 29.3537 5.9459 31.1445 8.15503 31.1445V29.1445ZM13.7386 29.1445H8.15503V31.1445H13.7386V29.1445ZM15.7386 27.1445C15.7386 28.2491 14.8431 29.1445 13.7386 29.1445V31.1445C15.9477 31.1445 17.7386 29.3537 17.7386 27.1445H15.7386ZM15.7386 21.909V27.1445H17.7386V21.909H15.7386ZM13.7386 19.909C14.8431 19.909 15.7386 20.8044 15.7386 21.909H17.7386C17.7386 19.6999 15.9477 17.909 13.7386 17.909V19.909ZM8.15503 19.909H13.7386V17.909H8.15503V19.909Z", fill: "black" })
] });
const ForwardRef = forwardRef(SvgInputUnchecked);
export default ForwardRef;
