import common from './common';
import * as core from './core';
import * as organization from './organization';
import * as suites from './suites';
import * as tests from './tests';
import * as user from './user';

export default {
  ...common,
  tests,
  suites,
  user,
  core,
  organization,
};
