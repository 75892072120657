import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMoneyPixel = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 64, height: 64, viewBox: "0 0 64 64", fill: "none", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 40.7273, height: 40.7273, transform: "matrix(-1 0 0 1 52.3636 11.6364)", fill: "#F5F5F5" }),
  /* @__PURE__ */ jsx("path", { d: "M17.4545 5.81824V11.6364H11.6364V17.4546H5.81818V46.5455H11.6364V17.4546H17.4545V11.6364H46.5454V5.81824H17.4545Z", fill: "#FCFCFC" }),
  /* @__PURE__ */ jsx("path", { d: "M46.5454 11.6364H52.3636V17.4545H46.5454V11.6364Z", fill: "#ECECEC" }),
  /* @__PURE__ */ jsx("path", { d: "M52.3636 46.5454V17.4545H58.1818V46.5454H52.3636Z", fill: "#ECECEC" }),
  /* @__PURE__ */ jsx("path", { d: "M46.5454 52.3636V46.5454H52.3636V52.3636H46.5454Z", fill: "#ECECEC" }),
  /* @__PURE__ */ jsx("path", { d: "M46.5454 58.1818V52.3636H17.4545V58.1818H46.5454Z", fill: "#ECECEC" }),
  /* @__PURE__ */ jsx("path", { d: "M34.9091 11.6364H29.0909V17.4545H23.2727V29.0909H29.0909V34.9091H34.9091V40.7273H23.2727V46.5454H29.0909V52.3636H34.9091V46.5454H40.7272V34.9091H34.9091V29.0909H29.0909V23.2727H40.7272V17.4545H34.9091V11.6364Z", fill: "#C9C9C9" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.4545 0V5.81818H11.6364V11.6364H5.81818V17.4545H0V46.5455H5.81818V52.3636H11.6364V58.1818H17.4545V64H46.5455V58.1818H52.3636V52.3636H58.1818V46.5455H64V17.4545H58.1818V11.6364H52.3636V5.81818H46.5455V0H17.4545ZM17.4545 5.81818V11.6364H11.6364V17.4545H5.81818V46.5455H11.6364V52.3636H17.4545V58.1818H46.5455V52.3636H52.3636V46.5455H58.1818V17.4545H52.3636V11.6364H46.5455V5.81818H17.4545Z", fill: "#C9C9C9" })
] });
const ForwardRef = forwardRef(SvgMoneyPixel);
export default ForwardRef;
