import { css } from 'styled-components';

export const lineClamp = ({ lines }) => css`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const textEllipsis = css`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const font = {
  regular: css`
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  `,
  medium: css`
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  semiBold: css`
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  `,
  bold: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
};

const typography = {
  link: css`
    ${font.medium};

    ${({ theme }) => css`
      color: ${theme.colors.primary};

      &:hover {
        color: ${theme.colors.primary};
      }
    `};
  `,
  warningMessage: css`
    ${font.regular};

    ${({ theme }) => `
      color: ${theme.colors.accent};
      background-color: ${theme.colors.accentSoft};
    `};
  `,
  successMessage: css`
    ${font.regular};

    ${({ theme }) => `
      color: ${theme.colors.status.success};
      background-color: ${theme.colors.background.success};
    `};
  `,
};

const layout = {
  flexRowCenter: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  flexRowStretch: css`
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  `,
  flexColumnCenter: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  gridSingleColumn: css`
    display: grid;
    grid-template-columns: 1fr;
  `,
  gridTwoColumns: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `,
};

export default {
  lineClamp,
  font,
  textEllipsis,
  typography,
  layout,
};
