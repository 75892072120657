import { keyframes } from 'styled-components';

import type { Keyframes } from 'styled-components';

import { COLOR, hexToRgba } from './colors';

export const spinAnimation = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

export const pulseAnimation = keyframes`
	0% {
		border-color: ${COLOR.GRAY_9};
	}

	50% {
		border-color: ${hexToRgba(COLOR.PRIMARY_LIGHT, 0.8)};
	}

	85% {
		border-color: ${COLOR.GRAY_9};
	}
`;

export type Animations = Record<string, Keyframes>;
