import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Container, Dot } from './DotsPicker.styled';

const DotsPicker = ({ className, error, name, items, value, onChange, preventEmptyValue }) => {
  const handleClick = useCallback(
    (id, selected) => () => {
      const updatedValue = selected ? value.filter((i) => i !== id) : [...value, id];

      if (updatedValue.length === 0 && preventEmptyValue) {
        return;
      }

      onChange({ target: { name, value: updatedValue } });
    },
    [preventEmptyValue, value, onChange, name],
  );

  const renderDot = useCallback(
    ({ id, name: itemName = '' }) => {
      const selected = value.includes(id);
      return (
        <Dot
          data-testid="DotsPicker.Dot"
          key={id}
          onClick={handleClick(id, selected)}
          selected={selected}
        >
          {itemName[0]}
        </Dot>
      );
    },
    [handleClick, value],
  );

  return (
    <Container className={className} error={error} data-testid="DotsPicker">
      {items.map(renderDot)}
    </Container>
  );
};

DotsPicker.defaultProps = {
  className: null,
  name: null,
  error: false,
  items: [],
  value: [],
  onChange: Function.prototype,
  preventEmptyValue: true,
};

DotsPicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  preventEmptyValue: PropTypes.bool,
};

export default DotsPicker;
