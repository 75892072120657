import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgPixelArrow = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 122.48, height: 61.24, viewBox: "0 0 122.48 61.24", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("symbol", { id: "e2d6d9ea-a4fd-456b-97d9-b7fa29fe5410", "data-name": "New Symbol", viewBox: "0 0 270 135", children: [
    /* @__PURE__ */ jsx("rect", { x: 125.5, y: 52.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 134.5, y: 52.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 143.5, y: 61.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 152.5, y: 61.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 161.5, y: 70.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 170.5, y: 70.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 116.5, y: 61.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 107.5, y: 61.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 98.5, y: 70.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 89.5, y: 70.45, width: 9, height: 9, style: {
      fill: "#d8d8d8"
    } }),
    /* @__PURE__ */ jsx("polygon", { points: "189 9 189 0 81 0 81 9 72 9 72 36 81 36 81 45 189 45 189 36 198 36 198 9 189 9", style: {
      fill: "#eceded"
    } }),
    /* @__PURE__ */ jsx("polygon", { points: "261 99 261 90 153 90 153 99 144 99 144 126 153 126 153 135 261 135 261 126 270 126 270 99 261 99", style: {
      fill: "#eceded"
    } }),
    /* @__PURE__ */ jsx("polygon", { points: "117 99 117 90 9 90 9 99 0 99 0 126 9 126 9 135 117 135 117 126 126 126 126 99 117 99", style: {
      fill: "#eceded"
    } })
  ] }) }),
  /* @__PURE__ */ jsx("title", { children: "empty-group-pixel" }),
  /* @__PURE__ */ jsx("g", { id: "f0495aa3-537e-47ae-ad26-05ae4df12fa6", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "adbe9fd0-2d17-4723-832b-90819d05318f", "data-name": "Layer 1", children: /* @__PURE__ */ jsx("use", { width: 270, height: 135, transform: "scale(0.45)", xlinkHref: "#e2d6d9ea-a4fd-456b-97d9-b7fa29fe5410" }) }) })
] });
const ForwardRef = forwardRef(SvgPixelArrow);
export default ForwardRef;
