import Icon from '@bugbug/core/components/Icon';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import { useCallback, useMemo } from 'react';

import type {
  EditAlertActionPickerFutureItem,
  EditAlertActionPickerItem,
} from './EditAlertActionPicker.types';

import type { SideEffect } from '@bugbug/core/types/utils';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { isFeatureFlagSetToFalse } from '~/utils/projects';

import EditAlertModalSelectComingSoon from '../EditAlertModalSelectComingSoon';

import * as S from './EditAlertActionPicker.styled';

interface EditAlertActionPickerProps {
  label: string;
  name: string;
  value?: string;
  items: EditAlertActionPickerItem[];
  onChange: SideEffect<Event>;
  futureItems?: EditAlertActionPickerFutureItem[];
}

const EditAlertActionPicker = ({
  items,
  name,
  label,
  value,
  onChange,
  futureItems,
}: EditAlertActionPickerProps) => {
  const featureFlags = useFeatureFlags();

  const renderCheckIcon = renderWhenTrueOtherwise(
    () => <S.ItemCheckIcon />,
    () => <S.IconPlaceholder />,
  );

  const handleChange = useCallback<SideEffect<EditAlertActionPickerItem['value']>>(
    (selectedValue) => {
      const changeEvent = new Event('change', { bubbles: true });
      const target = { name, value: selectedValue };
      Object.defineProperty(changeEvent, 'target', { value: target, enumerable: true });

      onChange(changeEvent);
    },
    [name, onChange],
  );

  const renderItem = (item: EditAlertActionPickerItem) =>
    !isFeatureFlagSetToFalse(featureFlags, item.featureFlag) && (
      <S.DropdownItem key={item.label} onClick={() => handleChange(item.value)}>
        {renderCheckIcon(value === item.value)}
        <S.NameWrapper>
          {item?.icon && (
            <S.IconWrapper>
              <Icon name={item.icon} />
            </S.IconWrapper>
          )}
          {item.label}
        </S.NameWrapper>
      </S.DropdownItem>
    );

  const selectedItem = items.find((row) => row.value === value);
  const selectedItemLabel = useMemo(
    () => (
      <S.NameWrapper>
        {selectedItem?.icon && (
          <S.IconWrapper>
            <Icon name={selectedItem.icon} />
          </S.IconWrapper>
        )}
        {selectedItem?.label}
      </S.NameWrapper>
    ),
    [selectedItem],
  );

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.Dropdown label={selectedItemLabel}>
        <S.OptionsContainer>
          {items.map(renderItem)}
          {futureItems && <EditAlertModalSelectComingSoon items={futureItems} />}
        </S.OptionsContainer>
      </S.Dropdown>
    </S.Container>
  );
};

export default EditAlertActionPicker;
