import axios from 'axios';

import { getFileNameFromUrl } from '~/utils/misc';

import api from './common';

export const create = (projectId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`/projects/${projectId}/artifacts/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const get = (url) =>
  axios.get(url, { responseType: 'blob' }).then(async (response) => {
    const fileName = getFileNameFromUrl(url);
    const buffer = await response.data.arrayBuffer();
    return new File([buffer], fileName, { type: response.data.type });
  });
