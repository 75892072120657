import memoize from 'lodash.memoize';
import { prop, propOr, props } from 'ramda';
import { createSelector } from 'reselect';

export const selectProfileDomain = prop('profile');

export const selectProfiles = createSelector(selectProfileDomain, propOr({}, 'profiles'));

export const selectOrder = createSelector(selectProfileDomain, propOr([], 'order'));

export const selectProfilesList = createSelector(selectOrder, selectProfiles, props);

export const selectDefaultProfile = createSelector(selectProfilesList, (profiles) =>
  profiles.find(prop('isDefault')),
);

export const selectProfile = memoize((id) =>
  createSelector(selectProfiles, selectDefaultProfile, (profilesMap, defaultProfile) =>
    propOr(defaultProfile, id, profilesMap),
  ),
);

export const selectHasMoreThanOneProfile = createSelector(
  selectProfilesList,
  (profiles) => profiles.length > 1,
);
