import i18n from '~/translations';

export const PAGE_SIZE = 50;

export const RUN_TRIGGER = {
  USER: 'user',
  API: 'api',
  SCHEDULER: 'scheduler',
  GITHUB: 'github',
  CLI: 'cli',
};

export const RUN_ENV = {
  LOCAL: 'local',
  SERVER: 'server',
};

export const SELECT_STATE = {
  ENABLED: 'true',
  DISABLED: 'false',
};

export const WAITING_CONDITION_TYPE = {
  DOCUMENT_COMPLETE: 'documentComplete',
  ELEMENT_IS_VISIBLE: 'elementIsVisible',
  ELEMENT_IS_NOT_DISABLED: 'elementIsNotDisabled',
  ELEMENT_IS_NOT_ANIMATING: 'elementIsNotAnimating',
  ELEMENT_HAS_ATTRIBUTE: 'elementHasAttribute',
  ELEMENT_HAS_FOCUS: 'elementHasFocus',
  ELEMENT_IS_NOT_COVERED: 'elementIsNotCovered',
  NETWORK_IDLE: 'networkIdle',
  PAGE_NAVIGATION_AFTER_EXECUTION: 'pageNavigationAfterExecution',
};

export const CUSTOM_WAITING_CONDITION = {
  ELEMENT_EXISTS: {
    label: i18n.t('waitingConditions.elementExists.label', 'Element is presented in document'),
    type: 'elementExists',
  },
};

export const CONDITION_PARAMS = {
  [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_COVERED]: {
    label: i18n.t(
      'waitingConditions.elementIsNotCovered.label',
      'Element is not covered by the other one',
    ),
  },
  [WAITING_CONDITION_TYPE.DOCUMENT_COMPLETE]: {
    label: i18n.t('waitingConditions.documentComplete.label', 'Document readyState is complete'),
  },
  [WAITING_CONDITION_TYPE.ELEMENT_IS_VISIBLE]: {
    label: i18n.t('waitingConditions.elementIsVisible.label', 'Element is visible'),
  },
  [WAITING_CONDITION_TYPE.PAGE_NAVIGATION_AFTER_EXECUTION]: {
    label: i18n.t(
      'waitingConditions.pageNavigationAfterExecution.label',
      'Page will navigate after step execution',
    ),
  },
  [WAITING_CONDITION_TYPE.ELEMENT_HAS_ATTRIBUTE]: {
    label: i18n.t(
      'waitingConditions.elementHasAttribute.label',
      'Element must have expected attribute',
    ),
    hasValue: true,
    valueOptions: {
      label: i18n.t('waitingConditions.elementHasAttribute.value.label', 'Attribute data'),
      placeholder: i18n.t('waitingConditions.elementHasAttribute.value.placeholder', 'key=value'),
    },
  },
  [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_DISABLED]: {
    label: i18n.t(
      'waitingConditions.elementIsNotDisabled.label',
      'Element must be active (without disabled attribute)',
    ),
  },
  [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_ANIMATING]: {
    label: i18n.t('waitingConditions.elementIsNotAnimating.label', 'Element is not animating'),
  },
  [WAITING_CONDITION_TYPE.ELEMENT_HAS_FOCUS]: {
    label: i18n.t('waitingConditions.elementHasFocus.label', 'Element has focus'),
  },
  [WAITING_CONDITION_TYPE.NETWORK_IDLE]: {
    label: i18n.t('waitingConditions.networkIdle.label', 'Page network requests are finished'),
    hasValue: true,
    valueOptions: {
      label: i18n.t('waitingConditions.networkIdle.value.label', 'Max. pending requests'),
      tooltip: i18n.t(
        'waitingConditions.networkIdle.value.tootlip',
        'Execute steps when maximum pending network requests for at least 1 sec will be under or equal this value',
      ),
    },
  },
};

export const WAITING_CONDITIONS_LIST = Object.values(WAITING_CONDITION_TYPE).map((type) => ({
  label: CONDITION_PARAMS[type].label,
  type,
}));

export const CORE_ERROR_TYPES = {
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  EXTENSION_DISCONNECTED: 'EXTENSION_DISCONNECTED',
  DISCONNECTED_OR_CORS: 'DISCONNECTED_OR_CORS',
};

export const DATA_RESTRICTIONS = {
  SUITE_NAME_MAX_LENGTH: 100,
  PROJECT_NAME_MAX_LENGTH: 100,
  SCHEDULE_NAME_MAX_LENGTH: 100,
  TEST_NAME_MAX_LENGTH: 255,
  COMPONENT_NAME_MAX_LENGTH: 255,
  VARIABLE_NAME_MAX_LENGTH: 30,
  PROFILE_NAME_MAX_LENGTH: 50,
};

export const USER_ROLE = {
  IMPERSONATE: 99,
  ADMIN: 10,
  EDITOR: 1,
};

export const USER_ROLE_LABELS = {
  [USER_ROLE.IMPERSONATE]: i18n.t('userRole.admin', 'Admin'),
  [USER_ROLE.ADMIN]: i18n.t('userRole.admin', 'Admin'),
  [USER_ROLE.EDITOR]: i18n.t('userRole.editor', 'Editor'),
};

export const ONBOARDING_STEP = {
  WELCOME: 'welcome',
  PROFILE: 'profile',
  ORGANIZATION: 'organization',
  FINISHED: 'organizationCreated',
};

export const SIGN_UP_ORIGIN = {
  REGULAR: 'regular',
  GITHUB: 'github',
  GOOGLE: 'google',
  INVITATION: 'invitation',
};

export const QUERY_ACTION = {
  START_TRIAL: 'startTrial',
  INSTALL_EXTENSION: 'installExtension',
  SHOW_SUITE_DETAILS: 'showSuiteDetails',
  SHOW_USER_INVITATION: 'showUserInvitation',
};
