import axios from 'axios';

import config from '~/modules/config';

export const getVersion = () =>
  axios
    .get(`/_meta.json?reqId=${Date.now()}`)
    .then((result) => result.data.version)
    .catch(() => config.VERSION);

export const hasVersionChanged = async () => {
  const deployedVersion = await getVersion();
  return window.navigator.onLine && deployedVersion !== config.VERSION;
};

export const reloadIfVersionChanged = async () => {
  if (await hasVersionChanged()) {
    window.location.reload();
  }
};
