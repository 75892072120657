import { RUN_STATUS_LABEL } from '@bugbug/core/constants/status';
import { useTimezone } from '@bugbug/core/hooks/useTimezone';
import { TextWithStatusColor } from '@bugbug/core/theme/typography';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import { useTranslation } from 'react-i18next';

import type { ExtendedRunStatus } from '@bugbug/core/types/base';
import type { Maybe } from '@bugbug/core/types/utils';
import type { WaitingCondition } from '@bugbug/core/types/waitingConditions';
import DurationTime from '~/components/DurationTime';
import { CONDITION_PARAMS } from '~/modules/constans';

import { IdField } from './IdField';
import { getTriggerLabel } from './RunMetadataSection.constants';
import * as S from './RunMetadataSection.styled';

interface RunMetadataSectionProps {
  className?: string;
  title?: string;
  running?: boolean;
  data: {
    status: ExtendedRunStatus;
    runMode?: string;
    sequence?: number;
    suiteRunId?: {
      label: string;
      value?: string;
    };
    testRunId?: {
      label: string;
      value?: string;
    };
    stepRunId?: {
      label: string;
      value?: string;
    };
    started?: Maybe<string>;
    ended?: string;
    rawDuration?: string;
    triggeredBy?: string;
    suiteRun?: boolean;
    logsArtifactUrl?: string;
    hiddenViaSuite?: boolean;
    waitingConditions?: {
      totalNumber: number;
      passed?: WaitingCondition[];
      failed?: WaitingCondition[];
      skipped?: WaitingCondition[];
    };
  };
}

const RunMetadataSection = ({ className, title, data, running }: RunMetadataSectionProps) => {
  const { t } = useTranslation();
  const { toProjectTZ } = useTimezone();

  const renderViaSuite = renderWhenTrueOtherwise(
    () => t('runMetadataSection.viaSuite.value.yes', 'Yes'),
    () => t('runMetadataSection.viaSuite.value.no', 'No'),
  );

  const renderCondition = ({ type }: WaitingCondition) => (
    <span key={type}>{CONDITION_PARAMS[type].label}</span>
  );

  return (
    <S.Section className={className}>
      {title && (
        <S.Title>
          {title} <hr />
        </S.Title>
      )}
      {data.sequence && (
        <S.MetaData>
          <S.Label>{t('runMetadataSection.id.label', 'Run ID')}</S.Label>
          <S.Value>
            {t('runMetadataSection.id.value', '#{{count}}', { count: data.sequence })}
          </S.Value>
        </S.MetaData>
      )}
      <IdField value={data.suiteRunId} />
      <IdField value={data.testRunId} />
      <IdField value={data.stepRunId} />
      <S.MetaData>
        <S.Label>{t('runMetadataSection.started.label', 'Started')}</S.Label>
        <S.Value>
          {data.started ? toProjectTZ(data.started).numeric : t('default.emptyValue')}
        </S.Value>
      </S.MetaData>

      <S.MetaData>
        <S.Label>{t('runMetadataSection.finished.label', 'Finished')}</S.Label>
        <S.Value>{data.ended ? toProjectTZ(data.ended).numeric : t('default.emptyValue')}</S.Value>
      </S.MetaData>

      <S.MetaData>
        <S.Label>{t('runMetadataSection.duration.label', 'Duration')}</S.Label>
        <S.Value>
          {data.rawDuration ? (
            <DurationTime value={data.rawDuration} running={running} showMilliseconds />
          ) : (
            t('default.emptyValue')
          )}
        </S.Value>
      </S.MetaData>

      <S.MetaData>
        <S.Label>{t('runMetadataSection.result.label', 'Status')}</S.Label>
        <TextWithStatusColor status={data.status}>
          {RUN_STATUS_LABEL[data.status] || t('default.emptyValue')}
        </TextWithStatusColor>
      </S.MetaData>

      {data.triggeredBy && (
        <S.MetaData>
          <S.Label>{t('runMetadataSection.trigger.label', 'Run by')}</S.Label>
          <S.Value>
            {getTriggerLabel(data.triggeredBy, {
              email: T.pathOr('user', ['user', 'email'], data),
            })}
          </S.Value>
        </S.MetaData>
      )}

      {!!data.waitingConditions?.totalNumber && (
        <>
          <S.MetaData>
            <S.Label>
              {t('runMetadataSection.waitingConditions.passed.label', 'Passed waiting conditions')}
            </S.Label>
            <S.Value>
              {t('runMetadataSection.waitingConditions.passed.value', '{{passed}} of {{total}}', {
                passed: data.waitingConditions.passed?.length ?? 0,
                total: data.waitingConditions.totalNumber,
              })}
            </S.Value>
          </S.MetaData>
          <S.MetaData>
            <S.Label>
              {t('runMetadataSection.waitingConditions.failed.label', 'Failed waiting conditions')}
            </S.Label>
            <S.Value>
              {t('runMetadataSection.waitingConditions.failed.value', '{{failed}} of {{total}}', {
                failed: data.waitingConditions.failed?.length ?? 0,
                total: data.waitingConditions.totalNumber,
              })}
              {!running && data.waitingConditions.failed
                ? data.waitingConditions.failed.map(renderCondition)
                : null}
            </S.Value>
          </S.MetaData>
          <S.MetaData>
            <S.Label>
              {t(
                'runMetadataSection.waitingConditions.skipped.label',
                'Skipped waiting conditions',
              )}
            </S.Label>
            <S.Value>
              {t('runMetadataSection.waitingConditions.skipped.value', '{{skipped}} of {{total}}', {
                skipped: data.waitingConditions.skipped?.length ?? 0,
                total: data.waitingConditions.totalNumber,
              })}
              {!running && data.waitingConditions.skipped
                ? data.waitingConditions.skipped.map(renderCondition)
                : null}
            </S.Value>
          </S.MetaData>
        </>
      )}

      {data.logsArtifactUrl && (
        <S.MetaData>
          <S.Label>{t('runMetadataSection.logsLink.label', 'Logs link')}</S.Label>
          <S.Value>
            <a href={data.logsArtifactUrl} target="_blank" rel="noreferrer">
              {t('runMetadataSection.logsLink.link', 'Show test run logs')}
              <S.LinkIcon />
            </a>
          </S.Value>
        </S.MetaData>
      )}

      {!data.hiddenViaSuite && (
        <S.MetaData>
          <S.Label>{t('runMetadataSection.viaSuite.label', 'Via suite')}</S.Label>
          <S.Value>
            {typeof data.suiteRun === 'undefined'
              ? t('default.emptyValue')
              : renderViaSuite(!!data.suiteRun)}
          </S.Value>
        </S.MetaData>
      )}
    </S.Section>
  );
};

export default RunMetadataSection;
