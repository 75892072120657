import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${COLOR.GRAY_9};
  border-radius: 5px;
`;

export const Rows = styled.div`
  padding: 8px 0;
`;

export const Row = styled.div`
  align-items: center;
  column-gap: 16px;
  display: grid;
  grid-template-columns: ${({ twoColumn }) => (twoColumn ? '49% 49%' : '32% 32% 32%')};
  min-height: 46px;
  padding: 6px 16px;
`;

export const Headers = styled.header`
  border-bottom: 1px solid ${COLOR.GRAY_9};
  margin: 0 16px;

  ${Row} {
    padding: 6px 0;
  }

  div {
    color: ${COLOR.GRAY_13};
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
`;
