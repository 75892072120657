import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgActionCount123Alt = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 33, height: 32, viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M6.37 9.193C6.26733 9.27367 6.15733 9.28467 6.04 9.226C5.93 9.16733 5.875 9.072 5.875 8.94V8.137C5.875 7.719 6.04367 7.389 6.381 7.147L7.492 6.333C7.822 6.091 8.19967 5.97 8.625 5.97H9.318C9.48667 5.97 9.63333 6.03233 9.758 6.157C9.88267 6.28167 9.945 6.42833 9.945 6.597V13.373C9.945 13.5417 9.88267 13.6883 9.758 13.813C9.63333 13.9377 9.48667 14 9.318 14H8.625C8.45633 14 8.30967 13.9377 8.185 13.813C8.06033 13.6883 7.998 13.5417 7.998 13.373V8.027H7.987H7.976L6.37 9.193Z", fill: "black" }),
  /* @__PURE__ */ jsx("path", { d: "M6.29302 26C6.12435 26 5.97768 25.9377 5.85302 25.813C5.72835 25.6883 5.66602 25.5417 5.66602 25.373V25.12C5.66602 24.7093 5.83102 24.3757 6.16102 24.119C7.39302 23.151 8.20702 22.414 8.60302 21.908C9.00635 21.402 9.20802 20.907 9.20802 20.423C9.20802 19.7483 8.83402 19.411 8.08602 19.411C7.63135 19.411 7.07402 19.5357 6.41402 19.785C6.27468 19.8363 6.14268 19.8217 6.01802 19.741C5.89335 19.6603 5.83102 19.5467 5.83102 19.4V18.938C5.83102 18.7473 5.88602 18.5787 5.99602 18.432C6.10602 18.278 6.25268 18.1753 6.43602 18.124C7.10335 17.948 7.74502 17.86 8.36102 17.86C9.24835 17.86 9.93035 18.0727 10.407 18.498C10.891 18.916 11.133 19.499 11.133 20.247C11.133 20.8923 10.9277 21.5083 10.517 22.095C10.1137 22.6743 9.32168 23.4627 8.14102 24.46C8.13368 24.4673 8.13002 24.4747 8.13002 24.482C8.13002 24.4893 8.13368 24.493 8.14102 24.493H10.55C10.7187 24.493 10.8617 24.5553 10.979 24.68C11.1037 24.8047 11.166 24.9513 11.166 25.12V25.373C11.166 25.5417 11.1037 25.6883 10.979 25.813C10.8617 25.9377 10.7187 26 10.55 26H6.29302Z", fill: "black" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2378 10C15.2378 9.44772 15.6855 9 16.2378 9H25.6282C26.1805 9 26.6282 9.44772 26.6282 10C26.6282 10.5523 26.1805 11 25.6282 11H16.2378C15.6855 11 15.2378 10.5523 15.2378 10Z", fill: "black" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2378 16C15.2378 15.4477 15.6855 15 16.2378 15H25.6282C26.1805 15 26.6282 15.4477 26.6282 16C26.6282 16.5523 26.1805 17 25.6282 17H16.2378C15.6855 17 15.2378 16.5523 15.2378 16Z", fill: "black" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2378 22C15.2378 21.4477 15.6855 21 16.2378 21H25.6282C26.1805 21 26.6282 21.4477 26.6282 22C26.6282 22.5523 26.1805 23 25.6282 23H16.2378C15.6855 23 15.2378 22.5523 15.2378 22Z", fill: "black" })
] });
const ForwardRef = forwardRef(SvgActionCount123Alt);
export default ForwardRef;
