import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

import { DropdownItem as DropdownItemBase } from '../EditAlertActionPicker/EditAlertActionPicker.styled';

export const Container = styled.div`
  color: ${COLOR.DARK_GRAY};
  max-width: 400px;
  border-top: 1px solid ${COLOR.GRAY_18};
`;

export const Title = styled.div`
  margin: 10px 0;
  padding-left: 15px;
  text-transform: uppercase;
  color: ${COLOR.GRAY_13};
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 12px;
  line-height: 150%;
`;

export const DropdownItem = styled(DropdownItemBase)`
  color: ${COLOR.DARK_GRAY};
  opacity: 0.6;
  cursor: none;
`;
