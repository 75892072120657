import { forwardRef } from 'react';

import type { IconName } from '../../types/icons';
import type { TooltipAnchor } from '../Tooltip';
import type React from 'react';

import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip';

import { Container } from './IconButton.styled';

interface IconButtonProps {
  className?: string;
  iconName?: IconName;
  tooltip?: string;
  tooltipAnchor?: TooltipAnchor;
  type?: HTMLButtonElement['type'];
  disabled?: boolean;
  pending?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  'data-testid'?: string;
  // @deprecated
  children?: React.ReactNode;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    children,
    pending,
    'data-testid': dataTestId = 'IconButton',
    tooltip = '',
    tooltipAnchor,
    type = 'button',
    iconName,
    ...buttonProps
  } = props;

  const renderButton = () => (
    <Container
      ref={ref}
      data-testid={dataTestId}
      pending={pending}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {iconName ? <Icon name={iconName} /> : children}
    </Container>
  );

  if (tooltip) {
    return (
      <Tooltip content={tooltip} anchor={tooltipAnchor}>
        {renderButton()}
      </Tooltip>
    );
  }

  return renderButton();
});

IconButton.displayName = 'IconButton';

export default IconButton;
