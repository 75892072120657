import { RUN_STATUS } from '@bugbug/core/constants/status';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectSingleProject, selectSingleProjectId } from '~/modules/project/project.selectors';
import { SuiteRunActions, SuiteRunTypes } from '~/modules/suiteRun/suiteRun.redux';
import { selectSuiteRunsPagination } from '~/modules/suiteRun/suiteRun.selectors';
import { selectUserSettings } from '~/modules/user/user.selectors';
import selectWebsocketChannelName from '~/modules/websocket/websocket.selectors';
import api from '~/services/api';
import toasts from '~/services/toasts';
import { getRelatedSuite } from '~/utils/runs';

export function* getSingle({ id }) {
  try {
    yield put(SuiteRunActions.reset());
    const { data: responseData } = yield call(api.suiteRuns.get, id);
    yield put(SuiteRunActions.getSingleSuccess(responseData));
  } catch (error) {
    yield put(SuiteRunActions.getSingleFailure(error));
  }
}

export function* getList({ page = 1, query, sortBy, descOrder = false }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    const pagination = yield select(selectSuiteRunsPagination);
    let ordering = sortBy;

    if (descOrder) {
      ordering = `-${ordering}`;
    }

    const params = {
      project_id: projectId,
      page_size: pagination.pageSize,
      page,
      query,
      ordering,
    };
    const { data: responseData } = yield call(api.suiteRuns.getList, params);
    yield put(SuiteRunActions.getListSuccess(responseData, query, sortBy, descOrder));
  } catch (error) {
    yield put(SuiteRunActions.getListFailure(error));
  }
}

export function* remove({ ids }) {
  try {
    if (ids) {
      yield call(api.suiteRuns.removeSelected, { suiteRunsIds: ids });
    } else {
      const project = yield select(selectSingleProject);
      yield call(api.suiteRuns.removeAll, { projectId: project.id });
    }

    yield put(SuiteRunActions.removeSuccess(ids));
  } catch (error) {
    yield put(SuiteRunActions.removeFailure(error));
  }
}

export function* stop({ ids }) {
  try {
    const userSettings = yield select(selectUserSettings);
    const channelName = yield select(selectWebsocketChannelName);
    const data = {
      channelName,
      runMode: userSettings.runMode,
    };

    if (ids) {
      data.suiteRunsIds = ids;
      yield call(api.suiteRuns.stopSelected, data);
    } else {
      const project = yield select(selectSingleProject);
      data.projectId = project.id;
      yield call(api.suiteRuns.stopAll, data);
    }
    yield put(SuiteRunActions.stopSuccess(ids));
    yield call(toasts.suites.showSuitesStopped, ids);
  } catch (error) {
    yield put(SuiteRunActions.stopFailure(error));
  }
}

export function* updated({ data }) {
  const toastFuncByStatus = {
    [RUN_STATUS.PASSED]: toasts.suites.showSuitePassed,
    [RUN_STATUS.FAILED]: toasts.suites.showSuiteFailed,
    [RUN_STATUS.ERROR]: toasts.suites.showSuiteError,
  };

  const projectId = yield select(selectSingleProjectId);

  if (getRelatedSuite(data)?.projectId !== projectId) {
    return;
  }

  yield put(SuiteRunActions.addUpdated(data));

  if (toastFuncByStatus[data.status]) {
    const organizationId = yield select(selectCurrentOrganizationId);
    yield call(toastFuncByStatus[data.status], data, organizationId);
  }
}

export default function* suiteRunSagas() {
  yield all([
    yield takeLatest(SuiteRunTypes.GET_SINGLE_REQUEST, getSingle),
    yield takeLatest(SuiteRunTypes.GET_LIST_REQUEST, getList),
    yield takeLatest(SuiteRunTypes.REMOVE_REQUEST, remove),
    yield takeLatest(SuiteRunTypes.STOP_REQUEST, stop),
    yield takeLatest(SuiteRunTypes.UPDATED, updated),
  ]);
}
