import dayjs from 'dayjs';

import type { Timezone } from '@bugbug/core/types/geography';

export const getFormattedDate = (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss');

export const parseTimeFromDuration = (duration) => {
  const [hours, minutes = '0', secondsWithMs = '0.0'] = (duration ?? '').split(':');
  const [seconds, milliSeconds] = secondsWithMs.split('.');
  return { hours: hours || '00', minutes, secondsWithMs, seconds, milliSeconds };
};

export const getFormattedDuration = (
  duration,
  showMilliseconds = false,
  showOnlySeconds = false,
) => {
  if (!duration) {
    return duration;
  }
  const { hours, minutes, seconds: sec, milliSeconds: ms } = parseTimeFromDuration(duration);

  const totalTimeInSeconds = parseInt(minutes, 10) * 60 + parseInt(sec, 10);
  const seconds = showOnlySeconds && minutes > 0 ? totalTimeInSeconds : sec;

  const secondsWithMilliseconds = showMilliseconds
    ? `${seconds}.${Math.min(Math.round(ms.substr(0, 3) / 10), 99)
        .toString(10)
        .padStart(2, '0')}`
    : seconds;

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }

  if (showOnlySeconds) {
    return `${secondsWithMilliseconds}s`;
  }

  return `${minutes}m ${secondsWithMilliseconds}s`;
};

export const getDaysLeft = (untilDate) =>
  dayjs(untilDate).startOf('day').diff(dayjs().startOf('day'), 'day');

export const convertHourValueBetweenTimezones = (
  hourValue: string,
  fromTimezone: Timezone,
  toTimezone: Timezone,
): string =>
  fromTimezone === 'Utc'
    ? convertHourFromUtc(toTimezone, hourValue)
    : convertHourToUtc(fromTimezone, hourValue);

const convertHourFromUtc = (timezone: Timezone, hourValue: string) => {
  const hour = Number(hourValue);
  const hourInFromTZ = dayjs().utc().hour(hour).minute(0);
  const offset = dayjs().tz(timezone).utcOffset() / 60;
  const roundedOffset = offset > 0 ? Math.floor(offset) : Math.ceil(offset);

  const resultingHour = hourInFromTZ.add(roundedOffset, 'hour').hour();
  return resultingHour.toFixed();
};

const convertHourToUtc = (timezone: Timezone, hourValue: string) => {
  const hour = Number(hourValue);
  const hourInFromTZ = dayjs().hour(hour).minute(0).tz(timezone);

  const offset = dayjs().tz(timezone, true).utcOffset() / 60;
  const roundedOffset = offset > 0 ? Math.floor(offset) : Math.ceil(offset);

  const resultingHour = hourInFromTZ.local().add(-roundedOffset, 'hour').hour();

  return Number(resultingHour).toFixed();
};

export const getRawDurationFromDate = (date: Date) => {
  const msDiff = Date.now() - date.valueOf();
  const h = Math.trunc((msDiff / 3600000) % 100)
    .toString()
    .padStart(2, '0');
  const m = Math.trunc((msDiff / 60000) % 60)
    .toString()
    .padStart(2, '0');
  const s = Math.trunc((msDiff / 1000) % 60)
    .toString()
    .padStart(2, '0');
  const ms = Math.trunc(msDiff % 1000)
    .toString()
    .padStart(3, '0');
  return `${h}:${m}:${s}.${ms}`;
};
