/* eslint-disable no-template-curly-in-string */
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { healXPathSelectorIfNeeded } from '@bugbug/core/utils/selectors';
import * as T from '@bugbug/core/utils/toolbox';
import { isValidUrl } from '@bugbug/core/utils/validation';
import postalCodes from 'postal-codes-js';
import { is } from 'ramda';
import * as Yup from 'yup';

import { USER_ROLE } from '~/modules/constans';
import i18n from '~/translations';

export const VALIDATION_MESSAGE = {
  REQUIRED: i18n.t('validationMessage.required', 'This field is required'),
  MIN_LENGTH: i18n.t('validationMessage.minLength', 'Min ${min} characters'),
  MAX_LENGTH: i18n.t('validationMessage.maxLength', 'Max ${max} characters'),
  TOO_LONG: i18n.t('validationMessage.tooLong', 'Too Long!'),
  PASSWORD_REGEXP: i18n.t(
    'validationMessage.password.regexp',
    'Password can only contain non-whitespace characters.',
  ),
  REQUESTS_REGEXP: i18n.t(
    'validationMessage.requests.regexp',
    'Requests pattern should have specific format eg. 2|2000',
  ),
  PASSWORD_MATCH: i18n.t('validationMessage.password.match', "Passwords don't match"),
  EMAIL: i18n.t('validationMessage.email', 'Provide correct e-mail'),
  POSITIVE_INTEGER: i18n.t(
    'validationMessage.positiveNumber',
    'This field should contain a positive integer',
  ),
  NUMBER: i18n.t('validationMessage.number', 'This field should contain a number'),
  URL: i18n.t('validationMessage.url', 'This URL is not valid'),
  CAMEL_CASE: i18n.t(
    'validationMessage.camelCase',
    'This field should contain text with proper format eg. simpleValue',
  ),
  ONLY_FIRST_LETTER: i18n.t(
    'validationMessage.onlyFirstLetter',
    'This value should start with a letter',
  ),
  MIN_NUMBER_VALUE: i18n.t('validationMessage.minNumberValue', 'Min value is ${min}'),
  MAX_NUMBER_VALUE: i18n.t('validationMessage.maxNumberValue', 'Max value is ${max}'),
  BROWSER_LANGUAGE_REGEXP: i18n.t(
    'validationMessage.browserLanguage.regexp',
    'Invalid language code. An example of a valid language code: en-US',
  ),
  POSTAL_CODE_COUNTRY: i18n.t(
    'validationMessage.postalCodeCountry',
    'This postal code is not valid for selected country',
  ),
  SELECTOR_PATTERN: i18n.t('validationMessage.selector', 'This is not valid selector'),
  FRAME_PATH_PATTERN: i18n.t(
    'validationMessage.framePath',
    'Frame path contains invalid selector: `${selector}`',
  ),
  MAX_FILE_SIZE: i18n.t('validationMessage.maxFileSize', 'File size should be less than 10MB'),
  SINGLE_LINE: i18n.t(
    'validationMessage.singleLine',
    'Only one value in one line allowed. If you want to select multiple values use the checkbox above.',
  ),
  ALPHANUMERIC: i18n.t(
    'validationMessage.alphanumeric',
    'This field should contain only alphanumeric characters',
  ),
  NUMBER_OR_VARIABLE: i18n.t(
    'validationMessage.numerOrVariable',
    'This field should contain either a number or a variable',
  ),
};

export const containsVariable = (value) => /\{{[^)]*\}}/.test(value);

export const oldPasswordValidator = Yup.string().required(VALIDATION_MESSAGE.REQUIRED);

export const passwordValidator = Yup.string()
  .min(8, VALIDATION_MESSAGE.MIN_LENGTH)
  .max(50, VALIDATION_MESSAGE.TOO_LONG)
  .matches(/^[\S]+$/, VALIDATION_MESSAGE.PASSWORD_REGEXP)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const repeatPasswordValidator = Yup.string()
  .oneOf([Yup.ref('password1'), null], VALIDATION_MESSAGE.PASSWORD_MATCH)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const newPasswordValidator = passwordValidator;

export const repeatNewPasswordValidator = Yup.string()
  .oneOf([Yup.ref('newPassword1'), null], VALIDATION_MESSAGE.PASSWORD_MATCH)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const emailValidator = Yup.string()
  .email(VALIDATION_MESSAGE.EMAIL)
  .nullable()
  .required(VALIDATION_MESSAGE.REQUIRED);

export const userRoleValidator = Yup.number()
  .oneOf(Object.values(USER_ROLE))
  .required(VALIDATION_MESSAGE.REQUIRED);

export const nameValidator = Yup.string().required(VALIDATION_MESSAGE.REQUIRED);
export const nullableString = Yup.string()
  .nullable()
  .transform((v) => (v === '' ? null : v));

export const alphanumericValidator = Yup.string()
  .test('alphanumeric', VALIDATION_MESSAGE.ALPHANUMERIC, (value) =>
    /^[a-zA-Z0-9\-_]*[a-zA-Z_][0-9]*$/.test(value),
  )
  .required(VALIDATION_MESSAGE.REQUIRED);

export const urlValidator = Yup.string()
  .required(VALIDATION_MESSAGE.REQUIRED)
  .test('url', VALIDATION_MESSAGE.URL, (value, testContext) => {
    switch (true) {
      case testContext.schema.spec.presence === 'optional':
        return !value || isValidUrl(value);

      case !value:
        return testContext.createError({
          path: testContext.path,
          message: VALIDATION_MESSAGE.REQUIRED,
        });

      default:
        return isValidUrl(value);
    }
  });

export const numberOrVariableValidator = Yup.mixed()
  .test('numberOrVariable', VALIDATION_MESSAGE.NUMBER_OR_VARIABLE, async (value) => {
    const isNumber = await Yup.number().isValid(value);

    return isNumber || containsVariable(value);
  })
  .transform(T.defaultTo(''))
  .default('');

export const urlWithVariablesValidator = Yup.string().test('url', async function validate(value) {
  if (containsVariable(value)) {
    return true;
  }

  try {
    return urlValidator.validateSync(value);
  } catch (error) {
    const { path, createError } = this;
    return createError({ path, message: error.message });
  }
});

export const idValidator = Yup.string().uuid();

export const positiveIntegerValidator = Yup.number(VALIDATION_MESSAGE.POSITIVE_INTEGER)
  .transform(function transform(value) {
    const defaultValue = this.default() === undefined ? value : this.default();
    return value ? Number(value) : defaultValue;
  })
  .min(0, VALIDATION_MESSAGE.POSITIVE_INTEGER)
  .integer(VALIDATION_MESSAGE.POSITIVE_INTEGER)
  .nullable()
  .default(0);

export const runTimeoutValidator = Yup.number(VALIDATION_MESSAGE.NUMBER)
  .min(1, VALIDATION_MESSAGE.MIN_NUMBER_VALUE)
  .max(300, VALIDATION_MESSAGE.MAX_NUMBER_VALUE);

export const sleepValidator = Yup.number(VALIDATION_MESSAGE.NUMBER)
  .min(0, VALIDATION_MESSAGE.MIN_NUMBER_VALUE)
  .max(300, VALIDATION_MESSAGE.MAX_NUMBER_VALUE);

export const windowSizeValidator = Yup.number(VALIDATION_MESSAGE.NUMBER)
  .min(500, VALIDATION_MESSAGE.MIN_NUMBER_VALUE)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const browserLanguageValidator = Yup.string()
  .matches(/^[a-z]{2}(?:-[a-zA-Z]{2}?)?$/, VALIDATION_MESSAGE.BROWSER_LANGUAGE_REGEXP)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const zipCodeValidator = Yup.string()
  .required(VALIDATION_MESSAGE.REQUIRED)
  .test('wrong-format', '', function validate(value) {
    const { path, createError, resolve } = this;
    const countryCode = resolve(Yup.ref('country'));
    const result = countryCode ? postalCodes.validate(countryCode, value) : false;

    if (is(String, result)) {
      return createError({ path, message: VALIDATION_MESSAGE.POSTAL_CODE_COUNTRY });
    }
    return true;
  });

export const jsCodeValidator = Yup.string();
export const variableNameValidator = Yup.string();

export const fileValidator = Yup.mixed()
  .required(VALIDATION_MESSAGE.REQUIRED)
  .default('')
  .test('max-file-size', '', function validate(file) {
    const { path, createError } = this;

    if (file && file.size && file.size > DATA_RESTRICTIONS.MAX_FILE_SIZE) {
      return createError({ path, message: VALIDATION_MESSAGE.MAX_FILE_SIZE });
    }
    return true;
  });

export function validateSelectorPattern(value = '') {
  const { path, createError } = this;

  try {
    const selectorWithoutVariables = value.replaceAll(/({{.+?}})/g, 'fake');
    const isXPathSelector =
      selectorWithoutVariables &&
      (selectorWithoutVariables.startsWith('./') ||
        selectorWithoutVariables.startsWith('/') ||
        selectorWithoutVariables.startsWith('('));
    if (isXPathSelector) {
      const selector = healXPathSelectorIfNeeded(selectorWithoutVariables);
      document.evaluate(
        selector,
        document,
        null,
        window.XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
        null,
      );
    } else {
      document.querySelector(selectorWithoutVariables);
    }
  } catch (error) {
    return createError({ path, message: VALIDATION_MESSAGE.SELECTOR_PATTERN });
  }
  return true;
}

export const selectorPatternValidator = Yup.string()
  .test('selectorPattern', validateSelectorPattern)
  .required(VALIDATION_MESSAGE.REQUIRED);

export const framePathValidator = Yup.string()
  .test('framePathPattern', function validateFramePath(value = '') {
    const { path, createError } = this;
    const pattern = /(?=.*):(?=\/)/;
    const selectors = value.split(pattern);

    for (let i = 0; i < selectors.length; i += 1) {
      const selector = selectors[i];
      const result = validateSelectorPattern.call(this, selector);
      if (result !== true) {
        return createError({
          path,
          message: VALIDATION_MESSAGE.FRAME_PATH_PATTERN,
          params: { selector },
        });
      }
    }

    return true;
  })
  .required(VALIDATION_MESSAGE.REQUIRED);

export const testNameValidator = nameValidator.max(
  DATA_RESTRICTIONS.TEST_NAME_MAX_LENGTH,
  VALIDATION_MESSAGE.MAX_LENGTH,
);

export const valueNameValidator = alphanumericValidator
  .max(DATA_RESTRICTIONS.VARIABLE_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH)
  .default('');

export const optionsListValidator = Yup.array().min(1, VALIDATION_MESSAGE.REQUIRED);
