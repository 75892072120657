import { COLOR } from '@bugbug/core/theme';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import { Controlled as CodeMirror } from 'react-codemirror2';
import styled, { css } from 'styled-components';

import type { CodeStyledProps } from '~/components/Code/Code.types';

export const Container = styled.div<Partial<CodeStyledProps>>`
  border-radius: 5px;
  border: 2px solid ${COLOR.GRAY_9};
  transition: border-color 150ms ease-in-out;
  color: ${COLOR.PRIMARY};
  cursor: text;

  &:hover {
    border-color: ${COLOR.GRAY_10};
  }

  ${styleWhenTrue(
    prop('focused'),
    css`
      border-color: ${COLOR.PRIMARY_LIGHT} !important;
    `,
  )}

  ${styleWhenTrue(
    prop('readOnly'),
    css`
      background-color: ${COLOR.GRAY_28};
      &:hover {
        border-color: ${COLOR.GRAY_9};
      }
    `,
  )}

  ${styleWhenTrue(
    prop('invalid'),
    css`
      border-color: ${COLOR.ERROR} !important;
    `,
  )}

  + ${ErrorMessage} {
    margin-top: 0;
  }
`;

export const Editor = styled(CodeMirror)`
  && {
    .CodeMirror {
      background: transparent;
      cursor: text;
      padding: 10px;
      color: ${COLOR.PRIMARY};
      font-family: monospace;
    }

    .cm-string {
      color: ${COLOR.YELLOW_1};
    }

    .CodeMirror-cursor {
      border-color: ${COLOR.PRIMARY};
    }

    .cm-variable-2 {
      color: #f17c83;
    }
  }
`;

export const Snippet = styled.div`
  padding: 10px;
`;

export const Function = styled.span`
  color: ${COLOR.VIOLET_1};
`;

export const Argument = styled.span`
  color: ${COLOR.BLUE_1};

  &:not(:last-child):after {
    content: ', ';
  }
`;

export const Line = styled.div`
  width: 100%;
`;
