import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgRightClick = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "right-click" }),
  /* @__PURE__ */ jsx("g", { id: "a96e8c39-2b00-43a3-b7b8-ed020246accd", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "f7f0da86-8d49-4f89-aeb9-fd05ac3f3dfb", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M16,7.17A4.88,4.88,0,0,1,21,12v8.4a5,5,0,0,1-9.94,0V12a4.88,4.88,0,0,1,5-4.8m0-2A6.9,6.9,0,0,0,9,12v8.4a7,7,0,0,0,13.94,0V12a6.9,6.9,0,0,0-7-6.8Z" }),
        /* @__PURE__ */ jsx("line", { x1: 16, y1: 7.17, x2: 16, y2: 13.99, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("line", { x1: 20.8, y1: 14.3, x2: 11.2, y2: 14.3, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, transform: "translate(32 32) rotate(-180)", style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M16.8,6.93v6.68H21a1,1,0,0,1,.17,0V12.13A5.36,5.36,0,0,0,16.8,6.93Z" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgRightClick);
export default ForwardRef;
