import { createApi } from '@reduxjs/toolkit/query/react';

import type { Project } from '@bugbug/core/types/projects';
import type { Suite } from '@bugbug/core/types/suites';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Suites';
export const suitesApi = createApi({
  reducerPath: 'suitesApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/suites/' }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSuites: builder.query<
      Suite[],
      { projectId: Project['id']; query?: string; ordering?: string; pagination?: string }
    >({
      query: (params) => ({
        params,
        url: '',
        method: 'get',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id } as const)), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),
  }),
});

export const { useGetSuitesQuery, useLazyGetSuitesQuery } = suitesApi;
