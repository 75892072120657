import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDblClick = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 32.5, height: 38.17, viewBox: "0 0 32.5 38.17", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "be5eec43-8a2d-4f20-9b5f-fe8a3052d8a1", children: /* @__PURE__ */ jsx("rect", { x: 0.5, width: 12.09, height: 13.04, style: {
    fill: "none"
  } }) }) }),
  /* @__PURE__ */ jsx("title", { children: "action-double-click" }),
  /* @__PURE__ */ jsx("g", { id: "b64509e8-8fe3-4371-aac9-0c1576db0846", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "b1c32098-ecea-4659-be20-9e41b069fff6", "data-name": "Layer 1", children: /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M16,10.17A4.88,4.88,0,0,1,21,15v8.4a5,5,0,0,1-9.94,0V15a4.88,4.88,0,0,1,5-4.8m0-2A6.9,6.9,0,0,0,9,15v8.4a7,7,0,0,0,13.94,0V15a6.9,6.9,0,0,0-7-6.8Z" }),
        /* @__PURE__ */ jsx("line", { x1: 16, y1: 10.17, x2: 16, y2: 16.99, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("line", { x1: 11.2, y1: 17.3, x2: 20.8, y2: 17.3, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsxs("g", { style: {
      clipPath: "url(#be5eec43-8a2d-4f20-9b5f-fe8a3052d8a1)"
    }, children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("rect", { x: 0.5, y: 3, width: 32, height: 32, style: {
          fill: "none"
        } }),
        /* @__PURE__ */ jsx("path", { d: "M16.5,2.17a12.9,12.9,0,0,1,13,12.8v8.4a13,13,0,0,1-25.94,0V15a12.9,12.9,0,0,1,13-12.8m0-2A14.9,14.9,0,0,0,1.53,15v8.4a15,15,0,0,0,29.94,0V15A14.9,14.9,0,0,0,16.5.17Z" })
      ] }),
      /* @__PURE__ */ jsx("path", { d: "M16.5,6.17a8.9,8.9,0,0,1,9,8.8v8.4a9,9,0,0,1-17.94,0V15a8.9,8.9,0,0,1,9-8.8m0-2A10.89,10.89,0,0,0,5.53,15v8.4a11,11,0,0,0,21.94,0V15a10.89,10.89,0,0,0-11-10.8Z" })
    ] })
  ] }) }) })
] });
const ForwardRef = forwardRef(SvgDblClick);
export default ForwardRef;
