import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { selectTestRun } from '~/modules/testRun/testRun.selectors';

import { Container, Content } from './DeleteTestRunsModal.styled';

const DeleteSuiteRunsModal = ({ testRunsIds, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const testRun = useSelector(selectTestRun(testRunsIds?.[0]));

  const handleDeleteSuccess = useCallback(() => {
    modal.hide();
    onDelete?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.hide, onDelete]);

  const removeRequestState = useActionState(TestRunActions.removeRequest, {
    onSuccess: handleDeleteSuccess,
  });

  const handleDeleteConfirm = useCallback(() => {
    dispatch(TestRunActions.removeRequest(testRunsIds));
  }, [dispatch, testRunsIds]);

  return (
    <Container>
      <Content>
        <DeleteIcon />
        <div>
          <Header>
            <Trans i18nKey="deleteTestRunsModal.title" defaults="Clear from history?" />
          </Header>
          <p>
            {testRunsIds?.length === 1 && (
              <Trans
                i18nKey="deleteTestRunsModal.single.description"
                defaults={
                  'You will remove 1 entry from the history of past test runs, the original test "{{ name }}" will not be deleted.'
                }
                values={{ name: testRun?.name }}
                shouldUnescape
              />
            )}
            {testRunsIds?.length > 1 && (
              <Trans
                i18nKey="deleteTestRunsModal.selected.description"
                defaults="You will remove {{ count }} entries from the history of past test runs. The original tests will not be deleted."
                values={{ count: testRunsIds.length }}
              />
            )}
            {!testRunsIds && (
              <Trans
                i18nKey="deleteTestRunsModal.all.description"
                defaults="You will remove all entries from the history of past test runs. The original tests will not be deleted."
              />
            )}
          </p>
        </div>
      </Content>
      <Footer>
        <ErrorInfo isVisible={removeRequestState.hasInternalServerError} />
        <Button onClick={modal.hide}>{t('deleteTestRunsModal.cancel', 'Cancel')}</Button>
        <ActionButton
          onClick={handleDeleteConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={removeRequestState.isLoading}
        >
          {testRunsIds
            ? t('deleteTestRunsModal.confirm', {
                defaultValue_one: 'Clear 1 run from history',
                defaultValue_other: 'Clear {{count}} runs from history',
                count: testRunsIds.length,
              })
            : t('deleteTestRunsModal.confirmAll', 'Clear all runs from history')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DeleteSuiteRunsModal.defaultProps = {
  className: null,
  testRunsIds: null,
  onDelete: Function.prototype,
};

DeleteSuiteRunsModal.propTypes = {
  className: PropTypes.string,
  testRunsIds: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
};

export default DeleteSuiteRunsModal;
