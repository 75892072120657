import spacetime from 'spacetime';

import type { DateInTimezone } from './useTimezone.types';

import { useTimeZoneContext } from './useTimezone.context';
import { collectData } from './useTimezone.utils';

export function useTimezone() {
  const projectTZ = useTimeZoneContext();

  const toProjectTZ = (utcDate: string): DateInTimezone => {
    const tzDate = spacetime(utcDate).goto(projectTZ);

    return collectData(tzDate);
  };

  const toUserTZ = (utcDate: string): DateInTimezone => {
    let userTZ = spacetime().tz;
    let isFallbackValue = false;

    // due to bug in Chrome 117.0.xxx on MacOS, we use `utc` as a fallback user tz
    if (/cet/i.test(userTZ)) {
      userTZ = 'utc';
      isFallbackValue = true;
    }

    const tzDate = spacetime(utcDate).goto(userTZ);

    return collectData(tzDate, isFallbackValue);
  };

  return {
    toProjectTZ,
    toUserTZ,
  };
}
