import CopyButtonBase from '@bugbug/core/components/CopyButton';
import Icon from '@bugbug/core/components/Icon';
import { EndAdornment } from '@bugbug/core/components/Input';
import SelectBase, { ToggleButton } from '@bugbug/core/components/Select';
import { COLOR } from '@bugbug/core/theme/colors';
import styled, { createGlobalStyle } from 'styled-components';

import { Backdrop } from '~/components/modals/Modal';

export const Form = styled.form`
  width: 570px;

  ${EndAdornment} {
    pointer-events: all;
    padding-top: 2px;
  }
`;

export const VariableTypeHeader = styled.div``;

export const VariableTypeDescription = styled.div`
  color: ${COLOR.GRAY_12};
  line-height: 18px;
`;

export const VariableDescription = styled.div`
  color: ${COLOR.GRAY_19};
  line-height: 18px;
  margin: -12px 0 15px 0;
`;

export const Select = styled(SelectBase)`
  ${ToggleButton} {
    ${VariableTypeDescription} {
      display: none;
    }
  }
`;

export const FormBackdropOverrides = createGlobalStyle`
  ${Backdrop} {
    padding-top: 30px;
  }
`;

export const Key = styled.div`
  color: ${COLOR.GRAY_13};
  white-space: nowrap;
  max-width: 13ch;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CopyButton = styled(CopyButtonBase)`
  && {
    color: ${COLOR.GRAY_13};
    margin: 0 3px;
  }
`;

export const KeyInfo = styled(Icon).attrs(() => ({
  name: 'info',
}))`
  && {
    width: 22px;
    height: 22px;
    color: ${COLOR.GRAY_13};

    &:hover {
      color: ${COLOR.DARK_GRAY};
    }
  }
`;
