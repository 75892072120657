import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgRemove = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", width: "24px", height: "24px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { d: "M21,7H3C2.4,7,2,6.6,2,6s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,7,21,7z" }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { d: "M17,23H7c-1.7,0-3-1.3-3-3V6c0-0.6,0.4-1,1-1s1,0.4,1,1v14c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1V6c0-0.6,0.4-1,1-1 s1,0.4,1,1v14C20,21.7,18.7,23,17,23z M16,7c-0.6,0-1-0.4-1-1V4c0-0.6-0.4-1-1-1h-4C9.4,3,9,3.4,9,4v2c0,0.6-0.4,1-1,1S7,6.6,7,6V4 c0-1.7,1.3-3,3-3h4c1.7,0,3,1.3,3,3v2C17,6.6,16.6,7,16,7z" }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { d: "M10,18c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C11,17.6,10.6,18,10,18z" }) }),
  /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { d: "M14,18c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C15,17.6,14.6,18,14,18z" }) })
] });
const ForwardRef = forwardRef(SvgRemove);
export default ForwardRef;
