import * as T from '@bugbug/core/utils/toolbox';
import {
  pathOr,
  propOr,
  path,
  pick,
  isEmpty,
  pipe,
  values,
  filter,
  prop,
  compose,
  map,
  find,
  complement,
  groupBy,
  flatten,
  props,
  sortBy,
  reverse,
} from 'ramda';
import { createSelector } from 'reselect';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import {
  selectTestRunGroups,
  selectTestRunStep,
  selectTestRunSteps,
  selectTestRunGroup,
} from '~/modules/testRun/testRun.selectors';
import { getStepParams } from '~/utils/misc';

const selectTestDomain = (state) => state.test;

export const selectSingleTest = createSelector(selectTestDomain, (state) =>
  pathOr({}, ['single', 'tests', path(['single', 'id'], state)], state),
);

export const selectCurrentStepId = createSelector(selectTestDomain, prop('currentStepId'));

export const selectSingleTestIsRecording = createSelector(selectSingleTest, prop('isRecording'));

export const selectSingleTestHasAnySavedStep = createSelector(
  selectTestDomain,
  pipe(pathOr({}, ['single', 'steps']), values, find(complement(prop('isTemporary'))), Boolean),
);

export const selectDefaultStepsParams = createSelector(
  selectTestDomain,
  prop('defaultStepsParams'),
);

export const selectDefaultStepParamsByType = T.memoize((stepType) =>
  createSelector(selectDefaultStepsParams, prop(stepType)),
);

export const selectIsSingleTestLoading = createSelector(selectTestDomain, prop('isSingleLoading'));

export const selectIsSingleTestRecording = createSelector(selectTestDomain, prop('isRecording'));

export const selectTestGroups = createSelector(selectTestDomain, pathOr({}, ['single', 'groups']));

export const selectTests = createSelector(selectTestDomain, pathOr({}, ['list', 'tests']));

export const selectTestsIdsList = createSelector(selectTestDomain, pathOr([], ['list', 'order']));

export const selectTestsList = createSelector(selectTestsIdsList, selectTests, (testsIds, tests) =>
  props(testsIds, tests),
);

export const selectHasUsedVariousScreenSizes = createSelector(selectTestsList, (tests) =>
  tests.some((test) => test.screenSizeType === SCREEN_RESOLUTION_TYPE.MOBILE),
);

export const selectIsComponent = T.memoize((groupId) =>
  createSelector(selectTestGroups, pathOr(false, [groupId, 'isComponent'])),
);

export const selectTest = T.memoize((testId) => createSelector(selectTests, propOr(null, testId)));

export const selectTestGroupsList = createSelector(
  selectSingleTest,
  selectTestGroups,
  (test, groups) => props(test.groups || [], groups),
);

export const selectTestSteps = createSelector(selectTestDomain, pathOr({}, ['single', 'steps']));

export const selectTestStepsActivation = createSelector(selectTestSteps, map(prop('isActive')));

export const selectTestStepsTemporaryIds = createSelector(
  selectTestSteps,
  compose(map(prop('id')), filter(prop('isTemporary')), values),
);

export const selectTestStep = T.memoize((stepId) =>
  createSelector(selectTestSteps, propOr(null, stepId)),
);

export const selectTestGroup = T.memoize((groupId) =>
  createSelector(selectTestGroups, propOr(null, groupId)),
);

export const selectHasSteps = createSelector(
  selectTestSteps,
  selectTestGroups,
  (steps, groups) => !!Object.keys(steps).length && !!Object.keys(groups).length,
);

export const selectTestIsRecording = createSelector(selectTestDomain, (state) =>
  !isEmpty(state.single) ? state.single.tests[state.single.id].isRecording : false,
);

export const selectTestProfileId = createSelector(selectTestDomain, (state) =>
  !isEmpty(state.single) ? state.single.tests[state.single.id].runProfileId : null,
);

export const selectSearchComponentsData = createSelector(
  selectTestDomain,
  pathOr([], ['search', 'components']),
);

export const selectElementsScreenshots = createSelector(
  selectTestDomain,
  pathOr({}, ['elementsScreenshots']),
);

export const selectElementScreenshot = T.memoize((id) =>
  createSelector(selectElementsScreenshots, prop(id)),
);

export const selectStep = T.memoize((stepId, isTestRun) => {
  const selectData = isTestRun ? selectTestRunStep : selectTestStep;
  return selectData(stepId);
}, T.joinAllArgs);

export const selectStepParams = T.memoize(
  (id, isTestRun) => createSelector(selectStep(id, isTestRun), getStepParams),
  T.joinAllArgs,
);

export const selectGroups = (isTestRun) => (state) =>
  isTestRun ? selectTestRunGroups(state) : selectTestGroups(state);

export const selectTestGroupsIds = createSelector(selectTestGroups, (testGroups) =>
  Object.keys(testGroups),
);

export const selectGroup = (isTestRun, groupId) =>
  isTestRun ? selectTestRunGroup(groupId) : selectTestGroup(groupId);

const getStepCacheId = (step) =>
  `${step.id || step.frontId}.${step.type}.${step.isActive}.${step.isBreakpoint}.${step.sleep}`;

const getGroupStepsList = T.memoize(
  (group, steps) => props(group?.steps || [], steps),
  (group, steps) =>
    group
      ? [group.id, ...group.steps.map((stepId) => getStepCacheId(steps[stepId]))].join()
      : 'undefined',
);

export const deleteGroupsStepsListCache = (groupId, steps) => {
  const key = [groupId, ...steps.map(getStepCacheId)].join();
  getGroupStepsList.cache.delete(key);
};

export const selectGroupStepsList = T.memoize(
  (groupId, isTestRun) =>
    createSelector(
      selectGroup(isTestRun, groupId),
      isTestRun ? selectTestRunSteps : selectTestSteps,
      getGroupStepsList,
    ),
  (groupId, isTestRun) => `${groupId}.${isTestRun}`,
);

export const selectUnconfirmedGroupsMap = createSelector(
  selectTestDomain,
  pathOr({}, ['single', 'unconfirmedGroups']),
);

export const selectHasUnconfirmedGroups = createSelector(
  selectSingleTest,
  pipe(path(['unconfirmedGroups', 'length']), Boolean),
);

export const selectUnconfirmedGroup = (groupId) =>
  createSelector(selectUnconfirmedGroupsMap, propOr({}, groupId));

export const selectRecentUnconfirmedGroup = createSelector(
  selectUnconfirmedGroupsMap,
  pipe(values, sortBy(prop('created')), reverse, prop(0)),
);

export const selectUnconfirmedGroupsByRelatedGroups = createSelector(
  selectUnconfirmedGroupsMap,
  pipe(values, groupBy(prop('unconfirmedRelatedGroup'))),
);

export const selectUnconfirmedGroups = T.memoize(
  (relatedGroupId, index, includeOutOfRange = false) =>
    createSelector(
      selectUnconfirmedGroupsByRelatedGroups,
      pipe(
        propOr([], relatedGroupId),
        filter((group) =>
          includeOutOfRange ? group.unconfirmedIndex >= index : group.unconfirmedIndex === index,
        ),
      ),
    ),
  T.joinAllArgs,
);

export const selectGroupHasUnconfirmedSteps = (groupId) =>
  createSelector(
    selectUnconfirmedGroupsByRelatedGroups,
    pipe(propOr({}, groupId), isEmpty, complement),
  );

export const selectUnconfirmedGroupStepsList = T.memoize((groupId) =>
  createSelector(selectUnconfirmedGroup(groupId), selectTestSteps, (group, steps) =>
    props(group.steps, steps),
  ),
);

export const selectOrderedGroupPartials = T.memoize(
  (groupId, isTestRun = false) =>
    createSelector(
      selectGroup(isTestRun, groupId),
      isTestRun ? selectTestRunSteps : selectTestSteps,
      selectUnconfirmedGroupsByRelatedGroups,
      (group, steps, unconfirmedGroupsMap) => {
        if (!group) {
          return [];
        }
        const unconfirmedGroups = isTestRun ? [] : propOr([], groupId, unconfirmedGroupsMap);
        const unconfirmedGroupsByIndex = groupBy(prop('unconfirmedIndex'), unconfirmedGroups);

        const partials = group.steps.map((stepId, index) => {
          const chunks = [];

          if (unconfirmedGroupsByIndex[index]) {
            chunks.push(unconfirmedGroupsByIndex[index]);
            delete unconfirmedGroupsByIndex[index];
          }
          chunks.push(steps[stepId]);

          return chunks;
        });
        const outOfRangeGroups = flatten(Object.values(unconfirmedGroupsByIndex));
        partials.push(outOfRangeGroups);

        return flatten(partials).map((partial) => ({
          ...partial,
          partialId: partial.steps
            ? `group.${partial.id}`
            : `step.${partial.id || partial.frontId}`,
        }));
      },
    ),
  T.joinAllArgs,
);

export const selectOrderedGroupPartialsIndices = (groupId, isTestRun = false) =>
  createSelector(selectOrderedGroupPartials(groupId, isTestRun), (partials) =>
    partials.reduce((indicesMap, partial, index) => {
      // eslint-disable-next-line no-param-reassign
      indicesMap[partial.partialId] = index;
      return indicesMap;
    }, {}),
  );

export const selectChangedNotificationByTestIdMap = createSelector(
  selectTestDomain,
  prop('changeNotificationByTestId'),
);

export const selectChangedNotificationByTestId = T.memoize((testId) =>
  createSelector(selectChangedNotificationByTestIdMap, propOr(null, testId)),
);

export const selectLastRunsByTestsIds = T.memoize(
  (ids = []) =>
    createSelector(selectTests, (testsMap) =>
      Object.values(pick(ids, testsMap))
        .map(path(['lastUnfinishedUserRun', 'id']))
        .filter(Boolean),
    ),
  (ids = []) => ids.join('.'),
);

export const selectComponentsUsedInTest = createSelector(
  selectSingleTest,
  selectGroups(false),
  (test, groups) => test.groups.filter((id) => groups[id].isComponent),
);

export const selectCurrentStepIndex = createSelector(
  selectTestSteps,
  selectCurrentStepId,
  (currentTestSteps, currentlySelectedStepId) => {
    if (!currentlySelectedStepId) {
      return null;
    }
    const index = Object.keys(currentTestSteps).indexOf(currentlySelectedStepId);
    return index < 0 ? null : index;
  },
);
