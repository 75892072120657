import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgPressMouseButton = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-mousedown" }),
  /* @__PURE__ */ jsx("g", { id: "a74c58d7-c636-488b-bf38-535b0d34a9c7", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "b8138d53-9e90-46f8-b049-1240f77f1cd4", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M16,7.17A4.88,4.88,0,0,1,21,12v8.4a5,5,0,0,1-9.94,0V12a4.88,4.88,0,0,1,5-4.8m0-2A6.9,6.9,0,0,0,9,12v8.4a7,7,0,0,0,13.94,0V12a6.9,6.9,0,0,0-7-6.8Z" }),
        /* @__PURE__ */ jsx("line", { x1: 16, y1: 7.17, x2: 16, y2: 13.99, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("line", { x1: 11.2, y1: 14.3, x2: 20.8, y2: 14.3, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M15.2,6.93v6.68H11a1,1,0,0,0-.17,0V12.13A5.36,5.36,0,0,1,15.2,6.93Z" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgPressMouseButton);
export default ForwardRef;
