import EmptyState from '@bugbug/core/components/EmptyState';
import Icon from '@bugbug/core/components/Icon';
import Loader, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { ScheduleFormValues } from '../EditScheduleModal.types';

import type { Suite } from '@bugbug/core/types/suites';
import useQueryString from '~/hooks/useQueryString';
import { useGetSuitesQuery } from '~/modules/suites/suites.api';

import * as S from './ScheduleSuitesList.styled';

const ScheduleSuitesList = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<ScheduleFormValues>();
  const queryString = useQueryString();
  const selectedSuites = formik.values.suites;
  const { currentData: suites = [], isLoading } = useGetSuitesQuery({
    projectId: formik.values.project,
    ordering: 'name',
    pagination: 'off',
  });

  const getToggleSuiteHandler =
    (suiteId: Suite['id'], selected: boolean): React.MouseEventHandler<HTMLDivElement> =>
    (event) => {
      event.preventDefault();

      const newValue = selected
        ? selectedSuites.filter((id) => id !== suiteId)
        : [...selectedSuites, suiteId];

      if (!selectedSuites.length) {
        formik.setFieldTouched('enabled', true);
        formik.setFieldValue('enabled', true);
      }
      if (!newValue.length) {
        formik.setFieldTouched('enabled', true);
        formik.setFieldValue('enabled', false);
      }

      formik.setFieldTouched('suites', true);
      formik.setFieldValue('suites', newValue);
    };

  const filteredSuites = suites.filter((suite) => {
    if (!queryString.query) {
      return true;
    }

    return suite.name.toLowerCase().includes(queryString.query.toString().toLowerCase());
  });

  return (
    <S.Container>
      <S.FormField
        label={t('schedulesSuitesList.label', 'Scheduled suites')}
        description={t('schedulesSuitesList.description', {
          defaultValue_one: '{{ count }} suite selected',
          defaultValue_other: '{{ count }} suites selected',
          count: selectedSuites.length,
        })}
      >
        <S.List>
          {/* Loading state */}
          {isLoading && (
            <LoaderFlexContainer>
              <Loader />
            </LoaderFlexContainer>
          )}

          {/* Empty state */}
          {!isLoading && !suites.length && (
            <EmptyState
              isVisible
              inline
              text={t('schedulesSuitesList.emptyList.text', "You haven't created any suites yet")}
            />
          )}

          {/* Empty state */}
          {!isLoading && suites.length && !filteredSuites.length && (
            <EmptyState
              isVisible
              inline
              text={t(
                'schedulesSuitesList.emptyFilteredList.text',
                'There is no suites matching your search query.',
              )}
            />
          )}

          {/* Suites List */}
          {!isLoading && !!filteredSuites.length
            ? filteredSuites.map((suite) => {
                const isSelected = selectedSuites.includes(suite.id);
                const handleToggleSuite = getToggleSuiteHandler(suite.id, isSelected);
                return (
                  <S.Suite
                    key={suite.id}
                    selected={isSelected}
                    aria-selected={isSelected}
                    onClick={handleToggleSuite}
                  >
                    {suite.name}
                    {/* @ts-expect-error ToggleButton extends IconButton which is still jsx */}
                    <S.ToggleButton onClick={handleToggleSuite}>
                      {isSelected ? <Icon name="check" /> : <Icon name="add" />}
                    </S.ToggleButton>
                  </S.Suite>
                );
              })
            : []}
        </S.List>
      </S.FormField>
    </S.Container>
  );
};

export default ScheduleSuitesList;
