import type { TestRun } from '@bugbug/core/types/tests';
import i18n from '~/translations';
import { urlValidator } from '~/utils/validators';
import urls, { reverse } from '~/views/urls';

import toasts from './common';

export const getRedirectUrl = (
  testRun: Pick<TestRun, 'projectId' | 'projectSlug' | 'id'>,
  organizationId: string,
) => {
  const url = reverse(urls.testRun, {
    projectId: testRun.projectId,
    projectSlug: testRun.projectSlug,
    testRunId: testRun.id,
    organizationId,
  });

  return urlValidator.isValidSync(`${window.location.origin}${url}`) ? url : '';
};

export const showTestStarted = (testRun: TestRun, organizationId: string) => {
  const redirectUrl = getRedirectUrl(testRun, organizationId);
  const content = i18n.t('notifications.tests.started', 'Test started #{{ sequence }}', {
    sequence: testRun.sequence,
  });

  toasts.show({ content, redirectUrl });
};

export const showTestsStarted = (testRuns: TestRun[], organizationId: string) => {
  if (testRuns?.length === 1) {
    showTestStarted(testRuns[0], organizationId);
  } else {
    const content = !testRuns
      ? i18n.t('notifications.tests.allStarted', 'All tests have been started')
      : i18n.t('notifications.tests.multiStarted', '{{count}} tests started', {
          count: testRuns.length,
        });
    const id = testRuns ? testRuns.join('.') : 'all';
    toasts.show({ content, id });
  }
};

export const showTestsStopped = (testRunsIds: string[]) => {
  const content = !testRunsIds
    ? i18n.t('notifications.tests.allStopped', 'All tests have been stopped')
    : i18n.t('notifications.tests.stopped', {
        defaultValue_one: 'Test run stopped',
        defaultValue_other: '{{count}} test runs have been stopped',
        count: testRunsIds.length,
      });
  const id = testRunsIds ? testRunsIds.join('.') : 'all';

  toasts.show({ content, id });
};

export const showTestPassed = (testRun: TestRun, organizationId: string) => {
  const redirectUrl = getRedirectUrl(testRun, organizationId);
  const content = i18n.t('notifications.tests.passed', 'Test passed #{{ sequence }}', {
    sequence: testRun.sequence,
  });

  toasts.showSuccess({ content, redirectUrl });
};

export const showTestFailed = (testRun: TestRun, organizationId: string) => {
  const redirectUrl = getRedirectUrl(testRun, organizationId);
  const content = i18n.t('notifications.tests.failed', 'Test failed #{{ sequence }}', {
    sequence: testRun.sequence,
  });

  toasts.showError({ content, redirectUrl });
};

export const showTestError = (testRun: TestRun, organizationId: string) => {
  const redirectUrl = getRedirectUrl(testRun, organizationId);
  const content = i18n.t('notifications.tests.error', 'Test error #{{ sequence }}', {
    sequence: testRun.sequence,
  });

  toasts.showError({ content, redirectUrl });
};

export const showGetDefaultStepsParamsError = () => {
  const content = i18n.t(
    'notifications.tests.getDefaultStepsParamsRequestError',
    'An error occurred while getting default params',
  );

  toasts.showError({ content });
};

export const showSearchComponentsError = () => {
  const content = i18n.t(
    'notifications.tests.searchComponentsError',
    'An error occurred while getting components',
  );

  toasts.showError({ content });
};
