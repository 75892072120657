import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { useTranslation } from 'react-i18next';

import { Header, Footer, InfoIcon } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import * as S from './UnavailableStepModal.styled';

const UnavailableStepModal = ({ className = '' }) => {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <S.Container
      className={className}
      aria-label={t(
        'unavailableStepModal.ariaLabel',
        "Provided step doesn't exist anymore in this test",
      )}
    >
      <S.Content>
        <InfoIcon />
        <div>
          <Header>
            {t('unavailableStepModal.title', "Provided step doesn't exist anymore in this test")}
          </Header>
          <p data-testid="Description">
            {t(
              'unavailableStepModal.description',
              "The link you're using is no longer valid because the step it refers to has been deleted.",
            )}
          </p>
        </div>
      </S.Content>
      <Footer>
        <Button onClick={modal.hide} bordered variant={BUTTON_VARIANT.PRIMARY}>
          {t('unavailableStepModal.close', 'Close')}
        </Button>
      </Footer>
    </S.Container>
  );
};

export default UnavailableStepModal;
