import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEyeNotVisible = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 33, viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M5 16.48C5 16.48 9 8.47998 16 8.47998C23 8.47998 27 16.48 27 16.48C27 16.48 23 24.48 16 24.48C9 24.48 5 16.48 5 16.48Z", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M16 19.48C17.6569 19.48 19 18.1368 19 16.48C19 14.8231 17.6569 13.48 16 13.48C14.3431 13.48 13 14.8231 13 16.48C13 18.1368 14.3431 19.48 16 19.48Z", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M7.15625 4.74557L27.9693 25.5586", stroke: "white", strokeWidth: 5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M7.15625 4.74557L27.9693 25.5586", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })
] });
const ForwardRef = forwardRef(SvgEyeNotVisible);
export default ForwardRef;
