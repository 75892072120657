import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { Header, Footer, ErrorInfo, DeleteIcon } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest, selectTest } from '~/modules/test/test.selectors';

import { Container, Content } from './DeleteTestsModal.styled';

const DeleteTestsModal = ({ testsIds, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const test = useSelector(selectTest(testsIds?.[0]));
  const currentTest = useSelector(selectSingleTest);

  const handleDeleteConfirm = useCallback(() => {
    dispatch(TestActions.removeRequest(testsIds));
  }, [dispatch, testsIds]);

  const handleSuccess = () => {
    onDelete();
    modal.hide();
  };

  const removeRequestState = useActionState(TestActions.removeRequest, {
    onSuccess: handleSuccess,
    reset: false,
  });

  const handleCancel = () => {
    modal.hide();
  };

  useUnmount(() => {
    removeRequestState.reset();
  });

  return (
    <Container>
      <Content>
        <DeleteIcon />
        <div>
          {testsIds?.length === 1 && (
            <>
              <Header>
                <Trans i18nKey="deleteTestsModal.single.title" defaults="Delete test?" />
              </Header>
              <p>
                <Trans
                  i18nKey="deleteTestsModal.single.description"
                  defaults={
                    'Are you sure you want to delete test "{{ name }}"? It will be deleted permanently. You <strong>can\'t undo this.</strong>'
                  }
                  values={{ name: test?.name || currentTest?.name }}
                  shouldUnescape
                />
              </p>
            </>
          )}
          {testsIds?.length > 1 && (
            <>
              <Header>
                <Trans
                  i18nKey="deleteTestsModal.selected.title"
                  defaults="Delete {{ count }} tests?"
                  values={{ count: testsIds.length }}
                />
              </Header>
              <p>
                <Trans
                  i18nKey="deleteTestsModal.selected.description"
                  defaults="Are you sure you want to delete {{ count }} tests? The tests will be deleted permanently. You <strong>can't undo this.</strong>"
                  values={{ count: testsIds.length }}
                />
              </p>
            </>
          )}
          {!testsIds && (
            <>
              <Header>
                <Trans i18nKey="deleteTestsModal.all.title" defaults="Delete all tests?" />
              </Header>
              <p>
                <Trans
                  i18nKey="deleteTestsModal.all.description"
                  defaults="Are you sure you want to delete all tests? The tests will be deleted permanently. You <strong>can't undo this.</strong>"
                />
              </p>
            </>
          )}
        </div>
      </Content>
      <Footer>
        <ErrorInfo isVisible={removeRequestState.hasInternalServerError} />
        <Button onClick={handleCancel}>{t('deleteTestsModal.cancel', 'Cancel')}</Button>
        <ActionButton
          onClick={handleDeleteConfirm}
          variant={BUTTON_VARIANT.PRIMARY}
          pending={removeRequestState.isLoading}
        >
          {testsIds
            ? t('deleteTestsModal.confirm', {
                defaultValue_one: 'Delete test',
                defaultValue_other: 'Delete {{count}} tests',
                count: testsIds.length,
              })
            : t('deleteTestsModal.confirmAll', 'Delete all tests')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

DeleteTestsModal.defaultProps = {
  className: null,
  testsIds: null,
  onDelete: Function.prototype,
};

DeleteTestsModal.propTypes = {
  className: PropTypes.string,
  testsIds: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
};

export default DeleteTestsModal;
