import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { values } from 'ramda';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { MODAL_TYPE } from '~/components/modals';
import { SUBSCRIPTION_TYPE } from '~/constants/subscription';
import useModal from '~/hooks/useModal';
import {
  selectIsOrganizationAdmin,
  selectIsTrialEnded,
  selectSubscription,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import * as S from './UpgradePlan.styled';

const UpgradePlan = (props) => {
  const { className, minPlan, centeredContent } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { organizationId } = useParams();
  const isAdmin = useSelector(selectIsOrganizationAdmin);
  const subscription = useSelector(selectSubscription);
  const isTrialEnded = useSelector(selectIsTrialEnded);
  const isTrialActive = subscription.isTrial && !isTrialEnded;
  const modal = useModal();

  const handleButtonClick = () => {
    analytics.trackEvent(TRACK_EVENT_TYPE.UPGRADE_SUBSCRIPTION);
    history.push(reverse(urls.subscription, { organizationId }));
  };

  const handleStartTrial = useCallback(() => modal.show(MODAL_TYPE.START_TRIAL), [modal]);

  const renderAdminContent = renderWhenTrue(() => (
    <>
      <S.Subtitle>
        {t('upgradePlan.subtitle', 'Your current plan: {{ currentPlan, uppercase}}', {
          currentPlan: subscription.planType,
        })}
      </S.Subtitle>
      {!isTrialActive && !isTrialEnded ? (
        <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleStartTrial} bordered>
          {t('upgradePlan.actionText.startTrial', 'Start free trial')}
        </Button>
      ) : (
        <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleButtonClick} bordered>
          {t('upgradePlan.actionText.upgrade', 'Upgrade Now')}
        </Button>
      )}
    </>
  ));

  return (
    <S.Container
      className={className}
      data-testid="UpgradePlan"
      noHeader
      centeredContent={centeredContent}
    >
      <S.Content>
        <S.Icon />
        <S.Title>
          {t(
            'upgradePlan.title',
            'This functionality is available in the {{ minPlan, uppercase}} plan or higher',
            {
              minPlan,
            },
          )}
        </S.Title>
        {renderAdminContent(isAdmin)}
      </S.Content>
    </S.Container>
  );
};

UpgradePlan.defaultProps = {
  centeredContent: false,
  className: null,
  minPlan: SUBSCRIPTION_TYPE.PRO,
};

UpgradePlan.propTypes = {
  className: PropTypes.string,
  centeredContent: PropTypes.bool,
  minPlan: PropTypes.oneOf(values(SUBSCRIPTION_TYPE)),
};

export default UpgradePlan;
