import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { ProjectActions } from '~/modules/project/project.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

const DeleteProjectModal = ({ className, project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => {
    modal.hide();
  }, [modal]);

  const { isLoading, hasInternalServerError, reset } = useActionState(
    ProjectActions.removeRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
    },
  );

  const handleProjectRemove = () => {
    analytics.trackEvent(TRACK_EVENT_TYPE.DELETE_PROJECT);
    dispatch(ProjectActions.removeRequest(project.id));
  };

  const handleCancel = () => {
    modal.hide();
  };

  useUnmount(() => {
    reset();
  });

  return (
    <ConfirmModal
      className={className}
      title={t('deleteProjectModal.title', `Are you sure to delete project "{{name}}"?`, {
        name: project.name,
        interpolation: { escapeValue: false },
      })}
      hasInternalServerError={hasInternalServerError}
      onCancel={handleCancel}
      onConfirm={handleProjectRemove}
      pending={isLoading}
    />
  );
};

DeleteProjectModal.defaultProps = {
  className: null,
};

DeleteProjectModal.propTypes = {
  className: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteProjectModal;
