import * as T from '@bugbug/core/utils/toolbox';
import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { Group } from '@bugbug/core/types/groups';
import type { IntegrationTrigger } from '@bugbug/core/types/integrations';
import type { ProjectSettingsTab } from '@bugbug/core/types/projects';
import type { RunsHistoryTab, Test } from '@bugbug/core/types/tests';
import type { VariablesTab } from '@bugbug/core/types/variables';

interface UIState {
  components: Record<string, object>;
  collapsedGroups: Record<Test['id'], Group['id'][]>;
  integrationTrigger?: IntegrationTrigger;
  recentlySelectedTabs: {
    projectSettings: ProjectSettingsTab;
    runsHistory: RunsHistoryTab;
    variables: VariablesTab;
  };
}

const initialState: UIState = {
  components: {},
  collapsedGroups: {},
  recentlySelectedTabs: {
    projectSettings: 'general',
    runsHistory: 'testRunsList',
    variables: 'builtInVariables',
  },
};

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,

  reducers: {
    setUIState(state, action: PayloadAction<{ componentName: string; data: object }>) {
      state.components[action.payload.componentName] = {
        ...state.components[action.payload.componentName],
        ...action.payload.data,
      };
    },

    forgetCollapsedGroup(
      state,
      action: PayloadAction<{ collapsedGroupId: Group['id']; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = T.removeArrayItem(
        state.collapsedGroups[action.payload.testId],
        action.payload.collapsedGroupId,
      );
    },

    setCollapsedGroups(
      state,
      action: PayloadAction<{ collapsedGroupsIds: Group['id'][]; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = action.payload.collapsedGroupsIds;
    },

    toggleCollapsedGroup(
      state,
      action: PayloadAction<{ collapsedGroupId: Group['id']; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = T.toggleArrayItem(
        state.collapsedGroups[action.payload.testId],
        action.payload.collapsedGroupId,
      );
    },

    setIntegrationTrigger(state, action: PayloadAction<UIState['integrationTrigger']>) {
      state.integrationTrigger = action.payload;
    },

    removeIntegrationTrigger(state) {
      delete state.integrationTrigger;
    },

    setProjectSettingsTab(state, action: PayloadAction<ProjectSettingsTab>) {
      state.recentlySelectedTabs.projectSettings = action.payload;
    },

    setRunsHistoryTab(state, action: PayloadAction<RunsHistoryTab>) {
      state.recentlySelectedTabs.runsHistory = action.payload;
    },

    setVariablesTab(state, action: PayloadAction<VariablesTab>) {
      state.recentlySelectedTabs.variables = action.payload;
    },
  },
});

export const UIStateActions = uiStateSlice.actions;

export default uiStateSlice.reducer;
