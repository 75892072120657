import { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Footer, ErrorInfo } from '~/components/modals/Modal';
import { SUBSCRIPTION_TYPE } from '~/constants/subscription';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import { selectSubscription } from '~/modules/organization/organization.selectors';

import { Container, Content, CloseButton } from './StartTrialModal.styled';

import LogoVert from '~/images/logo-vertical.svg?react';

const StartTrialModal = ({ className }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const dispatch = useDispatch();
  const subscription = useSelector(selectSubscription);
  const startTrialState = useActionState(OrganizationActions.startTrialRequest);

  const handleStart = () => {
    dispatch(OrganizationActions.startTrialRequest());
  };

  const renderInformation = () => (
    <>
      <LogoVert />
      <div>
        <Header>{t('startTrialModal.start.title', 'Start a free 14-day trial')}</Header>
        <p>
          <Trans
            i18nKey="startTrialModal.start.firstParagraph"
            defaults="You're currently on a {{ planType, uppercase }} plan which has limited features."
            values={{ planType: subscription.planType }}
          />
        </p>
        <p>
          <Trans
            i18nKey="startTrialModal.start.secondParagraph"
            defaults="<strong>Try a paid plan for free. No credit card required.</strong> Just click the button to test premium features, schedules and cloud runs for 14 days."
          />
        </p>
      </div>
    </>
  );

  const renderSuccess = () => (
    <>
      <Icon name="checkCircle" fill={COLOR.SUCCESS} />
      <div>
        <Header>{t('startTrialModal.success.title', 'Free trial started!')}</Header>
        <p>
          <Trans
            i18nKey="startTrialModal.success.firstParagraph"
            defaults="<strong>You have 14 days to test all premium features. Have fun!</strong>"
          />
        </p>
        <p>
          {t(
            'startTrialModal.success.secondParagraph',
            'The free trial that allows you to use our paid plan "{{ planType, uppercase }}" for free until {{ endDate, date }}. After that you will go automatically go back to plan "{{ nextPlanType, uppercase }}"',
            {
              planType: subscription.planType,
              nextPlanType: SUBSCRIPTION_TYPE.FREE,
              endDate: subscription.end,
            },
          )}
        </p>
      </div>
    </>
  );

  const ariaLabel = subscription.isTrial
    ? t('startTrialModal.start.title')
    : t('startTrialModal.success.title');

  return (
    <Container className={className} aria-label={ariaLabel}>
      <Content>{subscription.isTrial ? renderSuccess() : renderInformation()}</Content>
      <Footer>
        <ErrorInfo isVisible={startTrialState.hasInternalServerError} />
        <CloseButton onClick={modal.hide}>
          {subscription.isTrial ? t('default.button.close') : t('default.button.cancel')}
        </CloseButton>
        {!subscription.isTrial && (
          <ActionButton
            onClick={handleStart}
            variant={BUTTON_VARIANT.PRIMARY}
            pending={startTrialState.isLoading}
          >
            {t('startTrialModal.start.submitButton', 'Start free trial')}
          </ActionButton>
        )}
      </Footer>
    </Container>
  );
};

StartTrialModal.defaultProps = {
  className: null,
};

StartTrialModal.propTypes = {
  className: PropTypes.string,
};

export default StartTrialModal;
