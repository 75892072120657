import SearchInputBase from '@bugbug/core/components/SearchInput';
import { Form as FormBase } from 'formik';
import styled from 'styled-components';

import * as Modal from '~/components/modals/Modal';

export const Content = styled(Modal.Content)`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 20px;
  overflow: hidden;
`;

export const SearchInput = styled(SearchInputBase)`
  font-weight: 400;
`;

export const Form = styled(FormBase)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: hidden;
`;

export const Footer = styled(Modal.Footer)`
  && {
    margin-bottom: 6px;
    margin-right: 3px;
  }
`;
