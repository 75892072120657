import Input from '@bugbug/core/components/Input';
import Switch from '@bugbug/core/components/Switch';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { ScheduleFormValues } from '../EditScheduleModal.types';

import type { ScheduleTimer } from '@bugbug/core/types/schedules';
import FormField from '~/components/FormField';

import ScheduleTimePicker from '../ScheduleTimePicker';

import * as S from './ScheduleSettings.styled';

const ScheduleSettings = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<ScheduleFormValues>();

  const handleTimeUnitChange = (values: Partial<ScheduleTimer>) => {
    Object.entries(values).forEach(([key, value]) => {
      formik.setFieldValue(key, value);
    });
  };

  const isSwitchDisabled = !formik.values.suites.length;
  const switchTooltip = isSwitchDisabled
    ? t(
        'scheduleModal.enabledSwitch.disabledTooltip',
        'This schedule is empty. You need to select suites to enable it.',
      )
    : t(
        'scheduleModal.enabledSwitch.tooltip',
        'You can disable this schedule without permanently deleting it.',
      );

  return (
    <S.Container>
      <FormField label={t('scheduleModal.name.label', 'Name')} labelId="name">
        <Input
          {...formik.getFieldProps('name')}
          {...formik.getFieldMeta('name')}
          error={formik.errors.name}
          autoFocus
          fullWidth
          aria-labelledby="name"
        />
      </FormField>
      <FormField label={t('scheduleModal.time.label', 'Time')}>
        <ScheduleTimePicker value={formik.values} onChange={handleTimeUnitChange} />
      </FormField>

      <FormField>
        <Switch
          {...formik.getFieldMeta('enabled')}
          {...formik.getFieldProps('enabled')}
          disabled={isSwitchDisabled}
          label={t('scheduleModal.enabledSwitch.label', 'Enabled')}
          tooltip={switchTooltip}
          tooltipAnchor="right-center"
        />
      </FormField>
    </S.Container>
  );
};

export default ScheduleSettings;
