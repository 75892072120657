import Icon from '@bugbug/core/components/Icon';
import Input from '@bugbug/core/components/Input';
import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import { getTimezoneOffset } from '@bugbug/core/hooks/useTimezone';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Schedule, ScheduleTimer } from '@bugbug/core/types/schedules';
import { DAYS_OF_WEEK, HOURS, TIME_UNIT_DATA } from '~/constants/schedules';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { getTimeUnit } from '~/utils/schedules';

import { getDefaultTimerConfig } from '../EditScheduleModal.helpers';

import { getDaysOfWeekValue } from './ScheduleTimePicker.helpers';
import * as S from './ScheduleTimePicker.styled';

interface ScheduleTimePickerProps {
  value: ScheduleTimer;
  onChange: (value: Partial<ScheduleTimer>) => void;
}

const ScheduleTimePicker = ({ value, onChange }: ScheduleTimePickerProps) => {
  const { t } = useTranslation();
  const formik = useFormikContext<Schedule>();
  const timeUnit = getTimeUnit(value);

  const flags = useFeatureFlags();

  const handleTimeUnitChange = useCallback(
    (event) => {
      const defaultConfig = getDefaultTimerConfig(event.target.value);
      onChange(defaultConfig);
    },
    [onChange],
  );

  const handleDaysOfWeekChange = useCallback(
    (event) => {
      onChange({ dayOfWeek: event.target.value.join(',') });
    },
    [onChange],
  );

  const timeUnits = useMemo(
    () =>
      Object.values(TIME_UNIT_DATA).filter((item) => {
        if (item.id === 'everyFiveMinutes') {
          return flags.schedulerEveryFiveMinutes;
        }
        if (item.id === 'everyMinute') {
          return flags.schedulerEveryMinute;
        }

        return true;
      }),
    [flags.schedulerEveryFiveMinutes, flags.schedulerEveryMinute],
  );

  const showRunAt = !['hourly', 'everyMinute', 'everyFiveMinutes'].includes(timeUnit);

  return (
    <S.Container>
      <S.Interval>
        <S.SettingName>{t('scheduleTimePicker.field.run.label', 'Run')}</S.SettingName>
        <S.Select value={timeUnit} onChange={handleTimeUnitChange}>
          {timeUnits.map((item) => (
            <SelectOption key={item.id} value={item.id}>
              {item.name}
            </SelectOption>
          ))}
        </S.Select>
      </S.Interval>

      {timeUnit === 'weekly' && (
        <S.WeekDays>
          <S.SettingName>{t('scheduleTimePicker.field.dayOfWeek.label', 'on')}</S.SettingName>
          <S.DaysOfWeekPicker
            {...formik.getFieldProps('dayOfWeek')}
            {...formik.getFieldMeta('dayOfWeek')}
            onChange={handleDaysOfWeekChange}
            value={getDaysOfWeekValue(value)}
            error={formik.errors.dayOfWeek}
            items={DAYS_OF_WEEK}
          />
        </S.WeekDays>
      )}

      {timeUnit === 'monthly' && (
        <S.WeekDays>
          <S.SettingName>{t('scheduleTimePicker.field.dayOfMonth.label', 'on')}</S.SettingName>
          <S.DayOfMonth>
            <Input
              {...formik.getFieldProps('dayOfMonth')}
              {...formik.getFieldMeta('dayOfMonth')}
              endAdornment={t('scheduleTimePicker.field.dayOfMonth.description', 'day of month')}
              fullWidth
            />
          </S.DayOfMonth>
        </S.WeekDays>
      )}

      {showRunAt && (
        <S.Time>
          <S.SettingName>{t('scheduleTimePicker.field.at.label', 'at')}</S.SettingName>

          <S.Select {...formik.getFieldProps('hour')} {...formik.getFieldMeta('hour')}>
            {HOURS.map((item) => (
              <SelectOption key={item.id} value={item.id}>
                {item.name}
              </SelectOption>
            ))}
          </S.Select>
          <S.Timezone>
            <Tooltip
              content={t(
                'scheduleTimePicker.field.at.timezone',
                'Timezone can be changed in project settings',
              )}
              anchor="bottom-center"
            >
              {getTimezoneOffset(formik.values.timezone)}
              <Icon name="help" />
            </Tooltip>
          </S.Timezone>
        </S.Time>
      )}
    </S.Container>
  );
};

export default ScheduleTimePicker;
