import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormField from '~/components/FormField';
import { Header, Footer, Content } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { selectProfilesList, selectProfile } from '~/modules/profile/profile.selectors';

import { FORM_FIELD } from './RunWithProfileModal.constants';
import { Form } from './RunWithProfileModal.styled';

const RunWithProfileModal = ({ initialProfileId, onSuccess }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const profiles = useSelector(selectProfilesList);
  const defaultProfile = useSelector(selectProfile(initialProfileId));

  const initialValues = useMemo(
    () => ({
      runProfileId: defaultProfile?.id,
    }),
    [defaultProfile],
  );

  const handleSubmit = useCallback(
    (values, formik) => {
      onSuccess(values.runProfileId);
      formik.setSubmitting(true);
      modal.hide();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.hide, onSuccess],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Header>{t('runWithProfileModal.profile.title', 'Choose run profile')}</Header>
      <Content>
        <FormField label={t('runWithProfileModal.profile.label', 'Profile')}>
          <Select
            {...formik.getFieldProps(FORM_FIELD.PROFILE)}
            error={formik.touched[FORM_FIELD.PROFILE] && formik.errors[FORM_FIELD.PROFILE]}
          >
            {profiles.map((profile) => (
              <SelectOption key={profile.id} value={profile.id}>
                {profile.name}
              </SelectOption>
            ))}
          </Select>
        </FormField>
      </Content>
      <Footer>
        <Button
          data-testid="RunWithProfileModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="RunWithProfileModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={formik.isSubmitting}
        >
          {t('default.button.continue')}
        </ActionButton>
      </Footer>
    </Form>
  );
};

RunWithProfileModal.propTypes = {
  initialProfileId: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default RunWithProfileModal;
