import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Footer } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';

import { Container, Content, DiscardContainer } from './UnsavedChangesModal.styled';

const UnsavedChangesModal = ({ className, onSave, onDiscard }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleSave = useCallback(() => {
    onSave();
    modal.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiscard = useCallback(() => {
    onDiscard();
    modal.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className={className}
      aria-label={t('unsavedChangesModal.ariaLabel', 'Unsaved changes')}
    >
      <Content>
        <Icon name="help" />
        <div>
          <Header>{t('unsavedChangesModal.title', 'Unsaved changes')}</Header>
          <p>
            {t(
              'unsavedChangesModal.description',
              'You have unsaved changes. Would you like to save these changes before closing?',
            )}
          </p>
        </div>
      </Content>
      <Footer>
        <DiscardContainer>
          <Button onClick={handleDiscard} bordered variant={BUTTON_VARIANT.PRIMARY}>
            {t('unsavedChangesModal.discard', 'Discard changes')}
          </Button>
        </DiscardContainer>

        <Button onClick={modal.hide}>{t('unsavedChangesModal.return', 'Return to editing')}</Button>
        <ActionButton onClick={handleSave} variant={BUTTON_VARIANT.PRIMARY}>
          {t('unsavedChangesModal.saveChanges', 'Save changes')}
        </ActionButton>
      </Footer>
    </Container>
  );
};

UnsavedChangesModal.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default UnsavedChangesModal;
