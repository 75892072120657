import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgClose = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 10, height: 10, viewBox: "0 0 14 14", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "Close" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "Layer_1-2", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("line", { x1: 13, y1: 1, x2: 1, y2: 13, style: {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 1, y1: 1, x2: 13, y2: 13, style: {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
