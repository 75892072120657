import Button from '@bugbug/core/components/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { SideEffect } from '@bugbug/core/types/utils';
import FormField from '~/components/FormField';
import * as Modal from '~/components/modals/Modal';
import { usePrint } from '~/components/PrintableDocument';
import TextArea from '~/components/TextArea';
import useModal from '~/hooks/useModal';
import exportInfoSrc from '~/images/export-pdf.png';

import * as S from './DownloadPdfModal.styled';

interface DownloadPdfModalProps {
  name: string;
  onDownload?: SideEffect;
}

const DownloadPdfModal = ({ name, onDownload }: DownloadPdfModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const printContext = usePrint();

  const handleNoteChange = (event) => {
    printContext.setContent({
      description: event.target.value
        ? t('downloadPdfModal.print.note', 'Note: {{ note }}', {
            note: event.target.value,
          })
        : '',
    });
  };

  const handleDownloadReport = () => {
    onDownload?.();

    modal.hide();
    printContext.print();
  };

  useEffect(() => {
    printContext.setContent({
      description: '',
    });
  }, [printContext]);

  return (
    <S.Container>
      <Modal.Header>
        {t('downloadPdfModal.name.label', 'Download "{{ name }}" report as PDF file', {
          name,
        })}
      </Modal.Header>
      <Modal.Content>
        <FormField
          counter="1"
          label={t('downloadPdfModal.note.label', 'Customize your report')}
          description={t(
            'downloadPdfModal.note.description',
            'Add your own note to the report summary, for example you can explain why some tests were skipped etc.',
          )}
        >
          <TextArea
            placeholder={t('downloadPdfModal.note.placeholder', 'Please add your note here...')}
            autoFocus
            fullWidth
            initialHeight={70}
            onChange={handleNoteChange}
          />
        </FormField>
        <FormField
          counter="2"
          label={t('downloadPdfModal.save.label', 'Select “Save as PDF” in the next system dialog')}
        >
          <img src={exportInfoSrc} alt={t('downloadPdfModal.save.imgAlt', 'Export info')} />
        </FormField>
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={modal.hide}>{t('default.button.cancel')}</Button>
        <Button variant="primary" type="submit" onClick={handleDownloadReport}>
          {t('default.button.continue')}
        </Button>
      </Modal.Footer>
    </S.Container>
  );
};

export default DownloadPdfModal;
