import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Content as ModalContent } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 100%;
  max-width: 860px;
  min-width: 816px;
`;

export const Content = styled(ModalContent)`
  position: relative;
  padding-bottom: 33px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  z-index: 2;
`;
