import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from './BackButton.styled';

interface BackButtonLocationState {
  backUrl?: string;
}
export interface BackButtonProps {
  className?: string;
  defaultUrl?: string;
}

export const BackButton = ({ className, defaultUrl = '' }: BackButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory<BackButtonLocationState>();
  const { state } = history.location;
  const backUrl = state?.backUrl || defaultUrl;

  const handleClick = useCallback(() => {
    history.push(backUrl);
  }, [history, backUrl]);

  return (
    <Button
      aria-label={t('backButton.label', 'Back')}
      iconName="arrowBackRounded"
      className={className}
      onClick={handleClick}
    />
  );
};
