import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { useTimezone } from '@bugbug/core/hooks/useTimezone';
import * as T from '@bugbug/core/utils/toolbox';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Content, Footer } from '~/components/modals/Modal';
import RunEnvironmentSection from '~/components/RunEnvironmentSection';
import RunMetadataSection, * as SectionS from '~/components/RunMetadataSection';
import useModal from '~/hooks/useModal';
import { RUN_ENV, RUN_TRIGGER } from '~/modules/constans';

import * as S from './RunMetadataModal.styled';

const GeneralMetadataSection = memo(({ title, data }) => {
  const { t } = useTranslation();
  const { toProjectTZ } = useTimezone();

  return (
    <SectionS.Section>
      {title && (
        <SectionS.Title>
          {title} <hr />
        </SectionS.Title>
      )}
      <SectionS.IdField value={data.id} />
      {data.created && (
        <SectionS.MetaData>
          <SectionS.Label>
            {data.created.label ?? t('runMetadataModal.created.label', 'Created')}
          </SectionS.Label>
          <SectionS.Value>
            {data.created
              ? toProjectTZ(data.created.value ?? data.created).numeric
              : t('default.emptyValue')}
          </SectionS.Value>
        </SectionS.MetaData>
      )}
      {data.createdBy && (
        <SectionS.MetaData>
          <SectionS.Label>
            {data.createdBy.label ?? t('runMetadataModal.createdBy.label', 'Created by')}
          </SectionS.Label>
          <SectionS.Value>{data.createdBy}</SectionS.Value>
        </SectionS.MetaData>
      )}
    </SectionS.Section>
  );
});

GeneralMetadataSection.displayName = 'GeneralMetadataSection';

const RunMetadataModal = ({ className, runData = {}, generalData = {}, running = false }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const metadata = T.pick(
    [
      'ended',
      'hiddenViaSuite',
      'logsArtifactUrl',
      'rawDuration',
      'runMode',
      'sequence',
      'started',
      'status',
      'stepRunId',
      'suiteRun',
      'suiteRunId',
      'testRunId',
      'triggeredBy',
      'user',
      'waitingConditions',
    ],
    runData,
  );

  const environment = T.pick(
    [
      'browserHeight',
      'browserName',
      'browserVersion',
      'browserWidth',
      'extensionVersion',
      'osName',
      'profileName',
      'runMode',
      'screenSizeType',
      'windowSize',
    ],
    runData,
  );

  return (
    <S.Container className={className} data-testid="RunMetadataModal">
      <S.FormBackdropOverrides />
      <Header>{t('runMetadataModal.title', 'Run details')}</Header>
      <Content>
        {!T.isEmpty(metadata) && (
          <RunMetadataSection
            title={t('runMetadataModal.section.run', 'Last result')}
            data={metadata}
            running={running}
          />
        )}

        {!T.isEmpty(environment) && (
          <RunEnvironmentSection
            title={t('runMetadataModal.section.environment', 'Environment')}
            data={environment}
            running={running}
          />
        )}

        {!T.isEmpty(generalData) && (
          <GeneralMetadataSection
            title={t('runMetadataModal.section.general', 'General')}
            data={generalData}
          />
        )}
      </Content>
      <Footer>
        <Button variant={BUTTON_VARIANT.PRIMARY} onClick={modal.hide}>
          {t('default.button.close')}
        </Button>
      </Footer>
    </S.Container>
  );
};

RunMetadataModal.propTypes = {
  className: PropTypes.string,
  running: PropTypes.bool,
  runData: PropTypes.shape({
    logsArtifactUrl: PropTypes.string,
    hiddenViaSuite: PropTypes.bool,
    sequence: PropTypes.number,
    started: PropTypes.string,
    ended: PropTypes.string,
    duration: PropTypes.string,
    rawDuration: PropTypes.string,
    browserWidth: PropTypes.number,
    browserHeight: PropTypes.number,
    browserVersion: PropTypes.string,
    extensionVersion: PropTypes.string,
    browserName: PropTypes.string,
    waitingConditions: PropTypes.shape({}),
    osName: PropTypes.string,
    suiteRun: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    status: PropTypes.oneOf(Object.values(RUN_STATUS)),
    runMode: PropTypes.oneOf(Object.values(RUN_ENV)),
    triggeredBy: PropTypes.oneOf(Object.values(RUN_TRIGGER)),
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default RunMetadataModal;
