import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgActionReload = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 33, viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_6358_145879)", children: [
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27 8C27.5523 8 28 8.44772 28 9V15C28 15.5523 27.5523 16 27 16H21C20.4477 16 20 15.5523 20 15C20 14.4477 20.4477 14 21 14H26V9C26 8.44772 26.4477 8 27 8Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.1274 9.08123C15.41 8.83764 13.66 9.15881 12.1411 9.99634C10.6221 10.8339 9.41655 12.1424 8.70598 13.7247C7.99542 15.307 7.81838 17.0775 8.20154 18.7692C8.5847 20.4609 9.50731 21.9822 10.8303 23.1039C12.1534 24.2257 13.8051 24.887 15.5367 24.9883C17.2683 25.0896 18.9859 24.6254 20.4307 23.6656C21.8755 22.7057 22.9692 21.3024 23.547 19.6669C23.731 19.1461 24.3023 18.8731 24.823 19.0571C25.3437 19.2411 25.6167 19.8124 25.4328 20.3331C24.7105 22.3775 23.3434 24.1317 21.5374 25.3315C19.7314 26.5312 17.5844 27.1115 15.4199 26.9849C13.2554 26.8583 11.1907 26.0316 9.53694 24.6294C7.88316 23.2273 6.7299 21.3256 6.25095 19.211C5.772 17.0963 5.9933 14.8833 6.8815 12.9054C7.76971 10.9275 9.2767 9.29185 11.1754 8.24494C13.0741 7.19802 15.2616 6.79656 17.4083 7.10105C19.5498 7.40482 21.5351 8.39475 23.0662 9.92214L27.6854 14.272C28.0875 14.6506 28.1065 15.2835 27.7279 15.6856C27.3493 16.0876 26.7164 16.1066 26.3143 15.728L21.6843 11.368C21.677 11.3611 21.6697 11.354 21.6626 11.3469C20.4364 10.12 18.8448 9.32483 17.1274 9.08123Z", fill: "black" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_6358_145879", children: /* @__PURE__ */ jsx("rect", { width: 24, height: 24, fill: "white", transform: "translate(4 5)" }) }) })
] });
const ForwardRef = forwardRef(SvgActionReload);
export default ForwardRef;
