import * as R from 'ramda';

import type { Schedule, ScheduleTimer, ScheduleTimeUnit } from '@bugbug/core/types/schedules';

const isAsterisk = (propName: keyof Schedule) => R.compose(R.equals('*'), R.propOr('', propName));

export const getTimeUnit = R.cond<[scheduleTimer: ScheduleTimer], ScheduleTimeUnit>([
  [R.propEq('minute', '*/1'), R.always('everyMinute')],
  [R.propEq('minute', '*/5'), R.always('everyFiveMinutes')],
  [R.complement(isAsterisk('dayOfMonth')), R.always('monthly')],
  [R.complement(isAsterisk('dayOfWeek')), R.always('weekly')],
  [R.complement(isAsterisk('hour')), R.always('daily')],
  [R.T, R.always('hourly')],
]);
