import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.form``;

export const Info = styled.span`
  display: block;
  color: ${COLOR.GRAY_12};
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLOR.GRAY_9};
`;
