import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgClick = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-click" }),
  /* @__PURE__ */ jsx("g", { id: "b2857014-fd57-40b6-8b39-721fc8673772", children: /* @__PURE__ */ jsxs("g", { id: "bc12c259-c2b4-4f8e-913f-120c8a711953", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M16,7.17A4.88,4.88,0,0,1,21,12v8.4a5,5,0,0,1-9.94,0V12a4.88,4.88,0,0,1,5-4.8m0-2A6.9,6.9,0,0,0,9,12v8.4a7,7,0,0,0,13.94,0V12a6.9,6.9,0,0,0-7-6.8Z" }),
        /* @__PURE__ */ jsx("line", { x1: 16, y1: 7.17, x2: 16, y2: 13.99, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("line", { x1: 11.2, y1: 14.3, x2: 20.8, y2: 14.3, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgClick);
export default ForwardRef;
