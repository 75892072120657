import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isFinishedStatus, isRunningStatus } from '@bugbug/core/types/base';
import produce from 'immer';
import { normalize } from 'normalizr';
import { without, omit, is, path, mergeDeepRight, propOr, equals, isEmpty, prop } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { PAGE_SIZE } from '~/modules/constans';
import { getElementsScreenshotsFromSteps } from '~/modules/test/test.utils';

import testRunSchema, { stepRunSchema, testsRunsListSchema } from './testRun.schema';

export const { Types: TestRunTypes, Creators: TestRunActions } = createActions(
  {
    getSingleRequest: ['id'],
    getSingleSuccess: ['testRun'],
    getSingleFailure: ['error'],
    clearSingle: [],
    getListRequest: ['page', 'query', 'sortBy', 'descOrder'],
    getListSuccess: ['testRuns', 'query', 'sortBy', 'descOrder'],
    getListFailure: ['error'],
    removeRequest: ['ids'],
    removeSuccess: ['ids'],
    removeFailure: ['error'],
    stopRequest: ['ids'],
    stopSuccess: ['ids'],
    stopFailure: ['error'],
    updateStatusSucceeded: ['testRunId', 'status'],
    reset: [],
    addUpdated: ['data'],
    // from backend
    updated: ['data'],
    updatedMultiple: ['data'],
    stopped: ['data'],
    stoppedMultiple: ['data'],
    stepRunUpdated: ['data'],
    stepRunResultUpdated: ['data'],
    stepRunWindowScreenshotUpdated: ['data'],
    stepRunCoveringElementScreenshotUpdated: ['data'],
    stepElementScreenshotUpdated: ['data'],
  },
  { prefix: 'TESTRUN/' },
);

const INITIAL_STATE = {
  testsRuns: {},
  single: {},
  elementsScreenshots: {},
  queuedScreenshots: {},
  list: {
    order: [],
    added: [],
  },
  results: {},
  isFirstTestRun: false,
  query: '',
  descOrder: false,
  sortBy: '',
  pagination: {
    currentPage: 1,
    pageSize: PAGE_SIZE,
    totalCount: 0,
  },
};

const addUpdated = (state, action) =>
  produce(state, (draftState) => {
    const {
      result: id,
      entities: { testsRuns, stepsRuns = {} },
    } = normalize(action.data, testRunSchema);

    if (!draftState.testsRuns[id] && !draftState.list.added.includes(id)) {
      draftState.list.added.push(id);
    }
    if (draftState.testsRuns[id] || draftState.single.id === id) {
      draftState.testsRuns[id] = mergeDeepRight(draftState.testsRuns[id], testsRuns[id]);
      draftState.results[id] = mergeDeepRight(
        draftState.results[id] || { id, stepsRuns: {}, tests: {} },
        { stepsRuns },
      );
    }
  });

const updatedMultiple = (state, action) =>
  produce(state, (draftState) => {
    const updatedData = is(Array, action.data) ? action.data : [action.data];

    for (let index = 0; index < updatedData.length; index += 1) {
      const data = updatedData[index];
      if (
        draftState.testsRuns[data.id] &&
        (!draftState.single.id || draftState.single.id === data.id)
      ) {
        draftState.testsRuns[data.id].status = data.status;
      }
    }
  });

const getSingleSuccess = (state, { testRun: sourceTestRun }) =>
  produce(state, (draftState) => {
    const { result: id, entities } = normalize(
      omit(['isFirstTestRun'], sourceTestRun),
      testRunSchema,
    );
    draftState.isFirstTestRun = sourceTestRun.isFirstTestRun;
    draftState.testsRuns[id] = entities.testsRuns[id];
    draftState.single = { id };
    draftState.queuedScreenshots = {};
    draftState.elementsScreenshots = getElementsScreenshotsFromSteps(entities.steps);
    draftState.results[id] = {
      id,
      ...omit(['testsRuns'], entities),
    };
  });

const getListSuccess = (state, { testRuns, query, sortBy, descOrder }) =>
  produce(state, (draftState) => {
    const data = normalize(testRuns.results, testsRunsListSchema);
    draftState.testsRuns = mergeDeepRight(
      draftState.testsRuns,
      propOr({}, 'testsRuns', data.entities),
    );
    draftState.list = {
      order: data.result,
      added: [],
    };
    draftState.query = query;
    draftState.sortBy = sortBy;
    draftState.descOrder = descOrder;
    draftState.pagination.totalCount = testRuns.count;
    draftState.pagination.currentPage = testRuns.page;
  });

const removeSuccess = (state, { ids }) =>
  produce(state, (draftState) => {
    const removedTestRunsIds = ids || draftState.list.order;
    draftState.testsRuns = omit(removedTestRunsIds, draftState.testsRuns);
    draftState.list.order = without(removedTestRunsIds, draftState.list.order);
    draftState.pagination.totalCount -= removedTestRunsIds.length;
  });

const clearSingle = (state) =>
  produce(state, (draftState) => {
    draftState.single = {};
  });

const updateStatusSucceeded = (state, { testRunId, status }) =>
  produce(state, (draftState) => {
    if (path([testRunId], draftState.results)) {
      const { id } = draftState.results[testRunId];

      if (draftState.testsRuns[id]) {
        draftState.testsRuns[id].status = status;
      }
    }
  });

const updateStepElementScreenshot = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    draftState.elementsScreenshots[data.stepId] = data.screenshot;
  });

const stopSuccess = (state, { ids }) =>
  produce(state, (draftState) => {
    const testsRunsIds = ids || draftState.list.order;
    for (let i = 0; i < testsRunsIds.length; i += 1) {
      const testRunId = testsRunsIds[i];
      const testRun = draftState.testsRuns[testRunId];

      if (testRun && isRunningStatus(testRun.status)) {
        draftState.testsRuns[testRunId].status = RUN_STATUS.STOPPED;

        if (draftState.results[testRunId]) {
          const stepRunsIds = Object.keys(draftState.results[testRunId].stepsRuns || {});
          for (let j = 0; j < stepRunsIds.length; j += 1) {
            const stepRunId = stepRunsIds[j];
            const stepRun = draftState.results[testRunId].stepsRuns[stepRunId];
            if (isRunningStatus(stepRun.status)) {
              draftState.results[testRunId].stepsRuns[stepRunId].status = RUN_STATUS.STOPPED;
            }
          }
        }
      }
    }
  });

const stopped = (state, action) => {
  const getId = prop('testRunId');
  const testRunsIds = (is(String, getId(action.data)) ? [action.data] : action.data)?.map(getId);

  const shouldStopAll = !state.single.id && testRunsIds.length > state.list.order.length;
  return stopSuccess(state, {
    ids: shouldStopAll ? null : testRunsIds,
  });
};

const stepRunWindowScreenshotUpdated = (state, action) =>
  produce(state, (draftState) => {
    const {
      data: { testRunId, stepId, screenshot },
    } = action;

    if (draftState.results && draftState.results[testRunId]) {
      if (!draftState.results[testRunId].stepsRuns) {
        draftState.results[testRunId].stepsRuns = {};
      }

      if (draftState.results[testRunId].stepsRuns[stepId]) {
        draftState.results[testRunId].stepsRuns[stepId].screenshot = screenshot;
      } else {
        draftState.queuedScreenshots[stepId] = screenshot;
      }
    }
  });

const stepRunCoveringElementScreenshotUpdated = (state, action) =>
  produce(state, (draftState) => {
    const {
      data: { testRunId, stepId, coveringElementScreenshot },
    } = action;

    if (draftState.results && draftState.results[testRunId]) {
      if (!draftState.results[testRunId].stepsRuns) {
        draftState.results[testRunId].stepsRuns = {};
      }

      if (!draftState.results[testRunId].stepsRuns[stepId]) {
        draftState.results[testRunId].stepsRuns[stepId] = {};
      }

      draftState.results[testRunId].stepsRuns[stepId].coveringElementScreenshot =
        coveringElementScreenshot;
    }
  });

const retrySaveQueuedScreenshots = (draftState, testRunId) => {
  if (!isEmpty(draftState.queuedScreenshots)) {
    const stepIds = Object.keys(draftState.queuedScreenshots);
    for (let index = 0; index < stepIds.length; index += 1) {
      const stepId = stepIds[index];

      if (path(['results', testRunId, 'stepsRuns', stepId], draftState)) {
        const screenshot = draftState.queuedScreenshots[stepId];
        draftState.results[testRunId].stepsRuns[stepId].screenshot = screenshot;
        draftState.queuedScreenshots = omit([stepId], draftState.queuedScreenshots);
      }
    }
  }
};

const stepRunResultUpdated = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    const { testRunId, stepId } = data;

    if (draftState.results && draftState.results[testRunId]) {
      if (!draftState.results[testRunId].stepsRuns) {
        draftState.results[testRunId].stepsRuns = {};
      }
      const currentStatus = path(['results', testRunId, 'stepsRuns', stepId, 'status'], state);

      retrySaveQueuedScreenshots(draftState, testRunId);

      if (currentStatus && isFinishedStatus(currentStatus)) return;

      const {
        result: id,
        entities: { stepsRuns },
      } = normalize(data, stepRunSchema);
      const fieldNames = Object.keys(stepsRuns[id]);
      if (!draftState.results[testRunId].stepsRuns[stepId]) {
        draftState.results[testRunId].stepsRuns[stepId] = {};
      }

      for (let index = 0; index < fieldNames.length; index += 1) {
        const value = stepsRuns[id][fieldNames[index]];
        const currentValue = draftState.results[testRunId].stepsRuns[stepId][fieldNames[index]];

        if (!equals(currentValue, value)) {
          draftState.results[testRunId].stepsRuns[stepId][fieldNames[index]] = value;
        }
      }
    }
  });

export const reducer = createReducer(INITIAL_STATE, {
  [TestRunTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [TestRunTypes.GET_LIST_SUCCESS]: getListSuccess,
  [TestRunTypes.REMOVE_SUCCESS]: removeSuccess,
  [TestRunTypes.STOP_SUCCESS]: stopSuccess,
  [TestRunTypes.CLEAR_SINGLE]: clearSingle,
  [TestRunTypes.UPDATE_STATUS_SUCCEEDED]: updateStatusSucceeded,
  [TestRunTypes.ADD_UPDATED]: addUpdated,
  [TestRunTypes.UPDATED_MULTIPLE]: updatedMultiple,
  [TestRunTypes.STOPPED]: stopped,
  [TestRunTypes.STOPPED_MULTIPLE]: stopped,
  [TestRunTypes.STEP_RUN_RESULT_UPDATED]: stepRunResultUpdated,
  [TestRunTypes.STEP_RUN_WINDOW_SCREENSHOT_UPDATED]: stepRunWindowScreenshotUpdated,
  [TestRunTypes.STEP_RUN_COVERING_ELEMENT_SCREENSHOT_UPDATED]:
    stepRunCoveringElementScreenshotUpdated,
  [TestRunTypes.STEP_ELEMENT_SCREENSHOT_UPDATED]: updateStepElementScreenshot,
});
