import {
  ADVANCED_ASSERTION_TYPE,
  ADVANCED_STEP_TYPE,
  ELEMENT_ASSERTION_TYPE,
  INPUT_ASSERTION_TYPE,
  INPUT_STEP_TYPES,
  MOUSE_STEP_TYPE,
  WINDOW_STEP_TYPE,
  PAGE_ASSERTION_TYPE,
} from '~/constants/step';
import i18n from '~/translations';

export const STEP_TYPES = [
  { label: i18n.t('createStepModal.sectionLabel.mouse', 'Mouse'), items: MOUSE_STEP_TYPE },
  { label: i18n.t('createStepModal.sectionLabel.inputs', 'Inputs'), items: INPUT_STEP_TYPES },
  { label: i18n.t('createStepModal.sectionLabel.window', 'Window'), items: WINDOW_STEP_TYPE },
  { label: i18n.t('createStepModal.sectionLabel.advanced', 'Advanced'), items: ADVANCED_STEP_TYPE },
];

export const ASSERTION_TYPES = [
  {
    label: i18n.t('createStepModal.sectionLabel.element', 'Element'),
    items: ELEMENT_ASSERTION_TYPE,
  },
  { label: i18n.t('createStepModal.sectionLabel.forms', 'Forms'), items: INPUT_ASSERTION_TYPE },
  { label: i18n.t('createStepModal.sectionLabel.page', 'Page'), items: PAGE_ASSERTION_TYPE },
  {
    label: i18n.t('createStepModal.sectionLabel.advanced', 'Advanced'),
    items: ADVANCED_ASSERTION_TYPE,
  },
];

export type StepTypePickerType = 'actions' | 'assertions';
export const TAB: Record<string, StepTypePickerType> = {
  ACTIONS: 'actions',
  ASSERTIONS: 'assertions',
};

export const TABS = [
  { id: TAB.ACTIONS, label: i18n.t('createStepModal.tabLabel.actions', 'Actions') },
  { id: TAB.ASSERTIONS, label: i18n.t('createStepModal.tabLabel.assertions', 'Assertions') },
];
