import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const MetaData = styled.div`
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  line-height: 18px;

  &:last-child {
    border-bottom: none;
  }
`;

export const Label = styled.label`
  width: 30%;
  flex: none;
  padding-right: 17px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  margin: 0;
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'externalLink',
}))`
  width: 16px;
  margin-left: 3px;
`;

export const Value = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MetaDataId = styled(MetaData)`
  ${Label} {
    display: flex;
    align-items: center;
  }

  ${Value} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Title = styled.div`
  position: relative;
  display: inline-block;
  color: #8b8b8b;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  hr {
    flex: 1;
    margin-left: 8px;
    border-top: 1px solid #e6e6e6;
    border-bottom: none;
  }
`;
