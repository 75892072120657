import Icon from '@bugbug/core/components/Icon';

import type { ToastProps } from '../../Toast';

import Toast from '../../Toast';

const SuccessToast = ({ content }: Pick<ToastProps, 'content'>) => (
  <Toast Icon={<Icon name="checkCircle" />} content={content} />
);

export default SuccessToast;
