import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEyeTextNotVisible = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M5 16C5 16 9 8 16 8C23 8 27 16 27 16C27 16 23 24 16 24C9 24 5 16 5 16Z", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M7.15625 4.26559L27.9693 25.0786", stroke: "white", strokeWidth: 5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M7.15625 4.26559L27.9693 25.0786", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M32 15H20V28H32V15Z", fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M22.3799 19.27V17.72H30.6599V19.27", stroke: "white", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M22.3799 19.27V17.72H30.6599V19.27", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M24.97 26H28.07", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M26.5198 17.72V26", stroke: "white", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M26.5198 17.72V26", stroke: "black", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })
] });
const ForwardRef = forwardRef(SvgEyeTextNotVisible);
export default ForwardRef;
