import Button from '@bugbug/core/components/Button';
import Link from '@bugbug/core/components/Link';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import useAppRoutes from '~/hooks/useAppRoutes';
import config from '~/modules/config';
import { useAppDispatch } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState';

import { Description } from '../EditAlertModalThenContent.styled';

export const EditAlertModalActionTeamsAuth = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<EditAlertFormValues>();
  const { params, routeName } = useAppRoutes('alert');
  const dispatch = useAppDispatch();

  const handleConnectTeams = async () => {
    dispatch(
      UIStateActions.setIntegrationTrigger({
        routeName,
        params: {
          ...params,
          alertId: values.id ?? 'new',
        },
        queryParams: { action: values.action },
      }),
    );
    window.open(config.TEAMS_APP_URL, '_blank');
  };

  return (
    <>
      <Description>
        <Trans i18nKey="editAlertModal.teams.integration">
          You need to set up your <Link to={t('default.docs.teams')}>Teams integration</Link> first.
        </Trans>
      </Description>
      <Button
        variant="primary"
        bordered
        onClick={handleConnectTeams}
        iconName="openWindow"
        iconPosition="right"
      >
        {t('editAlertModal.teams.connectButton', 'Connect your Teams account')}
      </Button>
    </>
  );
};
