import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { InputProps } from '@bugbug/core/components/Input/Input';

import * as S from '../DeleteComponentModal.styled';

interface RelatedTestsGuardProps {
  onValidationChange: (isValid: boolean) => void;
  testsCount: number;
}

const RelatedTestsGuard = ({ onValidationChange, testsCount }: RelatedTestsGuardProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const handleOnChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(() => {
    setError('');
    onValidationChange(false);
  }, [setError, onValidationChange]);

  const handleValidation = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const rx = /^['"]?delete['"]?$/i;
      const value = e.target.value.replace(/\s/g, '');

      const isValid = rx.test(value);

      let errorMessage: string;

      switch (true) {
        case value === '':
          errorMessage = t('deleteComponentModal.confirm.noInputError', 'This field is required');
          break;

        case !isValid:
          errorMessage = t(
            'deleteComponentModal.confirm.mistypedError',
            'The word "DELETE" is mistyped',
          );
          break;

        default:
          errorMessage = '';
      }

      setError(errorMessage);
      onValidationChange(rx.test(value));
    },
    [setError, onValidationChange, t],
  );

  return (
    <div>
      <p>
        {t('deleteComponentModal.description', {
          count: testsCount,
          defaultValue_one: 'This will also remove the steps from 1 related test.',
          defaultValue_other: 'This will also remove the steps from all {{ count }} related tests.',
        })}{' '}
        <Trans i18nKey="deleteComponentModal.description.docsLink" shouldUnescape>
          If you want to preserve the steps in your tests you can{' '}
          <Link to={t('default.component.docs.unlink')}>unlink</Link> them first.
        </Trans>
      </p>

      <S.InputContainer>
        <p>{t('deleteComponentModal.confirm.message', 'Type “DELETE” to confirm')}</p>
        <Input
          autoFocus
          error={error}
          onBlur={handleValidation}
          onChange={handleOnChange as InputProps['onChange']}
          required
        />
      </S.InputContainer>
    </div>
  );
};

export default RelatedTestsGuard;
