import { H1 } from '@bugbug/core/theme/typography';
import { isString } from '@bugbug/core/utils/toolbox';

import type React from 'react';

import { HeaderContainer } from './Header.styled';

interface HeaderProps {
  className?: string;
  title: React.ReactNode;
  centeredContent?: boolean;
  noBorder?: boolean;
}

export const Header = ({
  className,
  title = '',
  centeredContent = false,
  noBorder = false,
}: HeaderProps) => (
  <HeaderContainer
    className={className}
    data-testid="Header"
    centeredContent={centeredContent}
    noBorder={noBorder}
  >
    {isString(title) ? <H1>{title}</H1> : title}
  </HeaderContainer>
);
