import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { OrganizationUsersActions } from '~/modules/organizationUsers/organizationUsers.redux';

const DeleteUserModal = ({ email }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const { isLoading, hasInternalServerError, reset } = useActionState(
    OrganizationUsersActions.removeRequest,
    {
      reset: false,
      onSuccess: modal.hide,
    },
  );

  const handleConfirm = useCallback(() => {
    dispatch(OrganizationUsersActions.removeRequest(email));
  }, [dispatch, email]);

  useUnmount(() => {
    reset();
  });

  return (
    <ConfirmModal
      title={t('deleteUserModal.title', 'Do you want to remove {{email}}?', { email })}
      pending={isLoading}
      onConfirm={handleConfirm}
      onCancel={modal.hide}
      hasInternalServerError={hasInternalServerError}
    />
  );
};

DeleteUserModal.defaultProps = {};

DeleteUserModal.propTypes = {
  email: PropTypes.string.isRequired,
};

export default DeleteUserModal;
