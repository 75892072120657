import api from './common';

export const getList = (params) => api.get(`/suiteruns/`, { params });

export const get = (id) => api.get(`/suiteruns/${id}/`);

export const create = (data) => api.post('/suiteruns/', data);

export const update = (id, data) => api.patch(`/suiteruns/${id}/`, data);

export const remove = (id) => api.delete(`/suiteruns/${id}/`);

export const removeSelected = (data) => api.delete(`/suiteruns/delete/`, { data });

export const removeAll = (data) => api.delete(`/suiteruns/delete/`, { data });

export const stop = (id, data) => api.post(`/suiteruns/${id}/stop-running/`, data);

export const stopSelected = (data) => api.post('/suiteruns/stop-running/', data);

export const stopAll = (data) => api.post('/suiteruns/stop-running/', data);
