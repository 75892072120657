import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { USER_ROLE, USER_ROLE_LABELS } from '~/modules/constans';
import { OrganizationUsersActions } from '~/modules/organizationUsers/organizationUsers.redux';

const ChangeUserRoleModal = ({ email, role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const isAdmin = role === USER_ROLE.ADMIN;
  const newRole = isAdmin ? USER_ROLE.EDITOR : USER_ROLE.ADMIN;
  const newRoleLabel = isAdmin
    ? USER_ROLE_LABELS[USER_ROLE.EDITOR]
    : USER_ROLE_LABELS[USER_ROLE.ADMIN];

  const { isLoading, hasInternalServerError, reset } = useActionState(
    OrganizationUsersActions.updateRequest,
    {
      reset: false,
      onSuccess: modal.hide,
    },
  );

  const handleConfirm = useCallback(() => {
    dispatch(OrganizationUsersActions.updateRequest({ email, role: newRole }));
  }, [dispatch, email, newRole]);

  useUnmount(() => {
    reset();
  });

  return (
    <ConfirmModal
      title={t(
        'changeUserRoleModal.title',
        `Do you want to change {{email}} role to {{newRoleLabel}}?`,
        { email, newRoleLabel },
      )}
      pending={isLoading}
      onConfirm={handleConfirm}
      onCancel={modal.hide}
      hasInternalServerError={hasInternalServerError}
    />
  );
};

ChangeUserRoleModal.defaultProps = {};

ChangeUserRoleModal.propTypes = {
  email: PropTypes.string.isRequired,
  role: PropTypes.oneOf(Object.values(USER_ROLE)).isRequired,
};

export default ChangeUserRoleModal;
