import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

const CloneGroupModal = ({ className, id, testId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const cloneRequestState = useActionState(TestActions.cloneGroupRequest, {
    onSuccess: modal.hide,
  });

  const handleCloneConfirm = useCallback(
    () => dispatch(TestActions.cloneGroupRequest(id, testId)),
    [dispatch, id, testId],
  );

  return (
    <ConfirmModal
      className={className}
      title={t('cloneGroupModal.title', 'Do you want to duplicate this group?')}
      pending={cloneRequestState.isLoading}
      hasInternalServerError={cloneRequestState.hasInternalServerError}
      onConfirm={handleCloneConfirm}
      onCancel={modal.hide}
    />
  );
};

CloneGroupModal.defaultProps = {
  className: null,
};

CloneGroupModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default CloneGroupModal;
