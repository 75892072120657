/* eslint-disable no-param-reassign */
import axios from 'axios';

import config from '~/modules/config';

import { errorHandlers, requestInterceptor, responseInterceptor } from './apiInterceptors';

const apiInstance = axios.create({
  baseURL: config.API_REST_URL,
  responseType: 'json',
  credentials: 'same-origin',
});

apiInstance.interceptors.request.use(requestInterceptor);
apiInstance.interceptors.response.use(responseInterceptor, errorHandlers);

export default apiInstance;
