import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgConfetti = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 52, height: 52, viewBox: "0 0 52 52", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 17.002, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 17.002, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 17.002, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 24.832, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 9.6582, width: 2.44796, height: 2.44796, fill: "#FFFA35" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFFA35" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 22.3867, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 15.043, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 43.9297, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 17.002, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 17.002, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 26.793, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 26.793, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 19.9375, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7305, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7305, y: 26.793, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 37.0742, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#E09D27" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 34.625, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7305, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#FFE975" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 26.793, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7305, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 29.7305, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 32.1758, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#FFDA15" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 27.2812, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 26.793, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 29.2422, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 24.832, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#7C4FE5" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { opacity: 0.3, x: 17.4883, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#6D582A" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 15.043, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 39.0332, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 43.9297, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 12.5938, y: 34.1367, width: 2.44796, height: 2.44796, fill: "#7F4BF7" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7305, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 41.4805, width: 2.44796, height: 2.44796, fill: "#FF9DD1" }),
  /* @__PURE__ */ jsx("rect", { x: 44.418, y: 31.6895, width: 2.44796, height: 2.44796, fill: "#FFA348" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#FFA348" }),
  /* @__PURE__ */ jsx("rect", { x: 37.0742, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 2.31445, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 36.5859, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 41.9688, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 44.418, y: 21.8984, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 46.8633, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 32.1758, y: 17.002, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 17.002, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 37.0742, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 37.0742, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 39.5195, y: 9.6582, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 44.418, y: 9.6582, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 44.418, y: 7.21094, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 34.625, y: 7.21094, width: 2.44796, height: 2.44796, fill: "#FF5B8B" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 17.002, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3867, y: 9.6582, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 7.21094, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 17.4883, y: 2.31445, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 19.9375, y: 4.76172, width: 2.44796, height: 2.44796, fill: "#FF4C7C" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 19.4492, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 10.1445, y: 17.002, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 14.5547, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 12.1055, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 7.69922, y: 9.6582, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 7.21094, width: 2.44796, height: 2.44796, fill: "#5996FF" }),
  /* @__PURE__ */ jsx("rect", { x: 5.25, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#D03554" }),
  /* @__PURE__ */ jsx("rect", { x: 27.2812, y: 24.3457, width: 2.44796, height: 2.44796, fill: "#E09D27" })
] });
const ForwardRef = forwardRef(SvgConfetti);
export default ForwardRef;
