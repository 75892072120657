import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgHash = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", className: "feather feather-hash", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("line", { x1: 4, y1: 9, x2: 20, y2: 9 }),
  /* @__PURE__ */ jsx("line", { x1: 4, y1: 15, x2: 20, y2: 15 }),
  /* @__PURE__ */ jsx("line", { x1: 10, y1: 3, x2: 8, y2: 21 }),
  /* @__PURE__ */ jsx("line", { x1: 16, y1: 3, x2: 14, y2: 21 })
] });
const ForwardRef = forwardRef(SvgHash);
export default ForwardRef;
