import Link from '@bugbug/core/components/Link';
import { Trans } from 'react-i18next';

import OrganizedList from '~/components/OrganizedList';
import useAppRoutes from '~/hooks/useAppRoutes';

import { TwoColumnContent } from './IntegrationModal.styled';

export const SlackDetails = () => {
  const { params, getRouteUrl } = useAppRoutes('integration');
  return (
    <TwoColumnContent>
      <article>
        <p>
          <Trans i18nKey="integrationModal.details.slack.introduction">
            <b>BugBug's integration for Slack</b> is designed to elevate your team's testing and
            issue management to a whole new level. With seamless connectivity to Slack, you can
            harness real-time updates and enrich your notifications with crucial information,
            ensuring efficient E2E testing and issue tracking.
            <br /> Here's what you can achieve with BugBug integration:
          </Trans>
        </p>
        <Trans i18nKey="integrationModal.details.slack.list">
          <OrganizedList>
            <li>
              <b>Channel and User Messaging:</b> Whether you need to alert the entire team in a
              dedicated channel or notify specific Slack users, BugBug offers flexible communication
              options.
            </li>
            <li>
              <b>Rich Notification Content:</b> Gone are the days of cryptic alerts. BugBug empowers
              you to include essential details about test runs, suite executions, or scheduled runs
              in your Slack messages. This extra information provides context, making it easier to
              identify issues and act promptly.
            </li>
            <li>
              <b>Real-Time Updates:</b> BugBug's integration with Slack ensures that your team stays
              up to date with instantaneous notifications. When a test run fails or an issue arises,
              you'll be the first to know, allowing for rapid response and issue resolution.
            </li>
            <li>
              <b>Efficiency and Productivity:</b> Incorporating BugBug's integration for Slack into
              your workflow will optimize your testing processes and enhance overall productivity.
              Get insights when you need them and act swiftly to maintain software quality.
            </li>
          </OrganizedList>
        </Trans>
        <p>
          <Trans i18nKey="integrationModal.details.slack.summary">
            BugBug's integration for Slack empowers your team to work smarter, not harder. Keep your
            entire team informed and responsive, and take full control of your testing and issue
            management with comprehensive, real-time notifications.
          </Trans>
        </p>
      </article>
      <aside>
        <h2>What you can do with this integration?</h2>
        <div>
          <Link to={getRouteUrl('newAlert', params, { action: 'sendSlackMessage' })}>
            Setup Slack message alert
          </Link>
        </div>
      </aside>
    </TwoColumnContent>
  );
};
