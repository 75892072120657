import { isEmpty } from 'ramda';
import { call, takeLatest, select, all, put, race, delay, take } from 'redux-saga/effects';

import { selectExtensionSettings } from '~/modules/extension/extension.selectors';
import { selectUserToken } from '~/modules/user/user.selectors';
import api from '~/services/api';

import { ExtensionTypes, ExtensionActions } from './extension.redux';

export const CONNECT_WITH_WEBAPP = 'CONNECT_WITH_WEBAPP';
export const OPEN_SETTINGS_URL = 'OPEN_SETTINGS_URL';
export const REGISTER_WEB_APP_SESSION = 'REGISTER_WEB_APP_SESSION';
export const GET_INCOGNITO_WINDOWS_STATUS = 'GET_INCOGNITO_WINDOWS_STATUS';

export function* setExtensionAsDisconnectedIfNeeded() {
  const { timeout } = yield race({
    timeout: delay(5000),
    pong: take(ExtensionTypes.UPDATE_SETTINGS_REQUESTED),
  });

  if (timeout) {
    console.debug('[App] Extension disconnected');
    yield put(ExtensionActions.updateSettingsSucceeded({ isDetected: false, isPending: false }));
  }
}

export function* connectWithWebappRequested() {
  console.debug('[App] Waiting for signal from extension...');
  const token = yield select(selectUserToken);
  const message = {
    type: CONNECT_WITH_WEBAPP,
    token,
  };
  yield call(window.postMessage, message, '*');
  yield call(setExtensionAsDisconnectedIfNeeded);
}

export function* updateSettingsRequested({ settings = {} }) {
  const { isDetected } = yield select(selectExtensionSettings);
  if (isDetected && isEmpty(settings)) {
    yield put(ExtensionActions.updateSettingsSucceeded({ isDetected: false, isPending: true }));
    yield call(setExtensionAsDisconnectedIfNeeded);
  } else {
    if (!isDetected && !isEmpty(settings)) {
      console.debug('[App] Extension detected');
    }
    console.debug('[App] Extension state updated');
    yield put(
      ExtensionActions.updateSettingsSucceeded({
        ...settings,
        isDetected: true,
        isPending: !settings.isConnectedWithServer,
      }),
    );
  }
}

export function* registerWebAppSessionRequested() {
  const message = {
    type: REGISTER_WEB_APP_SESSION,
  };
  yield call(window.postMessage, message, '*');
}

export function* openSettingsRequested({ url }) {
  const message = {
    type: OPEN_SETTINGS_URL,
    url,
  };
  yield call(window.postMessage, message, '*');
}

export function* closeWindowsRequested() {
  try {
    yield call(api.extension.closeWindows);
    yield put(ExtensionActions.closeWindowsSucceeded());
  } catch (error) {
    yield put(ExtensionActions.closeWindowsFailed());
  }
}

export function* getIncognitoWindowsStatusRequest({ meta }) {
  const message = {
    type: GET_INCOGNITO_WINDOWS_STATUS,
    meta,
  };
  yield call(window.postMessage, message, '*');
}

export default function* extensionSagas() {
  yield all([
    yield takeLatest(ExtensionTypes.CONNECT_WITH_WEBAPP_REQUESTED, connectWithWebappRequested),
    yield takeLatest(ExtensionTypes.CLOSE_WINDOWS_REQUESTED, closeWindowsRequested),
    yield takeLatest(ExtensionTypes.OPEN_SETTINGS_REQUESTED, openSettingsRequested),
    yield takeLatest(ExtensionTypes.UPDATE_SETTINGS_REQUESTED, updateSettingsRequested),
    yield takeLatest(
      ExtensionTypes.REGISTER_WEB_APP_SESSION_REQUESTED,
      registerWebAppSessionRequested,
    ),
    yield takeLatest(
      ExtensionTypes.GET_INCOGNITO_WINDOWS_STATUS_REQUEST,
      getIncognitoWindowsStatusRequest,
    ),
  ]);
}
