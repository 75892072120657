import SelectBase from '@bugbug/core/components/Select';
import clockSrc from '@bugbug/core/icons/clock.svg';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled, { css } from 'styled-components';

import DotsPicker from '~/components/DotsPicker';

export const Container = styled.div`
  border: 2px solid ${COLOR.GRAY_18};
  border-radius: 5px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 8px;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  > * {
    z-index: 1;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background: url(${clockSrc}) no-repeat top 7px right 14px;
    background-size: 48px;
  }
`;

export const SettingName = styled.div`
  width: 36px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

const settingValueStyles = css`
  display: flex;
  align-items: center;
  min-height: 38px;
  gap: 12px;
`;

export const Interval = styled.div`
  ${settingValueStyles};
`;

export const WeekDays = styled.div`
  ${settingValueStyles};
`;

export const Time = styled.div`
  ${settingValueStyles};
`;

export const Timezone = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.GRAY_8};

  svg {
    margin-left: 6px;
    margin-bottom: 1px;
  }
`;

export const DaysOfWeekPicker = styled(DotsPicker)`
  margin-left: -4px;
  margin-right: 4px;
`;

export const DayOfMonth = styled.div`
  display: grid;
  align-items: flex-start;
  gap: 8px;
  width: 185px;
  font-weight: ${FONT_WEIGHT.NORMAL};

  > * {
    width: 100%;
    overflow: hidden;
  }
`;

export const Select = styled(SelectBase)`
  width: 185px;
`;
