import { createActions, createReducer } from 'reduxsauce';

export const { Types: CoreTypes, Creators: CoreActions } = createActions(
  {
    error: ['data'],
    startHeartbeatRequested: [],
    stopHeartbeatRequested: [],
    ping: [],
    pong: [],
  },
  { prefix: 'CORE/' },
);

export const INITIAL_STATE = {};

export const reducer = createReducer(INITIAL_STATE, {});
