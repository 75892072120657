/* eslint-disable react/jsx-props-no-spreading */
import { KEY_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { is } from 'ramda';
import { useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useMount, useUnmount } from 'react-use';

import type { TextAreaStyledProps } from './TextArea.types';
import type React from 'react';

import {
  Container,
  ErrorMessage,
  RightAdornment,
  TextAreaBase,
  TextAreaWrapper,
} from './TextArea.styled';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    Omit<TextAreaStyledProps, 'invalid'> {
  className?: string;
  min?: string;
  type?: string;
  value?: string | number;
  required?: boolean;
  autoFocus?: boolean;
  autoSize?: boolean;
  error?: boolean | string;
  initialHeight?: number;
  maxInitialHeight?: number;
  autoComplete?: string;
  lineBreakDisabled?: boolean;
  clearOnFocus?: boolean;
  rightAdornment?: string | React.ReactNode;
  autoSelect?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    className,
    autoFocus,
    value = '',
    error = '',
    readOnly,
    disabled,
    fullWidth,
    initialHeight = 36,
    maxInitialHeight = 115,
    autoSize,
    lineBreakDisabled,
    clearOnFocus,
    autoSelect = true,
    autoComplete = 'off',
    required = false,
    rightAdornment,
    ...textAreaProps
  } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleValueChange = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${
        textAreaRef.current.value ? textAreaRef.current.scrollHeight : initialHeight
      }px`;
    }
  }, [initialHeight]);

  const handleKeypressEnter = useCallback(
    (event) => event.key === KEY_BINDINGS.ENTER && event.preventDefault(),
    [],
  );

  const handleFocus = useCallback(
    (event) => {
      if (clearOnFocus) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
        // @ts-expect-error incompatible types
        textAreaProps?.onChange?.({ target: event.target });
      }
      textAreaProps?.onFocus?.(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearOnFocus, textAreaProps.onChange, textAreaProps.onFocus],
  );

  useMount(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${initialHeight}px`;

      if (autoSize) {
        textAreaRef.current.style.maxHeight = `${maxInitialHeight}px`;
        textAreaRef.current.addEventListener('input', handleValueChange, false);
      }

      if (lineBreakDisabled) {
        textAreaRef.current.addEventListener('keypress', handleKeypressEnter, false);
      }
    }
  });

  // eslint-disable-next-line consistent-return
  useUnmount(() => {
    if (autoSize) {
      // eslint-disable-next-line no-unused-expressions
      textAreaRef.current?.removeEventListener('input', handleValueChange, false);
    }

    if (lineBreakDisabled) {
      // eslint-disable-next-line no-unused-expressions
      textAreaRef.current?.removeEventListener('keypress', handleKeypressEnter, false);
    }
  });

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.value = lineBreakDisabled
        ? `${value}`.replace(/(\r\n|\n|\r)/gm, '')
        : `${value}`;
    }
  }, [value, lineBreakDisabled]);

  useEffect(() => {
    if (textAreaRef.current && autoFocus) {
      textAreaRef.current.focus();
      if (autoSelect) {
        textAreaRef.current.select();
      }
    }
  }, [autoFocus, autoSelect]);

  useEffect(() => {
    if (textAreaRef.current && autoSize) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [autoSize]);

  useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement, []);

  const invalid = !!error && !readOnly;

  return (
    <Container>
      <TextAreaWrapper
        className={className}
        disabled={disabled}
        readOnly={readOnly}
        invalid={invalid}
        fullWidth={fullWidth}
      >
        <TextAreaBase
          {...textAreaProps}
          autoComplete={autoComplete}
          onFocus={handleFocus}
          disabled={disabled}
          ref={textAreaRef}
          data-testid="TextArea"
          readOnly={readOnly}
          aria-invalid={invalid}
          aria-required={required}
          required={required}
          rightAdornment={!!rightAdornment}
        />
      </TextAreaWrapper>
      {rightAdornment && <RightAdornment>{rightAdornment}</RightAdornment>}
      {is(String, error) && !readOnly && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
