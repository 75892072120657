export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export type FontWeight = typeof fontWeight;

/*
  Deprecated. Use theme.font instead
*/
export const FONT_WEIGHT = {
  LIGHT: fontWeight.light,
  NORMAL: fontWeight.regular,
  MEDIUM: fontWeight.medium,
  SEMIBOLD: fontWeight.semibold,
  BOLD: fontWeight.bold,
};
