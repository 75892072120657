import i18n from '~/translations';

import toasts from './common';

export const showInternalError = () => {
  const content = i18n.t(
    'notifications.core.internalError',
    'Error occurred (information has been sent to administrators)',
  );
  toasts.showError({ content });
};

export const showExtensionDisconnected = () => {
  const content = i18n.t('notifications.core.extensionDisconnected', 'Extension disconnected');
  toasts.showError({ content });
};

export const showDisconnectedOrCorsError = () => {
  const content = i18n.t(
    'notifications.core.networkDisconnected',
    'An unexpected network error occurred',
  );
  toasts.showError({ content });
};

export const showOfflineModeInfo = () => {
  const content = i18n.t('notifications.core.networkOffline', 'You are offline now');
  const toastId = toasts.show({ id: 'offline', content, autoClose: false, iconName: 'offline' });

  window.addEventListener(
    'online',
    () => {
      toasts.dismiss(toastId);
    },
    { once: true },
  );
};
