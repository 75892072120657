import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { selectSingleProjectId } from '~/modules/project/project.selectors';
import api from '~/services/api';
import toasts from '~/services/toasts';
import i18n from '~/translations';

import { ProfileTypes, ProfileActions } from './profile.redux';

export function* getListRequest({ query, projectId }) {
  try {
    // eslint-disable-next-line no-param-reassign
    projectId = projectId || (yield select(selectSingleProjectId));
    const { data: profiles } = yield call(api.profiles.getList, { query, project_id: projectId });

    yield put(ProfileActions.getListSuccess(profiles));
  } catch (error) {
    yield put(ProfileActions.getListFailure(error));
  }
}

export function* createRequest({ profile }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    const { data: createdProfile } = yield call(api.profiles.create, {
      ...profile,
      project_id: projectId,
    });

    yield put(ProfileActions.createSuccess(createdProfile));
    yield call(toasts.showSuccess, {
      content: i18n.t('profile.createSuccessInfo', 'Profile has been created successfully.'),
    });
  } catch (error) {
    yield put(ProfileActions.createFailure(error));
  }
}

export function* updateRequest({ id, profile }) {
  try {
    const { data: updatedProfile } = yield call(api.profiles.update, id, profile);

    yield put(ProfileActions.updateSuccess(updatedProfile));
    yield call(toasts.showSuccess, {
      content: i18n.t('profile.updateSuccessInfo', 'Profile has been updated successfully.'),
    });
  } catch (error) {
    yield put(ProfileActions.updateFailure(error));
  }
}

export function* deleteRequest({ id }) {
  try {
    yield call(api.profiles.remove, id);
    yield put(ProfileActions.deleteSuccess(id));
    yield call(toasts.showSuccess, {
      content: i18n.t('profile.deleteSuccessInfo', 'Profile has been deleted successfully.'),
    });
  } catch (error) {
    yield put(ProfileActions.deleteFailure(error));
  }
}

export default function* profileSagas() {
  yield all([
    yield takeLatest(ProfileTypes.GET_LIST_REQUEST, getListRequest),
    yield takeLatest(ProfileTypes.CREATE_REQUEST, createRequest),
    yield takeLatest(ProfileTypes.UPDATE_REQUEST, updateRequest),
    yield takeLatest(ProfileTypes.DELETE_REQUEST, deleteRequest),
  ]);
}
