import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sup } from './Beta.styled';

const Beta = ({ className }) => {
  const { t } = useTranslation();
  return <Sup className={className}>{t('default.beta')}</Sup>;
};

Beta.propTypes = {
  className: PropTypes.string,
};

export default Beta;
