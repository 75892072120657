import Logger from 'js-logger';

// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults({
  defaultLevel: Logger.ERROR,
  formatter: (messages) => {
    messages.unshift(new Date().toUTCString());
  },
});

export default Logger;
