import useAppRoutes from '~/hooks/useAppRoutes';
import { selectIsIntegrationsActiveInProject } from '~/modules/integrations/integrations.selectors';
import { selectIsFreePlan } from '~/modules/organization';
import { useAppSelector } from '~/modules/store';

import * as S from './EditAlertModalActionTeams.styled';
import { EditAlertModalActionTeamsAuth } from './EditAlertModalActionTeamsAuth';
import { EditAlertModalActionTeamsForm } from './EditAlertModalActionTeamsForm';

export const EditAlertModalActionTeams = () => {
  const { params } = useAppRoutes('alert');
  const isActive = useAppSelector(selectIsIntegrationsActiveInProject(params.projectId, 'teams'));
  const isFreePlan = useAppSelector(selectIsFreePlan) as boolean;

  if (isFreePlan) {
    return <S.UpgradePlan centeredContent={false} />;
  }

  return isActive ? <EditAlertModalActionTeamsForm /> : <EditAlertModalActionTeamsAuth />;
};
