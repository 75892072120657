import { useLayoutEffect, useCallback, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { REFRESH_TIME } from './DurationTime.constants';
import { getDurationString, getInitialTime } from './DurationTime.helpers';

interface DurationTimeProps {
  className?: string;
  value?: string;
  running?: boolean;
  showMilliseconds?: boolean;
  showOnlySeconds?: boolean;
}

const DurationTime = memo(
  ({ className, value, running, showMilliseconds, showOnlySeconds }: DurationTimeProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const time = useRef(getInitialTime(value));
    const { t } = useTranslation();

    const refreshTime = useCallback(() => {
      if (containerRef.current) {
        containerRef.current.textContent = getDurationString(
          time.current,
          showMilliseconds,
          showOnlySeconds,
        );
      }
    }, [showMilliseconds, showOnlySeconds]);

    useLayoutEffect(() => {
      time.current = getInitialTime(value);
      refreshTime();
    }, [value, refreshTime]);

    useLayoutEffect(() => {
      let timer: ReturnType<typeof setInterval>;

      if (running) {
        timer = setInterval(() => {
          time.current += REFRESH_TIME;
          refreshTime();
        }, REFRESH_TIME);
      }

      return () => {
        clearInterval(timer);
      };
    }, [t, running, value, refreshTime]);

    return (
      <div
        ref={containerRef}
        role="timer"
        aria-label={t('durationTime.label', 'Duration')}
        className={className}
      />
    );
  },
);

DurationTime.displayName = 'DurationTime';

export default DurationTime;
