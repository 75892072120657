import { without } from 'ramda';

import type { IconName } from '@bugbug/core/types/icons';
import i18n from '~/translations';

import { VARIABLE_TYPE } from './variables';

export const STEP_TYPE = {
  ASSERT: 'assert',
  CHANGE: 'change',
  CLEAR: 'clear',
  CLICK: 'click',
  DOUBLE_CLICK: 'dblClick',
  RIGHT_CLICK: 'rightClick',
  EXECUTE: 'execute',
  GOTO: 'goto',
  HOVER: 'hover',
  MOUSE_DOWN: 'mouseDown',
  MOUSE_UP: 'mouseUp',
  NEW_TAB: 'newTab',
  RELOAD_PAGE: 'reloadPage',
  CLOSE_TAB: 'closeTab',
  SWITCH_CONTEXT: 'switchContext',
  SCROLL: 'scroll',
  TYPE: 'type',
  UPLOAD_FILE: 'uploadFile',
  SELECT: 'select',
  DRAG_AND_DROP: 'dragAndDrop',
  ANSWER_PROMPT: 'answerPrompt',
  SET_LOCAL_VARIABLE: 'setLocalVariable',
};

export const STEP_SCROLL_TARGET_TYPE = {
  ELEMENT: 'element',
  WINDOW: 'window',
};

export const STEP_SCROLL_TO_TYPE = {
  COORDS: 'coords',
  EDGE: 'edge',
  UNTIL_NEXT_STEP_ELEMENT_IS_VISIBLE: 'untilNextStepElementIsVisible',
  INTO_VIEW: 'elementIntoView',
};

export const STEP_SCROLL_DIRECTION_TYPE = {
  DOWN: 'down',
  UP: 'up',
  RIGHT: 'right',
  LEFT: 'left',
};

export const STEP_SCROLL_TARGET = [
  {
    value: STEP_SCROLL_TARGET_TYPE.WINDOW,
    label: i18n.t('stepScroll.element.window', 'Window'),
  },
  {
    value: STEP_SCROLL_TARGET_TYPE.ELEMENT,
    label: i18n.t('stepScroll.element.element', 'Scrollable element on page'),
  },
];

export const STEP_SCROLL_TO = [
  {
    value: STEP_SCROLL_TO_TYPE.COORDS,
    label: i18n.t('stepScroll.scrollTo.coords', 'XY coordinates'),
  },
  { value: STEP_SCROLL_TO_TYPE.EDGE, label: i18n.t('stepScroll.scrollTo.edge', 'Position') },
  {
    value: STEP_SCROLL_TO_TYPE.UNTIL_NEXT_STEP_ELEMENT_IS_VISIBLE,
    label: i18n.t(
      'stepScroll.scrollTo.untilNextStepElementIsVisible',
      'Until element from next step is visible',
    ),
  },
];

export const STEP_SCROLL_DIRECTION = {
  DOWN: {
    value: STEP_SCROLL_DIRECTION_TYPE.DOWN,
    label: i18n.t('stepScroll.direction.down', 'Down'),
  },
  UP: { value: STEP_SCROLL_DIRECTION_TYPE.UP, label: i18n.t('stepScroll.direction.up', 'Up') },
  RIGHT: {
    value: STEP_SCROLL_DIRECTION_TYPE.RIGHT,
    label: i18n.t('stepScroll.direction.right', 'Right'),
  },
  LEFT: {
    value: STEP_SCROLL_DIRECTION_TYPE.LEFT,
    label: i18n.t('stepScroll.direction.left', 'Left'),
  },
};

export const INTERACTION_POSITION_TYPE = {
  SMART: 'smart',
  CUSTOM: 'custom',
  TOP_LEFT: 'topLeft',
  TOP_CENTER: 'topCenter',
  TOP_RIGHT: 'topRight',
  LEFT: 'middleLeft',
  CENTER: 'middleCenter',
  RIGHT: 'middleRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_CENTER: 'bottomCenter',
  BOTTOM_RIGHT: 'bottomRight',
};

export const INTERACTION_POSITIONS_MAP = {
  [INTERACTION_POSITION_TYPE.SMART]: {
    label: i18n.t('interactionPostitions.smart', 'Smart detection'),
    value: INTERACTION_POSITION_TYPE.SMART,
  },
  [INTERACTION_POSITION_TYPE.CUSTOM]: {
    label: i18n.t('interactionPostitions.custom', 'XY coordinates within element'),
    value: INTERACTION_POSITION_TYPE.CUSTOM,
  },
  [INTERACTION_POSITION_TYPE.TOP_LEFT]: {
    label: i18n.t('interactionPostitions.topLeft', 'Top left'),
    value: INTERACTION_POSITION_TYPE.TOP_LEFT,
  },
  [INTERACTION_POSITION_TYPE.TOP_CENTER]: {
    label: i18n.t('interactionPostitions.topCenter', 'Top center'),
    value: INTERACTION_POSITION_TYPE.TOP_CENTER,
  },
  [INTERACTION_POSITION_TYPE.TOP_RIGHT]: {
    label: i18n.t('interactionPostitions.topRight', 'Top right'),
    value: INTERACTION_POSITION_TYPE.TOP_RIGHT,
  },
  [INTERACTION_POSITION_TYPE.LEFT]: {
    label: i18n.t('interactionPostitions.middleLeft', 'Left'),
    value: INTERACTION_POSITION_TYPE.LEFT,
  },
  [INTERACTION_POSITION_TYPE.CENTER]: {
    label: i18n.t('interactionPostitions.middleCenter', 'Center'),
    value: INTERACTION_POSITION_TYPE.CENTER,
  },
  [INTERACTION_POSITION_TYPE.RIGHT]: {
    label: i18n.t('interactionPostitions.middleRight', 'Right'),
    value: INTERACTION_POSITION_TYPE.RIGHT,
  },
  [INTERACTION_POSITION_TYPE.BOTTOM_LEFT]: {
    label: i18n.t('interactionPostitions.bottomLeft', 'Bottom left'),
    value: INTERACTION_POSITION_TYPE.BOTTOM_LEFT,
  },
  [INTERACTION_POSITION_TYPE.BOTTOM_CENTER]: {
    label: i18n.t('interactionPostitions.bottomCenter', 'Bottom center'),
    value: INTERACTION_POSITION_TYPE.BOTTOM_CENTER,
  },
  [INTERACTION_POSITION_TYPE.BOTTOM_RIGHT]: {
    label: i18n.t('interactionPostitions.bottomRight', 'Bottom right'),
    value: INTERACTION_POSITION_TYPE.BOTTOM_RIGHT,
  },
};

export const INTERACTION_POSITIONS = [
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.SMART],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.CUSTOM],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.TOP_LEFT],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.TOP_CENTER],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.TOP_RIGHT],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.LEFT],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.CENTER],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.RIGHT],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.BOTTOM_LEFT],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.BOTTOM_CENTER],
  INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.BOTTOM_RIGHT],
];

export const STEP_SCROLL_EDGE_TYPE = INTERACTION_POSITION_TYPE;
export const STEP_SCROLL_EDGE = without(
  [
    INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.SMART],
    INTERACTION_POSITIONS_MAP[INTERACTION_POSITION_TYPE.CUSTOM],
  ],
  INTERACTION_POSITIONS,
);
export const STEP_SCROLL_EDGE_MAP = INTERACTION_POSITIONS_MAP;

const VALUE = 'value';
const COUNT = 'count';
const TEXT_CONTENT = 'textContent';
const VISIBLE = 'visible';
const NOT_VISIBLE = 'notVisible';
const EXIST = 'exist';
const NOT_EXIST = 'notExist';
const CHECKED = 'checked';
const NOT_CHECKED = 'notChecked';
const CUSTOM_JAVASCRIPT = 'customJavaScript';
const PAGE_HAS_TITLE = 'pageTitle';
const PAGE_SHOWS_TEXT = 'pageShowsText';
const PAGE_DOES_NOT_SHOW_TEXT = 'pageDoesNotShowText';
const PAGE_URL_IS = 'pageUrlIs';
const DOWNLOAD_STARTED = 'downloadStarted';
const VARIABLE_VALUE = 'variableValue';

export const ASSERTION_PROPERTY_TYPE = {
  VALUE,
  COUNT,
  TEXT_CONTENT,
  VISIBLE,
  NOT_VISIBLE,
  EXIST,
  NOT_EXIST,
  CHECKED,
  NOT_CHECKED,
  CUSTOM_JAVASCRIPT,
  PAGE_HAS_TITLE,
  PAGE_SHOWS_TEXT,
  PAGE_DOES_NOT_SHOW_TEXT,
  PAGE_URL_IS,
  DOWNLOAD_STARTED,
  VARIABLE_VALUE,
};

export const ASSERTION_PROPERTY = {
  [VALUE]: { label: 'value', value: VALUE },
  [TEXT_CONTENT]: { label: 'text content', value: TEXT_CONTENT },
  [COUNT]: { label: 'count', value: COUNT },
  [CUSTOM_JAVASCRIPT]: { label: 'custom Javascript', value: CUSTOM_JAVASCRIPT, isTrueFalse: true },
  [VISIBLE]: { label: 'visible', value: VISIBLE, isTrueFalse: true },
  [NOT_VISIBLE]: { label: 'not visible', value: NOT_VISIBLE, isTrueFalse: true },
  [EXIST]: { label: 'exist', value: EXIST, isTrueFalse: true },
  [NOT_EXIST]: { label: 'not exist', value: NOT_EXIST, isTrueFalse: true },
  [CHECKED]: { label: 'checked', value: CHECKED, isTrueFalse: true },
  [NOT_CHECKED]: { label: 'not checked', value: NOT_CHECKED, isTrueFalse: true },
  [PAGE_URL_IS]: { label: 'url', value: PAGE_URL_IS, isDocumentAssertion: true },
  [PAGE_HAS_TITLE]: { label: 'title', value: PAGE_HAS_TITLE, isDocumentAssertion: true },
  [PAGE_SHOWS_TEXT]: {
    label: 'show',
    value: PAGE_SHOWS_TEXT,
    isDocumentAssertion: true,
    hiddenOperator: true,
  },
  [PAGE_DOES_NOT_SHOW_TEXT]: {
    label: 'not show',
    value: PAGE_DOES_NOT_SHOW_TEXT,
    isDocumentAssertion: true,
    hiddenOperator: true,
  },
  [DOWNLOAD_STARTED]: {
    label: 'title',
    value: DOWNLOAD_STARTED,
    isDocumentAssertion: true,
    hiddenOperator: true,
  },
  [VARIABLE_VALUE]: { label: 'variable', value: VARIABLE_VALUE, isDocumentAssertion: true },
};

export const ASSERTION_PROPERTIES = [
  ASSERTION_PROPERTY[VALUE],
  ASSERTION_PROPERTY[TEXT_CONTENT],
  ASSERTION_PROPERTY[CUSTOM_JAVASCRIPT],
  ASSERTION_PROPERTY[COUNT],
  ASSERTION_PROPERTY[VISIBLE],
  ASSERTION_PROPERTY[NOT_VISIBLE],
  ASSERTION_PROPERTY[EXIST],
  ASSERTION_PROPERTY[NOT_EXIST],
  ASSERTION_PROPERTY[CHECKED],
  ASSERTION_PROPERTY[NOT_CHECKED],
  ASSERTION_PROPERTY[PAGE_HAS_TITLE],
  ASSERTION_PROPERTY[PAGE_SHOWS_TEXT],
  ASSERTION_PROPERTY[PAGE_DOES_NOT_SHOW_TEXT],
  ASSERTION_PROPERTY[DOWNLOAD_STARTED],
  ASSERTION_PROPERTY[VARIABLE_VALUE],
];

export const ASSERTION_TYPE_VARIANT = {
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  CONTAIN: 'contain',
  NOT_CONTAIN: 'notContain',
  GREATER_THAN: 'greaterThan',
  LESS_THAN: 'lessThan',
  MATCH: 'match',
  NOT_MATCH: 'notMatch',
};

export const ASSERTION_TYPE = {
  ANY: 'any',
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  CONTAIN: 'contain',
  NOT_CONTAIN: 'notContain',
  GREATER_THAN: 'greaterThan',
  LESS_THAN: 'lessThan',
  MATCH: 'match',
  NOT_MATCH: 'notMatch',
};

export const ASSERTION_TYPE_PARAMS = {
  [ASSERTION_TYPE.ANY]: { label: 'any', value: 'any' },
  [ASSERTION_TYPE.EQUAL]: { label: 'is', value: 'equal' },
  [ASSERTION_TYPE.NOT_EQUAL]: { label: 'is not', value: 'notEqual' },
  [ASSERTION_TYPE.CONTAIN]: { label: 'contains', value: 'contain' },
  [ASSERTION_TYPE.NOT_CONTAIN]: { label: 'does not contain', value: 'notContain' },
  [ASSERTION_TYPE.GREATER_THAN]: { label: 'is greater than', value: 'greaterThan' },
  [ASSERTION_TYPE.LESS_THAN]: { label: 'is less than', value: 'lessThan' },
  [ASSERTION_TYPE.MATCH]: { label: 'matches regex', value: 'match' },
  [ASSERTION_TYPE.NOT_MATCH]: { label: 'does not match regex', value: 'notMatch' },
};

const TEXT_PROPERTIES = [
  ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL],
  ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.NOT_EQUAL],
  ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.CONTAIN],
  ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.NOT_CONTAIN],
  ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.MATCH],
];

export const ASSERTION_TYPES_BY_PROPERTY = {
  [VALUE]: [
    ...TEXT_PROPERTIES,
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.GREATER_THAN],
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.LESS_THAN],
  ],
  [TEXT_CONTENT]: [
    ...TEXT_PROPERTIES,
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.GREATER_THAN],
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.LESS_THAN],
  ],
  [COUNT]: [
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL],
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.GREATER_THAN],
    ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.LESS_THAN],
  ],
  [VISIBLE]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [NOT_VISIBLE]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [EXIST]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [NOT_EXIST]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [CHECKED]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [NOT_CHECKED]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [CUSTOM_JAVASCRIPT]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.EQUAL]],
  [PAGE_HAS_TITLE]: TEXT_PROPERTIES,
  [PAGE_SHOWS_TEXT]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.CONTAIN]],
  [PAGE_DOES_NOT_SHOW_TEXT]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.NOT_CONTAIN]],
  [PAGE_URL_IS]: TEXT_PROPERTIES,
  [DOWNLOAD_STARTED]: [ASSERTION_TYPE_PARAMS[ASSERTION_TYPE.ANY], ...TEXT_PROPERTIES],
  [VARIABLE_VALUE]: TEXT_PROPERTIES,
};

export const STEP_SCROLL_DEFAULT = {
  type: STEP_TYPE.SCROLL,
  scrollInside: STEP_SCROLL_TARGET_TYPE.WINDOW,
  scrollTo: STEP_SCROLL_TO_TYPE.COORDS,
  scrollX: 0,
  scrollY: 0,
};

export const STEP_ASSERT_DEFAULT = {
  type: STEP_TYPE.ASSERT,
  assertionProperty: ASSERTION_PROPERTIES[0].value,
  assertionType: ASSERTION_TYPES_BY_PROPERTY[ASSERTION_PROPERTIES[0].value][0].value,
  assertionExpectedValue: '',
};

export const STEP_TYPE_LABEL = {
  [STEP_TYPE.ASSERT]: i18n.t('stepType.label.assert', 'Assert'),
  [STEP_TYPE.CLICK]: i18n.t('stepType.label.click', 'Click'),
  [STEP_TYPE.DOUBLE_CLICK]: i18n.t('stepType.label.dblClick', 'Double click'),
  [STEP_TYPE.RIGHT_CLICK]: i18n.t('stepType.label.rightClick', 'Right click'),
  [STEP_TYPE.HOVER]: i18n.t('stepType.label.hover', 'Hover'),
  [STEP_TYPE.SCROLL]: i18n.t('stepType.label.scroll', 'Scroll'),
  [STEP_TYPE.MOUSE_DOWN]: i18n.t('stepType.label.mouseDown', 'Press mouse button'),
  [STEP_TYPE.MOUSE_UP]: i18n.t('stepType.label.mouseUp', 'Release mouse button'),
  [STEP_TYPE.TYPE]: i18n.t('stepType.label.type', 'Type text'),
  [STEP_TYPE.CLEAR]: i18n.t('stepType.label.clear', 'Clear input'),
  [STEP_TYPE.CHANGE]: i18n.t('stepType.label.change', 'Change value'),
  [STEP_TYPE.SELECT]: i18n.t('stepType.label.select', 'Select option'),
  [STEP_TYPE.GOTO]: i18n.t('stepType.label.goto', 'Go to URL'),
  [STEP_TYPE.UPLOAD_FILE]: i18n.t('stepType.label.uploadFile', 'Upload file'),
  [STEP_TYPE.NEW_TAB]: i18n.t('stepType.label.newTab', 'New tab'),
  [STEP_TYPE.CLOSE_TAB]: i18n.t('stepType.label.closeTab', 'Close tab'),
  [STEP_TYPE.SWITCH_CONTEXT]: i18n.t('stepType.label.switchContext', 'Switch context'),
  [STEP_TYPE.EXECUTE]: i18n.t('stepType.label.js', 'Run custom Javascript'),
  [STEP_TYPE.DRAG_AND_DROP]: i18n.t('stepType.label.dragAndDrop', 'Drag & drop'),
  [STEP_TYPE.ANSWER_PROMPT]: i18n.t('stepType.label.answerPrompt', 'Answer a prompt'),
  [STEP_TYPE.SET_LOCAL_VARIABLE]: i18n.t('stepType.label.setLocalVariable', 'Set variable'),
  [STEP_TYPE.RELOAD_PAGE]: i18n.t('stepType.label.reloadPage', 'Reload page'),
  [ASSERTION_PROPERTY_TYPE.VISIBLE]: i18n.t(
    'createStepModal.itemLabel.elementIsVisible',
    'Element is visible',
  ),
  [ASSERTION_PROPERTY_TYPE.NOT_VISIBLE]: i18n.t(
    'createStepModal.itemLabel.elementIsNotVisible',
    'Element is not visible',
  ),
  [ASSERTION_PROPERTY_TYPE.TEXT_CONTENT]: i18n.t(
    'createStepModal.itemLabel.elementHasText',
    'Element has text',
  ),
  [ASSERTION_PROPERTY_TYPE.VALUE]: i18n.t(
    'createStepModal.itemLabel.formFieldHasValue',
    'Form input has value',
  ),
  [ASSERTION_PROPERTY_TYPE.CHECKED]: i18n.t(
    'createStepModal.itemLabel.formFieldIsChecked',
    'Form input is checked',
  ),
  [ASSERTION_PROPERTY_TYPE.NOT_CHECKED]: i18n.t(
    'createStepModal.itemLabel.formFieldIsNotChecked',
    'Form input is not checked',
  ),
  [ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT]: i18n.t(
    'createStepModal.itemLabel.customJavascript',
    'Custom Javascript',
  ),
  [ASSERTION_PROPERTY_TYPE.COUNT]: i18n.t(
    'createStepModal.itemLabel.count',
    'Number of elements in DOM',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT]: i18n.t(
    'createStepModal.itemLabel.pageShowsText',
    'Page shows specific text',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT]: i18n.t(
    'createStepModal.itemLabel.pageDoesNotShowText',
    'Page does not show specific text',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE]: i18n.t(
    'createStepModal.itemLabel.pageHasTitle',
    'Page has title',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_URL_IS]: i18n.t(
    'createStepModal.itemLabel.pageUrlIs',
    'URL address',
  ),
  [ASSERTION_PROPERTY_TYPE.EXIST]: i18n.t('createStepModal.itemLabel.exists', 'DOM element exists'),
  [ASSERTION_PROPERTY_TYPE.NOT_EXIST]: i18n.t(
    'createStepModal.itemLabel.notExist',
    'DOM element does not exist',
  ),
  [ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED]: i18n.t(
    'createStepModal.itemLabel.downloadStarted',
    'Download started',
  ),
  [ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE]: i18n.t(
    'createStepModal.itemLabel.variable',
    'Variable value',
  ),
};

export const STEP_TYPE_PARAMS_LABEL = {
  ...STEP_TYPE_LABEL,
  [ASSERTION_PROPERTY_TYPE.TEXT_CONTENT]: i18n.t(
    'stepTypesParamsLabel.textContent',
    'element text',
  ),
  [ASSERTION_PROPERTY_TYPE.VALUE]: i18n.t('stepTypesParamsLabel.value', 'form value'),
  [ASSERTION_PROPERTY_TYPE.VISIBLE]: i18n.t('stepTypesParamsLabel.visible', 'element is visible'),
  [ASSERTION_PROPERTY_TYPE.NOT_VISIBLE]: i18n.t(
    'stepTypesParamsLabel.notVisible',
    'element is not visible',
  ),
  [ASSERTION_PROPERTY_TYPE.CHECKED]: i18n.t(
    'stepTypesParamsLabel.checked',
    'form input is checked',
  ),
  [ASSERTION_PROPERTY_TYPE.NOT_CHECKED]: i18n.t(
    'stepTypesParamsLabel.notChecked',
    'form input is not checked',
  ),
  [ASSERTION_PROPERTY_TYPE.COUNT]: i18n.t(
    'stepTypesParamsLabel.count',
    'number of elements in DOM',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT]: i18n.t(
    'stepTypesParamsLabel.pageShowsText',
    'page shows a text',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT]: i18n.t(
    'stepTypesParamsLabel.pageDoesNotShowText',
    'page does not show text',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE]: i18n.t(
    'stepTypesParamsLabel.pageHasTitle',
    'page title',
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_URL_IS]: i18n.t('stepTypesParamsLabel.pageUrlIs', 'URL address'),
  [ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED]: i18n.t(
    'stepTypesParamsLabel.downloadStarted',
    'download started',
  ),
  [ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE]: i18n.t(
    'stepTypesParamsLabel.variable',
    'variable value',
  ),
};

export const STEP_TYPE_ICON_NAME: Record<string, IconName> = {
  [STEP_TYPE.CLICK]: 'click',
  [STEP_TYPE.DOUBLE_CLICK]: 'dblClick',
  [STEP_TYPE.RIGHT_CLICK]: 'rightClick',
  [STEP_TYPE.HOVER]: 'hover',
  [STEP_TYPE.SCROLL]: 'scroll',
  [STEP_TYPE.DRAG_AND_DROP]: 'dragAndDrop',
  [STEP_TYPE.MOUSE_DOWN]: 'pressMouseButton',
  [STEP_TYPE.MOUSE_UP]: 'releaseMouseButton',
  [STEP_TYPE.TYPE]: 'keyboard',
  [STEP_TYPE.CLEAR]: 'clearInput',
  [STEP_TYPE.CHANGE]: 'changeInput',
  [STEP_TYPE.SELECT]: 'dropdownSelect',
  [STEP_TYPE.GOTO]: 'goToBrowser',
  [STEP_TYPE.NEW_TAB]: 'newTab',
  [STEP_TYPE.CLOSE_TAB]: 'closeTab',
  [STEP_TYPE.SWITCH_CONTEXT]: 'context',
  [STEP_TYPE.EXECUTE]: 'jS',
  [STEP_TYPE.UPLOAD_FILE]: 'upload',
  [STEP_TYPE.ANSWER_PROMPT]: 'answerPrompt',
  [STEP_TYPE.SET_LOCAL_VARIABLE]: 'save',
  [STEP_TYPE.RELOAD_PAGE]: 'actionReload',
  [ASSERTION_PROPERTY_TYPE.VISIBLE]: 'eye',
  [ASSERTION_PROPERTY_TYPE.NOT_VISIBLE]: 'eyeNotVisible',
  [ASSERTION_PROPERTY_TYPE.TEXT_CONTENT]: 'eyeText',
  [ASSERTION_PROPERTY_TYPE.VALUE]: 'inputType',
  [ASSERTION_PROPERTY_TYPE.CHECKED]: 'inputChecked',
  [ASSERTION_PROPERTY_TYPE.NOT_CHECKED]: 'inputUnchecked',
  [ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT]: 'jS',
  [ASSERTION_PROPERTY_TYPE.EXIST]: 'exists',
  [ASSERTION_PROPERTY_TYPE.NOT_EXIST]: 'notExists',
  [ASSERTION_PROPERTY_TYPE.COUNT]: 'listCount',
  [ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT]: 'monitorText',
  [ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT]: 'monitorTextNotVisible',
  [ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE]: 'monitorParagraph',
  [ASSERTION_PROPERTY_TYPE.PAGE_URL_IS]: 'url',
  [ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED]: 'download',
  [ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE]: 'variableCondition',
} as const;

export const MOUSE_STEP_TYPE = [
  STEP_TYPE.CLICK,
  STEP_TYPE.DOUBLE_CLICK,
  STEP_TYPE.RIGHT_CLICK,
  STEP_TYPE.HOVER,
  STEP_TYPE.SCROLL,
  STEP_TYPE.DRAG_AND_DROP,
  STEP_TYPE.MOUSE_DOWN,
  STEP_TYPE.MOUSE_UP,
];

export const INPUT_STEP_TYPES = [
  STEP_TYPE.TYPE,
  STEP_TYPE.SELECT,
  STEP_TYPE.CLEAR,
  STEP_TYPE.CHANGE,
  STEP_TYPE.UPLOAD_FILE,
];

export const WINDOW_STEP_TYPE = [
  STEP_TYPE.GOTO,
  STEP_TYPE.NEW_TAB,
  STEP_TYPE.CLOSE_TAB,
  STEP_TYPE.RELOAD_PAGE,
];

export const ADVANCED_STEP_TYPE = [
  STEP_TYPE.SET_LOCAL_VARIABLE,
  STEP_TYPE.SWITCH_CONTEXT,
  STEP_TYPE.EXECUTE,
  STEP_TYPE.ANSWER_PROMPT,
];

export const ELEMENT_ASSERTION_TYPE = [
  ASSERTION_PROPERTY_TYPE.VISIBLE,
  ASSERTION_PROPERTY_TYPE.NOT_VISIBLE,
  ASSERTION_PROPERTY_TYPE.TEXT_CONTENT,
];

export const INPUT_ASSERTION_TYPE = [
  ASSERTION_PROPERTY_TYPE.VALUE,
  ASSERTION_PROPERTY_TYPE.CHECKED,
  ASSERTION_PROPERTY_TYPE.NOT_CHECKED,
];

export const PAGE_ASSERTION_TYPE = [
  ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT,
  ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT,
  ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE,
  ASSERTION_PROPERTY_TYPE.PAGE_URL_IS,
  ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED,
];

export const ADVANCED_ASSERTION_TYPE = [
  ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT,
  ASSERTION_PROPERTY_TYPE.EXIST,
  ASSERTION_PROPERTY_TYPE.NOT_EXIST,
  ASSERTION_PROPERTY_TYPE.COUNT,
  ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE,
];

export const ASSERTION_OPERATOR_FIELD_LABEL = {
  [TEXT_CONTENT]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.textContent', 'Element text'),
  },
  [VALUE]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.value', 'Form value'),
  },
  [COUNT]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.count', 'Number of elements'),
  },
  [PAGE_HAS_TITLE]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.pageHasTitle', 'Page title'),
  },
  [PAGE_DOES_NOT_SHOW_TEXT]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.pageDoesNotShowText', 'Page does not show text'),
  },
  [PAGE_SHOWS_TEXT]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.pageShowText', 'Page shows text'),
  },
  [PAGE_URL_IS]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.pageUrlIs', 'URL address'),
  },
  [DOWNLOAD_STARTED]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.downloadStarted', 'File name'),
  },
  [VARIABLE_VALUE]: {
    DEFAULT: i18n.t('assertionOperatorFieldLabel.variableValue', 'Variable value'),
  },
};

export const SELECT_TYPE = {
  TEXT: 'text',
  VALUE: 'value',
  INDEX: 'index',
};

export const SELECT_TYPE_LABEL = {
  [SELECT_TYPE.TEXT]: i18n.t('selectType.text.label', 'Text'),
  [SELECT_TYPE.VALUE]: i18n.t('selectType.value.label', 'Value'),
  [SELECT_TYPE.INDEX]: i18n.t('selectType.index.label', 'Index'),
};

export const CUSTOM_SELECTOR = {
  id: 'custom-selector',
  isActive: false,
  isCustom: true,
  selector: '',
};

export const STEPS_TYPES_WITHOUT_SELECTOR = [
  STEP_TYPE.GOTO,
  STEP_TYPE.NEW_TAB,
  STEP_TYPE.SWITCH_CONTEXT,
  STEP_TYPE.CLOSE_TAB,
  STEP_TYPE.SCROLL,
  STEP_TYPE.EXECUTE,
  STEP_TYPE.ANSWER_PROMPT,
  STEP_TYPE.RELOAD_PAGE,
];

export const EXPERIMENTAL_STEPS = [STEP_TYPE.DRAG_AND_DROP];

export const DND_TARGET_TYPE = {
  COORDS: 'coords',
  ELEMENT: 'element',
};

export const DND_DRAG_TARGET_TYPE_LABEL = {
  [DND_TARGET_TYPE.COORDS]: i18n.t('dnd.dragTarget.coords.label', 'XY coordinates in window'),
  [DND_TARGET_TYPE.ELEMENT]: i18n.t('dnd.dragTarget.element.label', 'Element'),
};

export const DND_DROP_TARGET_TYPE_LABEL = {
  [DND_TARGET_TYPE.COORDS]: i18n.t(
    'dnd.dropTarget.coords.label',
    'XY distance relative to drag start',
  ),
  [DND_TARGET_TYPE.ELEMENT]: i18n.t('dnd.dropTarget.element.label', 'Element'),
};

export const SET_LOCAL_VARIABLE_SOURCE_LABEL = {
  [VARIABLE_TYPE.ELEMENT]: i18n.t('setLocalVariable.source.element.label', 'From element'),
  [VARIABLE_TYPE.VALUE]: i18n.t('setLocalVariable.source.value.label', 'Text'),
  [VARIABLE_TYPE.EVALUATE]: i18n.t(
    'setLocalVariable.source.customJavaScript.label',
    'Custom JavaScript',
  ),
};
