import api from './common';

export const getList = (params) => api.get('/projects/', { params });

export const get = (projectId) => api.get(`/projects/${projectId}/`);

export const create = (data) => api.post(`/projects/`, data);

export const update = (projectId, data) => api.patch(`/projects/${projectId}/`, data);

export const updatePartial = (projectId, data) => api.put(`/projects/${projectId}/`, data);

export const remove = (projectId) => api.delete(`/projects/${projectId}/`);

export const clone = (projectId) => api.post(`/projects/${projectId}/clone/`);

export const updateSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/update-settings/`, settings);

export const updateGeneralSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/settings/general/`, settings);

export const updateWindowAndBrowserSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/settings/window-and-browser/`, settings);

export const updateSelectorsSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/settings/selectors/`, settings);

export const updateNotificationsSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/settings/notifications/`, settings);

export const updateWaitingConditionsSettings = (projectId, settings) =>
  api.patch(`/projects/${projectId}/settings/waiting-conditions/`, settings);

export const getProjectClipboard = (projectId) => api.get(`/projects/${projectId}/clipboard/`);

export const cleanClipboard = (projectId) => api.delete(`/tests/${projectId}/clean-steps/`);

export const removeClipboardGroup = (projectId) => api.delete(`/groups/${projectId}/`);
