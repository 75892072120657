import { createApi } from '@reduxjs/toolkit/query/react';

import type {
  ConnectAPIIntegration,
  Integration,
  IntegrationMember,
  NativeIntegration,
} from '@bugbug/core/types/integrations';
import type { Project } from '@bugbug/core/types/projects';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Integrations' as const;
export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/integrations/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // GET /integrations/
    getActiveIntegrations: builder.query<Integration[], Project['id']>({
      query: (projectId) => ({
        url: '',
        method: 'GET',
        params: { projectId },
      }),

      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id })), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),

    // GET /integrations/<type>/auth/
    getAuthUrl: builder.query<string, { type: NativeIntegration; projectId: Project['id'] }>({
      query: ({ type, ...params }) => ({
        url: `${type}/auth/`,
        method: 'GET',
        params,
      }),
    }),

    // GET /integrations/<type>/members/
    getMembersList: builder.query<
      IntegrationMember[],
      { type: NativeIntegration; projectId: Project['id'] }
    >({
      query: ({ type, ...params }) => ({
        url: `${type}/members/`,
        method: 'GET',
        params,
      }),
    }),

    // POST /integrations/<type>/connect/
    connect: builder.mutation<Integration, ConnectAPIIntegration>({
      query: ({ type, ...data }) => ({
        url: `${type}/connect/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG],
    }),

    // POST /integrations/<type>/disconnect/
    disconnect: builder.mutation<
      Integration,
      { type: NativeIntegration; projectId: Project['id'] }
    >({
      query: ({ type, ...data }) => ({
        url: `${type}/disconnect/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useGetActiveIntegrationsQuery,
  useLazyGetAuthUrlQuery,
  useConnectMutation,
  useDisconnectMutation,
  useGetMembersListQuery,
} = integrationsApi;
