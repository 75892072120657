import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgLogoVertical = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Warstwa_1", "data-name": "Warstwa 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 88.88 120", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("style", { children: ".cls-1{fill:#461e56;}" }) }),
  /* @__PURE__ */ jsx("title", { children: "Obszar roboczy 1" }),
  /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M2.24,102.23H0l0,13.28H8.91v-8.88H2.24Zm0,6.63H6.67v4.41H2.26Z" }),
  /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M40.9,106.63H32v8.88h6.65v2.25H32V120H40.9Zm-6.65,6.64v-4.41h4.41v4.41Z" }),
  /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "22.71 113.27 18.31 113.27 18.31 106.63 16.07 106.63 16.07 115.51 24.95 115.51 24.95 106.63 22.72 106.63 22.71 113.27" }),
  /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M50.21,102.23H48l0,13.28h8.88v-8.88H50.21Zm0,6.63h4.4v4.41h-4.4Z" }),
  /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M88.89,106.63H80v8.88h6.64v2.25H80V120h8.88Zm-6.65,6.64v-4.41h4.41v4.41Z" }),
  /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "70.61 113.27 66.2 113.27 66.2 106.63 63.96 106.63 63.96 115.51 72.84 115.51 72.84 106.63 70.61 106.63 70.61 113.27" }),
  /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "35.56 53.32 35.56 17.77 17.79 17.77 17.79 0 0.01 0 0.01 53.32 35.56 53.32" }),
  /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "88.88 53.32 88.88 17.77 71.11 17.77 71.11 0 53.33 0 53.33 53.32 88.88 53.32" }),
  /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "71.11 62.22 71.11 71.1 17.79 71.1 17.79 62.21 0.01 62.21 0.01 79.99 17.79 79.99 17.79 88.86 71.12 88.86 71.12 79.98 88.88 79.98 88.88 62.22 71.11 62.22" })
] });
const ForwardRef = forwardRef(SvgLogoVertical);
export default ForwardRef;
