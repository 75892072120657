import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmModal } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { ProfileActions } from '~/modules/profile/profile.redux';

const DeleteProfileModal = ({ className, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const deleteRequestState = useActionState(ProfileActions.deleteRequest, {
    onSuccess: modal.hide,
  });

  const handleDeleteConfirm = useCallback(
    () => dispatch(ProfileActions.deleteRequest(id)),
    [dispatch, id],
  );

  return (
    <ConfirmModal
      className={className}
      title={t('deleteProfileModal.title', 'Do you want to delete this profile?')}
      pending={deleteRequestState.isLoading}
      hasInternalServerError={deleteRequestState.hasInternalServerError}
      onConfirm={handleDeleteConfirm}
      onCancel={modal.hide}
    />
  );
};

DeleteProfileModal.defaultProps = {
  className: null,
};

DeleteProfileModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default DeleteProfileModal;
