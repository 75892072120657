import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '~/components/modals/Modal';
import StepTypePicker, { STEP_TYPE_PICKER_TAB } from '~/components/StepTypePicker';
import { STEP_TYPE } from '~/constants/step';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

import { Content, LoaderWrapper } from '../CreateStepModal/CreateStepModal.styled';

import { Container } from './EditStepModal.styled';

const EditStepModal = ({
  className,
  stepType,
  assertionProperty,
  assertionVariant,
  onStepChange,
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const initialTab =
    stepType === STEP_TYPE.ASSERT ? STEP_TYPE_PICKER_TAB.ASSERTIONS : STEP_TYPE_PICKER_TAB.ACTIONS;

  const { isLoading } = useActionState(TestActions.getDefaultStepsParamsRequest);

  const handleChange = useCallback(
    (groupType, newStepType, newAssertionProperty) => {
      onStepChange(newStepType, newAssertionProperty);
      modal.hide();
    },
    [modal, onStepChange],
  );

  const renderLoader = renderWhenTrue(() => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ));

  return (
    <Container className={className} data-testid="EditStepModal">
      <Header>{t('editStepModal.title', 'Change step')}</Header>
      <Content>
        {renderLoader(isLoading)}
        <StepTypePicker
          stepType={stepType}
          assertionProperty={assertionProperty}
          assertionVariant={assertionVariant}
          initialTab={initialTab}
          onChange={handleChange}
        />
      </Content>
    </Container>
  );
};

EditStepModal.defaultProps = {
  className: null,
  stepType: null,
  assertionProperty: undefined,
  onStepChange: Function.prototype,
};

EditStepModal.propTypes = {
  className: PropTypes.string,
  assertionProperty: PropTypes.string,
  onStepChange: PropTypes.func,
};

export default EditStepModal;
