import IconComponent from '@bugbug/core/components/Icon';
import LinkBase from '@bugbug/core/components/Link';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled, { css } from 'styled-components';

import Page from '~/components/Page';

export const Container = styled(Page)``;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const textStyle = css`
  display: block;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
`;

export const Title = styled.span`
  color: ${COLOR.GRAY_23};
  ${textStyle}
`;

export const Subtitle = styled.span`
  ${textStyle};
  color: ${COLOR.GRAY_20};
  margin-bottom: 24px;
`;

export const Link = styled(LinkBase)`
  ${textStyle};
  margin-top: 10px;
  color: ${COLOR.PRIMARY_LIGHT};
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'moneyPixel',
}))`
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
`;
