import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import { Warning } from '@bugbug/core/theme/typography';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { complement, path, prop, propEq, sortBy } from 'ramda';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUnmount } from 'react-use';

import CodeField from '~/components/CodeField';
import FormField from '~/components/FormField';
import { Content, ErrorInfo, Footer, Header } from '~/components/modals/Modal';
import MultiValueFormTable from '~/components/MultiValueFormTable';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import SecretToggleField from '~/components/SecretToggleField';
import { VARIABLE_TYPE } from '~/constants/variables';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectProfilesList } from '~/modules/profile/profile.selectors';
import { selectProjectSlug, selectSingleProjectId } from '~/modules/project/project.selectors';
import { VariableActions } from '~/modules/variable/variable.redux';
import urls, { reverse } from '~/views/urls';

import {
  DEFAULT_CUSTOM_JS_VALUE,
  MULTI_VALUE_FORM_COLUMNS,
  FORM_FIELD,
} from './EditVariableModal.constants';
import { VariableCreateSchema, VariableEditSchema } from './EditVariableModal.schema';
import * as S from './EditVariableModal.styled';

const EditVariableModal = ({ className, variable = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = !!variable?.id;
  const history = useHistory();
  const modal = useModal();
  const formSchema = isEdit ? VariableEditSchema : VariableCreateSchema;
  const profiles = useSelector(selectProfilesList);
  const projectId = useSelector(selectSingleProjectId);
  const projectSlug = useSelector(selectProjectSlug);
  const organizationId = useSelector(selectCurrentOrganizationId);

  const editLabels = {
    title: t('editVariableModal.edit.title.edit', 'Variable settings'),
    submitButton: t('editVariableModal.edit.submitButton', 'Save variable'),
  };
  const createLabels = {
    title: t('editVariableModal.new.title', 'New variable'),
    submitButton: t('editVariableModal.new.submitButton', 'Create variable'),
  };
  const labels = isEdit ? editLabels : createLabels;

  const initialValues = useMemo(() => {
    const variableValue = sortBy(complement(prop('isDefault')), profiles).map(
      ({ isDefault, variables, name, id }) => {
        let value = variable.value || null;
        const variableInProfile = variables.find(propEq('key', variable.key));
        const hasSecretValue = variableInProfile?.[FORM_FIELD.HAS_SECRET_CONTENT] ?? false;

        if (!isDefault) {
          value = variableInProfile?.value ?? null;
        }

        return {
          profileId: id,
          profile: name,
          hasSecretValue,
          isDefault,
          value,
        };
      },
    );

    return formSchema.cast({
      ...variable,
      hasSecretValue: variableValue.some(prop(FORM_FIELD.HAS_SECRET_CONTENT)),
      value: variableValue,
    });
  }, [formSchema, variable, profiles]);

  const handleSubmit = useCallback(
    (values, formik) => {
      const valuesWithKey = formSchema.cast({ ...values, name: values.key });

      if (isEdit) {
        dispatch(VariableActions.updateRequest(valuesWithKey.id, valuesWithKey));
      } else {
        history.push(reverse(urls.customVariables, { organizationId, projectId, projectSlug }));

        dispatch(VariableActions.createRequest(valuesWithKey));
      }
      formik.setSubmitting(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isEdit, formSchema, organizationId, projectId, projectSlug],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  });

  const handleSuccess = useCallback(() => {
    formik.setSubmitting(false);
    modal.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setSubmitting, modal.hide]);

  const handleFailure = useCallback((stateErrors) => {
    if (stateErrors) {
      formik.setErrors(stateErrors);
    }
    formik.setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHasSecretValueChange = useCallback((event) => {
    if (event.target.value === 'false') {
      formik.setFieldValue(
        FORM_FIELD.VALUE,
        formik.values[FORM_FIELD.VALUE].map((variableField) => ({
          ...variableField,
          value: '',
        })),
        true,
      );
    }
    formik.handleChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValuesChange = useCallback((event, index = 0) => {
    formik.setFieldValue(`${FORM_FIELD.VALUE}[${index}].value`, event.target.value, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = useCallback((event) => {
    formik.setFieldValue(
      `${FORM_FIELD.VALUE}[0].value`,
      event.target.value === VARIABLE_TYPE.EVALUATE ? DEFAULT_CUSTOM_JS_VALUE : '',
      true,
    );
    formik.handleChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManageProfiles = useCallback(
    () => {
      history.push(reverse(urls.profiles, { organizationId, projectId, projectSlug }));
      modal.hide();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.hide],
  );

  const getValueProps = useCallback(
    (variableValue, valueKey, profile) => {
      const placeholder = profile.isDefault
        ? t('editVariableModal.type.custom.default.placeholder.', 'Enter variable value')
        : t('editVariableModal.type.custom.others.placeholder', '(default)');

      const clearOnFocus = `${formik.values[FORM_FIELD.HAS_SECRET_CONTENT]}` === 'true';
      return { placeholder, clearOnFocus };
    },
    [t, formik.values],
  );

  const requestParams = { reset: false, onSuccess: handleSuccess, onFailure: handleFailure };
  const requestState = useActionState(
    isEdit ? VariableActions.updateRequest : VariableActions.createRequest,
    requestParams,
  );

  useUnmount(() => {
    requestState.reset();
  });

  const hasKeyError = formik.touched[FORM_FIELD.KEY] && formik.errors[FORM_FIELD.KEY];

  const NameDetails = useMemo(
    () => (
      <>
        {!hasKeyError && formik.values[FORM_FIELD.KEY] && (
          <S.Key>{`{{${formik.values[FORM_FIELD.KEY]}}}`}</S.Key>
        )}
        <Tooltip
          content={t('editVariableModal.name.copy.tooltip', 'Copy variable in curly brackets')}
        >
          <S.CopyButton small value={`{{${formik.values[FORM_FIELD.KEY]}}}`} />
        </Tooltip>
        <Tooltip
          content={t(
            'editVariableModal.name.info.tooltip',
            'You can use this variable in tests by placing its name in curly brackets in any input field',
          )}
        >
          <S.KeyInfo />
        </Tooltip>
      </>
    ),
    [formik.values, hasKeyError, t],
  );

  const ValueDescription = useMemo(
    () => (
      <Trans i18nKey="editVariableModal.value.decription">
        You can specify a different value for each profile. Leave empty field to keep the default
        value. <Link onMouseDown={handleManageProfiles}>Manage profiles</Link>
      </Trans>
    ),
    [handleManageProfiles],
  );

  const codeFieldName = `${FORM_FIELD.VALUE}[0].value`;

  return (
    <S.Form className={className} onSubmit={formik.handleSubmit}>
      <S.FormBackdropOverrides />
      <Header>{labels.title}</Header>
      {variable.isSystem && (
        <Warning>
          <Icon name="info" />
          {t(
            'editVariableModal.default.warning',
            "You can't edit as this is a built-in system variable.",
          )}
        </Warning>
      )}
      <Content>
        <FormField
          label={t('editVariableModal.name.label', 'Name')}
          description={
            !variable.isSystem &&
            t('editVariableModal.name.description', 'No spaces or other special characters allowed')
          }
        >
          <Input
            {...formik.getFieldProps(FORM_FIELD.KEY)}
            placeholder={t('editVariableModal.name.placeholder', 'Enter variable name')}
            error={hasKeyError}
            fullWidth
            autoFocus={!variable.isSystem}
            endAdornment={NameDetails}
            readOnly={variable.isSystem}
          />
        </FormField>
        {variable.description && (
          <S.VariableDescription>
            {variable.description}{' '}
            <Trans i18nKey="editVariableModal.name.systemDescription">
              For more information, see our{' '}
              <a
                href="https://docs.bugbug.io/editing-tests/variables#use-built-in-variables-for-dynamic-or-random-values"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>
            </Trans>
          </S.VariableDescription>
        )}
        <FormField label={t('editVariableModal.type.label', 'Type')}>
          <S.Select
            {...formik.getFieldProps(FORM_FIELD.TYPE)}
            onChange={handleTypeChange}
            disabled={variable.isSystem}
          >
            <SelectOption value={VARIABLE_TYPE.VALUE}>
              <S.VariableTypeHeader>
                {t('editVariableModal.type.value.label', 'Text')}
              </S.VariableTypeHeader>
              <S.VariableTypeDescription>
                {t('editVariableModal.type.value.description', 'Simple variable with single value')}
              </S.VariableTypeDescription>
            </SelectOption>
            <SelectOption value={VARIABLE_TYPE.EVALUATE}>
              <S.VariableTypeHeader>
                {t('editVariableModal.type.customJavaScript.label', 'Custom JavaScript')}
              </S.VariableTypeHeader>
              <S.VariableTypeDescription>
                {t(
                  'editVariableModal.type.customJavaScript.description',
                  'Execute JS function when test is running and return dynamic variable value',
                )}
              </S.VariableTypeDescription>
            </SelectOption>
          </S.Select>
        </FormField>
        {formik.values[FORM_FIELD.TYPE] === VARIABLE_TYPE.VALUE && (
          <SecretToggleField
            tooltipDisabled
            name={FORM_FIELD.HAS_SECRET_CONTENT}
            disabled={!!variable.isSystem}
            onChange={handleHasSecretValueChange}
            value={formik.values[FORM_FIELD.HAS_SECRET_CONTENT]}
          />
        )}
        {formik.values[FORM_FIELD.TYPE] === VARIABLE_TYPE.VALUE && !variable.isSystem && (
          <FormField
            label={t('editVariableModal.value.label', 'Value')}
            description={ValueDescription}
          >
            <MultiValueFormTable
              {...formik.getFieldProps(FORM_FIELD.VALUE)}
              onChange={handleValuesChange}
              columns={MULTI_VALUE_FORM_COLUMNS}
              error={formik.touched[FORM_FIELD.VALUE] && formik.errors[FORM_FIELD.VALUE]}
              getValueProps={getValueProps}
            />
          </FormField>
        )}
        {formik.values[FORM_FIELD.TYPE] === VARIABLE_TYPE.VALUE && variable.isSystem && (
          <FormField label={t('editVariableModal.value.label', 'Value')}>
            <Input
              {...formik.getFieldProps(FORM_FIELD.VALUE)}
              value={formik.values[FORM_FIELD.VALUE][0].value}
              placeholder={t(
                'editVariableModal.type.text.value.placeholder',
                'It will be automatically generated when running a test.',
              )}
              error={formik.touched[FORM_FIELD.VALUE] && formik.errors[FORM_FIELD.VALUE]}
              fullWidth
              readOnly={variable.isSystem}
            />
          </FormField>
        )}
        {formik.values[FORM_FIELD.TYPE] !== VARIABLE_TYPE.VALUE && (
          <CodeField
            {...formik.getFieldProps(codeFieldName)}
            value={formik.values[FORM_FIELD.VALUE][0].value}
            description={t(
              'editVariableModal.type.customJavaScript.value.description',
              'This function is executed every time when this variable is used.',
            )}
            error={
              path([FORM_FIELD.VALUE, 0, 'value'], formik.touched) &&
              (path([FORM_FIELD.VALUE, 0, 'value'], formik.errors) ||
                formik.errors[FORM_FIELD.VALUE])
            }
            functionArguments={['variables']}
            readOnly={variable.isSystem}
          />
        )}
      </Content>
      <Footer>
        <ErrorInfo isVisible={requestState.hasInternalServerError} />
        <Button onClick={modal.hide} disabled={formik.isSubmitting}>
          {t('default.button.cancel')}
        </Button>
        {!variable.isSystem && (
          <PaidFeature onRedirect={modal.hide}>
            {(isDisabled) => (
              <ActionButton
                disabled={isDisabled}
                pending={formik.isSubmitting}
                type="submit"
                variant={BUTTON_VARIANT.PRIMARY}
              >
                {labels.submitButton}
              </ActionButton>
            )}
          </PaidFeature>
        )}
      </Footer>
    </S.Form>
  );
};

EditVariableModal.propTypes = {
  className: PropTypes.string,
  variable: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(VARIABLE_TYPE)),
    value: PropTypes.string,
    isSystem: PropTypes.bool,
  }),
};

export default EditVariableModal;
