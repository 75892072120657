import IconButton from '@bugbug/core/components/IconButton';
import styled from 'styled-components';

export const Button = styled(IconButton)`
  width: 40px;
  height: 40px;
  opacity: 0.37;

  svg {
    width: 26px;
    height: 26px;
    color: ${({ theme }) => theme.colors.text.normal};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.border.medium};
    opacity: 0.66;
  }
`;
