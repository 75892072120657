import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled, { css } from 'styled-components';

import MoreSectionBase from '~/components/MoreSection';

export const TestSection = styled.div`
  min-height: 200px;

  button {
    margin-top: 16px;
  }
`;

export const TestResult = styled.div<{ succeeded?: boolean; failed?: boolean }>`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;

  &:not(:empty) {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 5px;

    span:first-child {
      font-weight: ${FONT_WEIGHT.MEDIUM};
    }
  }

  ${({ succeeded }) =>
    succeeded &&
    css`
      background-color: ${COLOR.SUCCESS};
      color: ${COLOR.WHITE};

      button {
        color: ${COLOR.WHITE} !important;
        opacity: 0.85;
        font-weight: ${FONT_WEIGHT.NORMAL};
      }
    `};

  ${({ failed }) =>
    failed &&
    css`
      background-color: ${COLOR.RED_1};
      color: ${COLOR.ERROR};
    `};

  button {
    margin-top: 16px;
  }

  span:first-child {
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;

export const MoreSection = styled(MoreSectionBase)`
  margin: 0;
  word-break: break-word;

  button {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-direction: row-reverse;
  }

  &[aria-expanded='true'] svg {
    transform: rotate(180deg);
  }

  &[aria-expanded='false'] svg {
    transform: rotate(0);
  }
`;
