import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTeams = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 90.26 42.5", role: "img", ref, ...props, children: /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "Logos", children: [
  /* @__PURE__ */ jsx("circle", { cx: 22.58, cy: 7.97, r: 6.2, style: {
    fill: "#7a7fbc"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M22.14,9.3H16.52l0,.12h0l0,.12A6.2,6.2,0,0,0,23.91,14V11.07A1.77,1.77,0,0,0,22.14,9.3Z", style: {
    opacity: 0.100000001490116,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M20.81,10.63H17a6.21,6.21,0,0,0,5.6,3.54V12.4A1.77,1.77,0,0,0,20.81,10.63Z", style: {
    opacity: 0.200000002980232,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("circle", { cx: 36.53, cy: 9.96, r: 4.65, style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M40.51,15.94H30.07a.85.85,0,0,0-.85.85V27.56a6.8,6.8,0,0,0,5.27,6.75,6.64,6.64,0,0,0,7.87-5.14,6.22,6.22,0,0,0,.14-1.36V17.93A2,2,0,0,0,40.51,15.94Z", style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M33.21,17.93V30.55a10.62,10.62,0,0,1-19.82,5.31,9.2,9.2,0,0,1-.67-1.33,10.17,10.17,0,0,1-.43-1.32A11.34,11.34,0,0,1,12,30.55V17.93a2,2,0,0,1,2-2H31.21a2,2,0,0,1,2,2Z", style: {
    fill: "#7a7fbc"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M20.81,10.63H17a6.21,6.21,0,0,0,5.6,3.54V12.4A1.77,1.77,0,0,0,20.81,10.63Z", style: {
    opacity: 0.200000002980232,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M23.91,15.94V32.77a1.75,1.75,0,0,1-1.38,1.71,1,1,0,0,1-.39.05H12.72a10.17,10.17,0,0,1-.43-1.32A11.34,11.34,0,0,1,12,30.55V17.93a2,2,0,0,1,2-2h10Z", style: {
    opacity: 0.100000001490116,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M22.58,15.94V34.1a1.58,1.58,0,0,1,0,.38,1.76,1.76,0,0,1-1.72,1.38H13.39a9.2,9.2,0,0,1-.67-1.33,10.17,10.17,0,0,1-.43-1.32A11.34,11.34,0,0,1,12,30.55V17.93a2,2,0,0,1,2-2h8.64Z", style: {
    opacity: 0.200000002980232,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M22.58,15.94v15.5a1.78,1.78,0,0,1-1.77,1.77H12.29A11.34,11.34,0,0,1,12,30.55V17.93a2,2,0,0,1,2-2h8.64Z", style: {
    opacity: 0.200000002980232,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M14,15.94a2,2,0,0,0-2,2h0V30.55a11.34,11.34,0,0,0,.34,2.66h7.2a1.78,1.78,0,0,0,1.76-1.77V15.94Z", style: {
    opacity: 0.200000002980232,
    isolation: "isolate"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M1.77,10.63H19.48a1.77,1.77,0,0,1,1.77,1.77h0V30.11a1.77,1.77,0,0,1-1.77,1.77H1.77A1.77,1.77,0,0,1,0,30.11H0V12.4a1.77,1.77,0,0,1,1.77-1.77Z", style: {
    fill: "#4f58a4"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M15.91,17.23h-4V27.89H9.33V17.23h-4V14.62H15.91Z", style: {
    fill: "#fff"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M0,0H42.5V42.5H0Z", style: {
    fill: "none"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M54.67,20H51.42V30H50.1V20H46.85V18.77h7.82Z", style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M61.16,26.34H55.48a3,3,0,0,0,.72,2.08,2.52,2.52,0,0,0,1.9.73,3.94,3.94,0,0,0,2.5-.9v1.21a4.67,4.67,0,0,1-2.8.77,3.37,3.37,0,0,1-2.68-1.1,4.47,4.47,0,0,1-1-3.08A4.41,4.41,0,0,1,55.21,23a3.41,3.41,0,0,1,2.64-1.19,3,3,0,0,1,2.45,1,4.27,4.27,0,0,1,.86,2.83Zm-1.32-1.09a2.61,2.61,0,0,0-.54-1.74,1.83,1.83,0,0,0-1.47-.62,2.09,2.09,0,0,0-1.55.65,3,3,0,0,0-.79,1.71Z", style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M69,30H67.66V28.78h0a2.68,2.68,0,0,1-2.47,1.45,2.64,2.64,0,0,1-1.89-.64,2.2,2.2,0,0,1-.68-1.69c0-1.5.89-2.37,2.66-2.62l2.41-.34c0-1.37-.55-2.05-1.65-2.05a4,4,0,0,0-2.63,1V22.56a5,5,0,0,1,2.74-.76c1.89,0,2.83,1,2.83,3ZM67.66,26l-1.94.27a3.09,3.09,0,0,0-1.35.44,1.29,1.29,0,0,0-.46,1.13,1.23,1.23,0,0,0,.42,1,1.66,1.66,0,0,0,1.13.38A2.08,2.08,0,0,0,67,28.47a2.36,2.36,0,0,0,.62-1.7Z", style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M82.89,30H81.6V25.42a3.42,3.42,0,0,0-.41-1.93,1.56,1.56,0,0,0-1.39-.6,1.72,1.72,0,0,0-1.4.75,2.94,2.94,0,0,0-.58,1.81V30H76.53V25.26q0-2.37-1.83-2.37a1.72,1.72,0,0,0-1.4.71,3,3,0,0,0-.55,1.85V30H71.46V22h1.29v1.28h0a2.75,2.75,0,0,1,2.5-1.47,2.38,2.38,0,0,1,1.44.46,2.31,2.31,0,0,1,.84,1.21,2.88,2.88,0,0,1,2.68-1.67c1.77,0,2.65,1.1,2.65,3.28Z", style: {
    fill: "#555da8"
  } }),
  /* @__PURE__ */ jsx("path", { d: "M84.92,29.75V28.37a3.84,3.84,0,0,0,2.32.78c1.13,0,1.7-.38,1.7-1.14a1,1,0,0,0-.14-.54,1.52,1.52,0,0,0-.4-.4,3.14,3.14,0,0,0-.58-.31l-.72-.29a9.6,9.6,0,0,1-.94-.42,3,3,0,0,1-.67-.49,1.68,1.68,0,0,1-.41-.62,2.21,2.21,0,0,1-.14-.81,1.93,1.93,0,0,1,.26-1,2.19,2.19,0,0,1,.69-.73,3.2,3.2,0,0,1,1-.45A4.35,4.35,0,0,1,88,21.8a4.46,4.46,0,0,1,1.87.37v1.3a3.61,3.61,0,0,0-2-.58,2.57,2.57,0,0,0-.65.08,1.65,1.65,0,0,0-.5.23,1.13,1.13,0,0,0-.33.36,1,1,0,0,0-.11.46,1.19,1.19,0,0,0,.11.53,1.21,1.21,0,0,0,.34.38,2.38,2.38,0,0,0,.53.29l.72.29c.35.14.67.28,1,.43a3.22,3.22,0,0,1,.72.48,1.9,1.9,0,0,1,.46.63,1.94,1.94,0,0,1,.16.84,2.06,2.06,0,0,1-.26,1,2.35,2.35,0,0,1-.71.73,3.18,3.18,0,0,1-1,.43,5.19,5.19,0,0,1-1.2.14A4.59,4.59,0,0,1,84.92,29.75Z", style: {
    fill: "#555da8"
  } })
] }) }) });
const ForwardRef = forwardRef(SvgTeams);
export default ForwardRef;
