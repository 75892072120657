import type { Alert } from './alerts';
import type { Component } from './components';
import type { Integration, NativeIntegration } from './integrations';
import type { Organization } from './organizations';
import type { Profile } from './profiles';
import type { Project, ProjectSettingsTab } from './projects';
import type { Schedule } from './schedules';
import type { Suite, SuiteRun } from './suites';
import type { Test, TestRun } from './tests';
import type { Variable } from './variables';
import type React from 'react';

import { isEmpty } from '../utils/toolbox';

export type RouteParams<T extends Route> = RouteParamsByRoute<T>;

export type Route = ProjectRoute | OrganizationRoute | AccountRoute | PublicRoute;

export type RouteModal<T> = RouteModalWithOptional<T> | RouteModalWithRequired<T>;

export type RouteModalWithOptional<T> = RawRouteModal<T | undefined>;
export type RouteModalWithRequired<T> = RawRouteModal<T>;

type RawRouteModal<T> = React.FC<{ data: T }>;

type RouteParamsByRoute<T> = T extends ProjectRoute
  ? ProjectRouteParamsByRoute[T]
  : T extends OrganizationRoute
  ? OrganizationRouteParamsByRoute[T]
  : T extends AccountRoute
  ? AccountRouteParamsByRoute[T]
  : T extends PublicRoute
  ? PublicRouteParamsByRoute[T]
  : never;

interface ProjectRouteParamsByRoute extends Record<ProjectRoute, ProjectRouteParams> {
  schedule: ScheduleDetailsRouteParams;
  suite: SuiteDetailsRouteParams;
  suiteRun: SuiteRunDetailsRouteParams;
  test: TestDetailsRouteParams;
  testRun: TestRunDetailsRouteParams;
  projectSettings: ProjectSettings;
  component: ComponentDetailsRouteParams;
  alert: AlertDetailsRouteParams;
  integration: IntegrationDetailsRouteParams;
  testsListEdit: TestsListEditRouteParams;
  profile: ProfileRouteParams;
  customVariable: VariableRouteParams;
  builtInVariable: VariableRouteParams;
}

interface OrganizationRouteParamsByRoute
  extends Record<OrganizationRoute, OrganizationRouteParams> {
  checkout: SubscriptionCheckoutRouteParams;
  organizationSettings: OrganizationSettingsRouteParams;
}

interface AccountRouteParamsByRoute extends Record<AccountRoute, object> {
  accountSettings: AccountSettingsRouteParams;
}

interface PublicRouteParamsByRoute extends Record<PublicRoute, object> {
  authProviderRedirect: AuthRouteParams;
  integrationAuthRedirect: IntegrationAuthRouteParams;
  integrationConfig: IntegrationConfigRouteParams;
}

export interface IntegrationDetailsRouteParams extends ProjectRouteParams {
  type: Integration['type'];
}

export interface ScheduleDetailsRouteParams extends ProjectRouteParams {
  scheduleId: Schedule['id'];
}

export interface SuiteDetailsRouteParams extends ProjectRouteParams {
  suiteId: Suite['id'];
}

export interface SuiteRunDetailsRouteParams extends ProjectRouteParams {
  suiteRunId: SuiteRun['id'];
}

export interface TestDetailsRouteParams extends ProjectRouteParams {
  testId: Test['id'];
}

export interface AlertDetailsRouteParams extends ProjectRouteParams {
  alertId: Alert['id'];
}

export interface ComponentDetailsRouteParams extends ProjectRouteParams {
  componentId: Component['id'];
}

export interface TestRunDetailsRouteParams extends ProjectRouteParams {
  testRunId: TestRun['id'];
}

export interface ProjectSettings extends ProjectRouteParams {
  settingsTabSlug?: ProjectSettingsTab;
}

export interface TestsListEditRouteParams extends ProjectRouteParams {
  testId: Test['id'];
}

export interface ProfileRouteParams extends ProjectRouteParams {
  profileId: Profile['id'];
}

export interface VariableRouteParams extends ProjectRouteParams {
  variableId: Variable['id'];
}

export interface ProjectRouteParams extends OrganizationRouteParams {
  projectId: Project['id'];
  projectSlug: Project['slug'];
}

// organization
export interface SubscriptionCheckoutRouteParams extends OrganizationRouteParams {
  stepId: string;
}

export interface OrganizationSettingsRouteParams extends OrganizationRouteParams {
  settingsTabSlug?: string;
}

export interface OrganizationRouteParams {
  organizationId: Organization['id'];
}

// Account
export interface AccountSettingsRouteParams {
  settingsTabSlug?: string;
}

export interface IntegrationAuthRouteParams {
  projectId: Project['id'];
  type: NativeIntegration;
}

export interface IntegrationConfigRouteParams {
  type: NativeIntegration;
}

export interface AuthRouteParams {
  provider: 'github' | 'google';
}

type ProjectRoute = (typeof projectRouteNames)[number];

const projectModalRouteNames = [
  'alert',
  'integration',
  'newAlert',
  'newSchedule',
  'newSuite',
  'schedule',
  'suite',
  'variable',
  'newTest',
  'testsListEdit',
  'profile',
  'newProfile',
  'customVariable',
  'newCustomVariable',
  'builtInVariable',
  'projectsNew',
  'projectsEdit',
] as const;

const projectRouteNames = [
  'projectsList',
  'project',
  'projectSettings',
  'projectSettingsIndex',
  'projectGeneralSettings',
  'projectScreenSizes',
  'projectSelectors',
  'projectWaitingConditions',
  'projectBrowser',
  'runsHistory',
  'testRunsList',
  'testRun',
  'testsList',
  'test',
  'suitesList',
  'suite',
  'newSuite',
  'suiteRunsList',
  'suiteRun',
  'schedulesList',
  'schedule',
  'newSchedule',
  'alerts',
  'alert',
  'newAlert',
  'integrations',
  'integration',
  'variables',
  'customVariables',
  'builtInVariables',
  'profiles',
  'componentsList',
  'component',
  ...projectModalRouteNames,
] as const;

export type OrganizationRoute =
  | 'organizations'
  | 'newOrganization'
  | 'organization'
  | 'organizationSettings'
  | 'organizationGeneral'
  | 'users'
  | 'billingAddress'
  | 'subscription'
  | 'checkout'
  | 'checkoutBillingAddress'
  | 'checkoutPayment'
  | 'checkoutConfirmation'
  | 'creditCard'
  | 'invoices'
  | 'planLimits';

type AccountRoute =
  | 'account'
  | 'accountSettings'
  | 'accountGeneral'
  | 'accountChangePassword'
  | 'accountNotifications';

export type PublicRoute =
  | 'home'
  | 'signUp'
  | 'signUpSuccess'
  | 'signUpConfirm'
  | 'signUpByInvitation'
  | 'onboarding'
  | 'welcome'
  | 'setupProfile'
  | 'setupOrganization'
  | 'onboardingRedirect'
  | 'passwordReset'
  | 'passwordResetSuccess'
  | 'passwordResetConfirmation'
  | 'login'
  | 'authProviderRedirect'
  | 'authGoogleRedirect'
  | 'authGithubRedirect'
  | 'integrationProcess'
  | 'integrationAuthRedirect'
  | 'integrationConfig'
  | 'notFound'
  | 'termsAndConditions';

export const isModalRoute = (params, route): params is ProjectRouteParams =>
  projectModalRouteNames.includes(route) && isEmpty(params);
