import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgChangeInput = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-input-check" }),
  /* @__PURE__ */ jsx("g", { id: "baec4b7d-1713-4c43-998c-421a3056c8ba", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "ed460564-b93b-4193-b724-22aadbd941d7", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("line", { x1: 12.62, y1: 9.21, x2: 25.52, y2: 9.21, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 16.56, y1: 20.8, x2: 12.62, y2: 20.8, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("line", { x1: 25.52, y1: 9.21, x2: 25.52, y2: 11.73, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M7.07,6.32A2.07,2.07,0,0,1,9.14,7.56a2.06,2.06,0,0,1,2.07-1.24", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("path", { d: "M11.21,23.7a2.06,2.06,0,0,1-2.07-1.25A2.08,2.08,0,0,1,7.07,23.7", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("polyline", { points: "22.89 19.43 24.62 21.16 30.38 15.39", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } }),
        /* @__PURE__ */ jsx("path", { d: "M29.81,20v4a1.16,1.16,0,0,1-1.16,1.16H20.58A1.15,1.15,0,0,1,19.43,24V16a1.14,1.14,0,0,1,1.15-1.15h6.34", style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("polyline", { points: "5.66 20.8 1.52 20.8 1.52 9.21 5.66 9.21", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 9.14, y1: 7.56, x2: 9.14, y2: 22.45, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgChangeInput);
export default ForwardRef;
