import { createGlobalStyle } from 'styled-components';

import { COLOR } from './colors';
import { FONT_WEIGHT } from './fonts';

export const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: ${COLOR.DARK_GRAY};
    letter-spacing: -0.01em;

    &, * {
      font-family: 'NeueFrutiger', sans-serif;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-button {
    background-color: #eaeaea;
    height: 0;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
    border-style: solid;
    border-color: transparent;
    border-width: 0px 0 0 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #D7D7D9;
    background-clip: padding-box;
    height: 8px;
    border: 0 solid rgba(0, 0, 0, 0);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #F1F1F3;
  }

  a {
    color: ${COLOR.PRIMARY_LIGHT};
    font-weight: ${FONT_WEIGHT.MEDIUM};

    &:hover {
      color: ${COLOR.PRIMARY_LIGHT};
    }
  }

  #app {
    height: 100vh;
  }
`;
