import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgReleaseMouseButton = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "action-mouseup" }),
  /* @__PURE__ */ jsx("g", { id: "bddc00ec-02f8-4b10-9cb5-13b253292153", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "b8a85420-dfdb-4003-b420-828c93e618c1", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx("path", { d: "M16,7.17A4.88,4.88,0,0,1,21,12v8.4a5,5,0,0,1-9.94,0V12a4.88,4.88,0,0,1,5-4.8m0-2A6.9,6.9,0,0,0,9,12v8.4a7,7,0,0,0,13.94,0V12a6.9,6.9,0,0,0-7-6.8Z" }),
        /* @__PURE__ */ jsx("line", { x1: 16, y1: 7.17, x2: 16, y2: 13.99, style: {
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2
        } })
      ] }),
      /* @__PURE__ */ jsx("line", { x1: 11.2, y1: 14.3, x2: 20.8, y2: 14.3, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] }),
    /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
      fill: "none"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M15.13,6.77v6.67H10.94a1,1,0,0,0-.18,0V12A5.36,5.36,0,0,1,15.13,6.77Z" }),
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("circle", { cx: 7.13, cy: 8.71, r: 5.61, style: {
        fill: "#fff",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("polyline", { points: "9.38 8.71 7.13 6.47 4.89 8.71", style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } }),
      /* @__PURE__ */ jsx("line", { x1: 7.13, y1: 10.96, x2: 7.13, y2: 6.47, style: {
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      } })
    ] })
  ] }) })
] });
const ForwardRef = forwardRef(SvgReleaseMouseButton);
export default ForwardRef;
