import memoize from 'lodash.memoize';
import { pathOr, path, pick, props, propOr } from 'ramda';
import { createSelector } from 'reselect';

const selectSuiteDomain = (state) => state.suite;

export const selectSuites = createSelector(selectSuiteDomain, pathOr({}, ['list', 'suites']));

export const selectSuiteOrder = createSelector(selectSuiteDomain, pathOr([], ['list', 'order']));

export const selectSuiteList = createSelector(selectSuiteOrder, selectSuites, (order, suites) =>
  order.map((suiteId) => suites[suiteId]),
);

export const selectSuitesIdsList = createSelector(selectSuiteDomain, pathOr([], ['list', 'order']));

export const selectSuitesList = createSelector(selectSuiteList, selectSuites, (testsIds, tests) =>
  props(testsIds, tests),
);

export const selectSuiteListPagination = createSelector(selectSuiteDomain, path(['pagination']));

export const selectSuiteTestList = createSelector(selectSuiteDomain, (state) => state.testList);

export const selectSuite = (suiteId) => createSelector(selectSuites, pathOr({}, [suiteId]));

export const selectSingleSuite = createSelector(selectSuiteDomain, (state) =>
  pathOr({}, ['single', 'suites', path(['single', 'id'], state)])(state),
);

export const selectLastRunsBySuitesIds = memoize(
  (ids = []) =>
    createSelector(selectSuites, (suitesMap) =>
      Object.values(pick(ids, suitesMap))
        .map(path(['lastUnfinishedUserRun', 'id']))
        .filter(Boolean),
    ),
  (ids = []) => ids.join('.'),
);

export const selectSuiteProfileId = (id) =>
  createSelector(selectSuite(id), propOr(null, 'runProfileId'));
