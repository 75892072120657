import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgGitlab = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 1017.49, height: 310.97, viewBox: "0 0 1017.49 310.97", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "gitlab-logo-clean" }),
  /* @__PURE__ */ jsx("g", { id: "a38638c5-b6cc-4a88-b71f-36faf4fa8723", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "a2a4557c-119c-438f-b1e4-765dcb787dbb", "data-name": "Layer 1", children: /* @__PURE__ */ jsx("g", { id: "af24572e-28f8-4361-ba29-c30d0143a881", "data-name": "logo art", children: /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("path", { id: "b8ca4bc9-7920-4b66-a007-aa2bc9876cb5", "data-name": "path14", d: "M715.72,74.27h-21.8l.1,162.5h88.3v-20.1h-66.5l-.1-142.4Z", style: {
        fill: "#8c929d"
      } }),
      /* @__PURE__ */ jsx("g", { id: "b9ea80d3-63ff-429f-b61d-47d40774945c", "data-name": "g24", children: /* @__PURE__ */ jsx("path", { id: "bc5667ad-cdf8-4e68-bf05-bb9e0ce64f71", "data-name": "path26", d: "M866.35,208.25a37.2,37.2,0,0,1-27,11.4c-16.6,0-23.3-8.2-23.3-18.9,0-16.1,11.2-23.8,35-23.8a104.65,104.65,0,0,1,15.4,1.2v30.1Zm-22.6-98.5a72.19,72.19,0,0,0-46.4,16.7l7.7,13.4c8.9-5.2,19.8-10.4,35.5-10.4,17.9,0,25.8,9.2,25.8,24.6V162a99.42,99.42,0,0,0-15.1-1.2c-38.2,0-57.6,13.4-57.6,41.4,0,25.1,15.4,37.7,38.7,37.7,15.7,0,30.8-7.2,36-18.9l4,15.9h15.4v-83.2c-.1-26.3-11.5-43.9-44-43.9Z", style: {
        fill: "#8c929d"
      } }) }),
      /* @__PURE__ */ jsx("g", { id: "a304c3aa-0080-4fd0-8d52-3a931a5363b9", "data-name": "g28", children: /* @__PURE__ */ jsx("path", { id: "f2ec63aa-b7f4-484b-9c75-f59d580f326a", "data-name": "path30", d: "M958.09,220.4c-8.2,0-15.4-1-20.8-3.5V141.8c7.4-6.2,16.6-10.7,28.3-10.7,21.1,0,29.2,14.9,29.2,39,0,34.2-13.1,50.3-36.7,50.3m9.2-110.6a39.93,39.93,0,0,0-30,13.3v-21l-.1-27.8h-21.3l.1,158.5c10.7,4.5,25.3,6.9,41.2,6.9,40.7,0,60.3-26,60.3-70.9-.1-35.5-18.2-59-50.2-59", style: {
        fill: "#8c929d"
      } }) }),
      /* @__PURE__ */ jsx("g", { id: "e5f6a730-52c2-4f73-9e41-9460ba7bb2ea", "data-name": "g32", children: /* @__PURE__ */ jsx("path", { id: "b0bf934e-46f2-4fab-8f5a-fccd6a5af4da", "data-name": "path34", d: "M478.37,91.9c19.3,0,31.8,6.4,39.9,12.9l9.4-16.3c-12.7-11.2-29.9-17.2-48.3-17.2-46.4,0-78.9,28.3-78.9,85.4,0,59.8,35.1,83.1,75.2,83.1a127.79,127.79,0,0,0,48.4-9.4l-.5-63.9V146.4h-59.5v20.1h38l.5,48.5c-5,2.5-13.6,4.5-25.3,4.5-32.2,0-53.8-20.3-53.8-63-.1-43.5,22.2-64.6,54.9-64.6", style: {
        fill: "#8c929d"
      } }) }),
      /* @__PURE__ */ jsx("g", { id: "b18028f0-a909-4d18-8635-ebc0fc9888cb", "data-name": "g36", children: /* @__PURE__ */ jsx("path", { id: "f65a559f-7509-4c8a-ab7b-86b417d1bcbc", "data-name": "path38", d: "M631.89,74.24h-21.3l.1,27.3v94.3c0,26.3,11.4,43.9,43.9,43.9a70.14,70.14,0,0,0,13.1-1.2v-19.1a62.36,62.36,0,0,1-9.9.7c-17.9,0-25.8-9.2-25.8-24.6v-65h35.7v-17.8H632l-.1-38.5Z", style: {
        fill: "#8c929d"
      } }) }),
      /* @__PURE__ */ jsx("path", { id: "fe4dc65a-6301-44b7-b26c-62859af18c79", "data-name": "path40", d: "M556.42,236.77h21.3v-124h-21.3v124Z", style: {
        fill: "#8c929d"
      } }),
      /* @__PURE__ */ jsx("path", { id: "a48992d4-d0c6-400e-b92f-3815dfebd174", "data-name": "path42", d: "M556.42,95.67h21.3V74.37h-21.3v21.3Z", style: {
        fill: "#8c929d"
      } })
    ] }),
    /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("path", { id: "b3ecd6e2-dd44-4c50-af32-d0c00f0475cd", "data-name": "path50", d: "M168.8,311h0L231,119.65H106.69L168.8,311Z", style: {
        fill: "#e24329"
      } }),
      /* @__PURE__ */ jsx("path", { id: "baf97e41-57e5-4487-a187-b7e5317b5a09", "data-name": "path66", d: "M19.57,119.65h0L.63,177.77a12.9,12.9,0,0,0,4.66,14.4L168.8,311,19.57,119.65Z", style: {
        fill: "#fca326"
      } }),
      /* @__PURE__ */ jsx("path", { id: "a885b607-31d4-4c59-9301-02a320c5cc0d", "data-name": "path74", d: "M19.57,119.65h87.12L69.2,4.42a6.47,6.47,0,0,0-12.27,0L19.57,119.65Z", style: {
        fill: "#e24329"
      } }),
      /* @__PURE__ */ jsx("path", { id: "af4b5d60-b060-42cc-8e6a-a8f33b460215", "data-name": "path82", d: "M318.12,119.65h0L337,177.77a12.87,12.87,0,0,1-4.66,14.4L168.8,311,318.12,119.65Z", style: {
        fill: "#fca326"
      } }),
      /* @__PURE__ */ jsx("path", { id: "b4a960b1-13d1-4050-9c8a-107d0c53d621", "data-name": "path86", d: "M318.12,119.65H231L268.43,4.42a6.47,6.47,0,0,1,12.27,0l37.42,115.23Z", style: {
        fill: "#e24329"
      } }),
      /* @__PURE__ */ jsx("polygon", { points: "168.8 310.97 231 119.64 318.12 119.64 168.8 310.97", style: {
        fill: "#fc6d26"
      } }),
      /* @__PURE__ */ jsx("polygon", { points: "168.8 310.97 19.57 119.64 106.69 119.64 168.8 310.97", style: {
        fill: "#fc6d26"
      } })
    ] })
  ] }) }) }) })
] });
const ForwardRef = forwardRef(SvgGitlab);
export default ForwardRef;
