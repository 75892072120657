import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMonitorParagraph = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: /* @__PURE__ */ jsx("g", { id: "e1de5519-aeca-40d8-b163-97bbad747fec", children: /* @__PURE__ */ jsxs("g", { id: "eb3d6348-bed4-4cd6-977c-b618ebae0f3e", children: [
  /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
    fill: "none"
  } }),
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("rect", { x: 6, y: 7, width: 20, height: 14, rx: 2, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 12, y1: 25, x2: 20, y2: 25, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 16, y1: 21, x2: 16, y2: 25, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }),
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("line", { x1: 23, y1: 14, x2: 9, y2: 14, style: {
      fill: "none",
      stroke: "#000",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 23, y1: 17, x2: 9, y2: 17, style: {
      fill: "none",
      stroke: "#000",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("polyline", { points: "12.23 11 9.97 11 9 11", style: {
      fill: "none",
      stroke: "#000",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }),
  /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
    fill: "none"
  } })
] }) }) });
const ForwardRef = forwardRef(SvgMonitorParagraph);
export default ForwardRef;
