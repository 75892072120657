import api from './common';

export const getList = (params) => api.get(`/testruns/`, { params });

export const get = (id) => api.get(`/testruns/${id}/`);

export const remove = (id) => api.delete(`/testruns/${id}/`);

export const removeSelected = (data) => api.delete(`/testruns/delete/`, { data });

export const removeAll = (data) => api.delete(`/testruns/delete/`, { data });

export const stop = (id, data) => api.post(`/testruns/${id}/stop-running/`, data);

export const stopSelected = (data) => api.post('/testruns/stop-running/', data);

export const stopAll = (data) => api.post('/testruns/stop-running/', data);
