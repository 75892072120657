import i18n from '~/translations';

export const UPDATE_ERRORS = [
  {
    code: 'atLeastOneOwner',
    content: i18n.t(
      'organizationUsers.update.failure.alreadyHasOrganization',
      'Project should have at least one owner',
    ),
  },
];
