import { SELECT_ALL_OPTION_VALUE } from '@bugbug/core/components/Select';
import * as T from '@bugbug/core/utils/toolbox';

import type { AlertActionFormValues, EditAlertFormValues } from './EditAlertFormValues.types';

import type { AlertAction, Alert, AlertTrigger, AlertActionType } from '@bugbug/core/types/alerts';
import type { Project } from '@bugbug/core/types/projects';

import { FORM_INITIAL_VALUE } from './EditAlertModal.constants';
import alertFormSchemas from './EditAlertModal.schema';

const hasAllOptionSelected = (options: string[]) =>
  options.some((row) => row === SELECT_ALL_OPTION_VALUE);

const buildAlertNotificationAction = (actionConfig: AlertActionFormValues): AlertAction => {
  switch (actionConfig.action) {
    case 'sendEmail': {
      const recipients = actionConfig.users.filter((row) => row !== SELECT_ALL_OPTION_VALUE);
      const recipientType = hasAllOptionSelected(actionConfig.users) ? 'all' : 'selected';

      return {
        type: actionConfig.action,
        config: {
          recipientType,
          extraEmails: actionConfig.extraEmails
            ?.split(/[\s,]+/)
            .map((row: string) => row.replace(/\s/g, '').toLowerCase())
            .filter((row) => row),
          users: recipientType === 'all' ? [] : recipients,
        },
      };
    }

    case 'sendSlackMessage':
      return { config: T.omit(['action'], actionConfig), type: actionConfig.action };

    case 'sendTeamsMessage':
      return { config: T.omit(['action'], actionConfig), type: actionConfig.action };

    default:
      return { config: T.omit(['action'], actionConfig), type: actionConfig.action };
  }
};

export const buildAlertRequestData = (formValues: EditAlertFormValues): Omit<Alert, 'id'> => {
  const formSchema = alertFormSchemas[formValues.action];
  const { base, trigger, action } = getAlertFormValuesByDomain(
    formSchema.cast(formValues, { stripUnknown: true }),
  );

  return {
    ...base,
    ...trigger,
    objects: hasAllOptionSelected(trigger.objects) ? [] : trigger.objects,
    runProfiles: hasAllOptionSelected(trigger.runProfiles) ? [] : trigger.runProfiles,
    notificationAction: buildAlertNotificationAction(action),
  };
};

const getAlertTrigger = (trigger: AlertTrigger): AlertTrigger => ({
  ...trigger,
  objects: trigger.objects.length ? trigger.objects : FORM_INITIAL_VALUE.objects,
  runProfiles: trigger.runProfiles.length ? trigger.runProfiles : FORM_INITIAL_VALUE.runProfiles,
});

const getAlertAction = (alertAction: AlertAction): AlertActionFormValues => {
  switch (alertAction.type) {
    case 'sendEmail':
      return {
        action: alertAction.type,
        extraEmails: alertAction.config.extraEmails.join(', '),
        recipientType: alertAction.config.recipientType,
        users:
          alertAction.config.recipientType === 'all'
            ? [SELECT_ALL_OPTION_VALUE]
            : alertAction.config.users,
      };

    case 'sendSlackMessage':
      return {
        action: alertAction.type,
        ...alertAction.config,
      };

    case 'sendTeamsMessage':
      return {
        action: alertAction.type,
        ...alertAction.config,
      };

    default:
      return {
        action: alertAction.type,
        ...alertAction.config,
      };
  }
};

export const getAlertFormValues = (
  initialProjectId: Project['id'],
  alert?: Alert,
  initialAlertAction?: AlertActionType,
): EditAlertFormValues => {
  let formValues = {
    ...FORM_INITIAL_VALUE,
    projectId: initialProjectId,
    action: initialAlertAction ?? FORM_INITIAL_VALUE.action,
  };

  if (alert) {
    const { id, projectId, isActive, notificationAction, ...trigger } = alert;
    formValues = {
      ...formValues,
      id,
      isActive,
      projectId,
      ...getAlertTrigger(trigger),
      ...getAlertAction(notificationAction),
    };
  }

  return alertFormSchemas[formValues.action].cast(formValues, { stripUnknown: true });
};

export const getAlertFormValuesByDomain = (formValues: EditAlertFormValues) => {
  const {
    isActive,
    projectId,
    event,
    status,
    runMode,
    triggeredBy,
    runProfiles,
    objects,
    ...notificationAction
  } = formValues;

  return {
    base: {
      isActive,
      projectId,
    },
    trigger: {
      event,
      status,
      runMode,
      triggeredBy,
      objects,
      runProfiles,
    },
    action: notificationAction,
  };
};
