import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isFinishedStatus } from '@bugbug/core/types/base';
import produce from 'immer';
import { normalize } from 'normalizr';
import {
  path,
  without,
  insert,
  defaultTo,
  isNil,
  prop,
  is,
  omit,
  mergeDeepRight,
  insertAll,
  mergeRight,
  pathOr,
  equals,
  propEq,
  pick,
} from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import testSchema, { testsListSchema, groupSchema } from '~/modules/test/test.schema';
import {
  groupDefaultStepsParams,
  getElementsScreenshotsFromSteps,
} from '~/modules/test/test.utils';
import { TestRunTypes } from '~/modules/testRun/testRun.redux';
import { convertListToObject } from '~/utils/misc';
import { getRelatedTest } from '~/utils/runs';

export const INSERT_BEFORE = 'insert_before';
export const INSERT_AFTER = 'insert_after';

export const { Types: TestTypes, Creators: TestActions } = createActions(
  {
    getSingleRequest: ['id', 'testRunId'],
    getSingleSuccess: ['test'],
    getSingleFailure: ['error'],
    createRequest: ['payload'],
    createSuccess: [],
    createFailure: ['error'],
    getListRequest: ['query', 'sortBy', 'descOrder'],
    getListSuccess: ['tests', 'query', 'sortBy', 'descOrder'],
    getListFailure: ['error'],
    cloneRequest: ['id', 'shouldNavigate'],
    cloneSuccess: ['data'],
    cloneFailure: ['error'],
    removeRequest: ['ids'],
    removeSuccess: ['ids'],
    removeFailure: ['error'],
    removePartialsRequest: ['id', 'steps', 'groups'],
    removePartialsSuccess: ['id', 'steps', 'groups'],
    removePartialsFailure: ['error'],
    stopRequest: ['ids'],
    stopSuccess: ['ids'],
    stopFailure: ['error'],
    updateRequest: ['id', 'test', 'meta'],
    updateSuccess: ['id', 'test', 'meta'],
    updateFailure: ['error', 'meta'],
    setRecordingState: ['data'],
    setRecordingStateSuccess: ['data'],
    startRecordingRequest: ['testId', 'initialUrl'],
    startRecordingSuccess: [],
    startRecordingFailure: ['error'],
    stopRecordingRequest: ['testId'],
    stopRecordingSuccess: ['test'],
    stopRecordingFailure: ['error'],
    startRunningRequest: ['testId', 'params'],
    startRunningSuccess: ['testId', 'testRun'],
    startRunningFailure: ['error'],
    startRunningSelectedTestsRequest: ['testsIds', 'params'],
    startRunningSelectedTestsSuccess: [],
    startRunningSelectedTestsFailure: ['error'],
    stopRunningRequest: ['testId', 'params'],
    stopRunningSuccess: ['testId', 'testRun'],
    stopRunningFailure: ['error'],
    updateStatus: ['data'],
    insertSteps: ['data'],
    newStepsSet: ['data'],
    cloneGroupRequest: ['id', 'testId'],
    cloneGroupSuccess: ['sourceGroupId', 'group'],
    cloneGroupFailure: ['error'],
    removeGroupRequest: ['id', 'meta'],
    removeGroupSuccess: ['id', 'meta'],
    removeGroupFailure: ['error', 'meta'],
    removeGroupRelationRequest: ['testId', 'groupId', 'meta'],
    removeGroupRelationSuccess: ['testId', 'groupId', 'meta'],
    removeGroupRelationFailure: ['error', 'meta'],
    insertGroupRequest: ['testId', 'groupId', 'atIndex'],
    insertGroupSuccess: ['testId', 'group', 'atIndex'],
    insertGroupFailure: ['error'],
    renameGroupRequest: ['id', 'name', 'meta'],
    renameGroupSuccess: ['id', 'name', 'meta'],
    renameGroupFailure: ['error', 'meta'],
    searchComponentsRequest: ['query'],
    searchComponentsSuccess: ['results'],
    searchComponentsFailure: ['error'],
    splitGroupRequest: ['testId', 'groupId', 'atIndex'],
    splitGroupSuccess: ['testId', 'group', 'stepsIds', 'atIndex'],
    splitGroupFailure: ['error'],
    createDefaultStepsGroupWithStepRequest: ['testId'],
    createDefaultStepsGroupWithStepSuccess: [''],
    createDefaultStepsGroupWithStepFailure: ['error'],
    createStepsGroupRequest: ['testId', 'name', 'stepsIds', 'atIndex'],
    createStepsGroupSuccess: ['testId', 'group', 'stepsIds', 'atIndex'],
    createStepsGroupFailure: ['error'],
    updateGroupPosition: ['testId', 'groupId', 'index'],
    updateGroupPositionSuccess: ['testId', 'groupId', 'index'],
    updateGroupPositionFailure: ['error'],
    updateStepsPosition: [
      'testId',
      'stepId',
      'sourceGroupId',
      'sourceIndex',
      'destGroupId',
      'destIndex',
    ],
    updateStepsPositionSuccess: [
      'stepId',
      'destGroupId',
      'sourcePartials',
      'destPartials',
      'sourcePartialsIndieces',
      'destPartialsIndieces',
    ],
    updateStepsPositionFailure: ['error'],
    saveStepSettingsRequest: ['id', 'groupId', 'settings', 'meta'],
    saveStepSettingsSuccess: ['id', 'data', 'meta'],
    saveStepSettingsFailure: ['error', 'meta'],
    createStepRequest: ['testId', 'groupId', 'step', 'atIndex'],
    createStepSuccess: ['groupId', 'step', 'atIndex'],
    createStepFailure: ['error'],
    updateStep: ['data'],
    updateSteps: ['data'],
    updateStepsSuccess: ['data'],
    cloneStepRequest: ['id'],
    cloneStepSuccess: ['step', 'sourceStepId'],
    cloneStepFailure: ['error'],
    copyStepsRequest: ['stepsIds'],
    copyStepsSuccess: [],
    copyStepsFailure: ['error'],
    pasteStepsRequest: ['testId', 'groupId', 'atIndex', 'stepsIds'],
    pasteStepsSuccess: ['testId', 'group', 'atIndex'],
    pasteStepsFailure: ['error'],
    stepElementScreenshotRecorded: ['data'],
    stepElementScreenshotUpdated: ['data'],
    toggleStepActiveRequest: ['id'],
    toggleStepActiveSuccess: ['id', 'params'],
    toggleStepActiveFailure: ['error'],
    toggleStepBreakpointRequest: ['id'],
    toggleStepBreakpointSuccess: ['id', 'params'],
    toggleStepBreakpointFailure: ['error'],
    updateStepPageScreenshot: ['data'],
    stepsIsHiddenModified: ['data'],
    removeStepsRequest: ['testId', 'stepsIds'],
    removeStepsSuccess: ['testId', 'stepsIds'],
    removeStepsFailure: ['error'],
    cleanStepsRequest: ['payload'],
    cleanStepsSuccess: ['payload'],
    cleanStepsFailure: ['error'],
    debugRunNextStepRequest: ['id'],
    debugRunNextStepSuccess: [],
    debugRunNextStepFailure: ['error'],
    debugPauseTestRequest: ['id', 'testRunId', 'runMode'],
    debugPauseTestSuccess: [],
    debugPauseTestFailure: ['error'],
    debugResumeTestRequest: ['id', 'testRunId', 'runMode'],
    debugResumeTestSuccess: [],
    debugResumeTestFailure: ['error'],
    setIsSingleLoading: ['data'],
    setGroupAsComponentRequest: ['id', 'meta'],
    setGroupAsComponentSuccess: ['id', 'meta'],
    setGroupAsComponentFailure: ['error', 'meta'],
    unlinkComponentRequest: ['testId', 'groupId', 'meta'],
    unlinkComponentSuccess: ['id', 'newGroup', 'meta'],
    unlinkComponentFailure: ['error', 'meta'],
    removeUnconfirmedGroupRequest: ['id', 'meta'],
    removeUnconfirmedGroupSuccess: ['id', 'testId', 'meta'],
    removeUnconfirmedGroupFailure: ['error', 'meta'],
    confirmUnconfirmedGroupRequest: ['id', 'groupId', 'index', 'meta'],
    confirmUnconfirmedGroupSuccess: ['id', 'groupId', 'index', 'steps', 'testId', 'meta'],
    confirmUnconfirmedGroupFailure: ['error', 'meta'],
    clearSingleRequest: [],
    clearChangedNotification: ['testId'],
    changed: ['data'],
    reset: [],
    getDefaultStepsParamsRequest: [],
    getDefaultStepsParamsSuccess: ['data'],
    getDefaultStepsParamsFailure: ['error'],
    createTemporaryStepRequest: ['testId', 'groupId', 'stepType', 'atIndex', 'params'],
    createTemporaryStepSuccess: ['testId', 'groupId', 'step', 'atIndex'],
    createTemporaryStepFailure: ['error'],
    cancelTemporaryStep: ['temporaryStepId', 'groupId'],
    saveTemporaryStepRequest: ['temporaryStepId', 'groupId', 'step', 'atIndex', 'meta'],
    saveTemporaryStepSuccess: ['temporaryStepId', 'groupId', 'newStepId', 'meta'],
    saveTemporaryStepFailure: ['error', 'meta'],
    setCurrentStepId: ['id'],
    toggleCurrentStepId: ['id'],
    updateStepsActivationRequest: ['testId', 'steps', 'value'],
    updateStepsActivationSuccess: ['steps', 'value'],
    updateStepsActivationFailure: ['error'],
    updatedSuccess: ['results'],
  },
  { prefix: 'TEST/' },
);

const INITIAL_STATE = {
  changeNotificationByTestId: {},
  single: {},
  elementsScreenshots: {},
  isSingleLoading: false,
  defaultStepsParams: {},
  currentStepId: null,
  list: {
    tests: {},
    order: [],
  },
  search: {
    components: [],
  },
};

const getSingleSuccess = (state, { test }) =>
  produce(state, (draftState) => {
    const data = normalize(test, testSchema);
    draftState.single = data.entities;
    draftState.single.id = data.result;
    draftState.elementsScreenshots = getElementsScreenshotsFromSteps(data.entities.steps);
    draftState.currentStepId = null;
  });

const getListSuccess = (state, { tests }) =>
  produce(state, (draftState) => {
    const data = normalize(tests, testsListSchema);
    draftState.list = {
      tests: data.entities.tests ?? {},
      order: data.result ?? [],
    };
  });

const removeSuccess = (state, { ids }) =>
  produce(state, (draftState) => {
    const removedTestsIds = ids || draftState.list.order;
    draftState.list.tests = omit(removedTestsIds, draftState.list.tests);
    draftState.list.order = without(removedTestsIds, draftState.list.order);
  });

const startRecordingSuccess = (state) =>
  produce(state, (draftState) => {
    draftState.single.tests[state.single.id].isRecording = true;
  });

const updateSingleTest = (draftState, test) => {
  if (draftState.single.id === test.id) {
    const data = normalize(test, testSchema);
    draftState.elementsScreenshots = getElementsScreenshotsFromSteps(data.entities.steps);
    draftState.single = data.entities;
    draftState.single.id = data.result;
  }
};

const stopRecordingSuccess = (state, action) =>
  produce(state, (draftState) => {
    updateSingleTest(draftState, action.test);
  });

export const updateUnconfirmedGroupsIndiecesFromIndex = (...options) => {
  const [state, relatedGroupId, value, startIndex = 0, endIndex, startUnconfirmedGroupIndex] =
    options;

  const { unconfirmedGroups } = state.single.tests[state.single.id];
  if (!unconfirmedGroups.length) {
    return;
  }

  const groupRelatedUnconfirmedGroups = unconfirmedGroups.filter(
    propEq('unconfirmedRelatedGroup', state.single.unconfirmedGroups[relatedGroupId]),
  );

  groupRelatedUnconfirmedGroups.forEach((unconfirmedGroupId, index) => {
    const { unconfirmedIndex } = state.single.unconfirmedGroups[unconfirmedGroupId];

    const isOnProperPositionOnTheList =
      !startUnconfirmedGroupIndex || index >= startUnconfirmedGroupIndex;
    const willBeAddedAtProperIndex =
      unconfirmedIndex >= startIndex && (!endIndex || unconfirmedIndex < endIndex);

    if (isOnProperPositionOnTheList && willBeAddedAtProperIndex) {
      // eslint-disable-next-line no-param-reassign
      state.single.unconfirmedGroups[unconfirmedGroupId].unconfirmedIndex += value;
    }
  });
};

const newStepsSet = (state, action) =>
  produce(state, (draftState) => {
    updateSingleTest(draftState, action.data);
  });

const insertSteps = (state, action) =>
  produce(state, (draftState) => {
    const { steps, destinationGroupId } = action.data;
    steps.forEach((step) => {
      draftState.single.steps[step.id] = {
        ...step,
        groupId: destinationGroupId,
      };
    });
  });

const updateStepsPositionSuccess = (state, action) =>
  produce(state, (draft) => {
    const {
      stepId,
      destGroupId,
      sourcePartials,
      destPartials,
      destPartialsIndieces,
      sourcePartialsIndieces,
    } = action;
    const step = draft.single.steps[stepId];
    const hasGroupChanged = step.groupId !== destGroupId;

    if (hasGroupChanged) {
      draft.single.groups[step.groupId].steps = [];
      sourcePartials.forEach((partial, index) => {
        if (partial.steps) {
          const diff = index - sourcePartialsIndieces[`group.${partial.id}`];
          // Unconfirmed group
          draft.single.unconfirmedGroups[partial.id].unconfirmedIndex += diff;
        } else {
          // Regular step
          draft.single.groups[step.groupId].steps.push(partial.id);
        }
      });
    }

    draft.single.groups[destGroupId].steps = [];
    destPartials.forEach((partial, index) => {
      if (partial.steps) {
        const diff = index - destPartialsIndieces[`group.${partial.id}`];
        draft.single.unconfirmedGroups[partial.id].unconfirmedIndex += diff;
      } else {
        draft.single.groups[destGroupId].steps.push(partial.id);
      }
    });

    draft.single.steps[stepId].groupId = destGroupId;
  });

const updateGroupPositionSuccess = (state, { testId, groupId, index }) =>
  produce(state, (draftState) => {
    const newGroupsIds = without([groupId], [...state.single.tests[testId].groups]);
    newGroupsIds.splice(index, 0, groupId);
    draftState.single.tests[testId].groups = newGroupsIds;
  });

const updateStepElementScreenshot = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    draftState.elementsScreenshots[data.stepId] = data.screenshot;
  });

const updateStep = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    if (draftState.single.steps && draftState.single.steps[data.id]) {
      draftState.single.steps[data.id] = data;
    }
  });

const updateStepsSuccess = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    const { removed = [], added = [], modified = [] } = data.delta;
    const test = draftState.single.tests[data.testId];
    const isRegularGroup = (groupId) => !test.hasSteps || test.groups.includes(groupId);
    const getGroupContainer = (step) =>
      isRegularGroup(step.groupId) ? 'groups' : 'unconfirmedGroups';

    for (let index = 0; index < removed.length; index += 1) {
      const stepId = removed[index];
      const step = draftState.single.steps[stepId];

      if (step) {
        const groupsContainer = getGroupContainer(step);

        draftState.single[groupsContainer][step.groupId].steps = without(
          [stepId],
          draftState.single[groupsContainer][step.groupId].steps,
        );
      }
    }

    draftState.single.steps = omit(removed, draftState.single.steps);

    for (let index = 0; index < added.length; index += 1) {
      const step = added[index];
      const stepId = step.id || step.frontId;
      const groupsContainer = getGroupContainer(step);

      if (!draftState.single.tests[data.testId][groupsContainer].includes(step.groupId)) {
        draftState.single.tests[data.testId][groupsContainer].push(step.groupId);
        draftState.single[groupsContainer] = {
          ...(draftState.single[groupsContainer] || {}),
          [step.groupId]: {
            id: step.groupId,
            name: 'Unnamed group',
            steps: [],
          },
        };
      }
      draftState.single[groupsContainer][step.groupId].steps.push(stepId);
      draftState.single.steps[stepId] = step;
      draftState.single.tests[data.testId].hasSteps = true;
    }

    for (let index = 0; index < modified.length; index += 1) {
      const step = modified[index];
      const stepId = step.id || step.frontId;
      draftState.single.steps[stepId] = mergeRight(draftState.single.steps[stepId], step);
    }
  });

const stepsIsHiddenModified = (state, action) =>
  produce(state, (draftState) => {
    const { steps } = action.data;
    steps.forEach((step) => {
      draftState.single.steps[step.id] = step;
      draftState.single.groups[step.groupId].steps.splice(step.order, 0, step.id);
    });
  });

const cleanStepsSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.payload, testSchema);
    draftState.single = data.entities;
    draftState.single.id = data.result;
  });

const updateSuccess = (state, { id, test }) =>
  produce(state, (draftState) => {
    if (path(['tests', id], draftState.single)) {
      draftState.single.tests[id] = {
        ...draftState.single.tests[id],
        ...test,
      };
    }
    if (path(['tests', id], draftState.list)) {
      draftState.list.tests[id] = {
        ...draftState.list.tests[id],
        ...test,
      };
    }
  });

const setIsSingleLoading = (state, { data: { isLoading, testId } }) =>
  produce(state, (draftState) => {
    if (draftState.single.id === testId) {
      draftState.isSingleLoading = isLoading;
    }
  });

const setRecordingStateSuccess = (state, action) =>
  produce(state, (draftState) => {
    const test = action.data;
    updateSingleTest(draftState, test);
    if (state.list.order.includes(test.id)) {
      draftState.list.tests[test.id].isRecording = !!test.isRecording;
    }
  });

const cloneGroupSuccess = (state, { sourceGroupId, group }) =>
  produce(state, (draftState) => {
    const testId = draftState.single.id;
    const data = normalize(group, groupSchema);
    draftState.single = mergeDeepRight(draftState.single, data.entities);
    draftState.elementsScreenshots = mergeDeepRight(
      draftState.elementsScreenshots,
      getElementsScreenshotsFromSteps(data.entities.steps),
    );
    const sourcePosition = draftState.single.tests[testId].groups.indexOf(sourceGroupId);
    if (sourcePosition > -1) {
      draftState.single.tests[testId].groups.splice(sourcePosition + 1, 0, group.id);
    }
  });

const cloneStepSuccess = (state, { step, sourceStepId }) =>
  produce(state, (draft) => {
    draft.single.steps[step.id] = step;
    draft.single.steps[step.id].isExpanded = true;
    draft.elementsScreenshots[step.id] = step.screenshot;
    const sourcePosition = draft.single.groups[step.groupId].steps.indexOf(sourceStepId);
    if (sourcePosition > -1) {
      draft.single.groups[step.groupId].steps.splice(sourcePosition + 1, 0, step.id);
      updateUnconfirmedGroupsIndiecesFromIndex(draft, step.groupId, sourcePosition + 1);
    }
  });

const removeStepsSuccess = (state, { stepsIds }) =>
  produce(state, (draft) => {
    if (stepsIds) {
      for (let index = 0; index < stepsIds.length; index += 1) {
        const stepId = stepsIds[index];
        const { groupId } = draft.single.steps[stepId];
        draft.single.steps = omit([stepId], draft.single.steps);
        draft.single.groups[groupId].steps = without([stepId], draft.single.groups[groupId].steps);

        if (draft.currentStepId === stepId) {
          draft.currentStepId = null;
        }

        updateUnconfirmedGroupsIndiecesFromIndex(draft, groupId, -1, index + 1);
      }

      draft.elementsScreenshots = without(stepsIds, draft.elementsScreenshots);
    }
  });

const removeRelatedUnconfirmedGroups = (draftState, id, testId) => {
  if (draftState.single.tests[testId]?.unconfirmedGroups?.length) {
    const deletedUnconfirmedGroups = [];
    draftState.single.tests[testId]?.unconfirmedGroups.forEach((groupId) => {
      if (draftState.single.unconfirmedGroups[groupId].unconfirmedRelatedGroup === id) {
        deletedUnconfirmedGroups.push(groupId);
        delete draftState.single.unconfirmedGroups[groupId];
      }
    });
    draftState.single.tests[testId].unconfirmedGroups = without(
      deletedUnconfirmedGroups,
      draftState.single.tests[testId].unconfirmedGroups,
    );
  }
};

const removeGroupSuccess = (state, { id }) =>
  produce(state, (draftState) => {
    const { groups, id: testId } = draftState.single;
    if (testId && groups) {
      if (groups[id] && groups[id].steps.includes(draftState.currentStepId)) {
        draftState.currentStepId = null;
      }
      draftState.single.groups = omit([id], groups);
      draftState.single.tests[testId].groups = without(
        [id],
        draftState.single.tests[testId].groups,
      );

      removeRelatedUnconfirmedGroups(draftState, id, testId);
    }
  });

const removeGroupRelationSuccess = (state, { testId, groupId }) =>
  produce(state, (draftState) => {
    draftState.single.tests[testId].groups = without(
      [groupId],
      draftState.single.tests[testId].groups,
    );

    removeRelatedUnconfirmedGroups(draftState, groupId, testId);
  });

const renameGroupSuccess = (state, { id, name }) =>
  produce(state, (draftState) => {
    if (draftState.single.groups && draftState.single.groups[id]) {
      draftState.single.groups[id].name = name;
    }
  });

const searchComponentsSuccess = (state, { results }) =>
  produce(state, (draftState) => {
    draftState.search.components = results;
  });

const saveStepSettingsSuccess = (state, { id, data }) =>
  produce(state, (draftState) => {
    Object.assign(draftState.single.steps[id], data);
  });

const toggleStepActiveSuccess = (state, { id, params }) =>
  produce(state, (draftState) => {
    draftState.single.steps[id].isActive = params.isActive;
    if (!params.isActive) {
      draftState.single.steps[id].isBreakpoint = false;
    }
  });

const toggleStepBreakpointSuccess = (state, { id, params }) =>
  produce(state, (draftState) => {
    draftState.single.steps[id].isBreakpoint = params.isBreakpoint;
    if (params.isBreakpoint) {
      draftState.single.steps[id].isActive = true;
    }
  });

const setGroupAsComponent =
  (isComponent) =>
  (state, { id }) =>
    produce(state, (draftState) => {
      if (draftState.single?.groups?.[id]) {
        draftState.single.groups[id].isComponent = isComponent;
      }
    });

const setGroupAsComponentSuccess = setGroupAsComponent(true);

const setGroupAsComponentFailure = setGroupAsComponent(false);

const unlinkComponentSuccess = (state, { id, newGroup }) =>
  produce(state, (draft) => {
    const testId = draft.single.id;
    const data = normalize(newGroup, groupSchema);
    draft.single = mergeDeepRight(draft.single, data.entities);
    draft.elementsScreenshots = mergeDeepRight(
      draft.elementsScreenshots,
      getElementsScreenshotsFromSteps(data.entities.steps),
    );
    draft.single.groups = omit([id], draft.single.groups);
    const sourcePosition = draft.single.tests[testId].groups.indexOf(id);

    if (sourcePosition > -1) {
      draft.single.tests[testId].groups.splice(sourcePosition, 1, newGroup.id);
    }
  });

const unlinkComponentFailure = setGroupAsComponent(true);

const updatedSuccess = (state, { results = [] }) =>
  produce(state, (draftState) => {
    for (let index = 0; index < results.length; index += 1) {
      const testRun = results[index];
      const testId = getRelatedTest(testRun)?.id;
      if (draftState.list.order.includes(testId)) {
        const newResult = pick(
          ['id', 'sequence', 'status', 'started', 'triggeredBy', 'user'],
          testRun,
        );
        if (testRun.isTriggeredByCurrentUser) {
          const lastUnfinishedUserRunSequence = pathOr(
            0,
            [testId, 'lastUnfinishedUserRun', 'sequence'],
            state.list.tests,
          );
          if (testRun.sequence >= lastUnfinishedUserRunSequence) {
            if (isFinishedStatus(testRun.status)) {
              draftState.list.tests[testId].lastResult = newResult;
              draftState.list.tests[testId].lastUnfinishedUserRun = null;
            } else {
              draftState.list.tests[testId].lastUnfinishedUserRun = newResult;
            }
          }
        }

        const lastResultSequence = pathOr(0, [testId, 'lastResult', 'sequence'], state.list.tests);
        if (testRun.sequence >= lastResultSequence && isFinishedStatus(testRun.status)) {
          draftState.list.tests[testId].lastResult = newResult;
        }
      }

      if (testId && draftState.single.id === testId && draftState.single.tests[testId].testRun) {
        draftState.single.tests[testId].testRun.status = testRun.status;
      }
    }
  });

const testRunStopped = (state, { data }) =>
  produce(state, (draftState) => {
    const getId = prop('testId');

    const testsIds = (is(String, getId(data)) ? [data] : data)?.map(getId) || draftState.list.order;

    for (let index = 0; index < testsIds.length; index += 1) {
      const testId = testsIds[index];
      if (draftState.list.tests && draftState.list.tests[testId]) {
        draftState.list.tests[testId].lastResult = {
          ...(draftState.list.tests[testId].lastResult || {}),
          status: RUN_STATUS.STOPPED,
        };
      }
    }
  });

const createStepsGroupSuccess = (state, { testId, group, stepsIds, atIndex }) =>
  produce(state, (draftState) => {
    if (!draftState.single.groups) {
      draftState.single.groups = {};
    }

    draftState.single.groups[group.id] = { ...group, steps: stepsIds, isNameFocused: true };
    const currentTestGroups = state.single.tests[testId].groups;
    const position = defaultTo(currentTestGroups.length, atIndex);

    draftState.single.tests[testId].groups = insert(
      position,
      group.id,
      state.single.tests[testId].groups,
    );

    if (group.steps.length) {
      const oldGroupId = state.single.steps[group.steps[0].id].groupId;
      let newStepIds = state.single.groups[oldGroupId].steps;
      group.steps.forEach((step) => {
        newStepIds = without([step.id], newStepIds);
        draftState.single.steps[step.id] = step;
      });
      draftState.single.groups[oldGroupId].steps = newStepIds;
    }
  });

export const createStepSuccess = (state, { groupId, step, atIndex }) =>
  produce(state, (draft) => {
    const position = isNil(atIndex) ? state.single.groups[groupId].steps.length : atIndex;
    draft.single.groups[groupId].steps = insert(
      position,
      step.id,
      state.single.groups[groupId].steps,
    );

    if (!draft.single.steps) {
      draft.single.steps = {};
    }

    draft.single.steps[step.id] = { ...step, isExpanded: true };

    updateUnconfirmedGroupsIndiecesFromIndex(draft, groupId, isNil(atIndex) ? 0 : 1, position);
  });

export const updateStepsActivationSuccess = (state, { steps, value }) =>
  produce(state, (draft) => {
    if (!draft.single.steps) {
      draft.single.steps = {};
    }

    steps.forEach((stepId) => {
      const step = draft.single.steps[stepId];
      if (step) {
        draft.single.steps[stepId].isActive = value;
      }
    });
  });

export const insertGroupSuccess = (state, { testId, group, atIndex }) =>
  produce(state, (draftState) => {
    const position = isNil(atIndex) ? state.single.test[testId].groups.length : atIndex;

    draftState.single.tests[testId].groups = insert(
      position,
      group.id,
      state.single.tests[testId].groups,
    );

    group.steps.forEach((step) => {
      if (!draftState.single.steps) {
        draftState.single.steps = {};
      }

      if (!draftState.single.steps[step.id]) {
        draftState.single.steps[step.id] = step;
        draftState.elementsScreenshots[step.id] = step.screenshot;
      }
    });

    if (!draftState.single.groups) {
      draftState.single.groups = {};
    }
    draftState.single.groups[group.id] = {
      ...group,
      testsCount: group.testsCount + 1,
      steps: group.steps.map(prop('id')),
    };
  });

export const pasteStepsSuccess = (state, { testId, group, atIndex }) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    if (!draft.single.tests[testId].groups.includes(group.id)) {
      // If groups doesn not exists
      return insertGroupSuccess(state, { testId, group, atIndex });
    }
    draft.single.groups[group.id].steps = group.steps.map(prop('id'));

    for (let index = 0; index < group.steps.length; index += 1) {
      const step = group.steps[index];
      draft.single.steps[step.id] = step;
    }

    draft.elementsScreenshots = getElementsScreenshotsFromSteps(draft.single.steps);
    updateUnconfirmedGroupsIndiecesFromIndex(draft, group.id, group.steps.length, atIndex);
  });

const clearSingleRequest = (state) =>
  produce(state, (draft) => {
    draft.single = {};
  });

const stopSuccess = (state, { ids }) => testRunStopped(state, { data: ids });

const removeUnconfirmedGroupSuccess = (state, { id, testId }) =>
  produce(state, (draft) => {
    if (draft.single.unconfirmedGroups) {
      draft.single.unconfirmedGroups = omit([id], draft.single.unconfirmedGroups);
      draft.single.tests[testId].unconfirmedGroups = without(
        [id],
        draft.single.tests[testId].unconfirmedGroups,
      );
    }
    draft.single.tests[testId].isRecording = false;
  });

const confirmUnconfirmedGroupSuccess = (state, action) => {
  const { id, groupId, index, steps, testId } = action;
  const unconfirmedGroupIndex = state.single.tests[testId].unconfirmedGroups.findIndex(equals(id));
  const stateWithoutUnconfirmedGroup = removeUnconfirmedGroupSuccess(state, {
    id,
    testId,
  });
  const atIndex = index;
  const stepsMap = convertListToObject(steps);

  return produce(stateWithoutUnconfirmedGroup, (draft) => {
    draft.single.groups[groupId].steps = insertAll(
      atIndex,
      Object.keys(stepsMap),
      draft.single.groups[groupId].steps,
    );
    draft.single.steps = mergeRight(draft.single.steps, stepsMap);

    updateUnconfirmedGroupsIndiecesFromIndex(
      draft,
      groupId,
      steps.length,
      atIndex,
      null,
      unconfirmedGroupIndex,
    );
  });
};

const startRunningSuccess = (state, { testId, testRun }) =>
  produce(state, (draft) => {
    if (draft.single.id === testId) {
      draft.single.tests[testId].testRun = testRun;
    }
  });

const stopRunningSuccess = (state, { testId, testRun }) =>
  produce(state, (draft) => {
    if (draft.single.id === testId) {
      draft.single.tests[testId].testRun = testRun;
    }

    if (path(['tests', testId], draft.list)) {
      draft.list.tests[testId].lastUnfinishedUserRun = testRun;
      draft.list.tests[testId].testRun = testRun;

      // TODO: Remove if BE returns status properly
      draft.list.tests[testId].testRun.status = RUN_STATUS.STOPPED;
      draft.list.tests[testId].lastUnfinishedUserRun.status = RUN_STATUS.STOPPED;
    }
  });

const changed = (state, { data }) =>
  produce(state, (draft) => {
    if (draft.single.id === data.testId) {
      draft.changeNotificationByTestId[data.testId] = data.triggeredBy;
    }
  });

const clearChangedNotification = (state, { testId }) =>
  produce(state, (draft) => {
    draft.changeNotificationByTestId[testId] = null;
  });

const createTemporaryStepSuccess = (state, { groupId, step }) =>
  produce(state, (draft) => {
    if (!draft.single.steps) {
      draft.single.steps = {};
    }

    if (!draft.single.groups) {
      draft.single.groups = {};
    }

    draft.single.steps[step.id] = step;

    draft.single.groups[groupId].steps = insert(
      step.atIndex,
      step.id,
      draft.single.groups[groupId].steps,
    );

    draft.currentStepId = step.id;

    updateUnconfirmedGroupsIndiecesFromIndex(draft, groupId, 1, step.atIndex);
  });

const removeTemporaryStep = (state, { temporaryStepId, groupId, newStepId = null }) =>
  produce(state, (draft) => {
    const stepIndex = state.single.groups[groupId].steps.findIndex(equals(temporaryStepId));
    delete draft.single.steps[temporaryStepId];

    draft.single.groups[groupId].steps = without(
      [temporaryStepId],
      draft.single.groups[groupId].steps,
    );

    if (draft.currentStepId === temporaryStepId) {
      draft.currentStepId = newStepId;
    }

    updateUnconfirmedGroupsIndiecesFromIndex(draft, groupId, -1, stepIndex);
  });

const getDefaultStepsParamsSuccess = (state, { data }) =>
  produce(state, (draft) => {
    draft.defaultStepsParams = groupDefaultStepsParams(data);
  });

const removePartialsSuccess = (state, { id, steps, groups }) => {
  const stateWithoutSteps = removeStepsSuccess(state, { stepsIds: steps });
  return produce(stateWithoutSteps, (draft) => {
    if (groups.length) {
      draft.single.groups = omit(groups, draft.single.groups);
      draft.single.tests[id].groups = without(groups, draft.single.tests[id].groups);
    }
  });
};

const setCurrentStepId = (state, { id }) =>
  produce(state, (draft) => {
    draft.currentStepId = id;
  });

const toggleCurrentStepId = (state, { id }) =>
  produce(state, (draft) => {
    draft.currentStepId = draft.currentStepId === id ? null : id;
  });

export const reducer = createReducer(INITIAL_STATE, {
  [TestTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [TestTypes.GET_LIST_SUCCESS]: getListSuccess,
  [TestTypes.REMOVE_SUCCESS]: removeSuccess,
  [TestTypes.START_RECORDING_SUCCESS]: startRecordingSuccess,
  [TestTypes.STOP_RECORDING_SUCCESS]: stopRecordingSuccess,
  [TestTypes.STOP_SUCCESS]: stopSuccess,
  [TestTypes.NEW_STEPS_SET]: newStepsSet,
  [TestTypes.INSERT_STEPS]: insertSteps,
  [TestTypes.UPDATE_STEPS_POSITION_SUCCESS]: updateStepsPositionSuccess,
  [TestTypes.UPDATE_GROUP_POSITION_SUCCESS]: updateGroupPositionSuccess,
  [TestTypes.UPDATE_STEP]: updateStep,
  [TestTypes.STEP_ELEMENT_SCREENSHOT_RECORDED]: updateStepElementScreenshot,
  [TestTypes.STEP_ELEMENT_SCREENSHOT_UPDATED]: updateStepElementScreenshot,
  [TestTypes.STEPS_IS_HIDDEN_MODIFIED]: stepsIsHiddenModified,
  [TestTypes.UPDATE_SUCCESS]: updateSuccess,
  [TestTypes.SET_RECORDING_STATE_SUCCESS]: setRecordingStateSuccess,
  [TestTypes.CLONE_GROUP_SUCCESS]: cloneGroupSuccess,
  [TestTypes.REMOVE_STEPS_SUCCESS]: removeStepsSuccess,
  [TestTypes.REMOVE_GROUP_SUCCESS]: removeGroupSuccess,
  [TestTypes.REMOVE_GROUP_RELATION_SUCCESS]: removeGroupRelationSuccess,
  [TestTypes.RENAME_GROUP_SUCCESS]: renameGroupSuccess,
  [TestTypes.SEARCH_COMPONENTS_SUCCESS]: searchComponentsSuccess,
  [TestTypes.SET_IS_SINGLE_LOADING]: setIsSingleLoading,
  [TestTypes.CLEAN_STEPS_SUCCESS]: cleanStepsSuccess,
  [TestTypes.SAVE_STEP_SETTINGS_SUCCESS]: saveStepSettingsSuccess,
  [TestTypes.TOGGLE_STEP_ACTIVE_SUCCESS]: toggleStepActiveSuccess,
  [TestTypes.TOGGLE_STEP_BREAKPOINT_SUCCESS]: toggleStepBreakpointSuccess,
  [TestTypes.CLONE_STEP_SUCCESS]: cloneStepSuccess,
  [TestTypes.SET_GROUP_AS_COMPONENT_FAILURE]: setGroupAsComponentFailure,
  [TestTypes.SET_GROUP_AS_COMPONENT_SUCCESS]: setGroupAsComponentSuccess,
  [TestTypes.UNLINK_COMPONENT_FAILURE]: unlinkComponentFailure,
  [TestTypes.UNLINK_COMPONENT_SUCCESS]: unlinkComponentSuccess,
  [TestTypes.CREATE_STEPS_GROUP_SUCCESS]: createStepsGroupSuccess,
  [TestTypes.SPLIT_GROUP_SUCCESS]: createStepsGroupSuccess,
  [TestTypes.CREATE_STEP_SUCCESS]: createStepSuccess,
  [TestTypes.UPDATE_STEPS_ACTIVATION_SUCCESS]: updateStepsActivationSuccess,
  [TestTypes.INSERT_GROUP_SUCCESS]: insertGroupSuccess,
  [TestTypes.CLEAR_SINGLE_REQUEST]: clearSingleRequest,
  [TestTypes.CONFIRM_UNCONFIRMED_GROUP_SUCCESS]: confirmUnconfirmedGroupSuccess,
  [TestTypes.REMOVE_UNCONFIRMED_GROUP_SUCCESS]: removeUnconfirmedGroupSuccess,
  [TestTypes.START_RUNNING_SUCCESS]: startRunningSuccess,
  [TestTypes.STOP_RUNNING_SUCCESS]: stopRunningSuccess,
  [TestTypes.CHANGED]: changed,
  [TestTypes.CLEAR_CHANGED_NOTIFICATION]: clearChangedNotification,
  [TestTypes.CREATE_TEMPORARY_STEP_SUCCESS]: createTemporaryStepSuccess,
  [TestTypes.CANCEL_TEMPORARY_STEP]: removeTemporaryStep,
  [TestTypes.SAVE_TEMPORARY_STEP_SUCCESS]: removeTemporaryStep,
  [TestTypes.GET_DEFAULT_STEPS_PARAMS_SUCCESS]: getDefaultStepsParamsSuccess,
  [TestTypes.REMOVE_PARTIALS_SUCCESS]: removePartialsSuccess,
  [TestTypes.UPDATE_STEPS_SUCCESS]: updateStepsSuccess,
  [TestTypes.SET_CURRENT_STEP_ID]: setCurrentStepId,
  [TestTypes.TOGGLE_CURRENT_STEP_ID]: toggleCurrentStepId,
  [TestTypes.UPDATED_SUCCESS]: updatedSuccess,
  [TestTypes.PASTE_STEPS_SUCCESS]: pasteStepsSuccess,
  //
  [TestRunTypes.STOPPED]: testRunStopped,
  [TestRunTypes.STOPPED_MULTIPLE]: testRunStopped,
});
