import { RUN_TRIGGER, RUN_ENV } from '~/modules/constans';

import { getFormattedDuration } from './dates';

export const getRelatedTest = (testRun) =>
  // We need to do that, because it is possible that test archive will be null.
  // It is happening because of pending copying process.
  // testArchive should appear at the latest a few seconds *after* running start.
  testRun?.testArchive || testRun?.test;

export const getRelatedSuite = (suiteRun) => suiteRun?.suiteArchive || suiteRun?.suite;

export const getProcessedTestRun = (run) => ({
  duration: getFormattedDuration(run.duration),
  rawDuration: run.duration,
  name: getRelatedTest(run)?.name,
});

export const getProcessedSuiteRun = (run) => ({
  duration: getFormattedDuration(run.duration),
  rawDuration: run.duration,
});

export const isTriggeredByCurrentUser = (run = {}, currentUserId, isLastUnfinishedRun = false) =>
  isLastUnfinishedRun || (run?.triggeredBy === RUN_TRIGGER.USER && run.user?.id === currentUserId);

export const isCloudRun = (run) => run?.runMode === RUN_ENV.SERVER;
