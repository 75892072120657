import { COLOR } from '@bugbug/core/theme';
import styled, { createGlobalStyle } from 'styled-components';

import * as ModalS from '~/components/modals/Modal';
import * as PageS from '~/components/Page';
import * as ProjectS from '~/views/Project/Project.styled';

import LogoSVG from '~/images/logo.svg?react';

export const Document = styled.div`
  display: flex;
  height: 100%;

  @media print {
    flex-direction: column;
    height: auto !important;

    > * {
      flex: none;
      float: none;
    }
  }
`;

export const DocumentHeader = styled.header`
  display: none;
  margin-bottom: 50px;

  @media print {
    height: auto;
    display: block;
  }

  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    margin: 0;

    span {
      font-weight: 400;
    }
  }

  p {
    font-weight: 400;
    font-size: 13px;
    margin: 30px 0 0;
    padding: 0;
  }

  main {
    height: auto;
    width: 100%;
  }
`;

export const Meta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  color: ${COLOR.GRAY_36};
`;

export const Author = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Copyright = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: right;
`;

export const Logo = styled(LogoSVG)`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const PrintableContent = styled.div`
  height: max-content;
  width: 100%;
  overflow: hidden;
  display: none;
  flex-direction: column;

  @media print {
    height: max-content;
    display: block;
    flex: none;

    ~ * {
      display: none !important;
    }
  }
`;

export const PrintStyles = createGlobalStyle`
  @media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  @media print {
    body {
      background: #fff
    }

    #app {
      nav, aside, .Toastify, button  {
        display: none;
      }

      ${ProjectS.Container} {
        flex: none;
        height: max-content;
        display: block;
        overflow: auto;
      }

      ${PageS.Container} {
        display: block;
      }

      ${PageS.Content} {
        padding: 0;
        max-width: 100% !important;
        min-height: max-content !important;
        overflow: hidden;
        display: block;
      }

      ${PageS.GoBackLink}, ${ModalS.Backdrop} {
        display: none !important;
      }
    }
  }

  @page {
    size: a4;
    margin: 30px;
  }
`;
