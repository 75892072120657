import IconComponent from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { animated } from '@react-spring/web';
import { path, ifElse, always, pathOr } from 'ramda';
import styled, { css } from 'styled-components';

const isInline = path(['theme', 'inline']);

const getContentInlineStyles = ifElse(
  isInline,
  () => css`
    flex-direction: row;
    justify-content: flex-start;
  `,
  always(''),
);

export const Container = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const Content = styled(animated.div)`
  width: 100%;
  height: 100%;
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${getContentInlineStyles};
  ${pathOr('', ['theme', 'extraContentStyle'])}
`;

const getIconInlineStyles = ifElse(
  isInline,
  () => css`
    top: 1px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 5px;
  `,
  always(''),
);

const getIconHideErrorTextStyles = ifElse(
  path(['theme', 'hideErrorText']),
  () => css`
    margin: 0;
  `,
  always(''),
);

export const Icon = styled(animated(IconComponent)).attrs(() => ({
  name: 'errorTriangle',
}))`
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: 18px;
  color: ${COLOR.ERROR};
  ${getIconInlineStyles};
  ${getIconHideErrorTextStyles};
`;

export const TextWrapper = styled(animated.span)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
`;

export const ErrorText = styled.span`
  color: ${COLOR.ERROR};
  margin-right: 3px;
`;

export const RetryButton = styled.button`
  border: none;
  box-shadow: none;
  color: ${COLOR.PRIMARY_LIGHT};
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-weight: ${FONT_WEIGHT.BOLD};

  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;
