import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Loader from '@bugbug/core/components/Loader';
import SearchInput from '@bugbug/core/components/SearchInput';
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import * as Yup from 'yup';

import { Content, ErrorInfo, Footer } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { selectIsFreePlan } from '~/modules/organization';
import { selectProfile } from '~/modules/profile/profile.selectors';
import { SuiteActions } from '~/modules/suite/suite.redux';
import { selectSuiteTestList, selectSuiteProfileId } from '~/modules/suite/suite.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { nameValidator, idValidator, VALIDATION_MESSAGE } from '~/utils/validators';

import SuiteContent from './SuiteContent';
import { LoaderContainer, Header } from './SuiteModal.styled';

const SuiteSchema = Yup.object().shape({
  id: idValidator.notRequired(),
  name: nameValidator
    .max(DATA_RESTRICTIONS.SUITE_NAME_MAX_LENGTH, VALIDATION_MESSAGE.MAX_LENGTH)
    .ensure(),
  tests: Yup.array().of(idValidator).default([]),
  autoAddNewTests: Yup.boolean().required(VALIDATION_MESSAGE.REQUIRED),
  autoRetry: Yup.number().required(VALIDATION_MESSAGE.REQUIRED).default(3),
  runInParallel: Yup.boolean().required(VALIDATION_MESSAGE.REQUIRED),
  runProfileId: idValidator,
});

const SuiteModal = ({ data: suite }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { hide } = useModal();
  const suiteId = suite?.id;
  const profileId = useSelector(selectSuiteProfileId(suiteId));
  const currentProfile = useSelector(selectProfile(profileId));
  const isFreePlan = useSelector(selectIsFreePlan);

  useActionState(suiteId ? SuiteActions.updateRequest : SuiteActions.createRequest, {
    onSuccess: hide,
  });

  const getTestsRequestState = useActionState(SuiteActions.getTestsRequest, { reset: false });
  const availableTests = useSelector(selectSuiteTestList);

  useMount(() => {
    dispatch(SuiteActions.getTestsRequest());
  });

  useUnmount(() => {
    getTestsRequestState?.reset?.();
  });

  const handleSubmit = useCallback(
    (values) => {
      const normalizedValues = SuiteSchema.cast(values);

      if (suiteId) {
        dispatch(SuiteActions.updateRequest(suiteId, normalizedValues));
        return;
      }

      analytics.trackEvent(TRACK_EVENT_TYPE.CREATE_SUITE);
      dispatch(SuiteActions.createRequest(normalizedValues));
    },
    [dispatch, suiteId],
  );

  const handleSearch = ({ target }) => setSearchValue(target.value);

  const renderContent = renderWhenTrueOtherwise(
    () => <SuiteContent searchValue={searchValue} availableTests={availableTests} />,
    () => (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    ),
  );

  const initialValues = SuiteSchema.cast({
    autoAddNewTests: false,
    runInParallel: false,
    tests: [],
    runProfileId: currentProfile?.id,
    ...suite,
    autoRetry: isFreePlan ? 0 : suite?.autoRetry,
  });

  const { isLoading } = getTestsRequestState;

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={SuiteSchema}
    >
      {({ isSubmitting, handleSubmit: onSubmit }) => (
        <form data-testid="SuiteModal" onSubmit={onSubmit}>
          <Header>
            {suiteId
              ? t('suiteDetailsModalContent.edit.title', 'Suite settings')
              : t('suiteDetailsModalContent.create.title', 'New suite')}
            <SearchInput
              placeholder={t('suiteDetailsModalContent.searchInputLabel', 'Search tests...')}
              onChange={handleSearch}
            />
          </Header>
          <Content>{renderContent(!isLoading)}</Content>
          <Footer>
            <ErrorInfo isVisible={getTestsRequestState.hasInternalServerError} />
            <Button onClick={hide} disabled={isSubmitting}>
              {t('default.button.cancel')}
            </Button>
            <ActionButton variant={BUTTON_VARIANT.PRIMARY} type="submit" pending={isSubmitting}>
              {suiteId
                ? t('suiteDetailsModalContent.edit.buttonLabel', 'Save')
                : t('suiteDetailsModalContent.create.buttonLabel', 'Create suite')}
            </ActionButton>
          </Footer>
        </form>
      )}
    </Formik>
  );
};

export default SuiteModal;
