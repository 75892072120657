import type { ErrorCode } from '../types/errors';

export const CORE_ERROR_CODE = {
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  EXTENSION_DISCONNECTED: 'EXTENSION_DISCONNECTED',
  EXTENSION_DISCONNECTED_ERROR: 'EXTENSION_DISCONNECTED_ERROR',
  DISCONNECTED_OR_CORS: 'DISCONNECTED_OR_CORS',
};

export const STEP_RUN_ERROR_CODE = {
  ...CORE_ERROR_CODE,
  ASSERT_FAILED: 'ASSERT_FAILED',
  CODE_EXECUTION_ERROR: 'CODE_EXECUTION_ERROR',
  DEBUGGER_DETACHED: 'DEBUGGER_DETACHED',
  ELEMENT_DOES_NOT_EXIST: 'ELEMENT_DOES_NOT_EXIST',
  EVENT_DISPATCH_FAILED: 'EVENT_DISPATCH_FAILED',
  EVENT_DISPATCHED_ON_INVALID_ELEMENT: 'EVENT_DISPATCHED_ON_INVALID_ELEMENT',
  EXTENSION_DOES_NOT_RESPONSE: 'EXTENSION_DOES_NOT_RESPONSE_ERROR',
  FAILED_WAITING_CONDITIONS: 'FAILED_WAITING_CONDITIONS',
  FILE_DOES_NOT_EXIST: 'FILE_DOES_NOT_EXIST',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
  FRAME_DOES_NOT_EXIST: 'FRAME_DOES_NOT_EXIST',
  FRAME_IS_NOT_INITIALIZED: 'FRAME_IS_NOT_INITIALIZED',
  FRAME_LOADS_TOO_LONG: 'FRAME_LOADS_TOO_LONG',
  INITIALIZATION_ERROR: 'INITIALIZATION_ERROR',
  INTERACTION_POSITION_OUT_OF_VIEWPORT: 'INTERACTION_POSITION_OUT_OF_VIEWPORT',
  INVALID_DATA_FORMAT: 'INVALID_DATA_FORMAT',
  INVALID_ELEMENT_SELECTOR: 'INVALID_ELEMENT_SELECTOR',
  INVALID_FIELD_VALUE: 'INVALID_FIELD_VALUE',
  INVALID_MOUSE_INPUT_PARAMS: 'INVALID_MOUSE_INPUT_PARAMS',
  INVALID_OPTION_INDEX: 'INVALID_OPTION_INDEX',
  INVALID_URL: 'INVALID_URL',
  LOGS_UPLOAD_TIMEOUT: 'LOGS_UPLOAD_TIMEOUT',
  MISSING_ELEMENT_SCREENSHOT: 'MISSING_ELEMENT_SCREENSHOT',
  MISSING_GOTO_STEP: 'MISSING_GOTO_STEP',
  MISSING_OPTION_INDEX: 'MISSING_OPTION_INDEX',
  MISSING_OPTION_TEXT: 'MISSING_OPTION_TEXT',
  MISSING_OPTION_VALUE: 'MISSING_OPTION_VALUE',
  MISSING_STEP_SCREENSHOT: 'MISSING_STEP_SCREENSHOT',
  MULTIPLE_OPTIONS_WITH_VALUE: 'MULTIPLE_OPTIONS_WITH_VALUE',
  NEXT_ACTIVE_STEP_WITH_ELEMENT_REQUIRED: 'NEXT_ACTIVE_STEP_WITH_ELEMENT_REQUIRED',
  NEXT_STEP_ELEMENT_REQUIRED: 'NEXT_STEP_ELEMENT_REQUIRED',
  PAGE_LOADING_ERROR: 'PAGE_LOADING_ERROR',
  PROMPT_DOES_NOT_EXIST: 'PROMPT_DOES_NOT_EXIST',
  REQUEST_ERROR: 'REQUEST_ERROR',
  RUNTIME_ERROR: 'RUNTIME_ERROR',
  SCROLL_FAILED: 'SCROLL_FAILED',
  SELECT_OPTION_FAILED: 'SELECT_OPTION_FAILED',
  SELECTOR_REQUIRED: 'SELECTOR_REQUIRED',
  STEP_RUN_INITIALIZATION_ERROR: 'STEP_RUN_INITIALIZATION_ERROR',
  TAB_CLOSED: 'TAB_CLOSED',
  TAKING_SCREENSHOT_TIMEOUT: 'TAKING_SCREENSHOT_TIMEOUT',
  TIMEOUT: 'TIMEOUT',
  TYPED_TEXT_DIFFERENT_THAN_EXPECTED: 'TYPED_TEXT_DIFFERENT_THAN_EXPECTED',
  UNCHANGABLE_ELEMENT: 'UNCHANGABLE_ELEMENT',
  UNEXPECTED_WINDOW_STATE_CHANGE: 'UNEXPECTED_WINDOW_STATE_CHANGE',
  UNHANDLED_PROMPT: 'UNHANDLED_PROMPT',
  UNRECOGNIZED_STEP_TYPE: 'UNRECOGNIZED_STEP_TYPE',
  UNSUPPORTED_BROWSER: 'UNSUPPORTED_BROWSER',
  VALUE_COMPUTING_ERROR: 'VALUE_COMPUTING_ERROR',
  VARIABLE_DOES_NOT_EXIST: 'VARIABLE_DOES_NOT_EXIST',
  VARIABLE_NESTING_LIMIT_EXCEEDED: 'VARIABLE_NESTING_LIMIT_EXCEEDED',
  WEBSOCKET_ERROR: 'WEBSOCKET_ERROR',
  WEBSOCKET_SETUP_ERROR: 'WEBSOCKET_SETUP_ERROR',
  WINDOW_MINIMIZED: 'WINDOW_MINIMIZED',
  WINDOW_CLOSED: 'WINDOW_CLOSED',
  WINDOW_OR_TAB_DOES_NOT_EXIST: 'WINDOW_OR_TAB_DOES_NOT_EXIST',
  INVALID_EXECUTION_RESULT: 'INVALID_EXECUTION_RESULT',
} as const;

export const PAGE_LOADING_ERROR_CODE = {
  // Common Chrome page load errors
  // https://support.google.com/chrome/answer/95669#zippy=%2Cpage-loading-error-codes-and-issues
  // Full list: chrome://network-errors/
  ERR_BAD_SSL_CLIENT_AUTH_CERT: 'ERR_BAD_SSL_CLIENT_AUTH_CERT',
  ERR_CACHE_MISS: 'ERR_CACHE_MISS',
  ERR_CERT_DATE_INVALID: 'ERR_CERT_DATE_INVALID',
  ERR_CONNECTION_REFUSED: 'ERR_CONNECTION_REFUSED',
  ERR_CONNECTION_RESET: 'ERR_CONNECTION_RESET',
  ERR_CONNECTION_TIMED_OUT: 'ERR_CONNECTION_TIMED_OUT',
  ERR_EMPTY_RESPONSE: 'ERR_EMPTY_RESPONSE',
  ERR_INTERNET_DISCONNECTED: 'ERR_INTERNET_DISCONNECTED',
  ERR_NAME_NOT_RESOLVED: 'ERR_NAME_NOT_RESOLVED',
  ERR_NETWORK_CHANGED: 'ERR_NETWORK_CHANGED',
  ERR_SNAP: 'Aw, Snap!',
  ERR_SSL_PROTOCOL_ERROR: 'ERR_SSL_PROTOCOL_ERROR',
} as const;

export const EXCEPTIONS_TO_DUMP_STATE: ErrorCode[] = [
  'EVENT_DISPATCH_FAILED',
  'EVENT_DISPATCHED_ON_INVALID_ELEMENT',
  'INITIALIZATION_ERROR',
  'INTERACTION_POSITION_OUT_OF_VIEWPORT',
  'PAGE_LOADING_ERROR',
  'SCROLL_FAILED',
  'STEP_RUN_INITIALIZATION_ERROR',
  'TAKING_SCREENSHOT_TIMEOUT',
  'TIMEOUT',
  'WINDOW_OR_TAB_DOES_NOT_EXIST',
];
