import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../EditAlertFormValues.types';
import type React from 'react';

import type { AlertActionType } from '@bugbug/core/types/alerts';
import ScrollShadow from '~/components/ScrollShadow';

import EditAlertActionPicker from '../EditAlertActionPicker';
import {
  ALERT_ACTIONS,
  FORM_ACTION_INITIAL_VALUE,
  FUTURE_ALERT_ACTIONS,
} from '../EditAlertModal.constants';
import { ScrollableContent } from '../EditAlertModal.styled';

import EditAlertModalActionEmail from './EditAlertModalActionEmail';
import EditAlertModalActionSlack from './EditAlertModalActionSlack';
import { EditAlertModalActionTeams } from './EditAlertModalActionTeams/EditAlertModalActionTeams';
import EditAlertModalActionWebhook from './EditAlertModalActionWebhook';
import * as S from './EditAlertModalThenContent.styled';

const formByActionType: Record<AlertActionType, React.FC> = {
  sendEmail: EditAlertModalActionEmail,
  sendWebhook: EditAlertModalActionWebhook,
  sendTeamsMessage: EditAlertModalActionTeams,
  sendSlackMessage: EditAlertModalActionSlack,
};

const EditAlertModalThenContent = () => {
  const { t } = useTranslation();
  const { getFieldMeta, getFieldProps, setValues, values } =
    useFormikContext<EditAlertFormValues>();

  const actionTypeFieldProps = getFieldProps('action');
  const ActionForm = formByActionType[actionTypeFieldProps.value] ?? null;

  const onChange = useCallback(
    (event) => {
      setValues({
        ...values,
        action: event.target.value,
        ...FORM_ACTION_INITIAL_VALUE[event.target.value],
      });
      actionTypeFieldProps.onChange(event);
    },
    [setValues, values, actionTypeFieldProps],
  );

  return (
    <S.Container>
      <EditAlertActionPicker
        {...getFieldMeta('action')}
        {...actionTypeFieldProps}
        onChange={onChange}
        items={ALERT_ACTIONS}
        futureItems={FUTURE_ALERT_ACTIONS}
        label={t('editAlertModal.thenPicker.label', 'Then:')}
      />
      <ScrollableContent>
        <ScrollShadow />
        <ActionForm />
      </ScrollableContent>
    </S.Container>
  );
};

export default EditAlertModalThenContent;
