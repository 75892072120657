import { H1 } from '@bugbug/core/theme/typography';
import { useTranslation } from 'react-i18next';

import useModal from '~/hooks/useModal';

import { ExternalLink } from './ExternalLink';
import { externalLinks } from './FirstSuccessfulRunModal.constants';
import * as S from './FirstSuccessfulRunModal.styled';

export const FirstSuccessfulRunModal = () => {
  const modal = useModal();
  const { t } = useTranslation(undefined, { keyPrefix: 'firstSuccessfulRunModal' });

  return (
    <S.Wrapper>
      <S.ConfettiIcon />
      <H1>{t('title', 'Achievement unlocked!')}</H1>
      <S.Subtitle>
        {t(
          'subtitle',
          'Your first automated test passed! Ready to level up? Read our guides and test like a pro.',
        )}
      </S.Subtitle>
      <S.ExternalLinksContainer>
        {externalLinks.map((details) => (
          <ExternalLink key={details.title} {...details} />
        ))}
      </S.ExternalLinksContainer>
      <S.ProceedButton onClick={modal.hide}>{t('awesomeButton', 'Awesome!')}</S.ProceedButton>
    </S.Wrapper>
  );
};
