import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTeams = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 64, height: 64, viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_4786_170092)", children: [
    /* @__PURE__ */ jsx("path", { d: "M44.642 24.3281H61.1734C62.7352 24.3281 64.0013 25.5942 64.0013 27.156V42.2139C64.0013 47.954 59.3481 52.6072 53.608 52.6072H53.5589C47.8188 52.608 43.1649 47.9555 43.1641 42.2154C43.1641 42.2149 43.1641 42.2144 43.1641 42.2139V25.8061C43.1641 24.9898 43.8258 24.3281 44.642 24.3281Z", fill: "#5059C9" }),
    /* @__PURE__ */ jsx("path", { d: "M55.8149 21.3485C59.5139 21.3485 62.5125 18.3498 62.5125 14.6508C62.5125 10.9518 59.5139 7.95312 55.8149 7.95312C52.1158 7.95312 49.1172 10.9518 49.1172 14.6508C49.1172 18.3498 52.1158 21.3485 55.8149 21.3485Z", fill: "#5059C9" }),
    /* @__PURE__ */ jsx("path", { d: "M34.9791 21.3489C40.3222 21.3489 44.6535 17.0175 44.6535 11.6744C44.6535 6.3314 40.3222 2 34.9791 2C29.6361 2 25.3047 6.3314 25.3047 11.6744C25.3047 17.0175 29.6361 21.3489 34.9791 21.3489Z", fill: "#7B83EB" }),
    /* @__PURE__ */ jsx("path", { d: "M47.88 24.3281H20.5922C19.049 24.3663 17.8282 25.647 17.864 27.1903V44.3647C17.6485 53.6257 24.9754 61.3109 34.2361 61.5375C43.4969 61.3109 50.8237 53.6257 50.6082 44.3647V27.1903C50.644 25.647 49.4232 24.3663 47.88 24.3281Z", fill: "#7B83EB" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.1, d: "M35.7245 24.3281V48.3951C35.7171 49.4988 35.0483 50.4903 34.0278 50.9105C33.7029 51.048 33.3536 51.1188 33.0008 51.1189H19.1738C18.9804 50.6277 18.8018 50.1365 18.6529 49.6305C18.1319 47.9226 17.8661 46.1472 17.864 44.3617V27.1858C17.8283 25.645 19.047 24.3663 20.5878 24.3281H35.7245Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M34.2362 24.3281V49.8835C34.2361 50.2363 34.1653 50.5856 34.0278 50.9105C33.6076 51.931 32.6161 52.5999 31.5125 52.6072H19.8734C19.6203 52.1161 19.3822 51.6249 19.1738 51.1189C18.9655 50.6128 18.8018 50.1365 18.6529 49.6305C18.1319 47.9227 17.8661 46.1472 17.864 44.3617V27.1858C17.8283 25.645 19.047 24.3663 20.5878 24.3281H34.2362Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M34.2362 24.3281V46.9068C34.2248 48.4063 33.012 49.6192 31.5124 49.6305H18.6529C18.1319 47.9227 17.8661 46.1472 17.864 44.3617V27.1858C17.8283 25.645 19.047 24.3663 20.5878 24.3281H34.2362Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M32.7478 24.3281V46.9068C32.7364 48.4063 31.5236 49.6192 30.024 49.6305H18.6529C18.1319 47.9227 17.8661 46.1472 17.864 44.3617V27.1858C17.8283 25.645 19.047 24.3663 20.5878 24.3281H32.7478Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.1, d: "M35.721 16.6299V21.3183C35.468 21.3332 35.2299 21.3481 34.9768 21.3481C34.7238 21.3481 34.4857 21.3332 34.2326 21.3183C33.7303 21.285 33.232 21.2053 32.7443 21.0802C29.7303 20.3664 27.2403 18.2527 26.0466 15.3946C25.8412 14.9147 25.6818 14.4163 25.5703 13.9062H32.9973C34.4992 13.9119 35.7153 15.128 35.721 16.6299Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M34.2329 18.1221V21.3222C33.7305 21.2888 33.2323 21.2091 32.7446 21.084C29.7306 20.3703 27.2406 18.2565 26.0469 15.3984H31.5092C33.0111 15.4041 34.2272 16.6202 34.2329 18.1221Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M34.2329 18.1221V21.3222C33.7305 21.2888 33.2323 21.2091 32.7446 21.084C29.7306 20.3703 27.2406 18.2565 26.0469 15.3984H31.5092C33.0111 15.4041 34.2272 16.6202 34.2329 18.1221Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { opacity: 0.2, d: "M32.7446 18.1222V21.084C29.7306 20.3703 27.2406 18.2565 26.0469 15.3984H30.0208C31.5227 15.4042 32.7388 16.6203 32.7446 18.1222Z", fill: "black" }),
    /* @__PURE__ */ jsx("path", { d: "M2.73208 15.3984H30.0199C31.5266 15.3984 32.7481 16.6199 32.7481 18.1266V45.4145C32.7481 46.9212 31.5266 48.1427 30.0199 48.1427H2.73208C1.22534 48.1427 0.00390625 46.9212 0.00390625 45.4145V18.1266C0.00390625 16.6199 1.22537 15.3984 2.73208 15.3984Z", fill: "url(#paint0_linear_4786_170092)" }),
    /* @__PURE__ */ jsx("path", { d: "M23.5551 25.7799H18.1002V40.6339H14.6249V25.7799H9.19531V22.8984H23.5551V25.7799Z", fill: "white" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_4786_170092", x1: 5.69224, y1: 13.2667, x2: 27.0598, y2: 50.2744, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#5A62C3" }),
      /* @__PURE__ */ jsx("stop", { offset: 0.5, stopColor: "#4D55BD" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3940AB" })
    ] }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip0_4786_170092", children: /* @__PURE__ */ jsx("rect", { width: 64, height: 64, fill: "white" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgTeams);
export default ForwardRef;
