import produce from 'immer';
import { normalize } from 'normalizr';
import { omit, propEq, without } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import { VariableTypes } from '~/modules/variable/variable.redux';

import { profileListSchema } from './profile.schema';

export const { Types: ProfileTypes, Creators: ProfileActions } = createActions(
  {
    getListRequest: ['query', 'projectId'],
    getListSuccess: ['profiles'],
    getListFailure: ['error'],
    createRequest: ['profile'],
    createSuccess: ['profile'],
    createFailure: ['error'],
    updateRequest: ['id', 'profile'],
    updateSuccess: ['profile'],
    updateFailure: ['error'],
    deleteRequest: ['id'],
    deleteSuccess: ['id'],
    deleteFailure: ['error'],
    reset: null,
    //
    updated: ['data'],
  },
  { prefix: 'PROFILE/' },
);

export const INITIAL_STATE = {
  profiles: {},
  order: [],
};

export const getListSuccess = (state, { profiles }) =>
  produce(state, (draft) => {
    const data = normalize(profiles, profileListSchema);
    draft.profiles = data.entities.profiles;
    draft.order = data.result;
  });

export const createSuccess = (state, { profile }) =>
  produce(state, (draft) => {
    draft.profiles[profile.id] = profile;
    draft.order.unshift(profile.id);
  });

export const updateSuccess = (state, { profile }) =>
  produce(state, (draft) => {
    draft.profiles[profile.id] = profile;
  });

export const deleteSuccess = (state, { id }) =>
  produce(state, (draft) => {
    draft.profiles = omit([id], draft.profiles);
    draft.order = without([id], draft.order);
  });

export const updated = (state, { data: profile }) =>
  produce(state, (draft) => {
    draft.profiles[profile.id] = profile;
  });

export const updateValuesUsingVariables = (state, { variables }) =>
  produce(state, (draft) => {
    for (let index = 0; index < variables.length; index += 1) {
      const { profileId, ...variable } = variables.at(index);
      if (draft.profiles[profileId]) {
        const variableIndex = draft.profiles[profileId].variables.findIndex(
          propEq('key', variable.key),
        );
        if (variableIndex === -1) {
          draft.profiles[profileId].variables.push(variable);
        } else {
          draft.profiles[profileId].variables.at(variableIndex).value = variable.value;
          draft.profiles[profileId].variables.at(variableIndex).hasSecretValue =
            variable.hasSecretValue;
        }
      }
    }
  });

export const reducer = createReducer(INITIAL_STATE, {
  [ProfileTypes.GET_LIST_SUCCESS]: getListSuccess,
  [ProfileTypes.CREATE_SUCCESS]: createSuccess,
  [ProfileTypes.UPDATE_SUCCESS]: updateSuccess,
  [ProfileTypes.DELETE_SUCCESS]: deleteSuccess,
  [VariableTypes.CREATE_SUCCESS]: updateValuesUsingVariables,
  [VariableTypes.UPDATE_SUCCESS]: updateValuesUsingVariables,
  //
  [ProfileTypes.UPDATED]: updated,
});
