import { lazy } from 'react';

import type { ComponentType } from 'react';

import { reloadIfVersionChanged } from './meta';

type ImportComponent = () => Promise<{ default: ComponentType }>;

export const loadLazy = (importStatement: ImportComponent) => {
  // todo: needs to be updated if after vite 4.4 release
  // https://github.com/vitejs/vite/pull/12084#issuecomment-1588699850
  const lazyWithVersionCheck = async () => {
    try {
      return importStatement();
    } catch (error) {
      console.error('[LazyLoad]', error);
      console.debug('[LazyLoad] Outdated chunk. Reloading page to get latest version');
      await reloadIfVersionChanged();
      return null;
    }
  };

  return lazy(lazyWithVersionCheck as ImportComponent);
};
