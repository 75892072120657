import Loader from '@bugbug/core/components/Loader';
import { KEY_CODES_BINDINGS } from '@bugbug/core/constants/keyBindings';
import * as T from '@bugbug/core/utils/toolbox';
import {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  Suspense,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useEvent, useUnmount } from 'react-use';

import type {
  CustomModalRenderer,
  ActiveModal,
  ModalSize,
  ModalRenderer,
  ModalContextProps,
} from './useModal.types';
import type { KeyboardEventHandler, ReactNode } from 'react';
import type { Modal } from '~/components/modals/modals.types';

import type { Route } from '@bugbug/core/types/routes';
import ModalComponent from '~/components/modals/Modal';
import { MODAL_COMPONENTS } from '~/components/modals/modals.import';
import analytics from '~/services/analytics';

import { LoaderContainer } from './ModalsProvider.styled';
import { isMouseEvent } from './useModal.types';

export const defaultModalContext: ModalContextProps = {
  show: T.noop,
  showCustom: T.noop,
  showWide: T.noop,
  showFullscreen: T.noop,
  showTutorial: T.noop,
  hide: T.noop,
};

const ModalContext = createContext<ModalContextProps>(defaultModalContext);

export const ModalContextProvider = ModalContext.Provider;

// Context provider
interface ModalsProviderProps {
  children: ReactNode;
}

export const ModalsProvider = ({ children }: ModalsProviderProps) => {
  const config = useRef<ActiveModal['config']>({});
  const showRequest = useRef<number>();
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const show = useCallback<ModalRenderer>(
    (type: Modal, props = {}, modalConfig = {}) => {
      if (activeModal?.type === type) return;

      showRequest.current = requestAnimationFrame(() => {
        config.current = modalConfig;
        analytics.trackModalView(type);
        setActiveModal({ type, props });
      });
    },
    [activeModal, setActiveModal],
  );

  const showCustom = useCallback<CustomModalRenderer>(
    (render) => {
      setActiveModal({ render, props: {}, config: {} });
    },
    [setActiveModal],
  );

  const getShowWithSize = useCallback<(size: ModalSize) => ModalRenderer>(
    (size: ModalSize) =>
      (type: Modal, props = {}, modalConfig = {}) => {
        show(type, props, { ...modalConfig, size });
      },
    [show],
  );

  const showWide = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('wide')(...args),
    [getShowWithSize],
  );

  const showFullscreen = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('full-screen')(...args),
    [getShowWithSize],
  );

  const showTutorial = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('tutorial')(...args),
    [getShowWithSize],
  );

  type MaybeEventRouteCallback = (payload?: MouseEvent | Route) => void;

  const hide = useCallback<MaybeEventRouteCallback>(
    (payload?: MouseEvent | Route) => {
      // intentionally we're dropping the mouse event object
      const args = !isMouseEvent(payload) ? payload : undefined;

      config.current?.onHide?.(args!);

      setActiveModal(null);
    },
    [setActiveModal],
  );

  const handleKeyEvent = useCallback<KeyboardEventHandler>(
    (event) => {
      if (!activeModal) {
        return;
      }
      if ([KEY_CODES_BINDINGS.ESC].includes(event.keyCode)) {
        hide();
      }
    },
    [activeModal, hide],
  );
  useEvent('keydown', handleKeyEvent);

  useUnmount(() => {
    if (showRequest.current) {
      cancelAnimationFrame(showRequest.current);
    }
  });

  const history = useHistory();

  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          setActiveModal(null);
        }
      }),
    [history],
  );

  const Fallback = useMemo<NonNullable<ReactNode>>(
    () => (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    ),
    [],
  );

  const contextValue = useMemo<ModalContextProps>(
    () => ({
      show,
      showCustom,
      showWide,
      showFullscreen,
      showTutorial,
      hide,
    }),
    [show, showCustom, showWide, showFullscreen, showTutorial, hide],
  );

  const renderModal = () => {
    if (!activeModal) {
      return null;
    }

    const Content = activeModal.render || MODAL_COMPONENTS[activeModal.type];

    return (
      <ModalComponent size={config.current?.size}>
        <Suspense fallback={Fallback}>
          <Content {...activeModal.props} />
        </Suspense>
      </ModalComponent>
    );
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {renderModal()}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export default useModal;
