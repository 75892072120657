import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div``;

export const ResolutionItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1px;

  > svg {
    width: 20px;
    margin-right: 12px;
    margin-bottom: -2px;
  }
`;

export const Resolution = styled.span`
  margin-top: 1px;
  margin-left: 8px;
  color: ${COLOR.GRAY_12};
  line-height: 19px;
`;

export const Settings = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 20px 10px 34px;
  cursor: pointer;
  border-top: 1px solid ${COLOR.GRAY_9};
  background-color: ${COLOR.GRAY_21};
  color: ${COLOR.DARK_GRAY};
  outline: none;

  &:active {
    outline: none;
  }

  svg {
    opacity: 0.69;
    width: 20px;
    margin-right: 12px;
  }
`;
