import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgStop = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", width: 10, height: 10, role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M0,0h24v24H0V0z", fill: "currentColor" }),
  /* @__PURE__ */ jsx("path", { d: "M6,6h12v12H6V6z", fill: "currentColor" })
] });
const ForwardRef = forwardRef(SvgStop);
export default ForwardRef;
