import IconButtonBase from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import FormFieldBase from '~/components/FormField';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: inherit;
`;

export const FormField = styled(FormFieldBase)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: inherit;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${COLOR.GRAY_18};
  border-radius: 5px;
  margin-top: 8px;
  flex: 1;
  overflow: auto;
`;

const selectedSuiteStyles = css`
  background-color: ${COLOR.PRIMARY_5};
  border-bottom-color: ${COLOR.BORDER_GRAY};
  border-top-color: ${COLOR.BORDER_GRAY};
`;

export const ToggleButton = styled(IconButtonBase)`
  height: 100%;
  flex: none;
  width: 32px;
  color: ${COLOR.PRIMARY_LIGHT};
  margin-top: -1px;
  border-radius: 0;
`;

export const Suite = styled.div<{ selected: boolean }>`
  color: ${COLOR.DARK_GRAY};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR.GRAY_18};
  border-top: 1px solid transparent;
  margin-bottom: -1px;
  padding: 0 0 0 15px;
  display: flex;
  height: 40px;
  flex: none;
  cursor: pointer;

  &:first-child {
    border-top: none;
  }

  // Selected
  ${styleWhenTrue(
    prop('selected'),
    css`
      ${selectedSuiteStyles};

      ${ToggleButton} {
        color: ${COLOR.GRAY_8};
      }
    `,
  )};

  &:hover {
    ${selectedSuiteStyles};
  }
`;
