import { memo, useCallback } from 'react';
import { useLayer, useHover } from 'react-laag';

import type { TooltipStyledProps } from './Tooltip.types';
import type React from 'react';

import { TOOLTIP_ENTER_DELAY } from './Tooltip.constants';
import { Container, Content, ArrowContainer, Arrow, Trigger } from './Tooltip.styled';

export interface TooltipProps extends Partial<TooltipStyledProps> {
  className?: string;
  children: React.ReactNode;
  content: React.ReactNode;
  hideOnScroll?: boolean;
  forceOpen?: boolean;
  overflowContainer?: boolean;
  onOutsideClick?: () => void;
  wrapperClassName?: string;
}

const Tooltip = memo<TooltipProps>(
  ({
    className,
    children,
    content = '',
    hideOnScroll,
    anchor = 'top-center',
    offset = 0,
    forceOpen,
    variant = 'dark',
    overflowContainer,
    onOutsideClick,
    wrapperClassName,
  }) => {
    const [isOpen, hoverProps] = useHover({ delayEnter: TOOLTIP_ENTER_DELAY, hideOnScroll });

    const isOpenAndNotEmpty = forceOpen || (isOpen && !!content);

    const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
      isOpen: isOpenAndNotEmpty,
      placement: anchor,
      overflowContainer,
      onOutsideClick,
    });

    const handleTooltipMouseEnter = useCallback<React.MouseEventHandler<HTMLDivElement>>(
      (event) => {
        if (!forceOpen) {
          hoverProps.onMouseLeave(event);
        }
      },
      [forceOpen, hoverProps],
    );

    const renderTooltip = () =>
      renderLayer(
        <Container
          anchor={arrowProps.layerSide}
          offset={offset}
          className={className}
          data-testid="Tooltip"
          onMouseEnter={handleTooltipMouseEnter}
          role="tooltip"
          {...layerProps}
        >
          <Content variant={variant}>{content}</Content>
          <ArrowContainer variant={variant} {...arrowProps} anchor={arrowProps.layerSide}>
            <Arrow />
          </ArrowContainer>
        </Container>,
      );

    return (
      <>
        <Trigger {...triggerProps} {...hoverProps} className={wrapperClassName}>
          {children}
        </Trigger>
        {isOpenAndNotEmpty && renderTooltip()}
      </>
    );
  },
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
