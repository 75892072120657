import { normalize } from 'normalizr';
import {
  equals,
  find,
  length,
  ifElse,
  prop,
  compose,
  head,
  pipe,
  defaultTo,
  map,
  groupBy,
  when,
  filter,
  either,
} from 'ramda';

import { elementScreenshotSchema } from './test.schema';

export const pickDefaultStepParams = (stepType, stepsParams, matchingParams) =>
  pipe(
    prop(stepType),
    ifElse(
      compose(equals(1), length),
      head,
      find((step) => equals({ ...step, ...matchingParams }, step)),
    ),
    defaultTo({}),
    when(prop('waitingConditions'), ({ waitingConditions, ...other }) => ({
      ...other,
      waitingConditions: filter(either(prop('isGlobal'), prop('isActive')))(waitingConditions),
    })),
  )(stepsParams);

export const groupDefaultStepsParams = compose(map(map(prop('params'))), groupBy(prop('type')));

export const getElementsScreenshotsFromSteps = (steps = {}) => {
  const elementsData = normalize(Object.values(steps), [elementScreenshotSchema]);
  return elementsData.entities.elementsScreenshots || {};
};
