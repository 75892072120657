import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMoneyPixelColor = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 30.5455, height: 30.5455, transform: "matrix(-1 0 0 1 39.2734 8.72656)", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M13.0906 4.36328V8.72692H8.72692V13.0906H4.36328V34.9087H8.72692V13.0906H13.0906V8.72692H34.9087V4.36328H13.0906Z", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.908 8.72656H39.2717V13.0902H34.908V8.72656Z", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M39.2717 34.9084V13.0902H43.6353V34.9084H39.2717Z", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.908 39.272V34.9084H39.2717V39.272H34.908Z", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.908 43.6357V39.272H13.0898V43.6357H34.908Z", fill: "#FFF8EA" }),
  /* @__PURE__ */ jsx("path", { d: "M26.1804 8.72656H21.8168V13.0902H17.4531V21.8175H21.8168V26.1811H26.1804V30.5447H17.4531V34.9084H21.8168V39.272H26.1804V34.9084H30.544V26.1811H26.1804V21.8175H21.8168V17.4538H30.544V13.0902H26.1804V8.72656Z", fill: "#FDCE1C" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.0909 0V4.36364H8.72727V8.72727H4.36364V13.0909H0V34.9091H4.36364V39.2727H8.72727V43.6364H13.0909V48H34.9091V43.6364H39.2727V39.2727H43.6364V34.9091H48V13.0909H43.6364V8.72727H39.2727V4.36364H34.9091V0H13.0909ZM13.0909 4.36364V8.72727H8.72727V13.0909H4.36364V34.9091H8.72727V39.2727H13.0909V43.6364H34.9091V39.2727H39.2727V34.9091H43.6364V13.0909H39.2727V8.72727H34.9091V4.36364H13.0909Z", fill: "#FDCE1C" })
] });
const ForwardRef = forwardRef(SvgMoneyPixelColor);
export default ForwardRef;
