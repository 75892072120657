import { useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';

import type { TypedUseSelectorHook } from 'react-redux';

import createStore from './createStore';

export const store = createStore();

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
