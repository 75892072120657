import api from './common';

export const getList = (params) => api.get(`/schedulers/`, { params });

export const get = (id) => api.get(`/schedulers/${id}/`);

export const create = (data) => api.post('/schedulers/', data);

export const update = (id, data) => api.patch(`/schedulers/${id}/`, data);

export const remove = (id) => api.delete(`/schedulers/${id}/`);

export const clone = (id) => api.post(`/schedulers/${id}/clone/`);

export const runNow = (id, data) => api.post(`/schedulers/${id}/run-now/`, data);

export const runNowMultiple = (data) => api.post(`/schedulers/run-now/`, data);

export const removeSelected = (data) => api.delete(`/schedulers/delete/`, { data });

export const removeAll = (data) => api.delete(`/schedulers/delete/`, { data });
