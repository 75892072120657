import { schema } from 'normalizr';

import testSchema from '~/modules/test/test.schema';
import { getFormattedDuration } from '~/utils/dates';
import { getProcessedTestRun } from '~/utils/runs';

export const stepRunSchema = new schema.Entity(
  'stepsRuns',
  {},
  {
    idAttribute: (value) => value.stepId,
    processStrategy: (stepRun) => ({
      ...stepRun,
      duration: getFormattedDuration(stepRun.duration),
      rawDuration: stepRun.duration,
    }),
  },
);

const testRunSchema = new schema.Entity(
  'testsRuns',
  {
    stepsRuns: [stepRunSchema],
    testArchive: testSchema,
    test: testSchema,
  },
  {
    processStrategy: (testRun) => ({
      ...testRun,
      ...getProcessedTestRun(testRun),
    }),
  },
);
export const testsRunsListSchema = new schema.Array(testRunSchema);
export default testRunSchema;
