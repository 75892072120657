import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMonitorText = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32, viewBox: "0 0 32 32", role: "img", ref, ...props, children: /* @__PURE__ */ jsx("g", { id: "fcad6520-dd6c-4b79-9a0f-4201228dd81b", children: /* @__PURE__ */ jsxs("g", { id: "e3f9d42e-19ae-4732-97c0-73a8a344351f", children: [
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("polyline", { points: "12.38 10.9 12.38 9.72 19.62 9.72 19.62 10.9", style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 14.64, y1: 16, x2: 17.36, y2: 16, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 16, y1: 9.72, x2: 16, y2: 16, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }),
  /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
    fill: "none"
  } }),
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("rect", { x: 6, y: 6, width: 20, height: 14, rx: 2, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 12, y1: 24, x2: 20, y2: 24, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } }),
    /* @__PURE__ */ jsx("line", { x1: 16, y1: 20, x2: 16, y2: 24, style: {
      fill: "none",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2
    } })
  ] }),
  /* @__PURE__ */ jsx("rect", { width: 32, height: 32, style: {
    fill: "none"
  } })
] }) }) });
const ForwardRef = forwardRef(SvgMonitorText);
export default ForwardRef;
