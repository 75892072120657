import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAddGroup = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 20 20", fill: "currentColor", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "add-group-square" }),
  /* @__PURE__ */ jsx("g", { id: "a604bbbe-b99a-4bc4-ae01-11627901d398", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "f41528d3-249c-449f-8919-e56604418465", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("path", { d: "M20,20H0V0H20ZM2,18H18V2H2Z" }),
    /* @__PURE__ */ jsx("path", { d: "M5,9H15v2H5Z", style: {
      fillRule: "evenodd"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M9,5h2V15H9Z", style: {
      fillRule: "evenodd"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgAddGroup);
export default ForwardRef;
