import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import FormField from '~/components/FormField';
import { Header, Footer, Content, ErrorInfo } from '~/components/modals/Modal';
import ScreenSizeDropdown from '~/components/ScreenSizeDropdown';
import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { selectDefaultProfile } from '~/modules/profile/profile.selectors';
import { TestActions } from '~/modules/test/test.redux';

import { FORM_FIELD } from './EditTestModal.constants';
import { TestCreateSchema, TestEditSchema } from './EditTestModal.schema';
import { Form } from './EditTestModal.styled';

const EditTestModal = ({ className, title, id, group, name, profileId, screenSizeType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const defaultProfile = useSelector(selectDefaultProfile);

  const isEdit = !!id;

  const labels = useMemo(() => {
    if (isEdit) {
      return {
        title: t('editTestModal.edit.title.edit', 'Edit test'),
        submitButton: t('editTestModal.edit.submitButton', 'Save test'),
        namePlaceholder: t('editTestModal.edit.name.placeholder', 'Type name here'),
      };
    }
    return {
      title: t('editTestModal.new.title', 'New test'),
      submitButton: t('editTestModal.new.submitButton', 'Create test'),
      nameDescription: t(
        'editTestModal.new.nameInput.description',
        'If you will not define the name it will be generated automatically.',
      ),
      namePlaceholder: t(
        'editTestModal.new.name.placeholder',
        'Type name here or just click ENTER button',
      ),
    };
  }, [t, isEdit]);

  const initialValues = useMemo(
    () => ({
      groupId: group.id,
      testId: group.testId || id,
      [FORM_FIELD.NAME]: name || null,
      [FORM_FIELD.PROFILE]: profileId || defaultProfile?.id,
      [FORM_FIELD.SCREEN_SIZE]: screenSizeType ?? SCREEN_RESOLUTION_TYPE.DESKTOP,
    }),
    [id, name, group, defaultProfile, profileId, screenSizeType],
  );

  const handleSubmit = useCallback(
    (values, formik) => {
      if (isEdit) {
        dispatch(
          TestActions.updateRequest(values.testId, {
            [FORM_FIELD.NAME]: null,
            ...pick([FORM_FIELD.NAME, FORM_FIELD.SCREEN_SIZE], values),
          }),
        );
      } else {
        if (!values.name) {
          // eslint-disable-next-line no-param-reassign
          values.name = null;
        }
        dispatch(TestActions.createRequest(values));
      }
      formik.setSubmitting(true);
    },
    [dispatch, isEdit],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: isEdit ? TestEditSchema : TestCreateSchema,
    onSubmit: handleSubmit,
  });

  const handleSuccess = useCallback(() => {
    formik.setSubmitting(false);
    modal.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setSubmitting, modal.hide]);

  const handleFailure = useCallback((stateErrors) => {
    if (stateErrors) {
      formik.setErrors(stateErrors);
    }
    formik.setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionCreator = isEdit ? TestActions.updateRequest : TestActions.createRequest;

  const requestParams = { reset: false, onSuccess: handleSuccess, onFailure: handleFailure };
  const requestState = useActionState(actionCreator, requestParams);

  useUnmount(() => {
    requestState.reset();
  });

  return (
    <Form className={className} data-testid="EditTestModal" onSubmit={formik.handleSubmit}>
      <Header>{title || labels.title}</Header>
      <Content>
        <FormField
          label={t('editTestModal.name.label', 'Name')}
          description={labels.nameDescription}
        >
          <Input
            {...formik.getFieldProps(FORM_FIELD.NAME)}
            data-hj-allow
            placeholder={labels.namePlaceholder}
            error={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
          />
        </FormField>
        <FormField label={t('editTestModal.screenSizeType.label', 'Screen size')}>
          <ScreenSizeDropdown
            {...formik.getFieldProps(FORM_FIELD.SCREEN_SIZE)}
            onSettingsClick={modal.hide}
          />
        </FormField>
      </Content>
      <Footer>
        <ErrorInfo isVisible={requestState.hasInternalServerError} />
        <Button
          data-testid="EditTestModal.CancelButton"
          onClick={modal.hide}
          disabled={formik.isSubmitting}
        >
          {t('default.button.cancel')}
        </Button>
        <ActionButton
          data-testid="EditTestModal.SubmitButton"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          pending={formik.isSubmitting}
        >
          {labels.submitButton}
        </ActionButton>
      </Footer>
    </Form>
  );
};

EditTestModal.defaultProps = {
  className: null,
  id: undefined,
  group: {
    id: undefined,
    testId: undefined,
  },
  title: '',
  name: '',
};

EditTestModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  profileId: PropTypes.string,
  group: PropTypes.shape({
    id: PropTypes.string,
    testId: PropTypes.string,
  }),
  name: PropTypes.string,
};

export default EditTestModal;
