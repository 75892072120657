import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

import CommandBase from '~/components/Command';

export const Container = styled.div`
  max-width: 472px;
`;

export const CloseButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.DEFAULT,
}))`
  position: relative;
  left: 14px;
`;

export const Paragraph = styled.p`
  margin-bottom: 8px;
`;

export const StepTitle = styled.span`
  display: block;
  padding-top: 8px;
  padding-bottom: 4px;
  font-weight: 700;
`;

export const StepsWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Command = styled(CommandBase)`
  margin-bottom: 8px;
`;
