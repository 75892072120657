export const DATA_RESTRICTIONS = {
  SUITE_NAME_MAX_LENGTH: 100,
  PROJECT_NAME_MAX_LENGTH: 100,
  SCHEDULE_NAME_MAX_LENGTH: 100,
  TEST_NAME_MAX_LENGTH: 255,
  COMPONENT_NAME_MAX_LENGTH: 255,
  VARIABLE_NAME_MAX_LENGTH: 30,
  PROFILE_NAME_MAX_LENGTH: 50,
  ASSERTION_EXPECTED_VALUE_MAX_LENGTH: 10000,
  MAX_FILE_SIZE: 10 * 1024 * 1024,
};
