import Link from '@bugbug/core/components/Link';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Content, Footer, Header } from '~/components/modals/Modal';
import { PaidFeatureBanner } from '~/components/PaidFeatureGuard';
import useModal from '~/hooks/useModal';
import { selectProjectApiToken } from '~/modules/project/project.selectors';
import analytics from '~/services/analytics';

import * as S from './TestCliCommandsModal.styled';

interface TestCliCommandsModalProps {
  data: {
    testId: string;
  };
}

const TestCliCommandsModal = ({ data: { testId } }: TestCliCommandsModalProps) => {
  const { t } = useTranslation();
  const apiToken = useSelector(selectProjectApiToken);
  const modal = useModal();

  const handleCopyInstallCli = useCallback(() => {
    analytics.trackEvent('cli_copied', {
      location: 'run via cli',
    });
  }, []);

  const handleCopyRunCommand = useCallback(() => {
    analytics.trackEvent('run_command_copied', {
      run_type: 'test',
    });
  }, []);

  return (
    <S.Container>
      <Header>{t('testCliCommandsModal.title', 'Run this test via CLI')}</Header>

      <Content>
        <PaidFeatureBanner messageFor="run test via cli" onRedirect={modal.hide} />

        <S.Paragraph>
          {t(
            'testCliCommandsModal.topInfo',
            `Use BugBug.io command line tool to run tests in your continuous integration\u00A0pipelines`,
          )}
        </S.Paragraph>
        <S.StepsWrapper>
          <S.StepTitle>
            {t('testCliCommandsModal.firstStepTitle', '1. Install CLI and configure token')}
          </S.StepTitle>
          <S.Command
            value={t(
              'testCliCommandsModal.configureCliCommand',
              `npm install -g @testrevolution/bugbug-cli && \\\n    bugbug config set-token \\\n    {{apiToken}}`,
              { apiToken },
            )}
            onCopy={handleCopyInstallCli}
          />
          <S.StepTitle>{t('testCliCommandsModal.secondStepTitle', '2. Run test')}</S.StepTitle>
          <S.Command
            value={t('testCliCommandsModal.runTestCommand', 'bugbug remote run test {{testId}}', {
              testId,
            })}
            onCopy={handleCopyRunCommand}
          />
        </S.StepsWrapper>
        <S.Paragraph>
          {t(
            'testCliCommandsModal.bottomInfo',
            'You can also use API commands for listing tests or checking results.',
          )}
          <br />
          {t('testCliCommandsModal.learnMore', 'Learn more in ')}
          <Link
            to={t(
              'testCliCommandsModal.documentationUrl',
              'https://docs.bugbug.io/integrations/api',
            )}
          >
            {t('testCliCommandsModal.documentationLinkLabel', 'documentation')}
          </Link>
        </S.Paragraph>
      </Content>
      <Footer>
        <S.CloseButton onClick={modal.hide}>
          {t('testCliCommandsModal.closeButtonLabel', 'Close')}
        </S.CloseButton>
      </Footer>
    </S.Container>
  );
};

export default TestCliCommandsModal;
